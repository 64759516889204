import { ApiBookableSlotDto } from "../../../api/models/BookableSlots";

export const splitRecommendedBookingSlotFromBookingSlots = (bookingSlots: ApiBookableSlotDto[]) => {
    let mostTravelMinutesSaved = 0;

    for(const bookingSlot of bookingSlots) {
        const { travelMinutesSaved } = bookingSlot;
        if(travelMinutesSaved > mostTravelMinutesSaved) {
            mostTravelMinutesSaved = travelMinutesSaved;
        }
    }

    const bookingSlotsWithMostTravelMinutesSaved = bookingSlots.filter(bookingSlot => bookingSlot.travelMinutesSaved === mostTravelMinutesSaved);

    const bookingSlotWithMostTravelMinutesSaved = bookingSlotsWithMostTravelMinutesSaved.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())[0];

    const regularBookingSlots = bookingSlots.filter(bookingSlot => bookingSlot.resourceId !== bookingSlotWithMostTravelMinutesSaved.resourceId || new Date(bookingSlot.start).getTime() !== new Date(bookingSlotWithMostTravelMinutesSaved.start).getTime());

    return { bookingSlotWithMostTravelMinutesSaved, regularBookingSlots };
};