import { Grid, makeStyles } from "@material-ui/core";
import WidgetPersonalDetails from "./WidgetPersonalDetails";
import { ErrorMessage, useField } from "formik";
import AppFormikCheckBox from "../../components/AppForm/AppFormikCheckbox";

const useStyles = makeStyles((theme) => ({
  hr: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    height: 1,
    border: 0,
    borderTop: "2px solid #dcdcdc",
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
  icon: {
    borderRadius: 3,
    width: 18,
    height: 18,
    boxShadow: `inset 0 0 0 2px ${theme.palette.grey[600]}`,
  },
  radioContainer: {
    display: "flex",
  },
  checkContainer: {
    display: "flex",
  },
  error: {
    marginTop: theme.spacing(1),
    color: "red",
  },
  checkedIcon: {
    color: "white",
    fontSize: "18px",
    backgroundColor: theme.palette.primary.main,
    borderRadius: 3,
    stroke: "white",
    strokeWidth: 0.75,
  },
}));

const WidgetCustomerDetails: React.FC= () => {
  const classes = useStyles();
  const [{ value: isInviteBooking }, ,] = useField<boolean>("isInviteBooking");
  
  return (
    <>
      <Grid container>
        {
          !isInviteBooking && 
          <Grid item xs={12}>
            <WidgetPersonalDetails />
            <hr className={classes.hr}></hr>
          </Grid>
        }
      
        <Grid item xs={12}>
          <p>
            <b>
              Please state that you have read and agreed to our{" "}
              <a
                href="https://assets.website-files.com/60d842d687b38e70ef26b323/61387e8497e84024d00c2956_MBH-Terms-%26-Conditions.pdf"
                className={classes.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Booking Terms
              </a>{" "}
              before you continue.
            </b>
          </p>
          <AppFormikCheckBox
            name="termsAccepted"
            formikName="termsAccepted"
            label="I Agree"
          />
          <div className={classes.error}>
            <ErrorMessage name="termsAccepted" />
          </div>
          <p>
            <b>
              We would like to send you discounts, exclusive offers and updates
              on our business by email, post, SMS, and phone. We will treat your
              data with the greatest of care and it will not be shared with any
              other parties, please refer to our{" "}
              <a
                className={classes.link}
                href="https://assets.website-files.com/60d842d687b38e70ef26b323/61387e6be44f2514a36f2b7c_MBH-Privacy-Policy.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                privacy policy
              </a>{" "}
              for information on how we use your data.
            </b>
          </p>
          <AppFormikCheckBox
            name="marketingAccepted"
            formikName="marketingAccepted"
            label="Yes please"
          />
          <div className={classes.error}>
            <ErrorMessage name="marketingAccepted" />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default WidgetCustomerDetails;
