import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useAuth } from "../auth";
import LogInForm from "../accountManagement/LogInForm";
import { LogInFormValues } from "../accountManagement/LogInForm/LogInFormValues";
import { useState } from "react";
import {errorToast, successToast} from "../toast";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  logo: {
    marginBottom: theme.spacing(2),
    maxWidth: "364px",
  },
}));

const LoginPage: React.FC = () => {
  const auth = useAuth();
  const classes = useStyles();
  const [loginError, setLoginError] = useState(false);
  const [initialValues, setInitialValues] = useState<LogInFormValues>();

  const handleLogin = async (
    { email, password }: LogInFormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setLoginError(false);
    const authResult = await auth.logIn(email, password);

    if (authResult.isError) {
      setLoginError(true);
      setInitialValues({ email, password });
      setSubmitting(false);
    }
  };

  const toastNotification = JSON.parse(localStorage.getItem('toastNotification') ?? "{}");
  if (toastNotification) {
    if (toastNotification.isSuccess) {
      successToast(toastNotification.message);
    } else {
      errorToast(toastNotification.message);
    }
    localStorage.removeItem('toastNotification');
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <img
          className={classes.logo}
          src="/assets/images/mbh-logo.svg"
          alt="My Booking Hub Logo"
        />
        <Typography component="h1" variant="h5" className={classes.title}>
          Sign in
        </Typography>
        <LogInForm
          onSubmit={(loginDetails: LogInFormValues, setSubmitting) =>
            handleLogin(loginDetails, setSubmitting)
          }
          loginError={loginError}
          initialValues={initialValues}
        />
      </div>
    </Container>
  );
};

export default LoginPage;
