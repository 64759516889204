import { useField, useFormikContext } from "formik";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RecurringBookableSlotWithSelection } from "../../../models/RecurringBookableSlot";
import { selectors } from "../../../store";
import { BookingFormValues } from "../BookingFormValues";
import BookingFormRecurringSlotSelectionEditPanel from "./BookingFormRecurringSlotSelectionEditPanel";
import BookingFormRecurringSlotSelectionItem from "./BookingFormRecurringSlotSelectionItem";
import {
  ArrowDropDown as DownArrowIcon,
  ArrowDropUp as UpArrowIcon,
} from "@material-ui/icons";
import AppLoader from "../../../components/AppLoader";
import BookingFormSummaryBox from "../BookingFormSummaryBox/BookingFormSummaryBox";
import { useBookingFormRecurringSlotSelection } from "./useBookingFormRecurringSlotSelection";
import AppFormHelperText from "../../../components/AppForm/AppFormHelperText";
import InformationTextBox from "../../../components/InformationTextBox";
import { makeStyles } from "@material-ui/core";


interface BookingFormRecurringSlotSelectionProps {
  manualOverride: boolean;
  activeStep: number;
}

export const useStyles = makeStyles((theme) => ({
  showMoreButtonToggle: {
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase",
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: "transparent",
    border: "none",
    margin: `1rem auto`,
    cursor: "pointer",
    "& > svg": {
      marginLeft: theme.spacing(1),
    },
  },
  alteredTitle: {
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing(0),
    "& + p": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
    },
  },
}));

const BookingFormRecurringSlotSelection: React.FC<BookingFormRecurringSlotSelectionProps> =
({ manualOverride, activeStep }) => {
    const classes = useStyles();
    const { values } = useFormikContext<BookingFormValues>();
    const [{ value: recurringBookableSlotsValue }, { error: recurringBookableSlotsError }, { setValue: setRecurringBookableSlots }] = useField<RecurringBookableSlotWithSelection[]>("recurringBookableSlots");

    const customerAddress = useSelector(
      selectors.customers.addressById(values.customerAddressId)
    );

    const {
      isLoading,
      recurringSchedule,
      editId,
      handleCheckboxToggle,
      handleSlotEdit,
      setEditId,
      resource
    } = useBookingFormRecurringSlotSelection(
      values,
      customerAddress?.postcode ?? "",
      manualOverride,
      values.recurringNumberOfOccurances ?? 0,
      values.recurringNumberOfWeeksBetweenBookings ?? 0,
      setRecurringBookableSlots,
      values.resourceId
    );
   
    const [isShowingFullSchedule, setIsShowingFullSchedule] = useState(false);

    const toggleFullSchedule = () =>
      setIsShowingFullSchedule((prevState) => !prevState);

    if (!recurringSchedule) return <AppLoader />;

    const recurringSlotsThatRequireAttention = recurringBookableSlotsValue.filter(
      (r) => r.requiresAttention === true
    );

    const isEditingSlotWhichRequiresAttention = editId && recurringBookableSlotsValue.find((item) => item.id === editId && item.requiresAttention === true && item.noSlotsDate);

    return (
      <>
        {manualOverride && resource && (
          <InformationTextBox>
            We advise checking {resource.name}’s calendar for any clashes
          </InformationTextBox>
        )}
        <AppFormHelperText
          error={!!recurringBookableSlotsError}
          helperText={recurringBookableSlotsError}
        />
        {isLoading && <AppLoader />}
        {recurringSlotsThatRequireAttention.length > 0 && (
          <>
            <h2 className={classes.alteredTitle}>
              We altered{" "}
              <b>{recurringSlotsThatRequireAttention.length} timeslots</b>
            </h2>
            <p>
              We do our best to replace any unavailable times. You can uncheck or 
              edit times if the selected times are not suitable.
            </p>
          </>
        )}
        {recurringSlotsThatRequireAttention.map((recurringSlot, i) => (
          <BookingFormRecurringSlotSelectionItem
            key={recurringSlot.start}
            index={i + 1}
            bookingDate={values.date}
            isSelected={recurringSlot.isSelected}
            isManual={manualOverride}
            slot={recurringSlot}
            onEdit={setEditId}
            onToggle={handleCheckboxToggle}
          />
        ))}
        {recurringSlotsThatRequireAttention.length > 0 && (
          <button
            id="showFullScheduleToggle"
            className={classes.showMoreButtonToggle}
            type="button"
            aria-expanded={isShowingFullSchedule}
            aria-controls="fullSchedule"
            onClick={toggleFullSchedule}
          >
            Show Full Schedule
            {isShowingFullSchedule ? <UpArrowIcon /> : <DownArrowIcon />}
          </button>
        )}
        {!isLoading && (
          <>
            <h2 className={classes.alteredTitle}>
              <b>Your schedule details</b>
            </h2>
            <p>
              We do our best to match your booking time. You can uncheck or 
              edit times if the selected times are not suitable.
            </p>
          </>
        )}
        <div
          id="fullSchedule"
          role="region"
          aria-labelledby="showFullScheduleToggle"
        >
          {(isShowingFullSchedule ||
            recurringSlotsThatRequireAttention.length === 0) &&
            recurringBookableSlotsValue.map((recurringSlot, i) => (
              <BookingFormRecurringSlotSelectionItem
                key={recurringSlot.start}
                index={i + 1}
                bookingDate={values.date}
                isSelected={recurringSlot.isSelected}
                isManual={manualOverride}
                slot={recurringSlot}
                onEdit={setEditId}
                onToggle={handleCheckboxToggle}
              />
            ))}
        </div>
        <BookingFormRecurringSlotSelectionEditPanel
          open={!!editId}
          initialDate={
            new Date(
              (isEditingSlotWhichRequiresAttention ? recurringBookableSlotsValue.find((item) => item.id === editId)?.noSlotsDate : recurringBookableSlotsValue.find((item) => item.id === editId)?.start) || new Date()
            )
          }
          appointmentTypeId={values.firstJobAppointmentTypeId ?? 0}
          resourceId={values.resourceId}
          postcode={customerAddress?.postcode ?? ""}
          customerAddressId={customerAddress?.id ?? 0}
          onClose={() => setEditId(undefined)}
          onSelect={handleSlotEdit}
        />
      </>
    );
  };

export default BookingFormRecurringSlotSelection;
