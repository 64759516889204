import { useField } from "formik";
import { AppointmentType } from "../../models/AppointmentType";
import WidgetAppointmentTypeSelect from "./WidgetBookingAppointmentTypeSelect";
import WidgetOptionalExtrasSelect from "./WidgetOptionalExtrasSelect";

interface WidgetStepOne {
  appointmentTypes: AppointmentType[];
  scrollToTop: () => void;
  currentTaxRatePercentage?: number;
  isUpdatingExistingService: boolean;
  setIsUpdatingExistingService: (value: boolean) => void;
}

const WidgetStepOne: React.FC<WidgetStepOne> = ({
  appointmentTypes,
  scrollToTop,
  currentTaxRatePercentage,
  isUpdatingExistingService,
  setIsUpdatingExistingService
}) => {
  const [{ value: selectedService }, ,] = useField<number>(
    "firstJobAppointmentTypeId"
  );
  const [appointmentTypeInputProps] = useField<number>(
    "firstJobAppointmentTypeId"
  );

  const availableOptionalExtras =
    appointmentTypes.find((_) => _.id == appointmentTypeInputProps.value)
      ?.availableOptionalExtras || [];

  if (selectedService <= 0) {
    return (
      <WidgetAppointmentTypeSelect
        appointmentTypes={appointmentTypes}
        scrollToTop={scrollToTop}
        currentTaxRatePercentage={currentTaxRatePercentage}
        isUpdatingExistingService={isUpdatingExistingService}
        setIsUpdatingExistingService={setIsUpdatingExistingService}
      />
    );
  }
  if (selectedService > 0 && availableOptionalExtras.length != 0) {
    return (
      <WidgetOptionalExtrasSelect
        appointmentTypes={appointmentTypes}
        scrollToTop={scrollToTop}
        currentTaxRatePercentage={currentTaxRatePercentage}
      />
    );
  }
  return (
    <WidgetAppointmentTypeSelect
      appointmentTypes={appointmentTypes}
      scrollToTop={scrollToTop}
      currentTaxRatePercentage={currentTaxRatePercentage}
      isUpdatingExistingService={isUpdatingExistingService}
      setIsUpdatingExistingService={setIsUpdatingExistingService}
    />
  );
};

export default WidgetStepOne;
