import { fade, FormHelperText, makeStyles } from "@material-ui/core";
import { Warning as WarningIcon } from "@material-ui/icons";

interface AppFormHelperTextProps {
    error?: boolean;
    helperText?: string;
}

const useStyles = makeStyles((theme) => ({
    formHelperText: {
        display: "flex",
        alignItems: "center",
        backgroundColor: fade(theme.palette.error.main, 0.2),
        padding: theme.spacing(1),
        border: `1px solid ${theme.palette.error.main}`,
        marginTop: theme.spacing(1),
    },
    warningIcon: {
        marginRight: theme.spacing(1)
    }
}));

const AppFormHelperText: React.FC<AppFormHelperTextProps> = ({
    error,
    helperText
}) => {
    const classes = useStyles();

    if(!error) return null;

    return (
        <FormHelperText className={classes.formHelperText} error={error}>
            <WarningIcon className={classes.warningIcon} />
            {helperText}
        </FormHelperText>
    );
};

export default AppFormHelperText;