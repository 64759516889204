import { makeStyles } from "@material-ui/core";
import { FormikValues, useField, useFormikContext } from "formik";
import { FunctionComponent, useEffect, useRef } from "react";
import AppFormHelperText from "../../../components/AppForm/AppFormHelperText";
import AppFormikCheckBox from "../../../components/AppForm/AppFormikCheckbox";
import AppFormikSelect from "../../../components/AppForm/AppFormikSelect";
import { AppSelectOption } from "../../../components/AppForm/AppSelect";
import AppFormikSwitch from "../../../components/AppFormikSwitch";
import { useConfig } from "../../../config/ConfigContext";
import {
  addXWeeksToDate,
  days,
  formatDateSuffix,
  formatMinutesAfterMidnightTo12HourWithAmPm,
} from "../../../dateFormatters";
import { BookingFormValues } from "../BookingFormValues";

const useStyles = makeStyles((theme) => ({
  inlineInput: {
    display: "flex",
    marginBottom: "1.25rem",
    "& .MuiFormControl-root ": {
      marginLeft: "1rem",
      marginRight: "1rem",
      width: "4rem",
    },
  },
  checkboxContainer: {
    marginBottom: "1.5rem",
  },
  verticalAlginContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
}));

interface BookingFormCreateReccuranceFormProps {
  initialValues: BookingFormValues;
}

const BookingFormCreateReccuranceForm: FunctionComponent<BookingFormCreateReccuranceFormProps> =
  ({ initialValues }) => {
    const isMounted = useRef(false);
    const { recurringBookingMaxPeriodWeeks, recurringBookingMaxRepetitions } =
      useConfig();

    const MAX_NUMBER_OF_WEEKS = recurringBookingMaxRepetitions;
    const MAX_WEEKS_BETWEEN_BOOKINGS = recurringBookingMaxPeriodWeeks;

    const { values } = useFormikContext<FormikValues>();
    const classes = useStyles();

    const [
      recurringNumberOfWeeksBetweenBookingsProps,
      recurringNumberOfWeeksBetweenBookingsMeta,
      recurringNumberOfWeeksBetweenBookings,
    ] = useField("recurringNumberOfWeeksBetweenBookings");
    const [
      recurringNumberOfOccurancesProps,
      recurringNumberOfOccurancesMeta,
      recurringNumberOfOccurances,
    ] = useField("recurringNumberOfOccurances");
    const [isRecurringBookingProps] = useField("isRecurringBooking");
    const [, , recurringBookableSlotsHelpers] = useField("recurringBookableSlots");

    const isError =
      (recurringNumberOfOccurancesMeta.touched &&
        !!recurringNumberOfOccurancesMeta.error) ||
      (recurringNumberOfWeeksBetweenBookingsMeta.touched &&
        !!recurringNumberOfWeeksBetweenBookingsMeta.error);

    const firstRecurranceDate = new Date(values.date.valueOf());
    const lastRecurranceDate = addXWeeksToDate(
      values.recurringNumberOfOccurances * values.recurringNumberOfWeeksBetweenBookings || 0,
      firstRecurranceDate
    );
    const lastRecurranceDateStringFormat = `${days[lastRecurranceDate.getDay()]} ${formatDateSuffix(
      lastRecurranceDate
    )}`;
    const lastRecurranceTimeStringFormat = `${formatMinutesAfterMidnightTo12HourWithAmPm(
      values.start
    )} - ${formatMinutesAfterMidnightTo12HourWithAmPm(values.end)}`;

    const numberOfRepetitionOptions: AppSelectOption[] = Array.from(
      Array(MAX_NUMBER_OF_WEEKS).keys()
    ).map((i) => ({
      label: (i + 1).toString(),
      value: i + 1,
    }));

    const recurringWeeksBetweenRepetitionOptions: AppSelectOption[] =
      Array.from(Array(MAX_WEEKS_BETWEEN_BOOKINGS).keys()).map((i) => ({
        label: (i + 1).toString(),
        value: i + 1,
      }));

    useEffect(() => {
      if(isMounted.current) {
        recurringBookableSlotsHelpers.setValue([]);
      }

      isMounted.current = true;
    }, [recurringNumberOfWeeksBetweenBookingsProps.value, recurringNumberOfOccurancesProps.value, isRecurringBookingProps.value]);

    return (
      <div>
        <h2>Create Recurrence</h2>
        <p>
          You can repeat this appointment weekly below. Otherwise press the Next button above.
        </p>

        <div className={classes.checkboxContainer}>
          <AppFormikSwitch
            formikName="isRecurringBooking"
            label="Repeat Booking Weekly?"
          />
        </div>

        {
          <AppFormHelperText
            error={isError}
            helperText={
              "Please enter the number of weeks and times for the bookings."
            }
          />
        }

        {values.isRecurringBooking && (
          <>
            <div className={classes.inlineInput}>
              <span className={classes.verticalAlginContainer}>Times repeated:</span>
              <AppFormikSelect
                name="recurringNumberOfOccurances"
                options={numberOfRepetitionOptions}
              />
              </div><div className={classes.inlineInput}>
              <span className={classes.verticalAlginContainer}>Weeks between bookings:</span>
              <AppFormikSelect
                name="recurringNumberOfWeeksBetweenBookings"
                options={recurringWeeksBetweenRepetitionOptions}
              />
            </div>
            {values.recurringNumberOfWeeksBetweenBookings != null &&
              values.recurringNumberOfWeeksBetweenBookings > 0 && (
                <p>
                  Repeats every <b>{values.recurringNumberOfWeeksBetweenBookings} week{(values.recurringNumberOfWeeksBetweenBookings > 1) && "s"}</b> until{" "}
                  <b>{lastRecurranceDateStringFormat}</b> at <b>{lastRecurranceTimeStringFormat}</b>
                </p>
              )}
          </>
        )}
      </div>
    );
  };

export default BookingFormCreateReccuranceForm;
