import { ErrorMessage, useField } from "formik";
import AppFormikCheckBox from "../../../components/AppForm/AppFormikCheckbox";
import InformationTextBox from "../../../components/InformationTextBox";
import { BookingFields } from "../BookingFormFields";
import { BookingFormStyles } from "../BookingFormStyles";
import {useEffect} from "react";
import {useConfig} from "../../../config/ConfigContext";

interface BookingFormNotesProps {
  isEdit?: boolean;
}

const BookingFormNotes: React.FC<BookingFormNotesProps> = ({ isEdit }) => {
  const classes = BookingFormStyles();
  const [muteBookingConfirmationEmails, , {setValue: setMuteBookingConfirmationEmails}]
      = useField<boolean>("muteConfirmationEmail");
  const vendorConfig = useConfig();

  useEffect(() => {
      setMuteBookingConfirmationEmails(vendorConfig.muteBookingConfirmationEmails);
  }, []);

  return (
    <>
      <InformationTextBox>
        Please fill out any extra details:
      </InformationTextBox>
      <div className={classes.stepContainer}>
        <h3 className={classes.stepContainerTitle}>Additional Information</h3>
        <div className={classes.textAreaContainer}>
          <BookingFields.description readonly={isEdit} />
        </div>
        <h3 className={classes.stepContainerTitle}>Booking Notes</h3>
        <div className={classes.textAreaContainer}>
          <BookingFields.notes />
        </div>
        {!isEdit && (
          <>
            <AppFormikCheckBox
              name="muteConfirmationEmail"
              formikName="muteConfirmationEmail"
              label="Mute Confirmation Email"
            />
            <div>
              <ErrorMessage name="muteConfirmationEmail" />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BookingFormNotes;
