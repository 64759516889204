import { makeStyles } from "@material-ui/core";
import AppButton from "../components/AppButton";
import { PageContainer } from "../components/MainLayout";
import PageHeader from "../components/PageHeader";
import { useConfig } from "../config/ConfigContext";
import { successToast } from "../toast";

const useStyles = makeStyles((theme) => ({
  bodyText: {
    maxWidth: 960,
  },
  codeBlock: {
    display: "block",
    backgroundColor: theme.palette.backgrounds.blue,
    padding: theme.spacing(4),
    width: "100%",
    marginTop: theme.spacing(4),
    overflow: "auto",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const WidgetConfigPage: React.FC = () => {
  const classes = useStyles();
  const { vendorId } = useConfig();

  const codeSnippet = `<iframe src="https://app.mybookinghub.co.uk/widget/${vendorId}" style="width: 100%; height: 800px;"></iframe>`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(codeSnippet);
    successToast("Copied to clipboard");
  };

  return (
    <PageContainer>
      <PageHeader
        title="Install Booking Widget"
        subtitle="Use the options below to install our booking widget on your website"
      />
      <hr />
      <div className={classes.bodyText}>
        <p>
          Our booking widget lets you embed a booking form directly onto your
          own website, allowing your customers to book directly within your
          site. It&apos;s quick and easy to implement, simply copy and paste the
          code below on to your website where you want the widget to show:
        </p>
      </div>
      <pre>
        <code className={classes.codeBlock}>{codeSnippet}</code>
      </pre>
      <div className={classes.buttonContainer}>
        <AppButton onClick={copyToClipboard}>Copy Code</AppButton>
      </div>
    </PageContainer>
  );
};

export default WidgetConfigPage;
