import { Route, Switch, Redirect, useParams } from "react-router-dom";
import { MainLayout } from "../../components/MainLayout";
import ServiceProvidersPage from "../../pages/ServiceProviders";
import BookingsPage from "../../pages/Bookings";
import SingleResourceCalendarPage from "../../pages/SingleResourceCalendar";
import AllResourceCalendarPage from "../../pages/AllResourceCalendar";
import AlgorithmDemoPage from "../../pages/AlgorithmDemo";
import CustomersPage from "../../pages/Customers";
import CustomerDetailPage from "../../pages/CustomerDetail";
import { RequireAdminUserRole } from "../../auth";
import WidgetConfigPage from "../../pages/WidgetConfig";
import DashboardPage from "../../pages/Dashboard";
import ServiceConfigPage from "../../pages/ServiceConfigPage";
import ExtrasConfigPage from "../../pages/ExtrasConfigPage";

export const adminPaths = {
  allResourceCalendar: "/",
  singleResourceCalendar: (resourceId: string | number): string =>
    `/calendar/resource/${resourceId}`,
  serviceProviders: "/mobile-workforce",
  bookings: "/bookings",
  algorithmDemo: "/algorithm-demo",
  customers: "/customers",
  customer: (customerId: string | number): string => `/customers/${customerId}`,
  widgetConfig: "/widget",
  serviceConfig: "/services",
  dashboard: "/dashboard",
  settings: {
    services: "/settings/services",
    extras: "/settings/extras",
  },
};

export const useCustomerId = (): number => {
  const { customerId } = useParams<{ customerId: string }>();
  return parseInt(customerId);
};

// Only available to admin user role
const AdminRoutes: React.FC = () => {
  return (
    <RequireAdminUserRole>
      <MainLayout>
        <Switch>
          <Route path={adminPaths.allResourceCalendar} exact>
            <AllResourceCalendarPage />
          </Route>
          <Route path={adminPaths.singleResourceCalendar(":id")} exact>
            <SingleResourceCalendarPage />
          </Route>
          <Route path={adminPaths.serviceProviders} exact>
            <ServiceProvidersPage />
          </Route>
          <Route path={adminPaths.bookings} exact>
            <BookingsPage />
          </Route>
          <Route path={adminPaths.algorithmDemo} exact>
            <AlgorithmDemoPage />
          </Route>
          <Route path={adminPaths.customers} exact>
            <CustomersPage />
          </Route>
          <Route path={adminPaths.customer(":customerId")}>
            <CustomerDetailPage />
          </Route>
          <Route path={adminPaths.widgetConfig}>
            <WidgetConfigPage />
          </Route>
          <Route path={adminPaths.settings.services}>
            <ServiceConfigPage />
          </Route>
          <Route path={adminPaths.settings.extras}>
            <ExtrasConfigPage />
          </Route>
          <Route path={adminPaths.dashboard}>
            <DashboardPage />
          </Route>
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </MainLayout>
    </RequireAdminUserRole>
  );
};

export default AdminRoutes;
