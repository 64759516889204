import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { exportCustomers } from "../api/customers";
import AppButton from "../components/AppButton";
import { AppSearchBox } from "../components/AppSearchBox";
import { PageContainer } from "../components/MainLayout";
import PageHeader from "../components/PageHeader";
import CreateCustomerModal from "../customers/CreateCustomerModal";
import CustomersTable from "../customers/CustomersTable";
import { actions, selectors } from "../store";

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "flex-between",
  },
  search: {
    width: "100%",
  }
}));

const CustomersPage: React.FC = () => {
  const [createCustomerModal, setCreateCustomerModal] = useState(false);
  const search = useSelector(selectors.customers.selectSearchTerm);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleSearchChange = (value: string) => {
    dispatch(actions.customers.setSearchTerm(value));
  };

  const handleSubmitSearch = () => {
    dispatch(actions.customers.doSearch());
  };

  const handleExportCustomers = async () => {
    const result = await exportCustomers();
    if (!result.isError) {
      const blob = new Blob([atob(result.content)], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "customers-export.csv";
      link.click();
    }
  };

  return (
    <PageContainer>
        <PageHeader
          title="Customers"
          action={() => setCreateCustomerModal(true)}
          actionLabel="Create new customer"
        >
      <div className={classes.header}>
          <AppButton onClick={handleExportCustomers}>
            Export Customers
          </AppButton>
          <AppSearchBox
            className={classes.search}
            placeholder={"Search by names or emails..."}
            onSubmit={handleSubmitSearch}
            onChange={handleSearchChange}
            value={search}
          ></AppSearchBox>
      </div>
        </PageHeader>
      <CustomersTable />
      <CreateCustomerModal
        open={createCustomerModal}
        handleClose={() => setCreateCustomerModal(false)}
      />
    </PageContainer>
  );
};

export default CustomersPage;
