import { Container } from "@material-ui/core";

const PageContainer: React.FC = ({ children }) => {
  return (
    <Container>
      <>{children}</>
    </Container>
  );
};

export default PageContainer;
