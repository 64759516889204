import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Paper,
  makeStyles,
  TableSortLabel,
} from "@material-ui/core";
import { ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { Pagination } from "../models/Pagination";
import { selectors } from "../store";

const useStyles = makeStyles((theme) => ({
  pagination: {
    display: "flex",
    justifyContent: "center",
  },
  table: {
    "& > tbody > tr:nth-child(2n + 1)": {
      backgroundColor: theme.palette.backgrounds.grey,
    },
    "& tr > td, & tr > th": {
      border: "none",
    },
    "& th": {
      fontSize: "1rem",
    },
    "& td": {
      fontSize: "1rem",
    },
  },
  tableContainer: {
    boxShadow: "none",
  },
  cell: {
    whiteSpace: "nowrap",
  },
}));

export interface HeaderOption {
  label: string;
  propertyName?: string;
}

export interface AppTableProps<TRow> {
  headers: HeaderOption[];
  entities: TRow[];
  renderRow: (entity: TRow) => JSX.Element;
  pagination?: Pagination;
  pageSizeOptions?: number[];
  onChangePageNumber?: (pageNumber: number) => void;
  onChangePageSize?: (pageSize: number) => void;
  noEntitiesMessage: string;
  onHeaderClick?: (headerSort: string) => void;
  activeSortBy?: string;
}

const AppTable = <TRow,>({
  headers,
  entities,
  renderRow,
  pagination,
  pageSizeOptions = [10, 25, 100],
  onChangePageNumber,
  onChangePageSize,
  noEntitiesMessage,
  onHeaderClick,
  activeSortBy,
}: AppTableProps<TRow>): JSX.Element => {
  const classes = useStyles();

  if (pagination) {
    if (!onChangePageNumber || !onChangePageSize) {
      console.warn(
        "AppTable: For pagination to work you must provide page number and size change handlers."
      );
    }
  }

  const handleChangePageNumber = (_: unknown, pageNumber: number) => {
    onChangePageNumber && onChangePageNumber(pageNumber + 1);
  };

  const handleChangePageSize = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const pageSize = parseInt(event.target.value);
    onChangePageSize && onChangePageSize(pageSize);
  };

  if (entities.length === 0) {
    return <Typography>{noEntitiesMessage}</Typography>;
  }

  return (
    <>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell className={classes.cell} key={index}>
                  {header.propertyName && onHeaderClick ? (
                    <TableSortLabel
                      onClick={() => onHeaderClick(header.propertyName!)}
                      direction="asc"
                      active={activeSortBy === header.propertyName}
                    >
                      {header.label}
                    </TableSortLabel>
                  ) : (
                    header.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{entities.map(renderRow)}</TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <TablePagination
          className={classes.pagination}
          component="div"
          rowsPerPageOptions={pageSizeOptions}
          count={pagination.totalCount}
          rowsPerPage={pagination.pageSize}
          page={pagination.pageNumber - 1}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={handleChangePageNumber}
          onChangeRowsPerPage={handleChangePageSize}
        />
      )}
    </>
  );
};

export default AppTable;
