import { minutesToHours } from "date-fns/esm";
import {
  ApiDashboardResourceSummary,
  ApiDashboardSummary,
} from "../api/models/Dashboard";
import { convertMinsToHrsMins } from "../dateFormatters";
import {
  DashboardResourceSummary,
  DashboardSummary,
} from "../models/Dashboard";

export const mapFromApiDashboardSummaryDetails = (
  apiDashboardSummary: ApiDashboardSummary
): DashboardSummary => {
  const { totalRevenue, costOfTotalIdleTime } = apiDashboardSummary;

  return {
    ...apiDashboardSummary,
    potentialIncome: costOfTotalIdleTime
      ? parseFloat((totalRevenue + costOfTotalIdleTime).toFixed(2))
      : null,
  };
};

export const mapFromApiResourceSummary = (
  apiResourceSummary: ApiDashboardResourceSummary
): DashboardResourceSummary => {
  return {
    ...apiResourceSummary,
    averageRevenuePerDay: parseFloat(
      apiResourceSummary.averageRevenuePerDay.toFixed(2)
    ),
  };
};
