import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApiResponseWrapper from "../../components/ApiResponseWrapper";
import AppTable from "../../components/AppTable";
import { actions, selectors, useDispatchEffect } from "../../store";
import EditBookingDrawer from "../EditBookingDrawer/EditBookingDrawer";
import BookingsTableRow from "./BookingsTableRow";

const BookingsTable: React.FC = () => {
  useDispatchEffect(actions.bookings.fetchAll);
  const dispatch = useDispatch();

  const { isFetching, isError } = useSelector(selectors.bookings.apiState);
  const [isEditModalOpen, setisEditModalOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState<number>();

  const bookings = useSelector(selectors.bookings.all);
  const pagination = useSelector(selectors.bookings.pagination);
  const noBookingsMessage = "There are currently no bookings.";

  const handleChangePageNumber = (pageNumber: number) => {
    dispatch(actions.bookings.setPageNumber(pageNumber));
  };

  const handleChangePageSize = (pageSize: number) => {
    dispatch(actions.bookings.setPageSize(pageSize));
  };

  const handleEditSelect = (id: number) => {
    setSelectedBooking(id);
    setisEditModalOpen(true);
  };

  const handleEditClose = () => {
    dispatch(actions.bookings.fetchAll());
    setisEditModalOpen(false);
    setSelectedBooking(undefined);
  };

  return (
    <>
      <ApiResponseWrapper isFetching={isFetching} isError={isError}>
        <AppTable
          headers={[
            { label: "ID" },
            { label: "Status" },
            { label: "Date" },
            { label: "Time" },
            { label: "Customer" },
            { label: "Mobile Worker" },
            { label: "Total Price" },
            { label: "" },
          ]}
          entities={bookings}
          noEntitiesMessage={noBookingsMessage}
          pagination={pagination}
          onChangePageNumber={handleChangePageNumber}
          onChangePageSize={handleChangePageSize}
          renderRow={(booking) => (
            <BookingsTableRow
              key={booking.id}
              booking={booking}
              handleEdit={() => handleEditSelect(booking.id)}
            />
          )}
        />
      </ApiResponseWrapper>
      <EditBookingDrawer
        open={isEditModalOpen}
        id={selectedBooking ?? 0}
        handleClose={handleEditClose}
      />
    </>
  );
};

export default BookingsTable;
