import { HttpClientResponse } from "./common/httpClient";
import {
  ApiPaginatedResponse,
  buildApiUrl,
  doErrorToastIfRequired,
  httpClient,
  mapHttpClientListResponse,
  mapHttpClientPaginatedResponse,
} from "./common";
import { ApiListResponse } from "./common/ApiListResponse";
import { ApiAppointmentType } from "./models/AppointmentType";
import { AppointmentType, OptionalExtra } from "../models/AppointmentType";

export const getAllAppointmentTypes = async (): Promise<
  HttpClientResponse<ApiListResponse<AppointmentType>>
> => {
  const url = buildApiUrl("api-vendor/appointment-types");
  const response = await httpClient.getRequest<
    ApiListResponse<ApiAppointmentType>
  >(url);
  doErrorToastIfRequired(response);
  return mapHttpClientListResponse(response, (m) => m);
};

export const getAppointmentTypesByPostcode = async (
  postcode: string
): Promise<HttpClientResponse<ApiListResponse<AppointmentType>>> => {
  const url = buildApiUrl(`api-vendor/appointment-types/${postcode}`);
  const response = await httpClient.getRequest<
    ApiListResponse<ApiAppointmentType>
  >(url);
  doErrorToastIfRequired(response);
  return mapHttpClientListResponse(response, (m) => m);
};

export const getPublicAppointmentTypesByPostcode = async (
  postcode: string
): Promise<HttpClientResponse<ApiListResponse<AppointmentType>>> => {
  const url = buildApiUrl(`api-public/appointment-types/${postcode}`);
  const response = await httpClient.getRequest<
    ApiListResponse<ApiAppointmentType>
  >(url);
  doErrorToastIfRequired(response);
  return mapHttpClientListResponse(response, (m) => m);
};

export const getAllPublicAppointmentTypes = async (): Promise<
  HttpClientResponse<ApiListResponse<AppointmentType>>
> => {
  const url = buildApiUrl(`api-public/appointment-types`);
  const response = await httpClient.getRequest<
    ApiListResponse<ApiAppointmentType>
  >(url);
  doErrorToastIfRequired(response);
  return mapHttpClientListResponse(response, (m) => m);
};



export const getAllExtras = async (): Promise<
  HttpClientResponse<ApiPaginatedResponse<OptionalExtra>>
> => {
  const queryParameters = [`pageIndex=1`, `pageSize=10000`, `searchTerm=`];
  const url = buildApiUrl(`api/extras?${queryParameters.join("&")}`);
  const response = await httpClient.getRequest<
    ApiPaginatedResponse<OptionalExtra>
  >(url);
  doErrorToastIfRequired(response);
  return mapHttpClientPaginatedResponse(response, (m) => m);
};
