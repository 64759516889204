import { Theme, makeStyles } from "@material-ui/core";
interface StyleProps {
  hasIcon: boolean;
  isExpanded: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  card: {
    width: "100%",
    padding: "20px",
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.backgrounds.lightBlue,
    borderRadius: 2,
    display: "flex",
    flexDirection: "column",

    gap: theme.spacing(0.5),
    [theme.breakpoints.up("sm")]: {
      minHeight: "320px",
    }
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100px",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      // justifyContent: "space-between",
    },
  },
  title: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    overflow: "hidden",
    overflowWrap: "break-word",
    "& h2": {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      // maxWidth: "66%",
    },
  },
  actions: {
    display: "none",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginRight: -15,
    "& > :not(:last-child)": {
      marginRight: theme.spacing(0.5),
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "33%",
      justifyContent: "flex-end",
    }
  },
  meta: {
    display: "flex",
    justifyContent: "space-between",
    color: theme.palette.grey[500],
    "& > div": {
      display: "flex",
      alignItems: "center",
    },
  },
  description: {
    [theme.breakpoints.up("sm")]: {
      minHeight: "150px",
    }
  },
  footer: {
    display: "flex",
    minHeight: "50px",
  },

  timerIcon: {
    marginRight: 5,
    height: 20,
    width: 20,
    color: theme.palette.primary.main,
  },

  accountIcon: {
    marginRight: 15,
    height: 40,
    width: 40,
    color: theme.palette.primary.main,
  },
  availableSwitch: {
    marginRight: -20,
    color: theme.palette.primary.main,
  },
  extras: {
    width: "100%",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    "& ul": {
      paddingLeft: theme.spacing(3),
    }
  },
  extrasList: {
    display: "none",
    columns: 2,
    "& > li": {
      marginBottom: theme.spacing(0.5),
      fontSize: 11,
      fontWeight: 400,
    },
  },
  // isExpanded MUST come AFTER extras & extrasList in order to override the display: none
  isExpanded: {
    display: "block",
  },
  toggler: {
    width: "100%",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
    "& > span": {
      color: theme.palette.grey[500],
    },
  },
}));
