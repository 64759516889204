import { makeStyles } from "@material-ui/core";
import { useField } from "formik";
import {
  days,
  formatDateSuffix,
  formatMinutesAfterMidnightTo12HourWithAmPm,
} from "../../dateFormatters";
import { AppointmentType, OptionalExtra } from "../../models/AppointmentType";
import { BookedExtra, Jobs } from "../../models/Booking";
import { formatExtraName } from "../../stringFormatters";
import { useEffect, useState } from "react";
import { calculateBasketTotal, calculateServiceTotal, excludeVATFromPriceIfRequired, formatNumber} from "../../numericalUtils";

// import OptionalExtrasListView from "../components/booking/optional-extras/index";
// import OptionalExtrasListViewItem from "../components/booking/optional-extras/OptionalExtrasListViewItem";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(4),
    backgroundColor: theme.palette.grey[200],
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down(599)]: {
      marginLeft: theme.spacing(0),
    },
  },
  summaryDescription: {
    marginBottom: theme.spacing(4),
    lineHeight: "20px",
    fontWeight: 420,
  },
  containerRowTitle: {
    color: "#000",
    fontWeight: "bold",
    fontSize: "1.25em",
    marginBottom: theme.spacing(2.5),
  },
  containerRow: {
    lineHeight: "9px",
    "& > *:last-child": {
      borderBottom: "2px solid #dcdcdc",
      paddingBottom: theme.spacing(2),
    },
    "& p": {
      lineHeight: "1.5",
    },
  },
  serviceCost: {
    marginLeft: "auto",
    "& p": {
      fontWeight: "bold",
    }
  },
  serviceName: {
    alignItems: "center",
    display: "flex",
    "& p": {
      marginBottom: theme.spacing(2),
    },
    marginTop: theme.spacing(-2),
  },
  dateAddress: {
    lineHeight: "20px",
  },
  totalCostContainer: {
    display: "flex",
    marginTop: theme.spacing(3),
  },
  totalCostText: {
    marginTop: theme.spacing(0.75),
    marginLeft: "auto",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down(599)]: {
      flexGrow: 1,
    },
  },
  totalCostNumber: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  optionalExtra: {
    marginTop: theme.spacing(2),
  },
  optionalList: {
    paddingBottom: theme.spacing(1),
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  appointment: {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "18px",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  optional: {
    fontWeight: 300,
    marginBottom: theme.spacing(1),
    lineHeight: "18px",
  }
}));

interface WidgetSummaryProps {
  appointmentTypes: AppointmentType[];
  selectedOptionalExtras: OptionalExtra[];
  bookedExtras: BookedExtra[];
  currentTaxRatePercentage?: number;
  basket: Jobs[];
}

const WidgetSummary: React.FC<WidgetSummaryProps> = ({
  appointmentTypes,
  selectedOptionalExtras,
  bookedExtras,
  currentTaxRatePercentage,
  basket
}) => {
  const classes = useStyles();

  const [{ value: date }, ,] = useField<Date>("date");
  const [{ value: selectedService }, ,] = useField<number>(
    "firstJobAppointmentTypeId"
  );
  const [{ value: start }, ,] = useField<number>("start");
  const [{ value: end }, ,] = useField<number>("end");
  const [{ value: firstName }, ,] = useField<string>("firstName");
  const [{ value: lastName }, ,] = useField<string>("lastName");
  const [{ value: email }, ,] = useField<string>("email");
  const [{ value: phoneNumber }, ,] = useField<string>("phoneNumber");
  const [{ value: addressLine1 }, ,] = useField<string>("addressLine1");
  const [{ value: addressLine2 }, ,] = useField<string>("addressLine2");
  const [{ value: addressLine3 }, ,] = useField<string>("addressLine3");
  const [{ value: town }, ,] = useField<string>("town");
  const [{ value: postcode }, ,] = useField<string>("postcode");
  const [{ value: company }, ,] = useField<string>("company");
  const [{ value: isInviteBooking }, ,] = useField<boolean>("isInviteBooking");

  const [serviceTotal, setServiceTotal] = useState<number>(0);

  const vatRate = currentTaxRatePercentage ?? 0;

  const selectedAppointment = appointmentTypes.find(
    (appointment) => appointment.id === selectedService
  );

  useEffect(() => {
    setServiceTotal(
      calculateServiceTotal(
        selectedOptionalExtras,
        bookedExtras,
        selectedAppointment?.price ?? 0
      )
    );
  }, [selectedAppointment, bookedExtras]);
  
  function sumOfBookings() : number{
    const tempSelectedAppointment = appointmentTypes.find(_ => _.id === selectedService);

    if(!tempSelectedAppointment) return 0;

    const selectedAppointmentPrice = excludeVATFromPriceIfRequired(tempSelectedAppointment.price, currentTaxRatePercentage ?? 0);

    const tempSelectedOptionalExtras = selectedOptionalExtras.map(_ => excludeVATFromPriceIfRequired(_.price, currentTaxRatePercentage ?? 0));

    const tempSelectedOptionalExtrasPrice = tempSelectedOptionalExtras && tempSelectedOptionalExtras.length > 0 ? tempSelectedOptionalExtras.reduce((a,b) => a+b) : 0;

    return parseFloat(tempSelectedOptionalExtrasPrice.toFixed(2)) + parseFloat(selectedAppointmentPrice.toFixed(2));
  };

  const priceExcludingVat = sumOfBookings();

  const renderServices = () => {
    return basket.map((service) => {
      const appointment = appointmentTypes.find(e => e.id === service.appointmentTypeId);
      return (
        <div key={`service-id-${service.appointmentTypeId}`}>
          <div className={classes.optionalList}>
            <p className={classes.appointment}>
              <span>{appointment?.name}</span>
              <span>&pound;{excludeVATFromPriceIfRequired(appointment?.price ?? 0, currentTaxRatePercentage ?? 0).toFixed(2)} {currentTaxRatePercentage ? ` (ex. VAT)` : ``}</span>
            </p>
          </div>
          {service.optionalExtras.length > 0 && (
            <div className={classes.optional}>
              {service.optionalExtras.map((extra) =>
                  renderServiceExtras(service.appointmentTypeId, extra)
              )}
            </div>
          )}
        </div>
      );
    });
  };

  const renderServiceExtras = (appointmentTypeId: number, optionalExtra: BookedExtra) => {
    const extra = appointmentTypes
      .find(app => app.id === appointmentTypeId)
      ?.availableOptionalExtras
      .find(ex => ex.id === optionalExtra.optionalExtraId);

      let extraLabel = `` + extra?.name;
      if (optionalExtra.quantity > 1) {
        extraLabel += ` (x${optionalExtra.quantity})`;
      }
    const price = excludeVATFromPriceIfRequired(optionalExtra.price * optionalExtra.quantity, currentTaxRatePercentage ?? 0);

    return (
      <div className={classes.optionalList} key={`service-extra-id-${optionalExtra.optionalExtraId}`}>
        <span>{extraLabel}</span>
        <span>&pound;{price.toFixed(2)} {currentTaxRatePercentage ? ` (ex. VAT)` : ``}</span>
      </div>
    );
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.containerRow}>
          <h2>Booking Summary</h2>
          <div className={classes.summaryDescription}>
            <p>
              A summary of your booking is outlined below. Please double check
              your details before proceeding.
            </p>
          </div>
          {
            !isInviteBooking && <>
              <div className={classes.containerRowTitle}>
                <p>Your Contact Details</p>
              </div>
              <p>
                <b>
                  {firstName} {lastName}
                </b>
              </p>
              <p>{company}</p>
              <p>{email}</p>
              <p>{phoneNumber}</p>
            </>
          }
        </div>

        <div className={classes.containerRow}>
          <div className={classes.containerRowTitle}>
            <p>When &amp; Where</p>
          </div>
          <p>
            <b>
              {days[date.getDay()]} {formatDateSuffix(date)} |{" "}
              {formatMinutesAfterMidnightTo12HourWithAmPm(start)} -{" "}
              {formatMinutesAfterMidnightTo12HourWithAmPm(end)}
            </b>
          </p>
          {
            !isInviteBooking && 
            <p>
              {addressLine1}, {addressLine2 && `${addressLine2}, `}
              {addressLine3 && `${addressLine3}, `}
              {town}, {postcode}
            </p>
          }
        </div>

        <div className={classes.containerRow}>
          <div className={classes.containerRowTitle}>
            <p>Your Booking</p>
          </div>
          { renderServices() }
        </div>
        
        <div>
          <div className={classes.containerRowTitle}></div>
          {
            vatRate ?
              <>
              <div className={classes.serviceName}>
                <p>
                  <b className={classes.containerRowTitle}>Sub Total (ex. VAT)</b>
                </p>
                <div className={classes.serviceCost}>
                  <p>{formatNumber(priceExcludingVat, { currency: true })}</p>
                </div>
              </div>

              <div className={classes.serviceName}>
                <p>
                  <b className={classes.containerRowTitle}>VAT</b>
                </p>
                <div className={classes.serviceCost}>
                  <p>{formatNumber(serviceTotal - priceExcludingVat, { currency: true })}</p>
                </div>
              </div>
              </>
              :
              <></>
          }
      
          <div className={classes.serviceName}>
            <p>
              <b className={classes.containerRowTitle}>Total</b>
            </p>
            <div className={classes.serviceCost}>
              <p>{formatNumber(calculateBasketTotal(basket), { currency: true })}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WidgetSummary;
