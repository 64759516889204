import { makeStyles } from "@material-ui/core";

interface MicrositeHeaderProps {
  imageUrl?: string;
  imageDesc?: string;
}

const useStyles = makeStyles((theme) => ({
  headerImage: {
    position: "relative",
    width: "100%",
    height: 260,
    backgroundColor: theme.palette.grey[100],
    "& > img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      objectPosition: "center",
    },
    [theme.breakpoints.up("md")]: {
      height: 400,
    },
  },
}));

const MicrositeHeader: React.FC<MicrositeHeaderProps> = ({
  imageUrl,
  imageDesc,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.headerImage}>
      {<img src={imageUrl} alt={imageDesc} />}
    </div>
  );
};

export default MicrositeHeader;
