import { IconButton, makeStyles, Theme } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { MouseEventHandler } from "react";
import { useDispatch } from "react-redux";
import { chunkHeightPx } from "../../config/algorithmDemoConstants";
import { AlgorithmDemoTimeSlot } from "../../models/AlgorithmDemo/TimeSlot";
import { actions } from "../../store";
import { formatTimeFromTotalMins } from "./dateFormatters";

export interface ResourceCalendarSlotProps extends AlgorithmDemoTimeSlot {
  resourceId: number;
  previousSlotIsBooked: boolean;
}

const useStyles = makeStyles<Theme, { isBooked: boolean; isHour: boolean }>({
  root: {
    backgroundColor: (p) => (p.isBooked ? "dodgerBlue" : "white"),
    color: (p) => (p.isBooked ? "white" : "dodgerBlue"),
    cursor: "pointer",
    display: "flex",
    fontWeight: (p) => (p.isHour ? "bold" : undefined),
    height: `${chunkHeightPx}px`,
    minWidth: "290px",
    margin: 0,
    paddingLeft: (p) => (p.isHour ? "5px" : "12px"),
    "&:hover": {
      backgroundColor: "#b8d3ff",
    },
  },
  postcode: {
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: "1.2em",
    fontWeight: "normal",
  },
  editButton: {
    marginLeft: "auto",
  },
});

const ResourceCalendarSlot: React.FC<ResourceCalendarSlotProps> = ({
  resourceId,
  startTotalMins,
  isBooked,
  previousSlotIsBooked,
  postcode,
}) => {
  const classNames = useStyles({ isBooked, isHour: startTotalMins % 60 === 0 });
  const dispatch = useDispatch();

  const handleClick = (startTotalMins: number) =>
    dispatch(actions.algorithmDemo.toggleBookSlot(resourceId, startTotalMins));

  const handleEditClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    dispatch(actions.algorithmDemo.setSelectedSlot(resourceId, startTotalMins));
  };

  return (
    <div
      key={startTotalMins}
      className={classNames.root}
      onClick={() => handleClick(startTotalMins)}
    >
      {formatTimeFromTotalMins(startTotalMins)}{" "}
      {previousSlotIsBooked || (
        <span className={classNames.postcode}>{postcode}</span>
      )}
      {isBooked && !previousSlotIsBooked && (
        <IconButton
          size="small"
          className={classNames.editButton}
          onClick={handleEditClick}
        >
          <EditIcon htmlColor="white" />
        </IconButton>
      )}
    </div>
  );
};

export default ResourceCalendarSlot;
