import { Grid, IconButton, makeStyles, Theme } from "@material-ui/core";
import { AppSearchBox } from "../components/AppSearchBox";
import ServiceCard from "../components/ServiceConfig/ServiceCard";
import { PageContainer } from "../components/MainLayout";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors, useDispatchEffect } from "../store";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { Dispatch, SetStateAction, useState } from "react";
import PageHeader from "../components/PageHeader";
import AddServiceModal from "../services/AddServiceModal";
import ServicePageNavBar from "../components/ServiceConfig/ServicePageNavBar";

interface StyleProps {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  pagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
  title: {
    fontSize: 40,
    fontWeight: 500,
    display: "flex",
    flexDirection: "row",
    marginLeft: 5,
    "& .grey": {
      color: theme.palette.grey[400],
      marginRight: 10,
    },
  },
  subtitle: {
    fontSize: 24,
    fontWeight: 500,
    marginLeft: 5,
  },
  optionalExtrasButton: {
    color: "white",
    textDecoration: "none",
  },
  topBar: {
    display: "flex",

    alignItems: "center",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      justifyContent: "end",
      marginRight: 30,
    },
    [theme.breakpoints.between("sm", "md")]: {
      justifyContent: "space-between",
      marginRight: 30,
    },
  },
  topRight: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      justifyContent: "end",
      marginRight: 0,
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "Center",
  },
  filterIcon: {
    visibility: "hidden",
    color: theme.palette.primary.main,
    "& svg": {
      fill: "url(#BrandGradient)",
      width: 36,
      height: 36,
    },
  },
  paginationButton: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: 12,
    background: "transparent",
    border: "none",
    marginLeft: 5,
    marginRight: 5,
    cursor: "pointer",
    "& .active": {
      fontSize: 16,
      fontWeight: 600,
    },
  },
  search: {
    width: "100%",
  },
  rightArrowButton: ({ hasNextPage }) => ({
    color:
      hasNextPage == true
        ? theme.palette.primary.main
        : theme.palette.grey[400],
  }),
  leftArrowButton: ({ hasPreviousPage }) => ({
    color:
      hasPreviousPage == true
        ? theme.palette.primary.main
        : theme.palette.grey[400],
  }),
}));

const PaginatedButtons = (props: {
  setCurrentPageNumber: Dispatch<SetStateAction<number>>;
  currentPageNumber: number;
}) => {
  const dispatch = useDispatch();
  const handleChangePageNumber = (pageNumber: number) => {
    dispatch(actions.services.setPageNumber(pageNumber));
    props.setCurrentPageNumber(pageNumber);
  };
  const pagination = useSelector(selectors.services.pagination);

  const classes = useStyles({
    hasNextPage: pagination.hasNextPage,
    hasPreviousPage: pagination.hasPreviousPage,
  } as StyleProps);

  const listOfButtons: Array<number> = [];

  if (props.currentPageNumber > pagination.totalPages - 3) {
    for (let i = pagination.totalPages - 3; i < pagination.totalPages; i++) {
      if (i > 0) {
        listOfButtons.push(i);
      }
    }
  } else {
    for (
      let i = props.currentPageNumber;
      i < props.currentPageNumber + 3;
      i++
    ) {
      if (i < pagination.totalPages) {
        listOfButtons.push(i);
      }
    }
  }
  listOfButtons.push(pagination.totalPages);
  return (
    <div className={`${classes.paginationButton} ${classes.row}`}>
      {listOfButtons.map((pageNumber: number) => (
        <>
          {pageNumber === pagination.totalPages &&
            pagination.totalPages > 4 &&
            listOfButtons[2] + 1 < pagination.totalPages && <div>...</div>}
          <button
            className={
              props.currentPageNumber === pageNumber
                ? classes.paginationButton + " active"
                : classes.paginationButton
            }
            key={pageNumber}
            onClick={() => {
              handleChangePageNumber(pageNumber);
            }}
          >
            {pageNumber}
          </button>
        </>
      ))}
    </div>
  );
};

const ServiceConfigPage: React.FC = () => {
  const dispatch = useDispatch();
  const searchTerm = useSelector(selectors.services.selectSearchTerm);

  const handleSearchChange = (value: string) => {
    dispatch(actions.services.setSearchTerm(value));
  };

  const handleSubmitSearch = () => {
    setCurrentPageNumber(1);
    dispatch(actions.services.doSearch());
  };

  useDispatchEffect(actions.services.fetchAll);

  const services = useSelector(selectors.services.all);
  const pagination = useSelector(selectors.services.pagination);

  const handleChangePageNumber = (direction: number) => {
    const pageNumber = currentPageNumber + direction;
    if (pageNumber <= 0 || pageNumber > pagination.totalPages) {
      return;
    }
    dispatch(actions.services.setPageNumber(pageNumber));
    setCurrentPageNumber(pageNumber);
  };

  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const classes = useStyles({
    hasNextPage: pagination.hasNextPage,
    hasPreviousPage: pagination.hasPreviousPage,
  } as StyleProps);

  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setMenuEl(null);
  };

  const [createServiceModal, setCreateServiceModal] = useState(false);

  const handleAddService = () => {
    return;
  };

  const renderServices = () => {
    if (services.length == 0) {
      return <div>No services found.</div>;
    }

    return (
      <>
        <Grid container spacing={2}>
          {services.map((service) => (
            <Grid item key={`service-item-${service.id}`} xs={12} sm={6} md={4}>
              <ServiceCard
                key={service.id}
                service={service}
              >
              </ServiceCard>
            </Grid>
          ))}
        </Grid>
        <div className={classes.pagination}>
          <IconButton
            onClick={() => {
              handleChangePageNumber(-1);
            }}
          >
            <ArrowBack className={classes.leftArrowButton}></ArrowBack>
          </IconButton>
          <PaginatedButtons
            setCurrentPageNumber={setCurrentPageNumber}
            currentPageNumber={currentPageNumber}
          ></PaginatedButtons>
          <IconButton
            onClick={() => {
              handleChangePageNumber(+1);
            }}
          >
            <ArrowForward className={classes.rightArrowButton}></ArrowForward>
          </IconButton>
        </div>
      </>
    );
  };

  return (
    <>
      {/* <ServicePageNavBar></ServicePageNavBar> */}
      <PageContainer>
        <PageHeader
          title="Services"
          action={() => setCreateServiceModal(true)}
          actionLabel={"Add a new Service"}
        >
          <AddServiceModal
            open={createServiceModal}
            handleClose={() => setCreateServiceModal(false)}
          ></AddServiceModal>
          <AppSearchBox
            className={classes.search}
            placeholder={"Search for services ..."}
            onSubmit={handleSubmitSearch}
            onChange={handleSearchChange}
            value={searchTerm}
          ></AppSearchBox>
        </PageHeader>
        {/*
      Cant query business name yet
      <div className={classes.subtitle}>
        <div>Your business</div>
      </div> */}
        {/*  <div className={classes.topBar}>*/}
        {/*      <div className={classes.topRight}>*/}
        {/*          <IconButton*/}
        {/*              className={classes.filterIcon}*/}
        {/*              onClick={(e: MouseEvent<HTMLButtonElement>) =>*/}
        {/*                setMenuEl(e.currentTarget)*/}
        {/*            }*/}
        {/*        >*/}
        {/*            <FilterIcon/>*/}
        {/*        </IconButton>*/}

        {/*        <Menu*/}
        {/*            id="filter-actions"*/}
        {/*            anchorEl={menuEl}*/}
        {/*            getContentAnchorEl={null}*/}
        {/*            anchorOrigin={{vertical: "bottom", horizontal: "center"}}*/}
        {/*            transformOrigin={{vertical: "top", horizontal: "center"}}*/}
        {/*            keepMounted*/}
        {/*            open={Boolean(menuEl)}*/}
        {/*            onClose={handleClose}*/}
        {/*        >*/}
        {/*            <MenuItem*/}
        {/*                onClick={() => {*/}
        {/*                    return;*/}
        {/*                }}*/}
        {/*            >*/}
        {/*                Sort by:*/}
        {/*            </MenuItem>*/}
        {/*            <MenuItem*/}
        {/*                onClick={() => {*/}
        {/*                    return;*/}
        {/*                }}*/}
        {/*            >*/}
        {/*                Service Name (A-Z)*/}
        {/*            </MenuItem>*/}
        {/*            <MenuItem*/}
        {/*                onClick={() => {*/}
        {/*                    return;*/}
        {/*                }}*/}
        {/*            >*/}
        {/*                Service Name (Z-A)*/}
        {/*            </MenuItem>*/}
        {/*            <MenuItem*/}
        {/*                onClick={() => {*/}
        {/*                    return;*/}
        {/*                }}*/}
        {/*            >*/}
        {/*                Available*/}
        {/*            </MenuItem>*/}
        {/*            <MenuItem*/}
        {/*                onClick={() => {*/}
        {/*                    return;*/}
        {/*                }}*/}
        {/*            >*/}
        {/*                Unavailable*/}
        {/*            </MenuItem>*/}
        {/*        </Menu>*/}
        {/*    </div>*/}
        {/*</div>*/}
        {renderServices()}
      </PageContainer>
    </>
  );
};

export default ServiceConfigPage;
