export const INCODE_REGEX = /\d[a-z]{2}$/i;

export const OUTCODE_REGEX = /^[a-z]{1,2}\d[a-z\d]?$/i;

export const INPUT_POSTCODE_REGEX = /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i;

const SPACE_REGEX = /\s/g;

export const sanitize = (s: string): string =>
  s.replace(SPACE_REGEX, "").replace(/[\u200B-\u200D\uFEFF]/g, "").toUpperCase();

const matchOn = (s: string, regex: RegExp): RegExpMatchArray | null =>
  sanitize(s).match(regex);

const firstOrNull = (match: RegExpMatchArray | null): string | null => {
  if (match === null) return null;
  return match[0];
};

export const isValid = (postcode: string): boolean =>
  postcode.match(INPUT_POSTCODE_REGEX) !== null;

export const toOutcode = (postcode: string): string | null => {
  if (!isValid(sanitize(postcode))) return null;
  return sanitize(postcode).replace(INCODE_REGEX, "");
};

export const toIncode = (postcode: string): string | null => {
  if (!isValid(sanitize(postcode))) return null;
  const match = matchOn(postcode, INCODE_REGEX);
  return firstOrNull(match);
};

export const toNormalised = (postcode?: string): string => {
  if (!postcode) return "";
  const outcode = toOutcode(postcode);
  if (outcode === null) return postcode;
  const incode = toIncode(postcode);
  if (incode === null) return postcode;
  return `${outcode} ${incode}`;
};
