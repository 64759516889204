import {TableCell, TableRow, IconButton} from "@material-ui/core";
import {formatDateDateOnly, formatTimeOnly} from "../../dateFormatters";
import {BookingSummary} from "../../models/Booking";
import {Edit} from "@material-ui/icons";

interface BookingsTableRowProps {
    booking: BookingSummary;
    handleEdit: () => void;
}

const BookingsTableRow: React.FC<BookingsTableRowProps> = ({
   booking,
   handleEdit,
}) => {

    const formattedTotalPrice = booking.totalPrice.toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    return (
        <TableRow>
            <TableCell>{booking.id}</TableCell>
            <TableCell>{booking.bookingStatusText}</TableCell>
            <TableCell>{formatDateDateOnly(booking.start, true)}</TableCell>
            <TableCell>{`${formatTimeOnly(booking.start)} to ${formatTimeOnly(booking.end)}`}</TableCell>
            <TableCell>
                {booking.customerFirstName} {booking.customerLastName}
            </TableCell>
            <TableCell>{booking.resourceName}</TableCell>
            <TableCell>£{formattedTotalPrice}</TableCell>
            <TableCell>
                <IconButton
                    onClick={() => handleEdit()}
                    disabled={booking.bookingStatus === 2}
                >
                    <Edit/>
                </IconButton>
            </TableCell>
        </TableRow>
    );

};

export default BookingsTableRow;
