import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { createBooking, createRecurringBooking } from "../../api/bookings";
import { SelectedSlotTime } from "../../components/AppCalendar/types";
import AppDrawer from "../../components/AppDrawer";
import { createIsoDateFromDateAndTime, formatMinutesAfterMidnightToTimeString } from "../../dateFormatters";
import { CreateBookingDto } from "../../models/Booking";
import { CreateRecurringBookingDto, RecurringSlotDateTime } from "../../models/RecurringBookableSlot";
import { actions, selectors } from "../../store";
import { successToast } from "../../toast";
import { BookingForm } from "../BookingForm";
import { BookingFormValues, defaultBookingFormValues } from "../BookingForm/BookingFormValues";
interface CreateBookingProps {
  open: boolean;
  onClose(): void;
  initialDate?: Date;
  initialResource?: number;
  initialCustomerId?: number;
  initialTimeSlotSelection?: SelectedSlotTime;
  initialBookingTags?: number[];
}

const CreateBookingDrawer: React.FC<CreateBookingProps> = ({
  open,
  onClose,
  initialDate,
  initialResource,
  initialCustomerId,
  initialTimeSlotSelection,
}) => {
  const dispatch = useDispatch();

  const basketStoreServices = useSelector(selectors.basket.services);

  const handleAddBooking = async (
    booking: BookingFormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ): Promise<void> => {
    if (booking.isRecurringBooking) {
      return await handleRecurringBooking(booking, setSubmitting);
    }

    return await handleSingleBooking(booking, setSubmitting);
  };

  const initialValues: BookingFormValues = {
    ...defaultBookingFormValues,
    date: initialTimeSlotSelection
      ? initialTimeSlotSelection.date
      : initialDate
      ? initialDate
      : defaultBookingFormValues.date,
    resourceId: initialResource
      ? initialResource
      : defaultBookingFormValues.resourceId,
    customerId: initialCustomerId
      ? initialCustomerId
      : defaultBookingFormValues.customerId,
    start: initialTimeSlotSelection
      ? initialTimeSlotSelection.start
      : defaultBookingFormValues.start,
    end: initialTimeSlotSelection
      ? initialTimeSlotSelection.end
      : defaultBookingFormValues.end,
  };

  const handleRecurringBooking = async (
    booking: BookingFormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const recurringDateTimes: RecurringSlotDateTime[] = [];

    booking.recurringBookableSlots.map((r) => {
      if (r.isSelected && r.start && r.end) {
        recurringDateTimes.push({
          start: r.start,
          end: r.end,
        });
      }
    });

    const bookingDto: CreateRecurringBookingDto = {
      resourceId: booking.resourceId,
      start: createIsoDateFromDateAndTime(
        booking.date,
        formatMinutesAfterMidnightToTimeString(booking.start)
      ),
      end: createIsoDateFromDateAndTime(
        booking.date,
        formatMinutesAfterMidnightToTimeString(booking.end)
      ),
      notes: booking.notes,
      description: booking.description,
      customerId: booking.customerId,
      customerAddressId: booking.customerAddressId,
      jobs: basketStoreServices,
      //TODO: add tags
      firstJobAppointmentTypeId: booking.firstJobAppointmentTypeId,
      firstJobOptionalExtras: booking.firstJobOptionalExtras,
      paymentStatusId: booking.paymentStatusId,
      overriddenTotalPrice: undefined,
      muteConfirmationEmail: booking.muteConfirmationEmail,
      dateTimes: recurringDateTimes,
      tagIds: booking.tags?.map((tag) => tag.id),
    };

    const response = await createRecurringBooking(bookingDto);

    if (!response.isError) {
      dispatch(actions.basket.resetBasket());
      onClose();
      successToast("Recurring bookings have been created");
    } else {
      setSubmitting(false);
    }
  };

  const handleSingleBooking = async (
    booking: BookingFormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const bookingDto: CreateBookingDto = {
      resourceId: booking.resourceId,
      start: createIsoDateFromDateAndTime(
        booking.date,
        formatMinutesAfterMidnightToTimeString(booking.start)
      ),
      end: createIsoDateFromDateAndTime(
        booking.date,
        formatMinutesAfterMidnightToTimeString(booking.end)
      ),
      notes: booking.notes,
      description: booking.description,
      customerId: booking.customerId,
      customerAddressId: booking.customerAddressId,
      firstJobAppointmentTypeId: booking.firstJobAppointmentTypeId,
      firstJobOptionalExtras: booking.firstJobOptionalExtras,
      jobs: basketStoreServices,
      paymentStatusId: booking.paymentStatusId,
      overriddenTotalPrice: undefined,
      muteConfirmationEmail: booking.muteConfirmationEmail,
      tagIds: booking.tags?.map((tag) => tag.id),
    };

    const response = await createBooking(bookingDto);

    if (!response.isError) {
      dispatch(actions.basket.resetBasket());
      onClose();
      successToast("Booking has been created");
    } else {
      setSubmitting(false);
    }
  };


  return (
    <AppDrawer open={open} anchor="right" onClose={onClose}>
      <BookingForm
        initialValues={initialValues}
        onSubmit={handleAddBooking}
        onClose={onClose}
      />
    </AppDrawer>
  );
};

export default CreateBookingDrawer;
