import { fade, makeStyles } from "@material-ui/core";
import AppLoader from "./AppLoader";

interface AppRelativelyPositionedLoaderProps {
  isLoading?: boolean;
}

const useStyles = makeStyles((theme) => ({
  isLoading: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: fade(theme.palette.grey[400], 0.8),
    zIndex: 11,
  },
  hidden: {
    display: "none",
  },
}));

const AppRelativelyPositionedLoader: React.FC<AppRelativelyPositionedLoaderProps> =
  ({ isLoading }) => {
    const classes = useStyles();

    return (
      <div className={isLoading ? classes.isLoading : classes.hidden}>
        <AppLoader />
      </div>
    );
  };

export default AppRelativelyPositionedLoader;
