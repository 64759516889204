import { useField } from "formik";
import AppCustomColourPicker, {
  AppCustomColourPickerProps,
} from "./AppCustomColourPicker/AppCustomColourPicker";
import { FormikInputPropsKeys } from "./common";

export interface AppFormikCustomColourPickerProps
  extends Omit<AppCustomColourPickerProps, FormikInputPropsKeys> {
  name: string;
}

const AppFormikCustomColourPicker: React.FC<AppFormikCustomColourPickerProps> =
  ({ name, ...restOfProps }) => {
    const [fieldProps, fieldMeta, { setValue }] = useField(name);
    const isError = fieldMeta.touched && !!fieldMeta.error;
    const helperText = fieldMeta.touched ? fieldMeta.error : undefined;

    const handleChange = (colour: string) => {
      setValue(colour);
    };

    const colours: string[] = [
      "#f44336",
      "#e91e63",
      "#e9c27b",
      "#673ab7",
      "#3f51b5",
      "#2196f3",
      "#03a9f4",
      "#00bcd4",
      "#009688",
      "#4caf50",
      "#8bc34a",
      "#cddc39",
      "#ffeb3b",
      "#ffc107",
      "#ff9800",
      "#ff5722",
      "#795548",
      "#607d8b",
    ];

    return (
      <AppCustomColourPicker
        {...restOfProps}
        {...fieldProps}
        onChange={handleChange}
        colours={colours}
        helperText={helperText}
        error={isError}
      />
    );
  };

export default AppFormikCustomColourPicker;
