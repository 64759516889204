import FullCalendar, { RefObject } from "@fullcalendar/react";
import { IconButton, makeStyles, Menu, MenuItem, Typography } from "@material-ui/core";
import {
  ChevronRight,
  ChevronLeft,
  Add as AddIcon,
  ViewWeek as HorizontalIcon,
  ViewAgenda as VerticalIcon
} from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import {
  addDays,
  addWeeks,
  endOfMonth,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { endOfWeek } from "date-fns/esm";
import { useState, MouseEvent } from "react";
import { useConfig } from "../../config/ConfigContext";
import { formatDateDateOnly } from "../../dateFormatters";
import AppButton from "../AppButton";
import { CALENDAR_DATE_SELECTION, CALENDAR_ORIENTATION, CALENDAR_VIEWS, useCalendar } from "./AppCalendarContext";
import { AppCalendarResourceFilter } from "./AppCalendarResourceFilter";
import StatIcon from './assets/StatIcon';
import {useDispatch} from "react-redux";
import {actions} from "../../store";

const viewLabelMap = {
  [CALENDAR_VIEWS.WEEK_VIEW]: "Week",
  [CALENDAR_VIEWS.DAY_VIEW]: "Day",
  [CALENDAR_VIEWS.DAY_PIVOT_VIEW]: "Pivot",
};

interface AppCalendarToolbarProps {
  calendar: RefObject<FullCalendar>;
  isResource?: boolean;
  isAnyResourceSelected: boolean;
  date?: Date;
  calendarIsOpen: boolean;
  handleCalendarOpen: (bool: boolean) => void;
  captureSelectedDate: (date: Date) => void;
  userIsResource?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column-reverse",
    display: "grid",
    gridTemplateColumns: "50% 50%",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      marginBottom: theme.spacing(2),
      display: "flex"
    },
  },
  dateNav: {
    display: "flex",
    alignItems: "center",
    flex: "none",
    justifyContent: "center",
    margin: "0 auto",
    padding: `${theme.spacing(1)}px 0`,
    [theme.breakpoints.up("sm")]: {
      padding: 0,
    },
  },
  datePicker: {
    position: "fixed",
    width: 0,
    height: 0,
    opacity: 0,
    cursor: "pointer",
    pointerEvents: "none",
  },
  actionRhs: {
    display: "flex",
    flex: "none",
    height: 0,
    justifyContent: "flex-end",
    [theme.breakpoints.up("sm")]: {
      flex: 1,
      height: "auto",
    },
  },
  actionLhs: {
    flex: "1",
    "& > *": {
      marginBottom: 0,
    },
  },
  actionBtn: {
    borderRadius: 0,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.backgrounds.blue,
    fontWeight: theme.typography.fontWeightBold,
    height: "48px",
    border: "none",
    boxShadow: "none",
    textTransform: "uppercase",
    cursor: "pointer",
    padding: 12,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      boxShadow: "none",
    },
  },
  actionBtnActive: {
    backgroundColor: theme.palette.grey[100],
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    boxShadow: "none",
  },
  actionMenu: {
    "& > .MuiPopover-paper": {
      borderRadius: "0 !important",
      backgroundColor: theme.palette.backgrounds.grey,
      boxShadow: "0px 3px 6px #00000029",
      marginTop: theme.spacing(2),
    },
  },
  statDropdown: {
    "& .MuiMenu-list": {
      padding: 0,
    },
    "& > .MuiPopover-paper": {
      borderRadius: "0 !important",
      backgroundColor: theme.palette.backgrounds.grey,
      boxShadow: "0px 3px 6px #00000029",
      marginTop: theme.spacing(2),
    },
  },
  earnings: {
    padding: theme.spacing(2),
    "& h3": {
      margin: 0,
      textTransform: "uppercase",
      fontSize: ".85rem",
      fontWeight: theme.typography.fontWeightMedium,
      marginBottom: theme.spacing(1),
    },
  },
  keyVal: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& span": {
      fontSize: "1.25rem",
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  viewBtns: {
    top: 0,
    right: 0,
  },
  adminViewBtns: {
    top: 0,
  },
  arrowBtn: {
    padding: 0,
    "& svg": {
      width: 32,
      height: 32,
      fill: "url(#BrandGradient)",
    },
  },
  dateViewSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: "none",
    gridRow: "2",
    gridColumn: "1 / span 2",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginBottom: 0
    }
  },
  leftSideSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 1,
  },
  rightSideSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flex: 1,
    width: "100%",
    marginBottom: theme.spacing(2),
    gridColumn: "2",
    gridRow: "1",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
      marginBottom: 0,
    },
  },
  dateLabel: {
    textTransform: "uppercase",
    color: theme.palette.primary.dark,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: "1.375rem",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  viewSwitcher: {
    display: "flex",
    justifyContent: "center",
  },
  viewBtn: {
    borderRadius: 0,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: theme.typography.fontFamily,
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    transition: "all .2s ease-in-out",
    cursor: "pointer",
    padding: "0 .75rem",
    fontSize: theme.typography.fontSize,
    "&:first-letter": {
      textTransform: "uppercase",
    },
    "&:hover": {
      opacity: 0.6,
    },
  },
  viewBtnActive: {
    color: theme.palette.primary.dark,
    "&:hover": {
      opacity: 1
    }
  },
  calendarAdditionBtn: {
    width: 48,
    height: 48,
    background:
      "transparent linear-gradient(45deg, #4570B6 0%, #5CB4DB 100%) 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: 0,
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      fill: "#FFFFFF",
      width: 36,
      height: 36,
    },
  },
  calendarAdditionBtnOpen: {
    background:
      "transparent linear-gradient(45deg, #2B2B2B 0%, #8A8A8A 100%) 0% 0% no-repeat padding-box",
  },
  inverseBtn: {
    width: 48,
    height: 48,
    background: "transparent",
    boxShadow: "none",
    borderRadius: 0,
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: theme.spacing(2),
    "& svg": {
      fill: "url(#BrandGradient)",
      width: 36,
      height: 36,
    },
  },
  inverseBtnOpen: {
    "& svg": {
      fill: "url(#BrandGradientActive)",
    }
  },
  gradientSvg: {
    position: "fixed",
    cursor: "none"
  },
  statTitle: {
    textTransform: "uppercase"
  },
  menuPaperStyleStats: {
    overflow: 'visible',
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      marginLeft: -0.5,
      marginRight: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: "calc(50% - 8px)",
      width: 16,
      height: 16,
      backgroundColor: theme.palette.backgrounds.grey,
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
  menuPaperStyleAction: {
    overflow: 'visible',
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      marginLeft: -0.5,
      marginRight: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: "calc(50% - 8px)",
      width: 16,
      height: 16,
      backgroundColor: theme.palette.backgrounds.grey,
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
      [theme.breakpoints.up("sm")]: {
        right: "calc(50% - 20px)"
      }
    },
  },
  oritentationToggleContainer: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down(768)]: {
      display: "none"
    }
  },
  toggleContainer: {
    display: "flex",
    borderRadius: theme.spacing(0.25),
    overflow: "hidden",
    border: `1px solid ${theme.palette.primary.main}`,
    height: 36,
  },
  toggleBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.backgrounds.grey,
    fontWeight: theme.typography.fontWeightBold,
    height: "100%",
    minWidth: 40,
    color: theme.palette.primary.main,
    border: "none",
    cursor: "pointer",
    "&[disabled]": {
      cursor: "not-allowed",
    }
  },
  toggleBtnActive: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: theme.typography.fontWeightBold,
    height: "100%",
    minWidth: 40,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    cursor: "not-allowed",
    pointerEvents: "none",
    border: "none"
  }
}));

export const AppCalendarToolbar: React.FC<AppCalendarToolbarProps> = ({
  calendar,
  isResource,
  isAnyResourceSelected,
  date,
  calendarIsOpen,
  handleCalendarOpen,
  captureSelectedDate,
  userIsResource
}) => {
  const dispatch = useDispatch();
  const {
    selectedDate,
    selectedView,
    handleDateChange,
    handleViewChange,
    canCreateEvent,
    canAddTimeOff,
    setCreateBookingModal,
    setTimeOffModal,
    availableViews,
    grandTotal,
    isPivotView,
  } = useCalendar();
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null);
  const [statDropdown, setStatDropdown] = useState<null | HTMLElement>(null);
  const classes = useStyles();

  const statMenuIsOpen = Boolean(statDropdown);
  const actionMenuIsOpen = Boolean(menuEl);
  const vendorConfig = useConfig();

  const datePickerBtnLabel = () => {
    if (
      selectedView === CALENDAR_VIEWS.DAY_VIEW ||
      selectedView === CALENDAR_VIEWS.DAY_PIVOT_VIEW
    ) {
      return formatDateDateOnly(selectedDate);
    }

    if (selectedView === CALENDAR_VIEWS.WEEK_VIEW || selectedView === CALENDAR_VIEWS.WEEK_PIVOT_VIEW) {
      return `${formatDateDateOnly(
        startOfWeek(selectedDate, { weekStartsOn: 1 })
      )} - ${formatDateDateOnly(endOfWeek(selectedDate, { weekStartsOn: 1 }))}`;
    }

    return `${formatDateDateOnly(
      startOfMonth(selectedDate)
    )} - ${formatDateDateOnly(endOfMonth(selectedDate))}`;
  };

  const handleNext = () => {
    const action = calendar.current?.getApi();
    const isWeekView = selectedView === CALENDAR_VIEWS.WEEK_VIEW;

    if (isWeekView) {
      const newDate = addWeeks(selectedDate, 1);
      handleDateChange(newDate);
    }

    if (!isWeekView) {
      const newDate = addDays(selectedDate, 1);
      handleDateChange(newDate);
    }

    action?.next();
  };

  const handlePrev = () => {
    const action = calendar.current?.getApi();
    const isWeekView = selectedView === CALENDAR_VIEWS.WEEK_VIEW;

    if (isWeekView) {
      const newDate = addWeeks(selectedDate, -1);
      handleDateChange(newDate);
    }

    if (!isWeekView) {
      const newDate = addDays(selectedDate, -1);
      handleDateChange(newDate);
    }

    action?.prev();
  };

  const handleClose = () => {
    setMenuEl(null);
  };

  const handleStatClose = () => {
    setStatDropdown(null);
  };

  const handleDatePickerChange = (date: MaterialUiPickersDate) => {
    const action = calendar.current?.getApi();

    const selectedDate = date ? new Date(date) : new Date();
    handleDateChange(selectedDate);
    captureSelectedDate(selectedDate);
    handleCalendarOpen(false);
    action?.gotoDate(selectedDate);
  };

  const onViewChange = (dateSelection: CALENDAR_DATE_SELECTION) => {
    const action = calendar.current?.getApi();
    let view: CALENDAR_VIEWS = selectedView;

    if(!isPivotView) {
       view = (dateSelection === CALENDAR_DATE_SELECTION.DAILY) ? CALENDAR_VIEWS.DAY_VIEW : CALENDAR_VIEWS.WEEK_VIEW;
    } else {
      view = (dateSelection === CALENDAR_DATE_SELECTION.DAILY) ? CALENDAR_VIEWS.DAY_PIVOT_VIEW : CALENDAR_VIEWS.WEEK_PIVOT_VIEW;
    }

    handleViewChange(view);
    action?.changeView(view);
  };

  const onOrientationChange = (orientation: CALENDAR_ORIENTATION) => {
    const action = calendar.current?.getApi();
    let view: CALENDAR_VIEWS = selectedView;

    if(orientation === CALENDAR_ORIENTATION.VERTICAL) {
      view = (selectedView === CALENDAR_VIEWS.DAY_PIVOT_VIEW) ? CALENDAR_VIEWS.DAY_VIEW : CALENDAR_VIEWS.WEEK_VIEW;
    }

    if(orientation === CALENDAR_ORIENTATION.HORIZONTAL) {
      view = CALENDAR_VIEWS.DAY_PIVOT_VIEW;
    }

    handleViewChange(view);
    action?.changeView(view);
  };

  const handleTodayClick = () => {
    const action = calendar.current?.getApi();
    
    handleDateChange(new Date());

    action?.changeView(selectedView, new Date());
  };


  const handleOnAddBookingClick = () => {
    dispatch(actions.basket.resetBasket());
    setCreateBookingModal(true);
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.leftSideSection}>
          <div className={classes.oritentationToggleContainer}>
            <div className={classes.toggleContainer}>
              <button className={(selectedView === CALENDAR_VIEWS.DAY_PIVOT_VIEW || selectedView === CALENDAR_VIEWS.WEEK_PIVOT_VIEW) ? classes.toggleBtnActive : classes.toggleBtn} onClick={() => onOrientationChange(CALENDAR_ORIENTATION.HORIZONTAL)}>
                <HorizontalIcon />
              </button>
              <button className={(selectedView === CALENDAR_VIEWS.DAY_VIEW || selectedView === CALENDAR_VIEWS.WEEK_VIEW) ? classes.toggleBtnActive : classes.toggleBtn} onClick={() => onOrientationChange(CALENDAR_ORIENTATION.VERTICAL)}>
                <VerticalIcon />
              </button>
            </div>
          </div>
          <div className={classes.toggleContainer}>
          {availableViews.includes(CALENDAR_VIEWS.DAY_VIEW) && (
              <button
                className={`${classes.toggleBtn} ${
                  (selectedView === CALENDAR_VIEWS.DAY_PIVOT_VIEW || selectedView === CALENDAR_VIEWS.DAY_VIEW) &&
                  classes.toggleBtnActive
                }`}
                key={CALENDAR_VIEWS.DAY_VIEW}
                onClick={() => onViewChange(CALENDAR_DATE_SELECTION.DAILY)}
              >
                {viewLabelMap[CALENDAR_VIEWS.DAY_VIEW]}
              </button>
            )}
            {availableViews.includes(CALENDAR_VIEWS.WEEK_VIEW) && (
              <button
                className={`${classes.toggleBtn} ${
                  (selectedView === CALENDAR_VIEWS.WEEK_VIEW || selectedView === CALENDAR_VIEWS.WEEK_PIVOT_VIEW) &&
                  classes.toggleBtnActive
                }`}
                key={CALENDAR_VIEWS.WEEK_VIEW}
                onClick={() => onViewChange(CALENDAR_DATE_SELECTION.WEEKLY)}
                disabled={isPivotView}
              >
                {viewLabelMap[CALENDAR_VIEWS.WEEK_VIEW]}
              </button>
            )}
          </div>
        </div>

        <div className={classes.dateViewSection}>
          <div className={classes.dateNav}>
            {isAnyResourceSelected && (
              <>
                <IconButton onClick={handlePrev} className={classes.arrowBtn}>
                  <ChevronLeft />
                </IconButton>
                <AppButton
                  variant="text"
                  color="default"
                  onClick={() => handleCalendarOpen(true)}
                  className={classes.dateLabel}
                >
                  {datePickerBtnLabel()}
                </AppButton>
                <IconButton onClick={handleNext} className={classes.arrowBtn}>
                  <ChevronRight />
                </IconButton>
              </>
            )}
          </div>
          <button
                className={classes.viewBtn}
                onClick={handleTodayClick}
              >
                View Today
          </button>
        </div>

        <div className={classes.rightSideSection}>
          <IconButton
            onClick={(e: MouseEvent<HTMLButtonElement>) =>
              setStatDropdown(e.currentTarget)
            }
            aria-label="Booking statistics overview"
            size="medium"
            className={`${classes.inverseBtn} ${statMenuIsOpen && classes.inverseBtnOpen}`}
          >
            <StatIcon />
          </IconButton>
          <Menu
            id="calendar-stats"
            className={classes.statDropdown}
            anchorEl={statDropdown}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            keepMounted
            open={Boolean(statDropdown)}
            onClose={handleStatClose}
            PaperProps={{
              className: classes.menuPaperStyleStats
            }}
          >
            <div className={classes.earnings}>
              <Typography variant="body2" className={classes.statTitle}>Earnings</Typography>
              <div className={classes.keyVal}>
                <Typography variant="body2" className={classes.statTitle}>Total</Typography>
                <span>£{grandTotal}</span>
              </div>
            </div>
          </Menu>
          {!isResource && <AppCalendarResourceFilter />}
          {!(vendorConfig.hidePricesFromResource && userIsResource) && (
            <IconButton
              onClick={
                canCreateEvent || canAddTimeOff
                  ? (e: MouseEvent<HTMLButtonElement>) =>
                      setMenuEl(e.currentTarget)
                  : undefined
              }
              aria-label="Calendar addition"
              size="medium"
              className={`${classes.calendarAdditionBtn} ${actionMenuIsOpen && classes.calendarAdditionBtnOpen}`}
              style={{ fill: "url(#BrandGradient)" }}
            >
              <AddIcon />
            </IconButton>
          )}
          {isAnyResourceSelected && (
            <Menu
              id="calendar-actions"
              className={classes.actionMenu}
              anchorEl={menuEl}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              keepMounted
              open={Boolean(menuEl)}
              onClose={handleClose}
              PaperProps={{
                className: classes.menuPaperStyleAction
              }}
            >
              {canCreateEvent && (
                <MenuItem onClick={handleOnAddBookingClick}>
                  Add Booking
                </MenuItem>
              )}
              {canAddTimeOff && (
                <MenuItem onClick={() => setTimeOffModal(true)}>
                  Add Time-off
                </MenuItem>
              )}
            </Menu>
          )}
        </div>
      </div>

      <DatePicker
        open={calendarIsOpen}
        className={classes.datePicker}
        value={date}
        onChange={handleDatePickerChange}
        format="dd/MM/yy"
        autoOk
        cancelLabel={false}
        onClose={() => () => handleCalendarOpen(false)}
      />
    </>
  );
};
