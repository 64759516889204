import { useEffect, useState } from "react";

export { default } from "./AppTimeInput";

export enum CHANGE_TYPE {
  HOUR,
  MINUTE,
}

export enum DIRECTION {
  UP,
  DOWN,
}

export const formatWithLeadingZero = (value: number) => {
  return value.toString().padStart(2, "0");
};

export const useMinutesAfterMidnight = (
  value: number
): {
  hour: number;
  minute: number;
  hourString: string;
  minuteString: string;
} => {
  const [minute, setMinute] = useState(value % 60);
  const [hour, setHour] = useState((value - minute) / 60);

  useEffect(() => {
    const minute = value % 60;
    const hour = (value - minute) / 60;

    setMinute(minute);
    setHour(hour);
  }, [value]);

  return {
    hour,
    minute,
    hourString: hour.toString(),
    minuteString: formatWithLeadingZero(minute),
  };
};

export const validateHour = (
  hour: number,
  minHour: number,
  maxHour: number
) => {
  if (isNaN(hour)) {
    return false;
  }

  if (hour < 0) {
    return false;
  }

  if (hour > 23) {
    return false;
  }

  if (hour < minHour) {
    return false;
  }

  if (hour > maxHour) {
    return false;
  }

  return true;
};

export const validateMinute = (
  minute: number,
  hour: number,
  minMinute: number,
  maxMinute: number,
  minHour: number,
  maxHour: number
) => {
  if (isNaN(minute)) {
    return false;
  }

  if (minute < 0) {
    return false;
  }

  if (minute > 59) {
    return false;
  }

  if (minute < minMinute && hour === minHour) {
    return false;
  }

  if (minute > maxMinute && hour === maxHour) {
    return false;
  }

  return true;
};

export const disableMinuteArrow = (
  minute: number,
  hour: number,
  minMinute: number,
  maxMinute: number,
  minHour: number,
  maxHour: number
) => {
  let disabled = false;

  if (minute >= 60) {
    minute = 60 - minute;
  }

  if (minute < 0) {
    minute = minute + 60;
  }

  if (minute < minMinute && hour === minHour) {
    disabled = true;
  }

  if (minute > maxMinute && hour === maxHour) {
    disabled = true;
  }

  return disabled;
};
