import React, { useEffect, useState } from "react";
import AppCheckbox from "../../../components/AppForm/AppCheckbox";
import { OptionalExtra } from "../../../models/AppointmentType";
import { BookedExtra } from "../../../models/Booking";
import { useField } from "formik";
import AppSelect, {
  AppSelectOption,
} from "../../../components/AppForm/AppSelect";
import {
  excludeVATFromPriceIfRequired,
  formatNumber,
} from "../../../numericalUtils";
import { makeStyles } from "@material-ui/core";
import { BookingFormValues } from "../BookingFormValues";
import { useConfig } from "../../../config/ConfigContext";
import { useAuth } from "../../../auth";

const useStyles = makeStyles((theme) => ({
  itemContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: "2rem",
  },
  itemSelectInput: { width: "4rem" },
  priceAndQuantityContainer: {
    display: "flex",
    "& > div": {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      marginLeft: "2rem",
      alignItems: "center",
    },
  },
}));

type Props = {
  optionalExtra: OptionalExtra;
  options: AppSelectOption[];
  basePrice: number;
  availableExtras: OptionalExtra[];
  checked: boolean;
  initialValues: BookingFormValues;
  handleItemChange: (extras: BookedExtra[]) => void;
};

const BookingFormOptionalExtraItem: React.FC<Props> = ({
  optionalExtra,
  options,
  checked,
  initialValues,
  handleItemChange,
}) => {
  const classes = useStyles();
  const vendorConfig = useConfig();
  const [
    { value: selectedOptionalExtras },
    ,
    { setValue: setSelectedOptionalExtras },
  ] = useField<BookedExtra[]>("firstJobOptionalExtras");
  const [{ value: totalPrice }, , { setValue: setTotalPrice }] =
    useField<number>("totalPrice");
  const [selectedExtra, setSelectedExtra] = useState<BookedExtra | null>(null);

  const { role } = useAuth();
  const priceHiddenFromResource =
    role === "resource" && vendorConfig.hidePricesFromResource;

  useEffect(() => {
    const matchedExtra = selectedOptionalExtras.find(
      (extra) => extra.optionalExtraId === optionalExtra.id
    );
    if (matchedExtra) setSelectedExtra(matchedExtra);
  }, [optionalExtra, selectedOptionalExtras]);

  const handleChange = (id: number) => {
    const newBookedExtra: BookedExtra = {
      optionalExtraId: id,
      quantity: 1,
      price: selectedExtra?.price ?? 0,
    };
    if (!checked) {
      handleItemChange([...(selectedOptionalExtras ?? []), newBookedExtra]);
    } else {
      handleItemChange(
        selectedOptionalExtras.filter(
          (extra) => extra.optionalExtraId !== optionalExtra.id ?? []
        )
      );
    }
  };

  const handleSelect = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const updatedExtra = {
      optionalExtraId: optionalExtra.id,
      quantity: parseInt(e.target.value as string),
      price: selectedExtra?.price ?? 0,
    };

    handleItemChange(
      selectedOptionalExtras.map((extra) => {
        return extra.optionalExtraId === optionalExtra.id //(selectedExtra?.optionalExtraId ?? 0)
          ? updatedExtra
          : extra;
      })
    );
  };

  const isBookingComplete = initialValues.bookingStatus > 0;

  const vatRate = isBookingComplete
    ? initialValues.taxRate ?? 0
    : vendorConfig.currentTaxRatePercentage;

  const priceExcludingVat = excludeVATFromPriceIfRequired(
    optionalExtra.price,
    vatRate
  );

  return (
    <div className={classes.itemContainer}>
      <AppCheckbox
        key={optionalExtra.id}
        label={optionalExtra.name}
        value={optionalExtra.id}
        checked={checked}
        onChange={() => handleChange(optionalExtra.id)}
        readOnly={false}
      />

      <div className={classes.priceAndQuantityContainer}>
        {checked && optionalExtra.hasQuantity ? (
          <AppSelect
            className={classes.itemSelectInput}
            name="paymentStatusId"
            fullWidth={false}
            options={options}
            isLoading={false}
            readOnly={false}
            value={selectedExtra?.quantity ?? 1}
            onChange={(e) => handleSelect(e)}
          />
        ) : (
          <></>
        )}
        <div>
          {priceHiddenFromResource
            ? ""
            : formatNumber(priceExcludingVat, { currency: true })}
        </div>
      </div>
    </div>
  );
};

export default BookingFormOptionalExtraItem;
