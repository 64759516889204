import { makeStyles } from "@material-ui/core";
import NavBar from "./NavBar";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    paddingTop: `calc(64px + ${theme.spacing(3)}px)`,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const MainLayout: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <NavBar />
      <main className={classes.mainContent}>{children}</main>
    </>
  );
};

export default MainLayout;
