import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

interface AppTableSortIconProps {
  direction?: "asc" | "desc";
}

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    display: "inline-flex",
    flexDirection: "column",
    verticalAlign: "middle",
  },
  ascArrow: {
    marginBottom: -8,
    opacity: 0.4,
  },
  descArrow: {
    marginTop: -8,
    opacity: 0.4,
  },
  arrowActive: {
    opacity: 1,
  },
}));

export const AppTableSortIcon: React.FC<AppTableSortIconProps> = ({
  direction,
}) => {
  const classes = useStyles();
  const arrowAscActive = direction === "asc";
  const arrowDescActive = direction === "desc";

  return (
    <div className={classes.iconContainer}>
      <ArrowDropUp
        className={`${classes.ascArrow} ${
          arrowAscActive && classes.arrowActive
        }`}
      />
      <ArrowDropDown
        className={`${classes.descArrow} ${
          arrowDescActive && classes.arrowActive
        }`}
      />
    </div>
  );
};
