import { HttpClientResponse } from "./common/httpClient";
import {
  ApiStandardResponse,
  buildApiUrl,
  doErrorToastIfRequired,
  httpClient,
  mapHttpClientResponse,
} from "./common";
import { ApiLoginResponse } from "./models/Authentication";
import { ResetPasswordDto } from "../models/AccountManagement";

export const login = async (
  email: string,
  password: string
): Promise<HttpClientResponse<ApiLoginResponse>> => {
  const url = buildApiUrl("api/account/login");
  const response = await httpClient.postRequest<
    ApiStandardResponse<ApiLoginResponse>
  >(url, { email, password });

  return mapHttpClientResponse(response, (m) => m);
};

export const forgottenPassword = async (
  email: string
): Promise<HttpClientResponse<void>> => {
  const url = buildApiUrl("api/account/forgot-password");
  const response = await httpClient.postRequest<ApiStandardResponse<void>>(
    url,
    { email }
  );

  doErrorToastIfRequired(response);
  return mapHttpClientResponse(response, (m) => m);
};

export const resetPassword = async (
  resetPasswordDto: ResetPasswordDto
): Promise<HttpClientResponse<void>> => {
  const url = buildApiUrl("api/account/reset-password");
  const response = await httpClient.postRequest<ApiStandardResponse<void>>(
    url,
    resetPasswordDto
  );

  doErrorToastIfRequired(response);
  return mapHttpClientResponse(response, (m) => m);
};

export const logout = async (): Promise<HttpClientResponse<void>> => {
  const url = buildApiUrl("api/account/logout");
  const response = await httpClient.postRequest<ApiStandardResponse<void>>(url);

  doErrorToastIfRequired(response);
  return mapHttpClientResponse(response, (m) => m);
};

export const checkIfEmailIsAvailable = async (
  email: string
): Promise<HttpClientResponse<boolean>> => {
  const url = buildApiUrl(`api-public/available-emails?email=${email}`);
  const response = await httpClient.getRequest<ApiStandardResponse<boolean>>(
    url
  );

  return mapHttpClientResponse(response, (m) => m);
};
