import { ResetPasswordFormFields } from ".";
import {
  defaultResetPasswordFormValues,
  resetPasswordFormValidationSchema,
  ResetPasswordFormValues,
} from "./ResetPasswordFormValues";
import { Form, Formik } from "formik";
import AppForm from "../../components/AppForm";
import React, { useState } from "react";
import AppFormikSubmitButton from "../../components/AppForm/AppFormikSubmitButton";
import { AppButtons } from "../../components/AppButton";
import { resetPassword } from "../../api/accountManagement";
import { publicPaths } from "../../routes";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

interface ResetPasswordProps {
  code: string;
  email: string;
  firstTimeLogin: boolean;
}

const useStyles = makeStyles((theme) => ({
  formLink: {
    color: theme.palette.info.main,
    textDecoration: "none",
  },
  buttonGroup: {
    marginTop: theme.spacing(4),
  },
}));

const ResetPasswordForm: React.FC<ResetPasswordProps> = ({
  code,
  email,
  firstTimeLogin,
}) => {
  const classes = useStyles();

  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [resetSubmit, setResetSubmit] = useState(false);

  const handleSubmit = async (values: ResetPasswordFormValues) => {
    setSubmissionAttempted(true);
    const resetPasswordDto = {
      code: code,
      email: email,
      password: values.password,
      confirmPassword: values.confirmPassword,
    };

    const response = await resetPassword(resetPasswordDto);

    if (!response.isError) {
      setResetSubmit(true);
    }
  };

  const wording = firstTimeLogin ? "Set" : "Reset";

  return (
    <>
      {!resetSubmit ? (
        <Formik
          initialValues={defaultResetPasswordFormValues}
          validationSchema={resetPasswordFormValidationSchema}
          validateOnBlur={submissionAttempted}
          onSubmit={handleSubmit}
        >
          <Form>
            <AppForm>
              <ResetPasswordFormFields.Password autoFocus />
              <ResetPasswordFormFields.ConfirmPassword />
            </AppForm>
            <div className={classes.buttonGroup}>
              <AppButtons>
                <AppFormikSubmitButton fullWidth>Submit</AppFormikSubmitButton>
              </AppButtons>
            </div>
          </Form>
        </Formik>
      ) : (
        <p>
          Your password has been {wording},
          <Link to={publicPaths.home} className={classes.formLink}>
            &nbsp;click here&nbsp;
          </Link>
          to login.
        </p>
      )}
    </>
  );
};

export default ResetPasswordForm;
