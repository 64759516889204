import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
    "& thead tr": {
      backgroundColor: theme.palette.common.white,
    },
  },
  tableHeader: {
    borderBottom: `2px solid ${theme.palette.text.primary}`,
    fontWeight: theme.typography.fontWeightLight,
    textAlign: "left",
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    verticalAlign: "bottom",
    transition: "all .2s ease-in-out",
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.common.white,
    "&:not(:first-of-type)": {
      textAlign: "right"
    },
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  tableRow: {
    "&:nth-child(2n)": {
      backgroundColor: theme.palette.backgrounds.grey,
    },
  },
  tableData: {
    fontWeight: theme.typography.fontWeightRegular,
    padding: theme.spacing(1.5),
    borderBottom: `1px solid ${theme.palette.secondaryGrey.main}`,
    "&:not(:first-of-type)": {
      textAlign: "right"
    }
  },
}));
