import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ApiResponseWrapper from "../../components/ApiResponseWrapper";
import AppTable from "../../components/AppTable";
import { actions, selectors, useDispatchEffect } from "../../store";
import CustomersTableRow from "./CustomersTableRow";

const CustomersTable: React.FC = () => {
  useDispatchEffect(actions.customers.fetchAll);
  const dispatch = useDispatch();

  const { isFetching, isError } = useSelector(selectors.customers.apiState);

  const customers = useSelector(selectors.customers.all);
  const pagination = useSelector(selectors.customers.pagination);
  const activeSortBy = useSelector(selectors.customers.selectSortBy);
  const noCustomersMessage =
    "We cannot find a customer with those details, please amend your search and try again.";

  const handleChangePageNumber = (pageNumber: number) => {
    dispatch(actions.customers.setPageNumber(pageNumber));
  };

  const handleChangePageSize = (pageSize: number) => {
    dispatch(actions.customers.setPageSize(pageSize));
  };

  const onHeaderClick = (sortByString: string) => {
    dispatch(actions.customers.setSortBy(sortByString));
  };

  return (
    <>
      <ApiResponseWrapper isFetching={isFetching} isError={isError}>
        <AppTable
          headers={[
            { label: "First Name", propertyName: "firstName" },
            { label: "Last Name", propertyName: "lastName" },
            { label: "Email", propertyName: "email" },
            { label: "Phone Number" },
            { label: "Company", propertyName: "company" },
            { label: "Address" },
          ]}
          entities={customers}
          noEntitiesMessage={noCustomersMessage}
          pagination={pagination}
          onChangePageNumber={handleChangePageNumber}
          onChangePageSize={handleChangePageSize}
          onHeaderClick={onHeaderClick}
          activeSortBy={activeSortBy}
          renderRow={(customer) => (
            <CustomersTableRow key={customer.id} customer={customer} />
          )}
        />
      </ApiResponseWrapper>
    </>
  );
};

export default CustomersTable;
