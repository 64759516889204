import {fade, makeStyles} from "@material-ui/core";
import {blue} from "@material-ui/core/colors";

export const BookingFormStyles = makeStyles((theme) => ({
    actions: {
        marginTop: theme.spacing(2),
    },
    block: {
        display: "block"
    },
    hidden: {
        display: "none"
    },
    mainContainer: {
        backgroundColor: theme.palette.backgrounds.main,
        padding: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(4),
        },
    },
    stepContainer: {
        backgroundColor: theme.palette.common.white,
    },
    stepContainerTitle: {
        margin: 0,
    },
    optionalExtraSearchContainer: {
        maxWidth: 240,
    },
    optionalExtraOptionList: {
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(2),
        "& .MuiCheckbox-root": {
            paddingTop: 3,
            paddingBottom: 3,
        },
    },
    optionalExtraOptionListDisabled: {
        pointerEvents: "none",
        cursor: "not-allowed",
        "& > .MuiFormControlLabel-root": {
            opacity: 0.6,
        },
    },
    twoColContainer: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: theme.spacing(4),
        marginTop: theme.spacing(2),
    },
    resourceSelectContainer: {
        marginTop: theme.spacing(1),
    },
    textAreaContainer: {
        marginBottom: theme.spacing(4),
        backgroundColor: theme.palette.backgrounds.grey,
        "& textarea": {
            padding: theme.spacing(1),
        },
    },
    bookingSlotContainer: {
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        backgroundColor: fade(theme.palette.primary.main, 0.05),
    },
    bookingSlot: {
        height: theme.spacing(5),
        backgroundColor: theme.palette.backgrounds.grey,
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        transition: "all .2s ease-in-out",
        "& > .MuiButton-label": {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "100%",
            position: "relative",
            justifyContent: "start",
            display: "block",
        },
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
    },
    bookingSlotSelected: {
        height: theme.spacing(5),
        backgroundColor: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightMedium,
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        "& > .MuiButton-label": {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "100%",
            position: "relative",
            justifyContent: "start",
            display: "block",
        },
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
    },
    recommendedSlot: {
        height: theme.spacing(5),
        backgroundColor: fade(theme.palette.secondary.main, 0.3),
        border: `1px solid ${theme.palette.secondary.main}`,
        fontWeight: theme.typography.fontWeightMedium,
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        transition: "all .2s ease-in-out",
        "& > .MuiButton-label": {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "100%",
            position: "relative",
            justifyContent: "start",
            display: "block",
        },
        "&:hover": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
        },
    },
    recommendedSlotSelected: {
        height: theme.spacing(5),
        backgroundColor: theme.palette.secondary.main,
        border: `1px solid ${theme.palette.secondary.main}`,
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightMedium,
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        "& > .MuiButton-label": {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "100%",
            position: "relative",
            justifyContent: "start",
            display: "block",
        },
        "&:hover": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
        },
    },
    recommendedBookingContainer: {
        marginTop: theme.spacing(2),
        borderBottom: `1px solid ${fade(theme.palette.secondary.main, 0.2)}`,
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        backgroundColor: fade(theme.palette.secondary.main, 0.1),
        "& h4": {
            marginBottom: theme.spacing(1),
        },
    },
    ecoIcon: {
        height: 22,
        marginLeft: theme.spacing(1),
    },
    bookingSlotGrid: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: theme.spacing(1),
        [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "1fr 1fr 1fr",
        },
    },
    searchInputWithButton: {
        display: "flex",
        "& button": {
            marginLeft: theme.spacing(2),
        },
    },
    bookingSlotMessageBox: {
        marginTop: theme.spacing(2),
        backgroundColor: theme.palette.backgrounds.blue,
        padding: theme.spacing(2),
    },
    serviceProviderContainer: {
        marginBottom: theme.spacing(2),
    },
    createCustomerButton: {
        display: "flex",
        alignItems: "left",
        backgroundColor: "transparent",
        border: "none",
        color: "#4a74b8",
        paddingTop: "10px",
        cursor: "pointer",
        position: "relative",
        fontSize: theme.typography.body1.fontSize,
        fontWeight: theme.typography.body1.fontWeight
    },
    plusIcon: {
        marginLeft: -8,
        marginTop: -2.5,
        alignItems: "left",
    },
    tagList: {
        listStyle: "none",
        margin: 0,
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        backgroundColor: theme.palette.backgrounds.grey,
        minHeight: 100,
        maxHeight: 168,
        overflowY: "auto",
        flexWrap: "wrap",
        "& > li": {
            display: "inline-block",
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },
    },
    errorMsg: {
        paddingTop: theme.spacing(2),
        textAlign: "center",
    },
    mobileErrorMsg: {
        textAlign: "center",
        marginTop: theme.spacing(2),
    },
}));