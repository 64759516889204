import { CreateInviteToBookDto, InviteToBookDto } from "../models/Booking";
import { ApiStandardResponse, buildApiUrl, doErrorToastIfRequired, httpClient, mapHttpClientListResponse, mapHttpClientResponse } from "./common";
import { ApiListResponse } from "./common/ApiListResponse";
import { HttpClientResponse } from "./common/httpClient";
import { ApiBookableSlotDto, ApiBookableSlotsInviteToBookParameters } from "./models/BookableSlots";

export const getInviteToBookDetails = async (
  guid: string
): Promise<HttpClientResponse<InviteToBookDto>> => {
  const url = buildApiUrl(`api-public/invite-to-book/${guid}`);
  const response = await httpClient.getRequest<ApiStandardResponse<InviteToBookDto>>(url);

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, (booking) => booking);
};


export const createInviteToBook = async (
  createInviteToBookDto: CreateInviteToBookDto,
  vendorId: number
): Promise<HttpClientResponse<number>> => {
  const url = buildApiUrl(`api-public/booking-invite?vendorId=${vendorId}`);
  const response = await httpClient.postRequest<ApiStandardResponse<number>>(
    url,
    createInviteToBookDto
  );

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, (bookingId) => bookingId);
};

export const getBookableSlotsInviteToBook = async ({
  customerAddressId,
  appointmentTypeId,
  optionalExtras,
  vendorId,
  date,
}: ApiBookableSlotsInviteToBookParameters): Promise<
  HttpClientResponse<ApiListResponse<ApiBookableSlotDto>>
> => {
  const queryParameters = [
    `vendorId=${vendorId}`,
    `appointmentTypeId=${appointmentTypeId}`,
    date ? `date=${date}` : "",
    `customerAddressId=${customerAddressId}`,
    `${optionalExtras}`,
  ];
  const url = buildApiUrl(
    `api-public/bookable-slots/invite-to-book?${queryParameters.join("&")}`
  );
  const response = await httpClient.getRequest<
    ApiListResponse<ApiBookableSlotDto>
  >(url);
  if (response.statusCode !== 500) {
    doErrorToastIfRequired(response);
  }
  return mapHttpClientListResponse(response, (m) => m);
};