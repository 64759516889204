import { useField } from "formik";
import { useCallback, useEffect } from "react";
import { useHttpRequest } from "../../api";
import { getAllResourceTypes } from "../../api/resources";
import { AppSelectOption } from "../../components/AppForm/AppSelect";
import { ResourceType } from "../../models/Resource";
import ResourceFormFields from "./ResourceFormFields";

const mapResponseToSelectOptions = ({
  name,
  id,
}: ResourceType): AppSelectOption => {
  return { label: `${name}`, value: id };
};

const ResourceFormResourceTypeSelect: React.FC = () => {
  const getResourceTypes = useCallback(() => getAllResourceTypes(), []);
  const { result, isLoading } = useHttpRequest(getResourceTypes);
  const [
    { value: selectedResourceTypeId },
    ,
    { setValue: setSelectedResourceTypeId },
  ] = useField<number>("typeId");
  const defaultResourceType = result?.content.find((type) => type.isDefault);

  if (defaultResourceType && selectedResourceTypeId === -1) {
    setSelectedResourceTypeId(defaultResourceType.id);
  }

  return (
    <ResourceFormFields.typeId
      options={result ? result.content.map(mapResponseToSelectOptions) : []}
      isLoading={isLoading}
    />
  );
};

export default ResourceFormResourceTypeSelect;
