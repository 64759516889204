import { makeStyles, fade } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  container: {
    display: "flex",
  },
  inputRow: {
    display: "flex",
    alignItems: "center",
    border: `1px solid ${theme.palette.secondaryGrey.main}`,
    borderRadius: 3,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    flex: "none",
  },
  inputItem: {
    border: "none",
    height: 30,
    width: 34,
    textAlign: "center",
    borderRadius: 3,
    appearance: "textfield",
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&:last-of-type": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  inputError: {
    backgroundColor: fade(theme.palette.error.light, 0.5),
    outlineColor: theme.palette.error.main,
    outlineWidth: 2,
  },
  divider: {
    flex: "none",
    width: 10,
    textAlign: "center",
  },
  timePeriodPicker: {
    display: "flex",
    alignItems: "center",
    border: "none",
    backgroundColor: theme.palette.backgrounds.grey,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: 3,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    width: 38,
    "& > svg": {
      fill: theme.palette.secondaryGrey.dark,
      opacity: 0.75,
    },
  },
  arrowRow: {
    display: "flex",
    alignItems: "center",
  },
  arrowButton: {
    width: 34,
    border: "none",
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
}));
