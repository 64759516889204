import * as Yup from "yup";
import { ukPostcodeRegex } from "../../constants";
import { validatePostcodeExists, validatePostcodeRegex } from "../../customers/Form/CustomerAddressFormValues";
import { RecurringBookableSlotWithSelection } from "../../models/RecurringBookableSlot";

export interface BookingFormPage1Values {
  customerId: number;
  customerAddressId: number;
  isCreateCustomer?: boolean;
}

export interface BookingFormPage1aValues {
  firstname?: string;
  lastname?: string;
  email?: string;
  phone?: string;
  companyName?: string;
}

export interface BookingFormPage1bValues {
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  town?: string;
  postcode?: string;
  notes?: string;
}

export interface BookingFormPage2Values {
  firstJobAppointmentTypeId?: number;
  firstJobOptionalExtraIds: number[];
  paymentStatusId: number;
  bookingStatus: number;
  overriddenTotalPrice?: number;
  totalPrice?: number;
  isEcoSlot: boolean;
  tags?: string[];
}

export interface BookingFormPage3Values {
  resourceId: number;
  date: Date;
  start: number;
  end: number;
  validBookableDates: boolean;
  hasSelectedTimeSlot: boolean;
}

export interface BookingFormPage4Values {
  recurringBooking?: boolean;
  recurringNumberOfWeeksBetweenBookings?: number;
  recurringNumberOfOccurances?: number;
}

export interface BookingFormPage5Values {
  recurringBookableSlots?: RecurringBookableSlotWithSelection[];
}

export interface BookingFormPage6Values {
  notes: string;
  description: string;
  muteConfirmationEmail?: boolean;
}

export type BookingFormValues = BookingFormPage1Values &
  BookingFormPage1aValues &
  BookingFormPage1bValues &
  BookingFormPage2Values &
  BookingFormPage3Values &
  BookingFormPage4Values &
  BookingFormPage5Values &
  BookingFormPage6Values;

export const defaultBookingFormValues: BookingFormValues = {
  resourceId: -1,
  date: new Date(),
  paymentStatusId: -1,
  start: 540,
  end: 1020,
  validBookableDates: false,
  hasSelectedTimeSlot: false,
  notes: "",
  tags: [],
  description: "",
  customerId: -1,
  customerAddressId: -1,
  firstJobAppointmentTypeId: -1,
  firstJobOptionalExtraIds: [],
  bookingStatus: -1,
  muteConfirmationEmail: false,
  isEcoSlot: false,
  isCreateCustomer: false,
  firstname: ""
};

const bookingFormPage1ValidationSchema: Yup.SchemaOf<BookingFormPage1Values> =
  Yup.object().shape({
    customerId: Yup.number().required()
      .when(('isCreateCustomer'), {
        is: (val: boolean) => !val,
        then: Yup.number().moreThan(0, "Please select a customer.").required("Please select a customer.")
      }),

    customerAddressId: Yup.number().required()
    .when(('isCreateCustomer'), {
      is: (val: boolean) => !val,
      then: Yup.number().moreThan(0, "Please select a customer address.").required("Please select a customer address."),
    }),
      
    isCreateCustomer: Yup.boolean()
  });

  const bookingFormPage1aValidationSchema: Yup.SchemaOf<BookingFormPage1aValues> =
  Yup.object().shape({
    firstname: Yup.string().required("Please provide a first name."),
    lastname: Yup.string().required("Please provide a surname."),
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("Please provide an email address."),
    companyName: Yup.string().ensure(),
    phone: Yup.string()
      .required("Please provide a phone number.")
      .min(7, "Phone number must be between 7 and 15 characters")
      .max(15, "Phone number must be between 7 and 15 characters"),
  });

  const bookingFormPage1bValidationSchema: Yup.SchemaOf<BookingFormPage1bValues> =
  Yup.object().shape({
    addressLine1: Yup.string().required("Please provide an address."),
  addressLine2: Yup.string().notRequired(),
  addressLine3: Yup.string().notRequired(),
  notes: Yup.string().notRequired(),
  town: Yup.string().required("Please provide a town or city."),
  postcode: Yup.string()
    .required("Please enter your postcode")
    .matches(ukPostcodeRegex, "Must be valid postcode including the space.")
    .test(
      "validatePostcodeFormat",
      "Please check you have entered a valid postcode",
      (postcode) => {
        if (!postcode) return true;

        const validRegexResult = validatePostcodeRegex(postcode);

        if (!validRegexResult) return false;

        return validatePostcodeExists(postcode);
      }
    ),
  });


//TODO: Add validation for page 2 Tags
const bookingFormPage2ValidationSchema = (isEdit?: boolean) => {
  return Yup.object().shape({
    firstJobAppointmentTypeId: Yup.number().min(0, "Please select a service type.").required(),
    firstJobOptionalExtras: Yup.array()
        .of(
            Yup.object()
                .shape({ optionalExtraId: Yup.number(), quantity: Yup.number() })
                .required()
        )
        .required()
        .when("requiresOptionalExtra", {
          is: (val: boolean) => val,
          then: Yup.array()
              .min(1, "Please select at least one optional extra")
              .required(),
          otherwise: Yup.array(),
        }),
    paymentStatusId: Yup.number()
      .moreThan(0, "Please select a payment status")
      .required(),
    bookingStatus: isEdit
      ? Yup.number().moreThan(-1, "Please select a booking status").required()
      : Yup.number(),
  });
};

const createBookingFormPage3ValidationSchema = (
  hasPreselectedResource?: boolean
) => {
  return Yup.object().shape({
    resourceId: hasPreselectedResource
      ? Yup.number().required()
      : Yup.number()
          .moreThan(0, "Please select a mobile worker.")
          .required("Please select a mobile worker."),
    date: Yup.date().required("Please select a date."),
    start: Yup.number()
      .lessThan(
        Yup.ref("end"),
        "Booking start time must be before booking end time."
      )
      .required("Please provide a start time."),
    end: Yup.number()
      .moreThan(
        Yup.ref("start"),
        "Booking end time must be after booking start time."
      )
      .required("Please provide a end time."),
    validBookableDates: Yup.boolean().test(
      "testValidBookableDates",
      "This mobile worker no longer trades on this date.",
      (value) => value !== false
    ),
    hasSelectedTimeSlot: Yup.boolean().test(
      "testhasSelectedTimeSlot",
      "Please select a time slot or manually override the algorithm.",
      (value) => value !== false
    ),
  });
};

const bookingFormPage4ValidationSchema = Yup.object().shape({
  recurringBooking: Yup.bool(),
  recurringNumberOfWeeksBetweenBookings: Yup.number().when("isRecurringBooking", {
    is: (val: boolean) => val,
    then: Yup.number().required(),
  }),
  recurringNumberOfOccurances: Yup.number().when("isRecurringBooking", {
    is: (val: boolean) => val,
    then: Yup.number().required(),
  }),
});

const bookingFormPage5ValidationSchema = Yup.object().shape({
  recurringBookableSlots: Yup.array().when("isRecurringBooking", {
    is: (val: boolean) => val,
    then: Yup.array().test({
      message: "Please select at least one booking slot.",
      test: val => val ? val.filter(i => i.isSelected).length > 0 : false
    })
  })
});

const bookingFormPage6ValidationSchema = Yup.object().shape({
  notes: Yup.string(),
  description: Yup.string(),
});

export const createBookingFormPageValidationSchemas = (
  hasPreselectedResource?: boolean,
  isEdit?: boolean
): unknown[] => {
  return [
    bookingFormPage1ValidationSchema,
    bookingFormPage1aValidationSchema,
    bookingFormPage1bValidationSchema,
    bookingFormPage2ValidationSchema(isEdit),
    createBookingFormPage3ValidationSchema(hasPreselectedResource),
    bookingFormPage4ValidationSchema,
    bookingFormPage5ValidationSchema,
    bookingFormPage6ValidationSchema,
  ];
};
