import {IconButton, makeStyles} from "@material-ui/core";
import { RemoveCircle, Edit as EditPencil} from "@material-ui/icons";
import { Jobs } from "../../../models/Booking";
import { OptionalExtrasListView } from "../optional-extras";
import { AppointmentType } from "../../../models/AppointmentType";
import { excludeVATFromPriceIfRequired } from "../../../numericalUtils";

interface ServicesListViewProps {
    services: Jobs[],
    appointmentTypes: AppointmentType[],
    currentTaxRatePercentage: number,
    showOptionalExtras?: boolean,
    canRemoveServices?: boolean,
    handleRemoveService?(service: Jobs): void;
    canEditServices?: boolean;
    handleEditService?(service: Jobs): void;
}

const useStyles = makeStyles((theme) => ({
    appointment: {
        fontSize: "14px",
        lineHeight: "14px",
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        // paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(0.5),
    },
    appointmentName: {
        display: "flex",
        alignItems: "center",
        paddingRight: theme.spacing(3),
    },
    appointmentPricing: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    optionalList: {
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
    },
    removeButton: {
        border: "none",
        cursor: "pointer",
        color: "red",
        display: "flex",
        alignItems: "center",
        paddingRight: theme.spacing(2),
    },
    editButton: {
        border: "none",
        cursor: "pointer",
        marginLeft: theme.spacing(1),
        "& svg": {
            fill: "url(#BrandGradient)",
        },
    },
    overriddenPrice: {
        textDecoration: "line-through",
        paddingLeft: theme.spacing(0.5)
    }
}));

const ServicesListView: React.FC<ServicesListViewProps> = ({
    services,
    appointmentTypes,
    currentTaxRatePercentage,
    showOptionalExtras = true,
    canRemoveServices = false,
    handleRemoveService,
    canEditServices = false,
    handleEditService
}) => {
    const classes = useStyles();

    const renderPricing = (service: Jobs) => {
        const isOverridden = service.overriddenPrice != null;
        const price = excludeVATFromPriceIfRequired(service.price, currentTaxRatePercentage).toFixed(2);
        const displayOverriddenPrice = isOverridden ? (<span>{excludeVATFromPriceIfRequired(service.overriddenPrice ?? 0, currentTaxRatePercentage).toFixed(2)}</span>) : ('');
        const displayPrice = isOverridden ? (<span className={classes.overriddenPrice}>(&pound;{price})</span>) : (<span>{price}</span>);
        return (
            <span>
                &pound;{displayOverriddenPrice}{displayPrice}{currentTaxRatePercentage ? ` (ex. VAT)` : ``}
            </span>
        );
    };

    return (
        <>
            {
                services.map((service) => {
                    const appointment = appointmentTypes.find(e => e.id === service.appointmentTypeId);
                    return (
                        <div key={`service-id-${service.appointmentTypeId}`}>
                            <div className={classes.appointment}>
                                <div className={classes.appointmentName}>
                                    <p>{appointment?.name}</p>
                                    {canEditServices &&
                                        <button
                                            type="button"
                                            onClick={() => { handleEditService && handleEditService(service); }}
                                            className={classes.editButton}
                                            title="Edit service">
                                            <EditPencil />
                                        </button>
                                    }
                                    {canRemoveServices &&
                                        <button onClick={() => { handleRemoveService && handleRemoveService(service); }}
                                            type="button"
                                            className={classes.removeButton}
                                            title="Remove service">
                                            <RemoveCircle />
                                        </button>
                                    }
                                </div>
                                <div className={classes.appointmentPricing}>
                                    {renderPricing(service)}
                                </div>
                            </div>
                            {showOptionalExtras &&
                                <div className={classes.optionalList}>
                                    <OptionalExtrasListView
                                        appointmentTypes={appointmentTypes}
                                        appointmentTypeId={service.appointmentTypeId}
                                        optionalExtras={service.optionalExtras}
                                        currentTaxRatePercentage={currentTaxRatePercentage ?? 0}
                                        isPriceOverridden={service.overriddenPrice != null}
                                    />
                                </div>
                            }
                        </div>
                    );
                })}
        </>
    );
};

export default ServicesListView;