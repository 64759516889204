import {
  IconButton,
  makeStyles,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export interface AppModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(2),
  },
  subtitle: {
    marginTop: theme.spacing(0),
  },
  content: {
    boxShadow: theme.shadows[5],
    margin: "auto",
    outline: "none",
    padding: theme.spacing(4),
    position: "absolute",
    minWidth: "75%",
    maxWidth: "95%",
    maxHeight: "90%",
    overflowY: "auto",
    borderRadius: 0,
    [theme.breakpoints.up("md")]: {
      minWidth: "33%",
    },
  },
  actionBtn: {
    borderRadius: 0,
    position: "absolute",
    top: "6px",
    right: "6px",
  },
}));

const AppModal: React.FC<AppModalProps> = ({
  isOpen,
  onClose,
  children,
  title,
  description,
}) => {
  const classes = useStyles();

  return (
    <>
      <Modal
        open={isOpen}
        className={classes.root}
        aria-labelledby={title}
        aria-describedby={description}
      >
        <Paper className={classes.content}>
          <IconButton
            onClick={onClose}
            size="medium"
            className={classes.actionBtn}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" component="h2" gutterBottom align="center">
            {title}
          </Typography>
          {description && <p className={classes.subtitle}>{description}</p>}
          {children}
        </Paper>
      </Modal>
    </>
  );
};

export default AppModal;
