import {
  Button,
  ButtonProps,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";

export interface AppButtonProps extends ButtonProps {
  showSpinner?: boolean;
  linkPath?: string;
  gradient?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    whiteSpace: "nowrap",
    textDecoration: "none",
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-12px",
    marginLeft: "-12px",
  },
  button: {
    boxShadow: "none",
    borderRadius: "2px",
    textTransform: "none",
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.body1.fontWeight
  },
  buttonPrimary: {
    background: theme.palette.primary.main,
    transition: "0.7s",
    backgroundSize: "200% auto",
    backgroundPosition: "right",
    textTransform: "uppercase",
    // increasing padding by 1px each way due to border on secondary button causing the
    // primary button to look 1px smaller
    padding: "8px 24px",
    "&:hover": {
      background: theme.palette.primary.light,
      boxShadow: "none",
    },
  },
  buttonPrimaryGradient: {
    backgroundImage: `linear-gradient(
            to left,
            ${theme.palette.primary.light} 0%,
            ${theme.palette.primary.main} 51%,
            ${theme.palette.primary.dark} 100%)`,
    transition: "0.7s",
    backgroundSize: "200% auto",
    backgroundPosition: "right",
    textTransform: "uppercase",
    // increasing padding by 1px each way due to border on secondary button causing the
    // primary button to look 1px smaller
    padding: "8px 24px",
    "&:hover": {
      backgroundPosition: "left center",
      boxShadow: "none",
    },
  },
  buttonSecondary: {
    color: theme.palette.primary.main,
    background: "#FAF9F9 0% 0% no-repeat padding-box",
    transition: "0.7s",
    border: `1px solid ${theme.palette.primary.main}`,
    opacity: "1",
    "&:hover": {
      background: theme.palette.backgrounds.blue,
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: "none",
    },
  },
  buttonTextVariant: {
    transition: "color 0.7s ease-in-out",
    backgroundPosition: "right",
    backgroundSize: "200% 200%",
    textTransform: "none",
    "&:hover": {
      backgroundPosition: "left center",
      color: theme.palette.primary.light,
      background: `linear-gradient(
                to right,
                ${theme.palette.primary.main},
                ${theme.palette.primary.light})`,
      WebkitBackgroundClip: "text",
      boxShadow: "none",
    },
  },
}));

const AppButton: React.FC<AppButtonProps> = ({
  disabled,
  showSpinner,
  linkPath,
  color = "primary",
  variant = "contained",
  gradient = true,
  ...restOfProps
}) => {
  const classes = useStyles();

  let buttonClass = "";

  if (variant === "text") {
    buttonClass = classes.buttonTextVariant;
  } else if (color === "primary") {
    buttonClass = gradient
      ? classes.buttonPrimaryGradient
      : classes.buttonPrimary;
  } else if (color === "secondary") {
    buttonClass = classes.buttonSecondary;
  }
  return (
    <span className={classes.root}>
      <Button
        {...(restOfProps as never)}
        className={`${classes.button} ${buttonClass} ${restOfProps?.className}`}
        component={linkPath ? Link : undefined}
        to={linkPath}
        disabled={disabled || showSpinner}
        color={color}
        variant={variant}
      />
      {showSpinner && (
        <CircularProgress size={24} className={classes.progress} />
      )}
    </span>
  );
};

export default AppButton;
