import { makeStyles, Grid } from "@material-ui/core";
import { useField } from "formik";
import { AppointmentType } from "../../models/AppointmentType";
import AppButton from "../../components/AppButton";
import { useEffect } from "react";
import { excludeVATFromPriceIfRequired } from "../../numericalUtils";

const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: theme.spacing(0.1),
  },
  appointmentSelector: {
    marginLeft: theme.spacing(0),
    width: "100%",
    "& > *": {
      borderTop: "2px solid #dcdcdc",
    },
    "& > *:last-child": {
      borderBottom: "2px solid #dcdcdc",
    },
  },
  appointmentType: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    [theme.breakpoints.down(600)]: {
      display: "block",
    },
  },
  mobileServiceInfo: {
    display: "flex",
  },
  mobileAppointmentName: {
    flexGrow: 2,
  },
  appointmentPrice: {
    fontWeight: "bold",
    marginRight: theme.spacing(2),
  },
  spacer: {
    flexGrow: 1,
  },
  button: {
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
}));

interface WidgetAppointmentTypeSelect {
  appointmentTypes: AppointmentType[];
  scrollToTop: () => void;
  currentTaxRatePercentage?: number;
  isUpdatingExistingService: boolean;
  setIsUpdatingExistingService: (value: boolean) => void;
}


const WidgetAppointmentTypeSelect: React.FC<WidgetAppointmentTypeSelect> = ({
  appointmentTypes,
  scrollToTop,
  currentTaxRatePercentage
}) => {
  const classes = useStyles();
  const [
    { value: selectedService },
    ,
    { setValue: setFirstJobAppointmentTypeId },
  ] = useField<number>("firstJobAppointmentTypeId");
  const [inputProps, fieldMeta, helpers] = useField("requiresOptionalExtra");

  const handleClick = (clickedId: number) => {
    const serviceIsAlreadySelected = selectedService === clickedId;
    setFirstJobAppointmentTypeId(serviceIsAlreadySelected ? -1 : clickedId);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const appointmentTypeDetail = appointmentTypes.find(
    (type) => type.id === selectedService
  );

  useEffect(() => {
    const requiresOptionalExtra = appointmentTypeDetail?.optionalExtraRequired;
    helpers.setValue(requiresOptionalExtra ?? false);
  }, [appointmentTypeDetail]);

  const getPriceExcludingVatIfRequired = (
    price : number
  ) : string  => {
    const newPrice = excludeVATFromPriceIfRequired(price, currentTaxRatePercentage ?? 0);
    return newPrice.toFixed(2);
  };

  const renderNoServicesAvailable = () => {
    if (appointmentTypes.length != 0) {
      return;
    }

    return (
        <>
          <h2>Sorry!</h2>
          <p>Unfortunately we can not provide any services for your area at this time</p>
        </>
    );
  };

  const renderServices = () => {
    // if (appointmentTypes.length == 0) {
    //   return;
    // }

    return (
        <>
          <h2 className={classes.header}>Choose a service</h2>
          <p>Pick from our services below to start your booking</p>

          <Grid item xs={12}>
            <div className={classes.appointmentSelector}>
              {appointmentTypes.map((option) => (
                  <div key={option.id} className={classes.appointmentType}>
                    {screen.width <= 599 && (
                        <>
                          <div className={classes.mobileServiceInfo}>
                            <p className={classes.mobileAppointmentName}>
                              <b>{option.name}</b>
                            </p>
                            <p>
                              {option.price > 0 && <b>£{getPriceExcludingVatIfRequired(option.price)} {currentTaxRatePercentage ? " (ex. VAT)" : ""}</b>}
                            </p>
                          </div>
                          <p>{option.description}</p>
                        </>
                    )}

                    {screen.width >= 600 && (
                        <>
                          <div>
                            <p>
                              <b>{option.name}</b>
                            </p>
                            <p>{option.description}</p>
                          </div>
                          <div className={classes.spacer} />
                          {option.price > 0 && (
                              <p className={classes.appointmentPrice}>
                                £{getPriceExcludingVatIfRequired(option.price)}
                                {currentTaxRatePercentage ? " (ex. VAT)" : ""}
                              </p>
                          )}
                        </>
                    )}

                    <div>
                      <AppButton
                          className={classes.button}
                          variant={
                            option.id === selectedService ? "contained" : "outlined"
                          }
                          color={
                            option.id === selectedService ? "primary" : "secondary"
                          }
                          gradient={false}
                          onClick={() => handleClick(option.id)}
                          style={{ textTransform: "capitalize" }}
                      >
                        {option.id === selectedService
                            ? "Selected"
                            : "Select this service"}
                      </AppButton>
                    </div>
                  </div>
              ))}
            </div>
          </Grid>
        </>
    );
  };

  return (
      <>
        {renderServices()}
        {/*{renderNoServicesAvailable()}*/}
      </>
  );
};

export default WidgetAppointmentTypeSelect;
