import { Form, Formik } from "formik";
import { checkIfEmailIsAvailable } from "../../api/accountManagement";
import {
  customerDetailsFormValidationSchema,
  CustomerDetailsFormValues,
  defaultCustomerDetailsFormValues,
  emailFieldName,
} from "./CustomerFormValues";

interface CustomerEditFormProps {
  onSubmit: (customer: CustomerDetailsFormValues) => Promise<void>;
  initialValues: CustomerDetailsFormValues;
}

const CustomerEditForm: React.FC<CustomerEditFormProps> = ({
  onSubmit,
  initialValues,
  children,
}) => {
  return (
    <Formik
      initialValues={initialValues || defaultCustomerDetailsFormValues}
      validationSchema={customerDetailsFormValidationSchema}
      onSubmit={async (values, formikBag) => {
        const { email: newEmail } = values;
        const { email: currentEmail } = initialValues;

        if (newEmail !== currentEmail) {
          const emailIsAvailable = await checkIfEmailIsAvailable(newEmail);

          if (emailIsAvailable.isError) {
            formikBag.setFieldError(
              emailFieldName,
              `Email ${newEmail} is already in use.`
            );
            return;
          }
        }

        await onSubmit(values);
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
};

export default CustomerEditForm;
