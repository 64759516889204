import { Calendar } from "@material-ui/pickers";
import { useState, useEffect } from "react";
import { ArrowBack as LeftArrowIcon } from "@material-ui/icons";
import { getBookableSlots } from "../../../api/bookableSlots";
import {
  ApiBookableSlotDto,
  ApiBookableSlotsParameters,
} from "../../../api/models/BookableSlots";
import AppDrawer from "../../../components/AppDrawer";
import {
  addXMinutesToDate,
  formatDateDateOnly,
  formatTimeOnly,
  toUTCISOString,
} from "../../../dateFormatters";
import { Button, IconButton, makeStyles } from "@material-ui/core";
import AppButton from "../../../components/AppButton";
import AppLoader from "../../../components/AppLoader";
import { useField } from "formik";
import { RecurringBookableSlotWithSelection } from "../../../models/RecurringBookableSlot";
import { addMinutes, isSameDay } from "date-fns";
import { useSelector } from "react-redux";
import { selectors } from "../../../store";
import { CustomerAddress } from "../../../models/Customers";
import { Tag } from "../../../models/Tag";
import { Jobs } from "../../../models/Booking";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(2),
  },
  saveBtn: {
    marginLeft: "auto",
  },
  calendarSlotContainer: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    gridGap: theme.spacing(4),
    [theme.breakpoints.up(768)]: {
      flexDirection: "row",
    },
  },
  calendar: {
    flex: "none",
    "& .MuiPickersCalendarHeader-switchHeader": {
      background: `linear-gradient(
                to right,
                ${theme.palette.primary.main},
                ${theme.palette.primary.light})`,
      color: theme.palette.common.white,
    },
    "& .MuiPickersCalendarHeader-iconButton": {
      backgroundColor: "transparent",
      "& .MuiSvgIcon-root": {
        fill: theme.palette.common.white,
      },
    },
  },
  slots: {
    flex: 1,
  },
  bookingSlotButton: {
    display: "block",
    width: "100%",
    height: theme.spacing(5),
    backgroundColor: theme.palette.backgrounds.grey,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: "all .2s ease-in-out",
    "& > .MuiButton-label": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "100%",
      position: "relative",
      justifyContent: "start",
      display: "block",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  bookingSlotButtonSelected: {
    height: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& > .MuiButton-label": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "100%",
      position: "relative",
      justifyContent: "start",
      display: "block",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}));

interface BookingFormRecurringSlotSelectionEditPanelProps {
  open: boolean;
  initialDate: Date;
  appointmentTypeId: number;
  resourceId: number;
  postcode: string;
  customerAddressId: number;
  onClose: () => void;
  onSelect: (bookableSlot: ApiBookableSlotDto) => void;
}

const BookingFormRecurringSlotSelectionEditPanel: React.FC<BookingFormRecurringSlotSelectionEditPanelProps> =
  ({
    open,
    initialDate,
    appointmentTypeId,
    resourceId,
    customerAddressId,
    postcode,
    onClose,
    onSelect,
  }) => {
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(true);
    const [bookableSlots, setBookableSlots] = useState<ApiBookableSlotDto[]>(
      []
    );
    const [selectedBookableSlot, setSelectedBookableSlot] =
      useState<ApiBookableSlotDto>();

    const [{ value: recurringBookableSlots }] = useField<
      RecurringBookableSlotWithSelection[]
    >("recurringBookableSlots");

    const basketStoreServices = useSelector(selectors.basket.services);

    const [{ value: tags }] = useField<Tag[]>("tags");

    const fetchBookableSlots = async () => {
      setIsLoading(true);
      const params: ApiBookableSlotsParameters = {
        date: toUTCISOString(selectedDate),
        postcode: postcode,
        resourceId: resourceId,
        appointmentTypeId: appointmentTypeId,
        optionalExtras: [],
        jobs: basketStoreServices,
        customerAddressId: customerAddressId,
        tagIds: tags,
      };

      const response = await getBookableSlots(params);

      if (!response.isError) {
        const existingRecurringBookableSlotsOnSelectedDay =
          recurringBookableSlots.filter((r) =>
            r.bookableSlot?.start
              ? isSameDay(new Date(r.bookableSlot?.start), selectedDate)
              : false
          );

        const nonOverlappingBookableSlots = response.content.content.filter(
          (bs) => {
            let isConflicting = false;

            existingRecurringBookableSlotsOnSelectedDay.forEach(
              (existingBookableSlot) => {
                if (existingBookableSlot?.start && existingBookableSlot?.end) {
                  if (
                    new Date(bs.start) < new Date(existingBookableSlot.end) &&
                    addMinutes(new Date(bs.start), bs.durationMinutes) >
                      new Date(existingBookableSlot.start)
                  ) {
                    isConflicting = true;
                  }
                }
              }
            );

            return !isConflicting;
          }
        );

        setBookableSlots(nonOverlappingBookableSlots);
        setIsLoading(false);
      }
    };

    useEffect(() => {
      if (open) {
        setSelectedDate(initialDate);
        setSelectedBookableSlot(undefined);
      }
    }, [open, initialDate]);

    useEffect(() => {
      fetchBookableSlots();
    }, [selectedDate]);

    return (
      <AppDrawer open={open} onClose={onClose} anchor="right">
        <div className={classes.header}>
          <IconButton onClick={onClose}>
            <LeftArrowIcon />
          </IconButton>
          <span>{formatDateDateOnly(initialDate)}</span>
          <div className={classes.saveBtn}>
            <AppButton
              onClick={() =>
                selectedBookableSlot ? onSelect(selectedBookableSlot) : null
              }
              disabled={selectedBookableSlot === undefined}
            >
              Save
            </AppButton>
          </div>
        </div>
        <div className={classes.calendarSlotContainer}>
          <div className={classes.calendar}>
            <Calendar
              disablePast={true}
              date={selectedDate}
              onChange={(date) => setSelectedDate(date as Date)}
            />
          </div>
          <div className={classes.slots}>
            {isLoading && <AppLoader />}
            {!isLoading && bookableSlots.length === 0 && (
              <div>No available bookable slots available on this date</div>
            )}
            {!isLoading &&
              bookableSlots.map((bs) => (
                <Button
                  key={bs.start.toString()}
                  onClick={() => setSelectedBookableSlot(bs)}
                  className={`${classes.bookingSlotButton} ${
                    selectedBookableSlot?.start === bs.start &&
                    classes.bookingSlotButtonSelected
                  }`}
                >
                  {`${formatTimeOnly(new Date(bs.start))} - ${formatTimeOnly(
                    addXMinutesToDate(bs.durationMinutes, new Date(bs.start))
                  )}`}
                </Button>
              ))}
          </div>
        </div>
      </AppDrawer>
    );
  };

export default BookingFormRecurringSlotSelectionEditPanel;

