import { AppointmentType } from "../../../models/AppointmentType";
import { BookedExtra } from "../../../models/Booking";
import OptionalExtrasListViewItem from "./OptionalExtrasListViewItem";

interface OptionalExtrasListViewProps {
    appointmentTypes: AppointmentType[],
    appointmentTypeId: number,
    optionalExtras: BookedExtra[],
    currentTaxRatePercentage: number,
    isPriceOverridden: boolean;
};

const OptionalExtrasListView: React.FC<OptionalExtrasListViewProps> = ({
    appointmentTypes, appointmentTypeId, optionalExtras, currentTaxRatePercentage, isPriceOverridden
}) => {
    return (
        <>
            {optionalExtras.map((extra) =>
                <OptionalExtrasListViewItem
                    key={`optional-extra-id-${extra.optionalExtraId}`}
                    appointmentTypes={appointmentTypes}
                    appointmentTypeId={appointmentTypeId}
                    optionalExtra={extra}
                    currentTaxRatePercentage={currentTaxRatePercentage ?? 0}
                    isPriceOverridden={isPriceOverridden}
                />
            )}
        </>
    );
};

export default OptionalExtrasListView;