import { AppSelectOption } from "./components/AppForm/AppSelect";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const shortMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const prependZero = (number: number): string => {
  if (number < 10) {
    return `0${number}`;
  } else {
    return number.toString();
  }
};

const formatTo12hrClock = (number: number): number => {
  if (number > 12) {
    return number - 12;
  } else {
    return number;
  }
};

export const formatDateStringDateOnly = (dateString: string): string => {
  try {
    const date = new Date(dateString);

    return formatDateDateOnly(date);
  } catch (error) {
    console.error("Error formatting date from string:", error);
    throw error;
  }
};

export const formatDateStringDateTime = (dateString: string): string => {
  try {
    const date = new Date(dateString);

    return formatDateDateTime(date);
  } catch (error) {
    console.error("Error formatting date time from string:", error);
    throw error;
  }
};

export const formatDateDateTime = (date: Date): string => {
  try {
    return `${formatDateDateOnly(date)} ${formatTimeOnly(date)}`;
  } catch (error) {
    console.error("Error formatting date time from date:", error);
    throw error;
  }
};

export const formatDateDateOnly = (date: Date, includeYear = false): string => {
  try {
    const monthString = months[date.getMonth()];
    return `${date.toLocaleString('default', {weekday: 'short'})}, ${date.getDate()}${nth(date)} ${monthString.substring(
        0,
        3
    )} ${includeYear ? date.getFullYear() : ''}`;
  } catch (error) {
    console.error("Error formatting date from date:", error);
    throw error;
  }
};

const nth = function (date: Date): string {
  if (date.getDate() > 3 && date.getDate() < 21) return "th";
  switch (date.getDate() % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const formatDateSuffix = (date: Date, excludeDate?: boolean): string => {
  try {
    const monthString = months[date.getMonth()];
    return `${date.getDate()}${nth(date)} ${monthString} ${
      !excludeDate ? date.getFullYear() : ""
    }`;
  } catch (error) {
    console.error("Error formatting date from date:", error);
    throw error;
  }
};

export const formatDateShortNoYear = (date: Date): string => {
  try {
    const monthString = shortMonths[date.getMonth()];
    return `${date.getDate()}${nth(date)} ${monthString}`;
  } catch (error) {
    console.error("Error formatting date from date:", error);
    throw error;
  }
};

export const formatTimeOnly = (date: Date, showTimePeriod = true): string => {
  try {
    const hour = showTimePeriod
        ? formatTo12hrClock(date.getHours())
        : date.getHours(),
      minute = prependZero(date.getMinutes()),
      hourForPeriod = prependZero(date.getHours()),
      period = parseInt(hourForPeriod) >= 12 ? "PM" : "AM";

    return `${hour}:${minute} ${showTimePeriod && period}`;
  } catch (error) {
    console.log("Error formatting time from date: ", error);
    throw error;
  }
};

export const formatNumericalTimeOnly = (date: Date): number => {
  const hour = date.getHours(),
    minute = date.getMinutes();

  return parseInt(`${hour}${minute < 10 ? `0${minute}` : minute}`);
};

export const toUTCISOString = (date: Date): string => {
  const year = date.getFullYear(),
    month = date.getMonth(),
    day = date.getDate();

  const d = new Date(Date.UTC(year, month, day));

  return d.toISOString();
};

export const createIsoDateFromDateAndTime = (
  date: Date,
  time: string
): Date => {
  const newDate = new Date(date);

  const [hour, minute] = time.split(":");
  newDate.setHours(parseInt(hour));
  newDate.setMinutes(parseInt(minute));
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);

  return newDate;
};

export const addXHoursToDate = (h: number, date: Date): Date => {
  return new Date(date.setTime(date.getTime() + h * 60 * 60 * 1000));
};

export const addXWeeksToDate = (w: number, date: Date): Date => {
  date.setDate(date.getDate() + w * 7);
  return date;
};

export const timeSlotOptions = (additionalTime?: number): AppSelectOption[] => {
  const quarterHours = ["00", "15", "30", "45"];
  const times = [];

  // there is an issue with the calendar display when times are close to midnight so as a short term fix we are preventing picking times before 2am or after 10pm
  for (let i = 2; i < 22; i++) {
    for (let j = 0; j < 4; j++) {
      const option = {
        label: `${prependZero(i)}:${quarterHours[j]}`,
        value: i * 60 + parseInt(quarterHours[j]),
      };

      times.push(option);
    }
  }

  if (
    additionalTime &&
    times.filter((time) => time.value === additionalTime).length === 0
  ) {
    times.push({
      label: formatMinutesAfterMidnightToTimeString(additionalTime),
      value: additionalTime,
    });

    times.sort((a, b) => (a.value > b.value ? 1 : -1));
  }

  return times;
};

export const maximumMinutesAfterMidnightValue = 1440;

export const formatTimeStringToMinutesAfterMidnight = (
  time: string
): number => {
  const [hours, minutes] = time.split(":");

  const hoursInt = parseInt(hours) * 60,
    minutesInt = parseInt(minutes);

  return hoursInt + minutesInt;
};

export const formatMinutesAfterMidnightToTimeString = (
  time: number
): string => {
  const hours = Math.floor(time / 60),
    minutes = time % 60;

  return `${hours}:${prependZero(minutes)}`;
};

export const formatMinutesAfterMidnightTo12Hour = (time: number): string => {
  const hours = Math.floor(time / 60),
    actualHours = formatTo12hrClock(hours),
    minutes = time % 60;

  if (minutes === 0) {
    return `${actualHours}`;
  }
  return `${actualHours}:${prependZero(minutes)}`;
};

export const formatMinutesAfterMidnightTo12HourWithAmPm = (
  time: number
): string => {
  const hours = Math.floor(time / 60);
  const actualHours = formatTo12hrClock(hours);
  const amPm = hours >= 12 ? "PM" : "AM";
  const minutes = time % 60;

  return `${actualHours}:${prependZero(minutes)} ${amPm}`;
};

export const roundUpMinutesAfterMidnightToNearestQuarter = (
  time: number
): number => {
  const remainder = time % 15;

  return remainder > 0 ? 15 - remainder + time : time;
};

export const roundedDownHourDate = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setMinutes(newDate.getMinutes() - newDate.getMinutes());
  return newDate;
};

export const addXMinutesToDate = (h: number, date: Date): Date => {
  return new Date(date.setTime(date.getTime() + h * 60 * 1000));
};

export const isDateUTC = (date: Date) : boolean => {
  return date.getTimezoneOffset() === 0;
};

export const convertMinsToHrsMins = (mins: number, includeUnits?: boolean) => {
  const h = Math.floor(mins / 60);
  const m = mins % 60;
  const formattedHr = h < 10 ? "0" + h : h;
  const formattedMin = m < 10 ? "0" + m : m;
  if (includeUnits) {
    return `${parseInt(formattedHr.toString())}hr ${parseInt(
      formattedMin.toString()
    )}min`;
  }

  return `${formattedHr}:${formattedMin}`;
};
