import { FormControl, Grid, makeStyles, TextField } from "@material-ui/core";
import { useField, useFormikContext } from "formik";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { postcodeCoverage, postcodeExists } from "../../api/geocoding";
import { getAllAddressesFromPostcode } from "../../api/widget";
import AppButton from "../../components/AppButton";
import AppFormError from "../../components/AppForm/AppFormError";
import { AppSelectOption } from "../../components/AppForm/AppFormikSelect";
import AppSelect from "../../components/AppForm/AppSelect";
import { AddressSearchResult } from "../../models/AddressSearchResult.";
import { toNormalised } from "../../postcodeUtils";
import { WidgetBookingFields } from "./WidgetBookingFormFields";

const useStyles = makeStyles((theme) => ({
  postcodeSearch: {
    display: "flex",
    justifyContent: "left",
    flexDirection: "row",
    [theme.breakpoints.down(599)]: {
      display: "block",
    },
  },
  mobileSearch: {
    [theme.breakpoints.down(599)]: {
      display: "flex",
    },
  },
  button: {
    alignItems: "center",
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  select: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down(599)]: {
      marginLeft: 0,
    },
  },
  newFields: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  extraField: {
    display: "flex",
    [theme.breakpoints.down(599)]: {
      display: "block",
    },
  },
  fieldsPadding: {
    marginTop: theme.spacing(2),
  },
  town: {
    marginRight: theme.spacing(6),
    marginTop: theme.spacing(2),
  },
  postcodeField: {
    width: "95%",
    marginTop: theme.spacing(2),
  },
}));

interface PageParams {
  vendorId: string;
}

type Props = {
  setSelectedPostcode: (value: string) => void;
};

const WidgetAddressSelect: React.FC<Props> = ({ setSelectedPostcode }) => {
  const classes = useStyles();
  const { vendorId } = useParams<PageParams>();
  const { validateForm } = useFormikContext();
  const [{ value: addressLine1 }, , { setValue: setAddressLine1 }] =
    useField<string>("addressLine1");
  const [{ value: addressLine2 }, , { setValue: setAddressLine2 }] =
    useField<string>("addressLine2");
  const [, , { setValue: setAddressLine3 }] = useField<string>("addressLine3");

  const [, , { setValue: setTown }] = useField<string>("town");
  const [
    { value: postcodeFormValue },
    postcodeMeta,
    { setValue: setFormikPostcode },
  ] = useField<string>("postcode");
  const [postcode, setPostcode] = useState<string>();
  const [addressSelected, setAddressSelected] = useState(false);
  const [value, setValue] = useState(-1);
  const [label, setLabel] = useState("Choose address");
  const [errorMessage, setErrorMessage] = useState(false);
  const [postcodeCovered, setPostcodeCovered] = useState(true);

  const [addressResults, setAddressResults] = useState<AddressSearchResult[]>(
    []
  );

  const addressListRef = useRef<HTMLInputElement>(null);

  const options: AppSelectOption[] = addressResults.map(
    (address: AddressSearchResult, index: number) => ({
      value: index,
      label: `${address.addressLine1}, ${address.addressLine2}`,
    })
  );

  const addressPreviouslySelected = addressLine1 !== "";

  const resetAddress = () => {
    setAddressLine1("");
    setAddressLine2("");
    setAddressLine3("");
    setTown("");
    setFormikPostcode("");
    setAddressResults([]);
    setValue(-1);
  };

  const postcodeIsCovered = async (postcode: string) => {
    setErrorMessage(false);
    const response = await postcodeCoverage(postcode, parseInt(vendorId));

    if (!response.isError) {
      const isCovered = response.content.postcodeIsCovered;
      if (isCovered) {
        const result = await getAllAddressesFromPostcode(postcode);

        if (result.isError) {
          return;
        }

        setAddressResults(result.content.content);
        setPostcode(postcode);
      }

      if (!isCovered) {
        setPostcodeCovered(false);
      }
    }

    if (response.isError) {
      setErrorMessage(true);
    }
  };

  const doAddressSearch = async (
    providedPostcode?: string,
    skipReset?: boolean
  ) => {
    const postcodeToSearch = providedPostcode
      ? toNormalised(providedPostcode)
      : toNormalised(postcode);
    if (postcodeToSearch) {
      !skipReset && resetAddress();
      setAddressSelected(false);
      setErrorMessage(false);
      setPostcodeCovered(true);

      const response = await postcodeExists(postcodeToSearch);
      if (response.statusCode === 404) {
        setErrorMessage(true);
      } else {
        if (response.statusCode === 200) {
          postcodeIsCovered(postcodeToSearch);
        }
      }
    } else {
      setErrorMessage(true);
    }
  };

  const handleAddressSelect = (value: number) => {
    const address = addressResults[value];
    setErrorMessage(false);
    if (!address)
      throw new Error(
        `AppFormikSelect: Expected to find option for value ${value}.`
      );
    setValue(value);
    setLabel(`${address.addressLine1}, ${address.addressLine2}`);
    setAddressLine1(address.addressLine1);
    setAddressLine2(address.addressLine2);
    setAddressLine3(address.addressLine3 ?? "");
    setTown(address.town);
    setFormikPostcode(address.postcode);
    setSelectedPostcode(address.postcode);
    setAddressSelected(true);
  };

  useEffect(() => {
    if (addressPreviouslySelected) {
      setPostcode(postcodeFormValue);
      doAddressSearch(postcodeFormValue, true);
      setAddressSelected(true);
    }
  }, []);

  useEffect(() => {
    if (addressPreviouslySelected) {
      const option = options.find(
        (opt) => opt.label === `${addressLine1}, ${addressLine2}`
      );
      setValue(-1);

      if (option) {
        setValue(option.value);
        setAddressSelected(true);
      } else {
        setAddressSelected(false);
      }
    }

    if (options.length > 0) {
      addressListRef.current?.focus();
    }
  }, [addressResults]);

  useEffect(() => {
    if (addressSelected) {
      validateForm();
    }
  }, [addressSelected]);

  return (
    <>
      <h2>Where would you like us to come?</h2>
      <div>
        <Grid container>
          <FormControl fullWidth={true}>
            <div className={classes.postcodeSearch}>
              {screen.width < 599 ? (
                <div className={classes.mobileSearch}>
                  <Grid item xs={9}>
                    <TextField
                      className={classes.postcodeField}
                      name="postcode"
                      label="Enter postcode"
                      value={postcode}
                      onChange={(e) => setPostcode(e.currentTarget.value)}
                      onKeyDown={(e) => {
                        if (e.code === "Enter") {
                          e.preventDefault();
                          doAddressSearch();
                        }
                      }}
                    />
                    <AppFormError show={errorMessage}>
                      Please enter a valid postcode
                    </AppFormError>
                    <AppFormError show={!postcodeCovered}>
                      Postcode is currently not covered
                    </AppFormError>
                  </Grid>
                  <Grid item xs={1}>
                    <AppButton
                      className={classes.button}
                      variant="text"
                      onClick={() => doAddressSearch()}
                    >
                      Search
                    </AppButton>
                  </Grid>
                </div>
              ) : (
                <>
                  <Grid item xs={3}>
                    <TextField
                      className={classes.postcodeField}
                      name="postcodedomain"
                      label="Enter postcode"
                      value={postcode}
                      onChange={(e) => setPostcode(e.currentTarget.value)}
                      onKeyDown={(e) => {
                        if (e.code === "Enter") {
                          e.preventDefault();
                          doAddressSearch();
                        }
                      }}
                    />
                    <AppFormError
                      show={
                        postcodeMeta.touched &&
                        postcodeMeta.error &&
                        !addressSelected &&
                        !postcode
                          ? true
                          : errorMessage
                      }
                    >
                      {errorMessage
                        ? "Please enter a valid postcode"
                        : "Please enter a postcode before continuing"}
                    </AppFormError>
                    <AppFormError show={!postcodeCovered}>
                      Postcode is currently not covered
                    </AppFormError>
                  </Grid>
                  <Grid item xs={1}>
                    <AppButton
                      className={classes.button}
                      variant="text"
                      onClick={() => doAddressSearch()}
                    >
                      Search
                    </AppButton>
                  </Grid>
                </>
              )}

              <Grid item xs={screen.width < 599 ? 12 : 9}>
                <AppSelect
                  pleaseSelectText={label}
                  label={" "}
                  disabled={addressResults.length === 0}
                  options={options}
                  value={value}
                  onChangeCallback={handleAddressSelect}
                  className={classes.select}
                  inputRef={addressListRef}
                />
              </Grid>
            </div>
            {addressSelected && (
              <div className={classes.newFields}>
                <WidgetBookingFields.addressLine1
                  className={classes.fieldsPadding}
                />
                <WidgetBookingFields.addressLine2
                  className={classes.fieldsPadding}
                />
                <WidgetBookingFields.addressLine3
                  className={classes.fieldsPadding}
                />
                <div className={classes.extraField}>
                  <WidgetBookingFields.town className={classes.town} />
                  <WidgetBookingFields.postcode
                    className={classes.postcodeField}
                    readonly
                  />
                </div>
              </div>
            )}
          </FormControl>
        </Grid>
      </div>
    </>
  );
};

export default WidgetAddressSelect;
