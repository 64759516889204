import { subDays } from 'date-fns';
import {
  updateResourceTimeOff,
  updateResourceTimeOffAdmin,
} from "../../api/resources";
import { useAuth } from "../../auth";
import AppModal from "../../components/AppModal";
import { TimeOff } from "../../models/Resource";
import { successToast } from "../../toast";
import { TimeOffForm, TimeOffFormValues } from "../TimeOffForm";

interface EditTimeOffModalProps {
  initialValues?: TimeOff;
  handleClose: () => void;
}

const EditTimeOffModal: React.FC<EditTimeOffModalProps> = ({
  initialValues,
  handleClose,
}) => {
  const { role } = useAuth();
  const handleSubmit = async (values: TimeOffFormValues) => {
    const { start, end } = values.range;

    if (initialValues) {
      const updateTimeOffDto = {
        id: initialValues.id,
        start: start.toISOString(),
        end: end.toISOString(),
      };

      if (role === "admin" && updateTimeOffDto.id) {
        const response = await updateResourceTimeOffAdmin(updateTimeOffDto);

        if (!response.isError) {
          handleClose();
          successToast("Time off has been updated");
        }
      } else {
        const response = await updateResourceTimeOff(updateTimeOffDto);

        if (!response.isError) {
          handleClose();
          successToast("Time off has been updated");
        }
      }
    }
  };

  return (
    <>
      <AppModal
        isOpen={Boolean(initialValues)}
        onClose={handleClose}
        title="Edit Time Off"
      >
        {initialValues && (
          <TimeOffForm
            initialValues={{
              ...initialValues,
              range: {
                start: initialValues.start,
                end: initialValues.end,
              },
            }}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            userIsAdmin={role === "admin"}
          />
        )}
      </AppModal>
    </>
  );
};

export default EditTimeOffModal;
