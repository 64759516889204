import { Container, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ResetPasswordForm from "../accountManagement/ResetPasswordForm";
import { publicPaths } from "../routes";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  logo: {
    marginBottom: theme.spacing(2),
  },
}));

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ResetPasswordPage: React.FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();
  const query = useQuery();
  const [resetCode, setResetCode] = useState("");
  const [email, setEmail] = useState("");
  const [firstTimeLogin, setFirstTimeLogin] = useState(false);

  const fetchQueryParams = () => {
    const resetCodeParam = query.get("code"),
      emailParam = query.get("email"),
      firstTimeLoginParam = query.get("firstTimeSignIn");

    if (!resetCodeParam || !emailParam) {
      history.push(publicPaths.home);
    } else {
      setResetCode(resetCodeParam);
      setEmail(emailParam);
      if (firstTimeLoginParam) {
        setFirstTimeLogin(true);
      }
    }
  };

  useEffect(() => {
    fetchQueryParams();
  });

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5" className={classes.title}>
          {firstTimeLogin ? "Set Password" : "Reset Password"}
        </Typography>
        <ResetPasswordForm
          code={resetCode}
          email={email}
          firstTimeLogin={firstTimeLogin}
        />
      </div>
    </Container>
  );
};

export default withRouter(ResetPasswordPage);
