import React from "react";
import { CheckboxProps, FormControlLabel, Switch } from "@material-ui/core";
import { useField } from "formik";

export interface AppFormikSwitchProps extends Omit<CheckboxProps, "onChange"> {
  label: string;
  readonly?: boolean;
  formikName: string;
}

const AppFormikSwitch: React.FC<AppFormikSwitchProps> = ({
  label,
  formikName,
  disabled,
  readonly,
  color = "primary",
  ...restOfProps
}) => {
  const [{ value }, , { setValue }] = useField(formikName);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.checked);
  };

  return (
    <FormControlLabel
      disabled={disabled || readonly}
      control={
        <Switch
          {...restOfProps}
          disabled={disabled || readonly}
          checked={value}
          onChange={handleChange}
          color={color}
        />
      }
      label={label}
    />
  );
};

export default AppFormikSwitch;
