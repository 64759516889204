import * as Yup from "yup";

export type CustomerDetailsFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  phoneNumber: string;
};

export const emailFieldName = "email";

export const defaultCustomerDetailsFormValues: CustomerDetailsFormValues = {
  firstName: "",
  lastName: "",
  [emailFieldName]: "",
  company: "",
  phoneNumber: "",
};

export const customerDetailsFormValidationFields = {
  firstName: Yup.string().required("Please provide a first name."),
  lastName: Yup.string().required("Please provide a surname."),
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Please provide an email address."),
  company: Yup.string().ensure(),
  phoneNumber: Yup.string()
    .required("Please provide a phone number.")
    .min(7, "Phone number must be between 7 and 15 characters")
    .max(15, "Phone number must be between 7 and 15 characters"),
};

export const customerDetailsFormValidationSchema: Yup.SchemaOf<CustomerDetailsFormValues> =
  Yup.object().shape({
    ...customerDetailsFormValidationFields,
  });
