import { formatTimeStringToMinutesAfterMidnight } from "../../../dateFormatters";
import { WorkingHoursDay } from "../../../models/Resource";

export { default as AppWorkingHoursPicker } from "./AppWorkingHoursPicker";

export const defaultStart = formatTimeStringToMinutesAfterMidnight("09:00"),
  defaultFinish = formatTimeStringToMinutesAfterMidnight("17:00"),
  defaultWorkingDaysValues: WorkingHoursDay[] = [
    {
      dayOfWeekId: 1,
      startTime: defaultStart,
      finishTime: defaultFinish,
      isWorking: true,
      applyToAll: true,
    },
    {
      dayOfWeekId: 2,
      startTime: defaultStart,
      finishTime: defaultFinish,
      isWorking: true,
    },
    {
      dayOfWeekId: 3,
      startTime: defaultStart,
      finishTime: defaultFinish,
      isWorking: true,
    },
    {
      dayOfWeekId: 4,
      startTime: defaultStart,
      finishTime: defaultFinish,
      isWorking: true,
    },
    {
      dayOfWeekId: 5,
      startTime: defaultStart,
      finishTime: defaultFinish,
      isWorking: true,
    },
    {
      dayOfWeekId: 6,
      startTime: defaultStart,
      finishTime: defaultFinish,
      isWorking: false,
    },
    {
      dayOfWeekId: 0,
      startTime: defaultStart,
      finishTime: defaultFinish,
      isWorking: false,
    },
  ];
