import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useField, useFormikContext } from "formik";
import { useEffect } from "react";
import WidgetInformationTextBox from "../../components/InformationTextBox/WidgetInformationTextBox";
import { AppointmentType } from "../../models/AppointmentType";
import WidgetFormikOptionalExtrasSelect from "./WidgetFormikOptionalExtrasSelect";
import AppFormError from "../../components/AppForm/AppFormError";

interface WidgetOptionalExtrasSelect {
  appointmentTypes: AppointmentType[];
  scrollToTop: () => void;
  currentTaxRatePercentage?: number;
}

const useStyles = makeStyles((theme) => ({
  textBox: {
    [theme.breakpoints.up(600)]: {
      marginTop: theme.spacing(4),
    },
  },
}));

const WidgetOptionalExtrasSelect: React.FC<WidgetOptionalExtrasSelect> = ({
  appointmentTypes,
  scrollToTop,
  currentTaxRatePercentage
}) => {
  // const [appointmentTypeInputProps] = useField<number>(
  //   "firstJobAppointmentTypeId"
  // );
  const [
    { value: selectedService },
    ,
    { setValue: setFirstJobAppointmentTypeId },
  ] = useField<number>("firstJobAppointmentTypeId");

  const classes = useStyles();

  const availableOptionalExtras =
    appointmentTypes.find((_) => _.id == selectedService)
      ?.availableOptionalExtras || [];

  useEffect(() => {
    if (availableOptionalExtras.length > 0) {
      scrollToTop();
    }
  }, [availableOptionalExtras]);

  const handleDeselectionClick = () => {
    setFirstJobAppointmentTypeId(-1);
  };

  return (
    <>
      <div className={classes.textBox}>
        <WidgetInformationTextBox>
          Add one or more of the optional additional services below to your booking.
        </WidgetInformationTextBox>
      </div>
      <Grid item xs={12}>
        {selectedService > 0 && (
          <Grid item xs={12}>

            {currentTaxRatePercentage && currentTaxRatePercentage > 0 ? <Typography variant="h5">All prices are ex. VAT</Typography> : <></>}
            <WidgetFormikOptionalExtrasSelect
              currentTaxRatePercentage={currentTaxRatePercentage}
              availableOptionalExtras={availableOptionalExtras}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default WidgetOptionalExtrasSelect;
