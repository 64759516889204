import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApiResponseWrapper from "../../components/ApiResponseWrapper";
import AppTable from "../../components/AppTable";
import { actions, selectors, useDispatchEffect } from "../../store";
import EditResourceModal from "../EditResourceModal";
import ResourcesTableRow from "./ResourcesTableRow";

const ResourcesTable: React.FC = () => {
  useDispatchEffect(actions.resources.fetchAll);
  const dispatch = useDispatch();

  const [editModal, setEditModal] = useState(false);
  const [selectedResource, setSelectedResource] = useState<number>();

  const { isFetching, isError } = useSelector(selectors.resources.apiStateAll);

  const resources = useSelector(selectors.resources.all);
  const pagination = useSelector(selectors.resources.pagination);
  const activeSortBy = useSelector(selectors.resources.selectSortBy);
  const noResourcesMessage = "There are currently no mobile workers.";

  const handleChangePageNumber = (pageNumber: number) => {
    dispatch(actions.resources.setPageNumber(pageNumber));
  };

  const handleChangePageSize = (pageSize: number) => {
    dispatch(actions.resources.setPageSize(pageSize));
  };

  const handleEditSelect = (id: number) => {
    setSelectedResource(id);
    setEditModal(true);
  };

  const handleEditClose = () => {
    setEditModal(false);
    setSelectedResource(undefined);
  };

  const onHeaderClick = (sortByString: string) => {
    dispatch(actions.resources.setSortBy(sortByString));
  };

  return (
    <>
      <ApiResponseWrapper isFetching={isFetching} isError={isError}>
        <AppTable
          headers={[
            { label: "Name", propertyName: "name" },
            { label: "Email", propertyName: "email" },
            { label: "Postcode", propertyName: "basePostcode" },
            { label: "Travel Distance", propertyName: "maxTravelDistance" },
            { label: "Bookable From", propertyName: "bookableFrom" },
            { label: "Bookable Until", propertyName: "bookableUntil" },
            { label: "Colour Tag", propertyName: "" },
            { label: "" },
          ]}
          entities={resources}
          noEntitiesMessage={noResourcesMessage}
          pagination={pagination}
          onChangePageNumber={handleChangePageNumber}
          onChangePageSize={handleChangePageSize}
          onHeaderClick={onHeaderClick}
          activeSortBy={activeSortBy}
          renderRow={(resource) => (
            <ResourcesTableRow
              key={resource.id}
              resource={resource}
              handleEdit={() => handleEditSelect(resource.id)}
            />
          )}
        />
      </ApiResponseWrapper>
      {selectedResource && (
        <EditResourceModal
          open={editModal}
          id={selectedResource}
          handleClose={() => handleEditClose()}
        />
      )}
    </>
  );
};

export default ResourcesTable;
