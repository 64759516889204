import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  ThemeProvider,
  CssBaseline,
} from "@material-ui/core";

interface AppThemeProps {
  primaryColourMain?: string;
  primaryColourLight?: string;
  primaryColourDark?: string;
  secondaryColourMain?: string;
  secondaryColourDark?: string;
}

interface ThemeProps {
  primaryColourMain: string;
  primaryColourLight: string;
  primaryColourDark: string;
  secondaryColourMain: string;
  secondaryColourDark: string;
}

const primaryColoursDefaults = {
  main: "#4570B6",
  dark: "#1B283D",
  light: "#5CB4DB",
  navy: "#1B283D",
};

const secondaryColoursDefaults = {
  green: {
    main: "#93D700",
    dark: "#5AA100",
  },
  yellow: {
    main: "#F7B213",
    dark: "#CA5816",
  },
  red: {
    main: "#AA2216",
    dark: "#5CB4DB",
  },
  grey: {
    main: "#C9C6BE",
    dark: "#2E2E2E",
  },
};

const theme = (props: ThemeProps) =>
  createMuiTheme({
    typography: {
      fontFamily: ["Ubuntu", "sans-serif"].join(","),
      fontSize: 14,
      h1: {
        fontSize: "2.857rem",
        fontWeight: "bold",
      },
      h2: {
        fontSize: "2.143rem",
        fontWeight: "bold",
      },
      h4: {
        fontSize: "1.429rem",
        fontWeight: "bold",
      },
      h5: {
        fontSize: "1.143rem",
        fontWeight: "bolder",
      },
      h6: {
        fontSize: "1rem",
        fontWeight: "normal",
        textTransform: "uppercase",
      },
      subtitle1: {
        fontSize: "1.143rem",
        fontWeight: "lighter",
      },
      body2: {
        fontSize: "0.875rem",
      },
    },
    palette: {
      primary: {
        main: props.primaryColourMain,
        light: props.primaryColourLight,
        dark: props.primaryColourDark,
      },
      secondary: {
        main: props.secondaryColourMain,
        dark: props.secondaryColourDark,
      },
      secondaryYellow: {
        main: secondaryColoursDefaults.yellow.main,
        dark: secondaryColoursDefaults.yellow.dark,
      },
      secondaryRed: {
        main: secondaryColoursDefaults.red.main,
        dark: secondaryColoursDefaults.red.dark,
      },
      secondaryGrey: {
        main: secondaryColoursDefaults.grey.main,
        dark: secondaryColoursDefaults.grey.dark,
      },
      backgrounds: {
        main: "#FFFFFF",
        grey: "#F6F6F4",
        blue: "#D5E6F3",
        lightBlue: "#F9FBFF",
      },
      background: {
        default: "#FFFFFF",
      },
      text: {
        primary: primaryColoursDefaults.dark,
      },
      error: {
        main: "#b44137",
      },
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          html: {
            fontSize: 14,
          },
          body: {
            fontSize: "1rem",
          },
        },
      },
      MuiInput: {
        input: {
          paddingLeft: "4px",
        },
      },
    },
  });

const AppTheme: React.FC<AppThemeProps> = ({
  children,
  primaryColourMain = primaryColoursDefaults.main,
  primaryColourLight = primaryColoursDefaults.light,
  primaryColourDark = primaryColoursDefaults.dark,
  secondaryColourMain = secondaryColoursDefaults.green.main,
  secondaryColourDark = secondaryColoursDefaults.green.dark,
}) => {
  return (
    <ThemeProvider
      theme={theme({
        primaryColourMain,
        primaryColourLight,
        primaryColourDark,
        secondaryColourMain,
        secondaryColourDark,
      })}
    >
      <CssBaseline />
      {children}
      <svg
        width="0"
        height="0"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient
            id="BrandGradient"
            x1="95%"
            y1="95%"
            x2="5%"
            y2="5%"
            gradientTransform="rotate(-20)"
          >
            <stop offset="5%" stopColor="#5CB4DB" />
            <stop offset="95%" stopColor="#4570B6" />
          </linearGradient>
        </defs>
      </svg>

      <svg
        width="0"
        height="0"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient
            id="BrandGradientActive"
            x1="45%"
            y1="45%"
            x2="5%"
            y2="5%"
            gradientTransform="rotate(-60)"
          >
            <stop offset="5%" stopColor="#8A8A8A" />
            <stop offset="95%" stopColor="#2B2B2B" />
          </linearGradient>
        </defs>
      </svg>
    </ThemeProvider>
  );
};

export default AppTheme;
