import { minutesToHours } from "date-fns";
import { useCallback } from "react";
import { useHttpRequest } from "../../api";
import { getDashboardBooking } from "../../api/Dashboard";
import AppRelativelyPositionedLoader from "../../components/AppRelativelyPositionLoader";
import { makeStyles } from "@material-ui/core";
import DashboardPanelGraph from "./DashboardSummaryGraph";
import DashboardPanelStats from "./DashboardPanelStats";

interface Props {
  startDate: string;
  endDate: string;
}

export const useStyles = makeStyles((theme) => ({
  panelContainer: {
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    gridAutoRows: "auto",
    gridGap: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      gridGap: theme.spacing(4)
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "1fr 2fr",
      display: "grid",
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  dashboardPanelLoader: {
    height: 270,
  },
}));

const DashboardSummaryPanel: React.FC<Props> = ({ startDate, endDate }) => {
  const classes = useStyles();
  const getBookingPanelData = useCallback(
    () => getDashboardBooking(startDate, endDate),
    [startDate, endDate]
  );
  const { result: summaryDetails, isLoading } =
    useHttpRequest(getBookingPanelData);

  return (
    <div className={classes.panelContainer}>
      {isLoading ? (
        <div className={classes.dashboardPanelLoader}>
          <AppRelativelyPositionedLoader isLoading={true} />
        </div>
      ) : (
        <>
          <DashboardPanelGraph summaryDetails={summaryDetails} />
          <DashboardPanelStats summaryDetails={summaryDetails} />
        </>
      )}
    </div>
  );
};

export default DashboardSummaryPanel;
