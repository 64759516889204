import { makeStyles } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { ForgottenPasswordFields } from ".";
import { forgottenPassword } from "../../api/accountManagement";
import { AppButtons } from "../../components/AppButton";
import AppForm from "../../components/AppForm";
import AppFormikSubmitButton from "../../components/AppForm/AppFormikSubmitButton";
import {
  defaultForgottenPasswordFormValues,
  forgottenPasswordFormValidationSchema,
  ForgottenPasswordFormValues,
} from "./ForgottenPasswordFormValues";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
  },
  buttonGroup: {
    marginTop: theme.spacing(4),
  },
}));

const ForgottenPasswordForm: React.FC = () => {
  const classes = useStyles();
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleSubmit = async (values: ForgottenPasswordFormValues) => {
    setSubmissionAttempted(true);
    const response = await forgottenPassword(values.email);

    if (!response.isError) {
      setEmailSent(true);
    }
  };

  return (
    <>
      {!emailSent ? (
        <Formik
          initialValues={defaultForgottenPasswordFormValues}
          validationSchema={forgottenPasswordFormValidationSchema}
          validateOnBlur={submissionAttempted}
          onSubmit={(values: ForgottenPasswordFormValues) =>
            handleSubmit(values)
          }
        >
          <Form className={classes.form}>
            <AppForm>
              <ForgottenPasswordFields.Email autoFocus />
            </AppForm>
            <div className={classes.buttonGroup}>
              <AppButtons>
                <AppFormikSubmitButton fullWidth>Submit</AppFormikSubmitButton>
              </AppButtons>
            </div>
          </Form>
        </Formik>
      ) : (
        <p>We have sent you an email to reset your password</p>
      )}
    </>
  );
};

export default ForgottenPasswordForm;
