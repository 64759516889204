import AppFormikSelect from "../../../components/AppForm/AppFormikSelect";
import { BookingFormStyles } from "../BookingFormStyles";

interface bookingStatus {
  label: string;
  value: number;
}

const bookingStatuses: bookingStatus[] = [
  {
    label: "Provisional",
    value: 0,
  },
  {
    label: "Confirmed",
    value: 1,
  },
  {
    label: "Cancelled",
    value: 2,
  },
  {
    label: "Completed",
    value: 3,
  },
];

const BookingStatus: React.FC = () => {
  const classes = BookingFormStyles();

  return (
    <h3 className={classes.stepContainerTitle}>Booking status
      <AppFormikSelect
        name="bookingStatus"
        options={bookingStatuses}
      />
    </h3>
  );
};

export default BookingStatus;