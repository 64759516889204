import { Formik, FormikHelpers, useField, useFormikContext } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useHttpRequest } from "../../api";
import { getAllAppointmentTypesWidget } from "../../api/widget";
import { useWidgetVendorId } from "../../routes/public/PublicRoutes";
import WidgetBookingForm from "./WidgetBookingForm";
import {
  defaultWidgetBookingFormValues,
  widgetBookingFormPageValidationSchemas,
  WidgetBookingFormValues,
} from "./WidgetBookingFormValues";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "../../store";
import { getVendorMicrositeConfig } from "../../api/microsite";
import AppLoader from "../../components/AppLoader";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  loaderContainer: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export interface WidgetBookingFormContainerProps {
  initialValues: WidgetBookingFormValues;
  isInviteBooking?: boolean;
  onSubmit: (
    booking: WidgetBookingFormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => void;
}

const WidgetBookingFormContainer: React.FC<WidgetBookingFormContainerProps> = ({
  onSubmit,
  isInviteBooking = false,
  initialValues,
}) => {
  const classes = useStyles();
  const vendorId = useWidgetVendorId();
  const [activeStep, setActiveStep] = useState(isInviteBooking ? 2 : 0);

  const getConfig = useCallback(
    () => getVendorMicrositeConfig(vendorId),
    [vendorId]
  );
  const { result, isLoading } = useHttpRequest(getConfig);

  const isLastStep =
    activeStep === widgetBookingFormPageValidationSchemas.length - 1;
  const isSubmitStep =
    activeStep === widgetBookingFormPageValidationSchemas.length - 2;

  const handleSubmit = (
    values: WidgetBookingFormValues,
    { setSubmitting, setFieldTouched }: FormikHelpers<WidgetBookingFormValues>
  ) => {
    if (!isSubmitStep) {
      Object.keys(defaultWidgetBookingFormValues).forEach((key) =>
        setFieldTouched(key, false)
      );
      setSubmitting(false);
      setActiveStep((previousActiveStep) => previousActiveStep + 1);
      return;
    }

    onSubmit(values, setSubmitting);
    setActiveStep((previousActiveStep) => previousActiveStep + 1);
  };
  

  if(!result){
    return (
      <div className={classes.loaderContainer}>
        <AppLoader />
      </div>
    );
  }

  return (
    <div>
      {
        <Formik
          initialValues={initialValues}
          validationSchema={widgetBookingFormPageValidationSchemas[activeStep]}
          onSubmit={handleSubmit}
        >
          <WidgetBookingForm
            activeStep={activeStep}
            isLastStep={isLastStep}
            isSubmitStep={isSubmitStep}
            onPreviousClick={() => setActiveStep(activeStep - 1)}
            vendorId={vendorId}
            setActiveStep={setActiveStep}
            currentTaxRatePercentage={result?.currentTaxRatePercentage ?? 0}
          />
        </Formik>
      }
    </div>
  );
};

export default WidgetBookingFormContainer;
