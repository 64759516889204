import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  calendarContainer: {
    "& [class*=MuiToolbar-root]": {
      display: "none",
    },

    "& [class*=MuiPickersSlideTransition-transitionContainer]": {
      overflow: "hidden",
    },

    "& *": {
      fontWeight: 800,
    },

    "& [class*=MuiPickersCalendarHeader-switchHeader]": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },

    "& [class*=MuiPickersCalendarHeader-iconButton]": {
      width: "25px",
      height: "25px",
      margin: "10px 20px",
    },

    "& [class*=MuiSvgIcon-root]": {
      color: theme.palette.primary.main,
      borderRadius: "100%",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.9)",
      },
    },

    "& [class*=MuiPickersCalendar-week]:first-of-type": {
      marginTop: "2.5em",
    },

    "& [class*=MuiPickersCalendar-transitionContainer]": {
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      marginTop: "-25px",
      paddingTop: "25px",
      display: "flex",
      flexGrow: "1",
    },

    "& [class*=MuiTypography-caption]": {
      color: theme.palette.grey[600],
      visibility: "hidden",
      "&:first-letter": {
        paddingLeft: "0.5em",
        visibility: "visible",
      },
    },
    "& [class*=MuiPickersBasePicker-container]": {
      [theme.breakpoints.down(599)]: {
        alignItems: "center",
      },
    },
  },
}));
