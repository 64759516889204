import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.backgrounds.blue,
    padding: theme.spacing(2.5, 2, 2.5, 2),
    fontSize: ".8rem",
    marginBottom: theme.spacing(2),
    minWidth: "100%",
    width: "0",
    fontWeight: "bold",
  },
}));

const InformationTextBox: React.FC = ({ children }) => {
  const classNames = useStyles();

  return <div className={classNames.box}>{children}</div>;
};

export default InformationTextBox;
