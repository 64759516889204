import createPreconfiguredAppFormikWorkingHoursPicker from "../../components/AppForm/createPreconfiguredAppFormikWorkingHoursPicker";
import { WorkingHoursDay } from "../../models/Resource";

export { default as ResourceAccountForm } from "./ResourceAccountForm";

export interface ResourceAccountFormValues {
  workingHours: WorkingHoursDay[];
}

export const ResourceAccountFormFields = {
  WorkingHours: createPreconfiguredAppFormikWorkingHoursPicker({
    name: "workingHours",
    label: "Working hours",
  }),
};
