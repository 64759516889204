import { endOfWeek, format, startOfWeek } from "date-fns";
import { useCallback, useState } from "react";
import { useHttpRequest } from "../api";
import { getAllResources } from "../api/resources";
import { PageContainer } from "../components/MainLayout";
import PageHeader from "../components/PageHeader";
import DashboardControls from "../dashboard/DashboardControls";
import { DashboardResourceSummaryTable } from "../dashboard/DashboardResourceSummaryTable";
import DashboardSummaryPanel from "../dashboard/DashboardSummaryPanel/DashboardSummaryPanel";

const DashboardPage: React.FC = () => {
  const getAllResourceList = useCallback(() => getAllResources(), []);
  const { result: resources, isLoading } = useHttpRequest(getAllResourceList);

  const [startDate, setStartDate] = useState(
    format(startOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd")
  );
  const [endDate, setEndDate] = useState(
    format(endOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd")
  );

  const resourceIds = isLoading
    ? []
    : resources?.content.map((resource) => resource.id) ?? [];

  return (
    <PageContainer>
      <PageHeader title="Performance Dashboard" />
      <DashboardControls
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        startDate={startDate}
        endDate={endDate}
      />
      <DashboardSummaryPanel startDate={startDate} endDate={endDate} />
      {!isLoading && (
        <DashboardResourceSummaryTable
          startDate={startDate}
          endDate={endDate}
          resourceIds={resourceIds}
        />
      )}
    </PageContainer>
  );
};

export default DashboardPage;
