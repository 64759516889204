import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { Chip, Drawer, IconButton, makeStyles } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import NavBarMenu from "./NavBarMenu";
import AccountMenu from "./AccountMenu";
import { useAuth } from "../../auth";

const useStyles = makeStyles((theme) => ({
  mobileNavBtn: {
    marginLeft: "auto",
  },
  mobileNavDrawer: {
    "& .MuiDrawer-paper": {
      width: "100%",
      maxWidth: 320,
    },
  },
  mobileNavAccount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1),
    marginTop: "auto",
  },
}));

const MobileNavBarMenu: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { userName } = useAuth();
  const [navOpen, setNavOpen] = useState(false);

  useEffect(() => {
    history.listen(() => {
      setNavOpen(false);
    });
  }, [history]);

  return (
    <>
      <IconButton
        className={classes.mobileNavBtn}
        onClick={() => setNavOpen(!navOpen)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        className={classes.mobileNavDrawer}
        anchor="right"
        open={navOpen}
        onClose={() => setNavOpen(false)}
      >
        <NavBarMenu />
        <div className={classes.mobileNavAccount}>
          <Chip label={userName} />
          <AccountMenu />
        </div>
      </Drawer>
    </>
  );
};

export default MobileNavBarMenu;
