import {
  RecurringBookableSlotWithSelection,
  RecurringSlotDateTime,
} from "../../models/RecurringBookableSlot";
import {
  BookedExtra,
  BookingDocument,
  Image,
  Jobs,
} from "../../models/Booking";
import { Tag } from "../../models/Tag";
export interface BookingFormPage1Values {
  customerId: number;
  customerAddressId: number;
}

export interface BookingFormPage1aValues {
  firstname?: string;
  lastname?: string;
  email?: string;
  phone?: string;
  companyName?: string;
}

export interface BookingFormPage1bValues {
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  town?: string;
  postcode?: string;
  notes?: string;
}

export interface BookingFormPage2Values {
  id: number;
  firstJobAppointmentTypeId: number;
  firstJobOptionalExtraIds: number[];
  firstJobOptionalExtras: BookedExtra[] | any;
  jobs: Jobs[] | any;
  paymentStatusId: number;
  paymentStatusName?: string;
  bookingStatus: number;
  tags?: Tag[];
  overriddenTotalPrice?: number;
  totalPrice?: number;
  taxRate?: number;
  isEcoSlot: boolean;
  requiresOptionalExtra: boolean;
  recurringBookingId?: number;
  recurringBookings?: RecurringSlotDateTime[];
}

export interface BookingFormPage3Values {
  resourceId: number;
  date: Date;
  start: number;
  end: number;
  validBookableDates: boolean;
  hasSelectedTimeSlot: boolean;
}

export interface BookingFormPage4Values {
  isRecurringBooking?: boolean;
  recurringNumberOfWeeksBetweenBookings?: number;
  recurringNumberOfOccurances?: number;
}

export interface BookingFormPage5Values {
  recurringBookableSlots: RecurringBookableSlotWithSelection[];
}

export interface BookingFormPage6Values {
  notes: string;
  description: string;
  muteConfirmationEmail?: boolean;
}

export type BookingFormValues = BookingFormPage1Values &
  BookingFormPage1aValues &
  BookingFormPage1bValues &
  BookingFormPage2Values &
  BookingFormPage3Values &
  BookingFormPage4Values &
  BookingFormPage5Values &
  BookingFormPage6Values & {
    images?: Image[];
    documents?: BookingDocument[];
  };

export const defaultBookingFormValues: BookingFormValues = {
  id: -1,
  resourceId: -1,
  date: new Date(),
  paymentStatusId: -1,
  paymentStatusName: "",
  start: 540,
  end: 1020,
  validBookableDates: false,
  hasSelectedTimeSlot: false,
  notes: "",
  jobs: [] as Jobs[],
  tags: [],
  description: "",
  customerId: -1,
  customerAddressId: -1,
  firstJobAppointmentTypeId: -1,
  firstJobOptionalExtraIds: [],
  firstJobOptionalExtras: [] as BookedExtra[],
  bookingStatus: -1,
  muteConfirmationEmail: false,
  isEcoSlot: false,
  requiresOptionalExtra: false,
  isRecurringBooking: false,
  recurringNumberOfOccurances: 1,
  recurringNumberOfWeeksBetweenBookings: 1,
  recurringBookableSlots: [],
};
