import * as Yup from "yup";

export type ForgottenPasswordFormValues = {
  email: string;
};

export const defaultForgottenPasswordFormValues: ForgottenPasswordFormValues = {
  email: "",
};

export const forgottenPasswordFormValidationSchema: Yup.SchemaOf<ForgottenPasswordFormValues> =
  Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("Please provide an email address."),
  });
