import { IconButton, TableCell, TableRow, makeStyles } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";
import { adminPaths } from "../../routes";
import { CustomerSummary } from "../../models/Customers";

interface CustomersTableRowProps {
  customer: CustomerSummary;
}

const useStyles = makeStyles(() => ({
  company: {
    maxWidth: "150px",
    wordWrap: "break-word"
  }
}));

const CustomersTableRow: React.FC<CustomersTableRowProps> = ({ customer }) => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell>{customer.firstName}</TableCell>
      <TableCell>{customer.lastName}</TableCell>
      <TableCell>{customer.email}</TableCell>
      <TableCell>{customer.phoneNumber}</TableCell>
      <TableCell><div className={classes.company}>{customer.company}</div></TableCell>
      <>
        {customer.addresses.length >= 2 ? (
          <TableCell>Multiple</TableCell>
        ) : (
          <TableCell>
            {customer.addresses[0].addressLine1},{" "}
            {customer.addresses[0].postcode}
          </TableCell>
        )}
      </>
      <TableCell>
        <Link to={adminPaths.customer(customer.id)}>
          <IconButton>
            <Visibility />
          </IconButton>
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default CustomersTableRow;
