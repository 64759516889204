import { useState } from "react";
import CreateBookingDrawer from "../bookings/CreateBookingDrawer/CreateBookingDrawer";
import { AppCalendar } from "../components/AppCalendar";
import { CALENDAR_VIEWS } from "../components/AppCalendar/AppCalendarContext";
import { useConfig } from "../config/ConfigContext";

const AllResourceCalendarPage: React.FC = () => {
  const { isFresh } = useConfig();

  return (
      <AppCalendar
        canEditEvent
        canCreateEvent={!isFresh}
        canAddTimeOff
        initialView={CALENDAR_VIEWS.DAY_VIEW}
        allowedViews={[CALENDAR_VIEWS.WEEK_VIEW, CALENDAR_VIEWS.DAY_VIEW]}
      />
  );
};

export default AllResourceCalendarPage;
