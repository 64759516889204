import { makeStyles, Theme } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { MouseEvent } from "react";

type MakeStyleProps = {
  colour: string;
  selectedColour?: string;
};

export interface AppCustomColourButtonProps {
  colour: string;
  selectedColour?: string;
  onChange: (colour: string) => void;
}

const useStyles = makeStyles<Theme, MakeStyleProps>((theme: Theme) => ({
  button: {
    padding: 0,
    width: 25,
    height: 25,
    borderRadius: "50%",
    border: "none",
    backgroundColor: ({ colour }) => colour,
    "& svg": {
      fill: theme.palette.common.white,
    },
  },
}));

const AppCustomColourButton: React.FC<AppCustomColourButtonProps> = ({
  colour,
  selectedColour,
  onChange,
}) => {
  const classes = useStyles({ colour, selectedColour });
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onChange(colour);
  };

  return (
    <>
      {colour === selectedColour ? (
        <button onClick={handleClick} className={classes.button}>
          <CheckIcon />
        </button>
      ) : (
        <button onClick={handleClick} className={classes.button} />
      )}
    </>
  );
};

export default AppCustomColourButton;
