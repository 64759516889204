import { makeStyles } from "@material-ui/core";
import { Form, Formik } from "formik";
import { useCallback } from "react";
import { ResourceAccountFormFields, ResourceAccountFormValues } from ".";
import { useHttpRequest } from "../../api";
import { editResourceMeWorkingHours, getResourceMe } from "../../api/resources";
import ApiResponseWrapper from "../../components/ApiResponseWrapper";
import AppFormikSubmitButton from "../../components/AppForm/AppFormikSubmitButton";
import { mapToApiWorkingHours } from "../../modelMappings/Resource";
import { successToast } from "../../toast";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: theme.spacing(3),
  },
}));

const ResourceAccountForm: React.FC = () => {
  const classes = useStyles();
  const getAccountDetails = useCallback(() => getResourceMe(), []);
  const {
    result: accountDetails,
    isLoading,
    isError,
  } = useHttpRequest(getAccountDetails);

  const handleAccountUpdate = async (values: ResourceAccountFormValues) => {
    const updateWorkingHoursDto = {
      workingHours: mapToApiWorkingHours(values.workingHours),
    };

    const response = await editResourceMeWorkingHours(updateWorkingHoursDto);

    if (!response.isError) {
      successToast("Your working hours have been updated");
    }
  };

  return (
    <ApiResponseWrapper isError={isError} isFetching={isLoading}>
      {accountDetails && (
        <Formik
          initialValues={{ workingHours: accountDetails.workingHours }}
          onSubmit={handleAccountUpdate}
        >
          <Form>
            <ResourceAccountFormFields.WorkingHours />
            <div className={classes.buttonContainer}>
              <AppFormikSubmitButton>Update</AppFormikSubmitButton>
            </div>
          </Form>
        </Formik>
      )}
    </ApiResponseWrapper>
  );
};

export default ResourceAccountForm;
