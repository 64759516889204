interface TabPanelsProps {
  value: number;
  index: number;
}

const AppTabPanel: React.FC<TabPanelsProps> = ({ value, index, children }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

export default AppTabPanel;
