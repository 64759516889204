import { InputAdornment } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { createPreconfiguredAppFormikTextField } from "../../components/AppForm";
import createPreconfiguredAppFormikCheckbox from "../../components/AppForm/createPreconfiguredAppFormikCheckbox";
import createPreconfiguredAppFormikServiceSelect from "../../components/AppForm/createPreconfiguredAppFormikServiceSelect";
import createPreconfiguredAppFormikSelect from "../../components/AppForm/createPreconfiguredAppFormSelect";

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatCustom = (props: NumberFormatCustomProps) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.target.value,
          },
        });
      }}
      allowNegative={false}
      isNumericString
      decimalScale={2}
      fixedDecimalScale
    />
  );
};

export default {
  Name: createPreconfiguredAppFormikTextField({
    name: "name",
    label: "Name",
  }),
  Description: createPreconfiguredAppFormikTextField({
    name: "description",
    label: "",
    multiline: true,
    rows: 4,
  }),
  OptionalExtras: createPreconfiguredAppFormikServiceSelect({
    name: "optionalExtras",
    label: "Assign optional Extras",
    pleaseSelectText: "-- Please select services --",
  }),
  Price: createPreconfiguredAppFormikTextField({
    name: "price",
    label: "Cost",
    InputProps: {
      inputComponent: NumberFormatCustom as any,
      startAdornment: <InputAdornment position="start">£</InputAdornment>,
    },
  }),
  Duration: createPreconfiguredAppFormikSelect({
    name: "duration",
    label: "Duration",
    pleaseSelectText: "-- Please select duration --",
  }),
  isBackOffice: createPreconfiguredAppFormikCheckbox({
    name: "isBackOfficeOnly",
    label: "Internal booking only",
  }),
  isAvailable: createPreconfiguredAppFormikCheckbox({
    name: "isAvailable",
    label: "Active",
  }),
};
