import {BookedExtra, OptionalExtra} from "../models/Booking";

export   const parseOptionalExtras = (
    extras: BookedExtra[] | OptionalExtra[]
): BookedExtra[] => {
    if (extras.length < 1 || !extras) {
        return [];
    }

    if ("optionalExtraId" in extras[0]) {
        return extras as BookedExtra[];
    }

    const optionalExtras = extras as OptionalExtra[];
    return optionalExtras.map((extra) => ({
        optionalExtraId: extra.id,
        quantity: extra.quantity ?? 1,
        price: extra.price,
    }));
};