import {
  ApiPaginatedResponse,
  ApiStandardResponse,
  buildApiUrl,
  doErrorToastIfRequired,
  httpClient,
  mapHttpClientPaginatedResponse,
  mapHttpClientResponse,
} from "./common";
import { HttpClientResponse } from "./common/httpClient";
import {
  ApiCreateServiceDto,
  ApiPaginatedServiceQueryParams,
  ServiceResponse,
} from "./models/Services";

export const getPaginatedServices = async ({
  pageIndex: pageNumber,
  pageSize,
  searchTerm,
  sortBy,
}: ApiPaginatedServiceQueryParams): Promise<
  HttpClientResponse<ApiPaginatedResponse<ServiceResponse>>
> => {
  const queryParameters = [
    `pageIndex=${pageNumber}`,
    `pageSize=${pageSize}`,
    `searchTerm=${searchTerm}`,
    `sortBy=${sortBy}`,
    `includes=extras`,
  ];

  const url = buildApiUrl(`api/services?${queryParameters.join("&")}`);
  const response = await httpClient.getRequest<
    ApiPaginatedResponse<ServiceResponse>
  >(url);

  doErrorToastIfRequired(response);

  return mapHttpClientPaginatedResponse(response, (m) => m);
};

export const attachServices = async (
  serviceIds: number[],
  resourceId: number
): Promise<HttpClientResponse<void>> => {
  const url = buildApiUrl(`api/resources/${resourceId}/services`);
  const response = await httpClient.putRequest<ApiStandardResponse<void>>(url, {
    appointmentIds: serviceIds,
  });

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, (m) => m);
};

export const createService = async (
  createServiceDto: ApiCreateServiceDto
): Promise<HttpClientResponse<void>> => {
  const url = buildApiUrl("api/services");
  const response = await httpClient.postRequest<ApiStandardResponse<void>>(
    url,
    createServiceDto
  );

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, (m) => m);
};
