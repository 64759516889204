import { makeStyles } from "@material-ui/core";
import { useCalendar } from "../AppCalendarContext";

interface Props {
  start: string;
  end: string;
  duration: number;
}

const useStyles = makeStyles((theme) => ({
  eventContainer: {
    background: `repeating-linear-gradient(
            135deg,
            #bddaf0,
            #bddaf0 20px,
            #D5E6F3 20px,
            #D5E6F3 35px
          )`,
    opacity: 0.6,
    height: "100%",
    padding: 12,
    cursor: "pointer",
    transition: "all .2s ease-in-out",
    "&:hover": {
      opacity: 0.8,
    },
  },
  eventContainerSmall: {
    display: "flex",
    alignItems: "center",
  },
  timeOffInfo: {
    display: "inline-block",
    backgroundColor: theme.palette.primary.light,
    padding: "4px 6px",
    borderRadius: 2,
  },
  timeOffInfoSmall: {
    display: "inline-flex",
    justifyContent: "space-between",
    gridGap: theme.spacing(2),
  },
  timeOffTitle: {},
  timeOffPeriod: {},
}));

const TimeOffEventCard: React.FC<Props> = ({ start, end, duration }) => {
  const classes = useStyles();
  const { isPivotView } = useCalendar();

  if(isPivotView) {
    return (
      <div
        className={`${classes.eventContainer} ${
          duration <= 15 && classes.eventContainerSmall
        }`}
      >
        <div
          className={`${classes.timeOffInfo} ${
            classes.timeOffInfoSmall
          }`}
        >
          <div className={classes.timeOffTitle}>Holiday</div>
          <div className={classes.timeOffPeriod}>
            {start} - {end}
          </div>
        </div>
      </div>  
    );
  }

  return (
    <div
      className={`${classes.eventContainer} ${
        duration <= 15 && classes.eventContainerSmall
      }`}
    >
      <div
        className={`${classes.timeOffInfo} ${
          duration <= 15 && classes.timeOffInfoSmall
        }`}
      >
        <div className={classes.timeOffTitle}>Holiday</div>
        <div className={classes.timeOffPeriod}>
          {start} - {end}
        </div>
      </div>
    </div>
  );
};

export default TimeOffEventCard;
