import * as Yup from "yup";
import { OptionalExtra } from "../../models/AppointmentType";

export const emailFieldName = "email";

export type ServiceFormPage1Values = {
  name: string;
  description?: string;
  optionalExtras: Partial<OptionalExtra>[];
  price: number;
  durationMinutes: number;
  isBackOfficeOnly: boolean;
  isAvailable: boolean;
};

export type ServiceFormValues = ServiceFormPage1Values;

export const defaultServiceFormValues: ServiceFormValues = {
  name: "",
  description: "",
  optionalExtras: [],
  price: 0,
  durationMinutes: 0,
  isBackOfficeOnly: false,
  isAvailable: true,
};

export const ServiceFormPage1ValidationSchema: Yup.SchemaOf<ServiceFormPage1Values> =
  Yup.object().shape({
    name: Yup
        .string()
        .required("Please provide a name.")
        .min(3, "Name must be between 3 and 256 characters")
        .max(256, "Name must be between 3 and 256 characters")
        .test(
          "Valid name characters test",
          "Valid name characters are A-Z a-z 0-9 . -",
          (value) => !(/(?=.[!@#$%^&()_=+{};:,<>]+)/gmu.test(value ?? ""))
      ),
    description: Yup.string().notRequired().max(1024, "Description must be less than 1024 characters"),
    optionalExtras: Yup.array(
      Yup.object().shape({
        id: Yup.number().required("Please provide an id."),
        name: Yup.string().required("Please provide a name."),
        price: Yup.number().required("Please provide a price."),
        description: Yup.string().notRequired(),
        hasQuantity: Yup.boolean().notRequired(),
      })
    ).notRequired(),
    price: Yup.number().required("Please provide a price."),
    durationMinutes: Yup.number().required("Please provide a duration."),
    isBackOfficeOnly: Yup.boolean().required("Please provide a value."),
    isAvailable: Yup.boolean().required("Please provide a value."),
  });

export const ServiceFormPageValidationSchemas = [
  ServiceFormPage1ValidationSchema,
];
