import { lighten, fade, makeStyles, Theme } from "@material-ui/core";

interface StyleProps {
    colour: string;
    isProvisional: boolean;
  }

export const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    eventContainer: {
      position: "relative",
      backgroundColor: ({ colour, isProvisional }) =>
        isProvisional
          ? theme.palette.common.white
          : `${lighten(colour, 0.8)} !important`,
      border: ({ colour, isProvisional }) =>
        isProvisional ? `2px dashed ${colour}` : undefined,
      color: "#1a283d",
      height: "100%",
      overflow: "hidden",
      padding: "0 0.4rem",
      cursor: "pointer",
      transition: "background-color .2s ease-in-out",
      fontSize: 14,
      "&::before": {
        content: ({ isProvisional }) => (isProvisional ? "" : "''"),
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: 5,
        background: ({ colour }) =>
          `linear-gradient(90deg, ${colour} 0%, ${lighten(colour, 0.5)} 100%)`,
      },
      "&:hover": {
        backgroundColor: ({ colour }) => `${lighten(colour, 0.7)} !important`,
      },
    },
    eventContainerLg: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      backgroundColor: ({ colour, isProvisional }) =>
        isProvisional
          ? theme.palette.common.white
          : `${lighten(colour, 0.8)} !important`,
      border: ({ colour, isProvisional }) =>
        isProvisional ? `2px dashed ${colour}` : undefined,
      color: "#1a283d",
      height: "100%",
      overflow: "hidden",
      padding: "0 0.4rem",
      cursor: "pointer",
      transition: "background-color .2s ease-in-out",
      fontSize: theme.typography.body1.fontSize,
      "&:hover": {
        backgroundColor: ({ colour }) => `${lighten(colour, 0.7)} !important`,
        "& $overflowGradient": {
          "&::after": {
            opacity: 1,
          },
        },
      },
      "&::before": {
        content: ({ isProvisional }) => (isProvisional ? "" : "''"),
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: 5,
        background: ({ colour }) =>
          `linear-gradient(90deg, ${colour} 0%, ${lighten(colour, 0.5)} 100%)`,
      },
    },
    eventHeaderXS: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "100%",
      whiteSpace: "nowrap",
      gridGap: theme.spacing(2),
      paddingTop: ({ isProvisional }) => (isProvisional ? 0 : 5),
      "& span:first-of-type": {
        flex: "auto",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
      "& span:last-of-type": {
        flex: 1,
        textAlign: "right",
      },
    },
    eventHeaderMd: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: 36,
      paddingTop: ({ isProvisional }) => (isProvisional ? 0 : 5),
      whiteSpace: "nowrap",
      gridGap: theme.spacing(2),
      "& span:first-of-type": {
        flex: "auto",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
      "& span:last-of-type": {
        flex: 1,
        textAlign: "right",
      },
    },
    pivotEventHeaderMd: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "100%",
      paddingTop: ({ isProvisional }) => (isProvisional ? 0 : 5),
      whiteSpace: "nowrap",
      gridGap: theme.spacing(2),
      "& span:first-of-type": {
        flex: "auto",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
      "& span:last-of-type": {
        flex: 1,
        textAlign: "right",
      },
    },
    serviceInfoMd: {
      display: "flex",
      alignItems: "center",
      height: 30,
      justifyContent: "space-between",
      whiteSpace: "nowrap",
      gridGap: theme.spacing(2),
      "& span:first-of-type": {
        flex: "auto",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
      "& span:last-of-type": {
        flex: 1,
        textAlign: "right",
      },
    },
    serviceInfoLg: {
      flex: 1,
      display: "flex",
      padding: "4px 0 0 4px",
      whiteSpace: "nowrap",
      flexDirection: "column",
      overflow: "hidden",
    },
    serviceInfoLgLHS: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      flex: "1",
      whiteSpace: "pre-wrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      overflowY: "auto",
      // justifyContent: "space-between",
      "& span:first-of-type": {
        position: "relative",
        whiteSpace: "pre-wrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
      "& span:last-of-type": {
        marginLeft: theme.spacing(1),
      },
      "& div:last-of-type": {
        // marginLeft: theme.spacing(1),
      },
      "& p": {
        fontWeight: "bold",
        marginBottom: 0,
      },
      "& div": {
        paddingBottom: 0,
        marginBottom: 0,
      }
    },
    serviceInfoLgRHS: {
      flex: "none",
      display: "flex",
      justifyContent: "flex-end",
    },
    optionalExtraList: {
      // display: "flex",
      // flexDirection: "column",
      listStyleType: "none",
      padding: 0,
      paddingLeft: theme.spacing(1),
      margin: 0,
      "& li:before": {
        content: "'+ '",
      },
    },
    overflowGradient: {
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: theme.spacing(2),
      background: ({ colour }) =>
        `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, ${lighten(
          colour,
          0.8
        )} 100%)`,
      "&::after": {
        content: "''",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0,
        transition: "all .2s ease-in-out",
        background: ({ colour }) =>
          `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, ${lighten(
            colour,
            0.7
          )} 100%)`,
      },
    },
    servicePriceLHS: {
      display: "flex",
      gridGap: theme.spacing(1),
      borderBottom: ({ colour, isProvisional }) =>
        `2px solid ${isProvisional ? fade(colour, 0.2) : theme.palette.common.white
        }`,
    },
  }));