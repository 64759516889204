import { ApiBookingDetails, ApiBookingSummary } from "../api/models/Booking";
import { BookingDetails, BookingSummary } from "../models/Booking";

export const mapFromApiBookingSummary = (
  apiBooking: ApiBookingSummary
): BookingSummary =>
  ({
    ...apiBooking,
    start: new Date(apiBooking.start),
    end: new Date(apiBooking.end),
  } as BookingSummary);

export const mapFromApiBookingDetails = (
  apiBooking: ApiBookingDetails
): BookingDetails => ({
  ...apiBooking,
  start: new Date(apiBooking.start),
  end: new Date(apiBooking.end),
});
