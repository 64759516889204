import {
  FormControlLabel,
  InputLabel,
  makeStyles,
  Radio,
  Step,
  StepLabel,
  Stepper,
} from "@material-ui/core";
import { Form, useField, useFormikContext } from "formik";
import AppButton from "../../components/AppButton";
import AppForm from "../../components/AppForm";
import AppButtonsSpacer from "../../components/AppForm";
import AppFormButtons from "../../components/AppForm/AppFormButtons";
import AppFormikSubmitButton from "../../components/AppForm/AppFormikSubmitButton";
import { useWindowSize } from "../../components/MainLayout/WindowSize";
import { CoverageMap } from "../CoverageMap";
import InformationTextBox from "../../components/InformationTextBox/InformationTextBox";
import ResourceFormFields from "./ResourceFormFields";
import ResourceFormResourceTypeSelect from "./ResourceFormResourceTypeSelect";
import AppFormikBookableDatesPicker from "./AppFormikBookableDatesPicker";
import { useCallback, useEffect, useState } from "react";
import { getGeoJsonByOutcode } from "../../api/geocoding";
import { validateOutcodeRegex } from "./ResourceFormValues";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "../../store";
import { getAllTagTypes } from "../../api/tags";
import { useHttpRequest } from "../../api";
import { getAllAppointmentTypes } from "../../api/appointmentTypes";
import { AppointmentTypeCapabilities } from "../../models/Resource";

export type ResourceFormProps = {
  activeStep: number;
  isEdit?: boolean;
  isLastStep: boolean;
  onPreviousClick: () => void;
};

const useStyles = makeStyles((theme) => ({
  stepper: {
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: 450,
    [theme.breakpoints.down("sm")]: {
      minWidth: 350,
    },
  },
  label: {
    "& span": {
      fontSize: "10px",

      [theme.breakpoints.up("sm")]: {
        fontSize: theme.typography.fontSize,
      },
    },
  },
  coverageContainer: {
    [theme.breakpoints.up("md")]: {
      display: "grid",
      "& > div": {
        gridRow: "1 / 2",
      },
    },
  },
  radioInput: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    alignItems: "flex-start",
    "& .MuiFormControlLabel-root": {
      alignItems: "flex-start",
    },
    "& .MuiIconButton-root": {
      paddingTop: 0,
      alignItems: "flex-start",
      flex: "none",
    },
  },
}));

const ResourceForm: React.FC<ResourceFormProps> = ({
  activeStep,
  isEdit,
  isLastStep,
  onPreviousClick,
}) => {
  const { width } = useWindowSize();
  const classes = useStyles();
  const { submitForm } = useFormikContext();
  const [{ value: basePostcode }, { error: basePostcodeError }] =
    useField("basePostcode");
  const [{ value: maxTravelDistanceMiles }] = useField(
    "maxTravelDistanceMiles"
  );
  const [{ value: coverageType }, , { setValue: setCoverageType }] =
    useField("coverageType");
  const [{ value: coveredOutcodes }] = useField("coveredOutcodes");
  const outcodeGeoJsonItems = useSelector(selectors.outcodes.all);
  const dispatch = useDispatch();

  const getServices = useCallback(() => getAllAppointmentTypes(), []);
  const { result: services } = useHttpRequest(getServices);

  const getTagTypes = useCallback(() => getAllTagTypes(), []);
  const { result: tags } = useHttpRequest(getTagTypes);

  const validateOutcodeTagInput = async (outcode: string) => {
    const outcodes = outcode.split(/[, ]/).filter((element) => {
      return validateOutcodeRegex(element);
    });

    outcodes.forEach((element) => {
      if (outcodeGeoJsonItems.map((o) => o.outcode).includes(element)) {
        return true;
      }
    });

    return await Promise.all(
      outcodes.map(async (outcode) => {
        const result = await getGeoJsonByOutcode(outcode);

        if (!result.isError) {
          dispatch(actions.outcodes.addOutcodeGeoJson(result.content));
          return true;
        }

        return false;
      })
    )
      .then((values) => {
        return !values.includes(false);
      })
      .catch((error) => {
        console.log(`Err: resolving JSON outcode ${error}`);
        return false;
      });
  };

  const outcodeGeoJsonList = outcodeGeoJsonItems.filter((o) =>
    coveredOutcodes.includes(o.outcode)
  );

  const formatServiceLabel = (name: string, price: number) => {
    if (price != 0) {
      return `${name} (£${price.toFixed(2)})`;
    }

    return name;
  };

  const stepContent = [
    <>
      <InformationTextBox>
        Mobile workers are the people that you have out on the road visiting
        your customers. When you finish creating them, they’ll get a
        confirmation email to let them know they’ve been registered.
      </InformationTextBox>
      <ResourceFormFields.Name />
      <ResourceFormResourceTypeSelect />
      <ResourceFormFields.Email />
      <ResourceFormFields.PhoneNumber />
      <ResourceFormFields.Colour />
    </>,
    <>
      <InformationTextBox>
        Here you can select and deselect different services for your mobile
        worker.
      </InformationTextBox>
      {services?.content.length != 0 && (
        <ResourceFormFields.Services
          services={services ? services.content : []}
          serviceMapper={(s: AppointmentTypeCapabilities) => ({
            value: s.id,
            label: formatServiceLabel(s.name, s.price),
          })}
          serviceMatcher={(id) => services?.content.find((s) => s.id === id)}
          serviceLabel={(s: AppointmentTypeCapabilities) =>
            formatServiceLabel(s.name, s.price)
          }
        />
      )}
      {tags?.content.length != 0 && (
        <ResourceFormFields.Tags
          tags={tags ? tags.content : []}
          tagMapper={(t) => ({ value: t.id, label: `${t.name}: ${t.value}` })}
          tagMatcher={(id) => tags?.content.find((t) => t.id === id)}
          tagLabel={(t) => `${t.name}: ${t.value}`}
        />
      )}
    </>,
    <>
      <InformationTextBox>
        Select the days and times this mobile worker will be working:
      </InformationTextBox>
      <ResourceFormFields.WorkingHours />
    </>,
    <>
      <div className={classes.coverageContainer}>
        <CoverageMap
          coverageType={coverageType}
          basePostcode={basePostcodeError ? undefined : basePostcode}
          maxTravelDistanceMiles={maxTravelDistanceMiles}
          outcodeGeoJson={outcodeGeoJsonList}
        />
        <div>
          <InformationTextBox>
            There are a few ways you can set up your mobile workers for where
            they can travel to. Please select one of the options below:
          </InformationTextBox>
          <ResourceFormFields.BasePostcode />
          <FormControlLabel
            className={classes.radioInput}
            label="Set up this mobile worker based on miles travelled, ie. 5 mile radius from a base postcode"
            control={
              <Radio
                color="primary"
                checked={coverageType === "radius"}
                onChange={() => setCoverageType("radius")}
                value="radius"
              />
            }
          />
          {coverageType === "radius" && (
            <>
              <ResourceFormFields.MaxTravelDistanceMiles />
            </>
          )}
          <FormControlLabel
            className={classes.radioInput}
            label="Set up this mobile worker based on a list of postcodes covered, ie. ML3, ML4"
            control={
              <Radio
                color="primary"
                checked={coverageType === "outcodeList"}
                onChange={() => setCoverageType("outcodeList")}
                value="outcodeList"
              />
            }
          />
          {coverageType === "outcodeList" && (
            <ResourceFormFields.CoveredOutcodes
              validation={validateOutcodeTagInput}
            />
          )}
          <br />
          <AppFormikBookableDatesPicker label="Mobile worker activation" />
        </div>
      </div>
    </>,
  ];

  return (
    <Form>
      <Stepper
        activeStep={activeStep}
        alternativeLabel={width < 768}
        className={classes.stepper}
      >
        <Step>
          <StepLabel className={classes.label}>Details</StepLabel>
        </Step>
        <Step>
          <StepLabel className={classes.label}>Services</StepLabel>
        </Step>
        <Step>
          <StepLabel className={classes.label}>Working Hours</StepLabel>
        </Step>
        <Step>
          <StepLabel className={classes.label}>Coverage</StepLabel>
        </Step>
      </Stepper>
      <div>
        <AppForm>{stepContent[activeStep]}</AppForm>
        <AppFormButtons>
          {activeStep !== 0 && (
            <AppButton color="secondary" onClick={onPreviousClick}>
              Previous
            </AppButton>
          )}
          <AppButtonsSpacer />
          {!isLastStep && <AppButton onClick={submitForm}>Next</AppButton>}
          {isLastStep && (
            <>
              <AppFormikSubmitButton>
                {isEdit ? "Save Changes" : "Create"}
              </AppFormikSubmitButton>
            </>
          )}
        </AppFormButtons>
      </div>
    </Form>
  );
};

export default ResourceForm;
