import { makeStyles } from "@material-ui/core";
import { AppointmentType } from "../../../models/AppointmentType";
import { BookedExtra } from "../../../models/Booking";
import { excludeVATFromPriceIfRequired } from "../../../numericalUtils";

interface OptionalExtraListProps {
  appointmentTypes: AppointmentType[],
  appointmentTypeId: number,
  optionalExtra: BookedExtra | any,
  currentTaxRatePercentage: number,
  isPriceOverridden: boolean;
}

const useStyles = makeStyles((theme) => ({
  optional: {
    fontWeight: 300,
    marginBottom: theme.spacing(1),
    lineHeight: "18px",
  },
  optionalList: {
    paddingBottom: theme.spacing(0.1),
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  overriddenPrice: {
    textDecoration: "line-through",
  },
}));

const OptionalExtrasListViewItem: React.FC<OptionalExtraListProps> = ({ appointmentTypes, appointmentTypeId, optionalExtra, currentTaxRatePercentage, isPriceOverridden }) => {
  const classes = useStyles();
  const extra = appointmentTypes
    .find(app => app.id === appointmentTypeId)
    ?.availableOptionalExtras
    .find(ex => ex.id === optionalExtra.optionalExtraId ?? optionalExtra.id);

  let extraLabel = `` + optionalExtra.name;
  if (extra !== undefined) {
    extraLabel = `` + extra.name;
  }

  if (optionalExtra.quantity > 1) {
    extraLabel += ` (x${optionalExtra.quantity})`;
  }

  return (
    <div className={classes.optional}>
      <div className={classes.optionalList} key={`service-extra-id-${optionalExtra.optionalExtraId}`}>
        <span>{extraLabel}</span>
        <span>&pound;<span className={`${isPriceOverridden ? classes.overriddenPrice : ''}`}>{
          excludeVATFromPriceIfRequired(optionalExtra.price * optionalExtra.quantity, currentTaxRatePercentage ?? 0).toFixed(2)
        }</span> {currentTaxRatePercentage ? ` (ex. VAT)` : ``}</span>
      </div>
    </div>
  );
};

export default OptionalExtrasListViewItem;