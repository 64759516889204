import { Grid } from "@material-ui/core";
import WidgetDateSelect from "./WidgetDateSelect";
import WidgetBookableSlotsSelect from "./WidgetBookableSlotsSelect";
import { useEffect, useRef, useState } from "react";
import { useField } from "formik";
import { useWidgetVendorId } from "../../routes/public/PublicRoutes";
import { toUTCISOString } from "../../dateFormatters";
import AppLoader from "../../components/AppLoader";
import { isAfter } from "date-fns";
import useGetWidgetBookableSlots from "../hooks/useGetWidgetBookableSlots";
import { BookedExtra } from "../../models/Booking";
import {useSelector} from "react-redux";
import {selectors} from "../../store";

export interface WidgetBookingDetailsProps {
  handleSlotSelected: (bool: boolean) => void;
  scrollToSlots: (childPosition: number) => void;
}

const WidgetBookingDetails: React.FC<WidgetBookingDetailsProps> = ({
  handleSlotSelected,
  scrollToSlots,
}) => {
  const vendorId = useWidgetVendorId();
  const slotContainerRef = useRef<HTMLDivElement>(null);
  const bookingBasketServices = useSelector(selectors.basket.services);

  const [{ value: selectedDate }, , { setValue: setDate }] =
    useField<Date | null>("date");
  const [{ value: postcode }, ,] = useField<string>("postcode");
  const [{ value: appointmentTypeId }, ,] = useField<number>(
    "firstJobAppointmentTypeId"
  );
  const [{ value: firstJobOptionalExtras }, ,] = useField<BookedExtra[]>(
    "firstJobOptionalExtras"
  );

  const [initialSearchIsComplete, setInitialSearchIsComplete] = useState(false);

  const searchSlotParams = {
    date: !selectedDate ? selectedDate : toUTCISOString(new Date(selectedDate)),
    postcode: postcode,
    appointmentTypeId: appointmentTypeId,
    vendorId: vendorId,
    optionalExtras: firstJobOptionalExtras
      .map(
        (extra, index) =>
          `optionalExtras[${index}].optionalExtraId=${extra.optionalExtraId}&optionalExtras[${index}].quantity=${extra.quantity}`
      )
      .join("&"),
  };

  const {
    isLoading,
    slots: bookingSlots,
    firstAvailableSlots,
    setParameters,
  } = useGetWidgetBookableSlots(searchSlotParams);

  useEffect(() => {
    setParameters(searchSlotParams);
  }, [
    selectedDate,
    postcode,
    appointmentTypeId,
    vendorId,
    firstJobOptionalExtras,
    bookingBasketServices,
  ]);

  const firstAvailableSlot = firstAvailableSlots[0] ?? undefined;

  useEffect(() => {
    if (firstAvailableSlot) {
      const availableDate = new Date(firstAvailableSlot.start);
      const availableIsAfter = !selectedDate
        ? null
        : isAfter(availableDate, new Date(selectedDate));

      if (availableIsAfter) {
        setDate(availableDate);
      }
    }
  }, [firstAvailableSlot]);

  useEffect(() => {
    if (!isLoading) {
      if (!initialSearchIsComplete) {
        setInitialSearchIsComplete(true);
      }

      if (initialSearchIsComplete) {
        scrollToSlots(slotContainerRef.current?.offsetTop ?? 1000);
      }
    }
  }, [isLoading]);

  if (isLoading) return <AppLoader />;

  if (firstAvailableSlot === undefined) {
    return (
      <p>
        <b>Sorry we are fully booked at the moment, please try again later.</b>
      </p>
    );
  }

  return (
    <Grid container>
      <Grid item xs={screen.width < 599 ? 12 : 6}>
        <WidgetDateSelect
          minDate={new Date(firstAvailableSlot.start).toISOString()}
        />
      </Grid>
      <Grid item xs={screen.width < 599 ? 12 : 6}>
        <div ref={slotContainerRef}>
          <WidgetBookableSlotsSelect
            handleSlotSelected={handleSlotSelected}
            bookingSlots={bookingSlots}
            firstAvailableBookingSlot={firstAvailableSlot}
            isLoading={isLoading}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default WidgetBookingDetails;
