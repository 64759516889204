import { AppCalendar } from "./AppCalendar";
import { CALENDAR_VIEWS } from "./AppCalendarContext";
import AppCalendarProvider from "./AppCalendarProvider";

export interface AppCalendarContainerProps {
  canCreateEvent?: boolean;
  canEditEvent?: boolean;
  canAddTimeOff?: boolean;
  initialView?: CALENDAR_VIEWS;
  allowedViews?: CALENDAR_VIEWS[];
  initialResourceId?: number;
}

export const AppCalendarContainer: React.FC<AppCalendarContainerProps> = ({
  canCreateEvent,
  canEditEvent,
  canAddTimeOff,
  initialView,
  allowedViews,
  initialResourceId,
}) => {
  return (
    <AppCalendarProvider
      canCreateEvent={canCreateEvent}
      canEditEvent={canEditEvent}
      canAddTimeOff={canAddTimeOff}
      initialView={initialView}
      allowedViews={allowedViews}
      initialResourceId={initialResourceId}
    >
      <AppCalendar />
    </AppCalendarProvider>
  );
};
