import ComboBox from "react-responsive-combo-box";
import { Search as SearchIcon } from "@material-ui/icons";
import "react-responsive-combo-box/dist/index.css";
import { appComboBoxStyles } from "./AppComboBoxStyles";
import { Close as CloseIcon } from "@material-ui/icons";
import AppFormHelperText from "../AppForm/AppFormHelperText";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { CircularProgress } from "@material-ui/core";

export interface ComboBoxOption {
    id: number;
    label: string;
}

export interface AppComboBoxProps {
    options: ComboBoxOption[];
    value?: ComboBoxOption;
    placeholder?: string;
    error?: boolean;
    helperText?: string;
    readOnly?: boolean;
    isLoading?: boolean;
    onChange(option: ComboBoxOption | null): void;
    debouncedAction?: (searchInput: string) => void;
}

const AppComboBox: React.FC<AppComboBoxProps> = ({
    options,
    value,
    placeholder,
    error,
    helperText,
    readOnly,
    isLoading,
    onChange,
    debouncedAction
}) => {
    const classes = appComboBoxStyles();
    const [searchTerm, setSearchTerm] = useState("");

    const debouncedSearch = useRef(
        debounce(async (searchInput: string) => {
            debouncedAction && debouncedAction(searchInput);
        }, 300)
    ).current;

    const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        debouncedSearch.cancel();
        setSearchTerm(e.currentTarget.value);
        debouncedSearch(e.currentTarget.value);
    };

    const handleSelect = (val: string) => {
        const option = options.find(option => option.label === val);
        
        option && onChange(option);
    };

    const undoSelectedOption = () => {
        onChange(null);
    };

    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    const comboBoxOptions = options.map(option => option.label);
    const comboBoxValue = value?.label;
    const noSearchResults = (options.length === 0 && searchTerm !== "" && !isLoading);
    
    return (
        <>
            <div className={`${classes.comboBoxContainer} ${readOnly && classes.comboBoxContainerDisabled}`}>
                <div className={classes.comboBoxIcon}>
                    <SearchIcon />
                </div>
                {isLoading &&
                    <div className={classes.comboBoxLoading}>
                        <CircularProgress size={24} />
                    </div>
                }
                <ComboBox 
                    className={classes.comboBoxWrapper}
                    inputClassName={classes.comboBoxInput}
                    optionsClassName={classes.comboBoxOption}
                    placeholder={placeholder}
                    options={comboBoxOptions}
                    onSelect={handleSelect} 
                    defaultValue={comboBoxValue}
                    onChange={debouncedAction && handleSearchInputChange}
                    enableAutocomplete
                    editable={!(comboBoxValue)} />
                {comboBoxValue && !readOnly &&
                    <div className={classes.selectedOverlay}>
                        <button type="button" className={classes.selectedRemoveBtn} onClick={undoSelectedOption}>
                            <CloseIcon />
                            <span className={classes.visuallyHidden}>Undo selected option</span>
                        </button>
                    </div>
                }
                {noSearchResults &&
                    <div className={classes.noOptionsFound}>
                        No results found matching <b>&quot;{searchTerm}&quot;</b>
                    </div>
                }
            </div>
            <AppFormHelperText error={error} helperText={helperText} />
        </>
    );
};

export default AppComboBox;