import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { BookingFields } from "./BookingFormFields";
import {
  getAllResources,
  getAvailableResources,
  getResourceMe,
} from "../../api/resources";
import { AppSelectOption } from "../../components/AppForm/AppFormikSelect";
import { useAuth } from "../../auth";
import { useField } from "formik";
import { ResourceSummary } from "../../models/Resource";
import {useSelector} from "react-redux";
import {selectors} from "../../store";

interface BookingManualResourceSelectionProps {
  readOnly?: boolean;
  fetchAll?: boolean;
  userIsAdmin?: boolean;
  onGranularityChange?: (granularity: number) => void;
}

const BookingManualResourceSelection: React.FC<BookingManualResourceSelectionProps> =
  ({ readOnly, fetchAll, userIsAdmin, onGranularityChange }) => {
    const [resources, setResources] = useState<ResourceSummary[]>([]);
    const [resourceOptions, setResourceOptions] = useState<AppSelectOption[]>(
      []
    );
    const [{ value: firstJobAppointmentTypeId }] = useField<number>(
      "firstJobAppointmentTypeId"
    );
    const [{ value: resourceId }, , { setValue: setResourceId }] =
      useField<number>("resourceId");

    const basketStoreServices = useSelector(selectors.basket.services);

    const fetchAvailableResources = async () => {
      const response = await getAvailableResources(basketStoreServices.map(service => service.appointmentTypeId).join(","));

      if (!response.isError) {
        const options = response.content.content.map((resource) => {
          return { label: resource.name, value: resource.id };
        });
        setResources(response.content.content);
        setResourceOptions(options);
      }
    };

    const fetchAllResources = async () => {
      const response = await getAllResources();

      if (!response.isError) {
        const options = response.content.content.map((resource) => {
          return { label: resource.name, value: resource.id };
        });
        setResources(response.content.content);
        setResourceOptions(options);
      }
    };

    const fetchCurrentResource = async () => {
      const result = await getResourceMe();

      if (!result.isError) {
        const options = [
          {
            value: result.content.id,
            label: result.content.name,
          },
        ];

        setResourceOptions(options);
        setResources([result.content]);
        setResourceId(result.content.id);
      }
    };

    const handleResourceChange = () => {
      if (resourceId > -1 && onGranularityChange) {
        const resource = resources.find((r) => r.id === resourceId);
        resource && onGranularityChange(resource.timeSlotGranularityMinutes);
      }
    };

    useEffect(() => {
      handleResourceChange();
    }, [resourceId, resources]);

    useEffect(() => {
      if (userIsAdmin) {
        fetchAll ? fetchAllResources() : fetchAvailableResources();
      } else if (!userIsAdmin) {
        fetchCurrentResource();
      }
    }, []);

    return (
      <>
        {userIsAdmin && (
          <Grid item xs={12}>
            <BookingFields.resourceId
              readOnly={readOnly}
              options={resourceOptions}
            />
          </Grid>
        )}
      </>
    );
  };

export default BookingManualResourceSelection;
