import { HttpClientResponse } from ".";
import {
  ApiStandardResponse,
  buildApiUrl,
  doErrorToastIfRequired,
  httpClient,
  mapHttpClientResponse,
} from "./common";
import { ApiVendorMicrositeConfiguration } from "./models/Configuration";

export const getVendorMicrositeConfig = async (
  vendorId: number
): Promise<HttpClientResponse<ApiVendorMicrositeConfiguration>> => {
  const url = buildApiUrl(`api-public/vendor-information?vendorId=${vendorId}`);
  const response = await httpClient.getRequest<
    ApiStandardResponse<ApiVendorMicrositeConfiguration>
  >(url);

  doErrorToastIfRequired(response);
  return mapHttpClientResponse(response, (m) => m);
};
