import { Grid, IconButton, makeStyles } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import AppTabs from "../components/AppTabs";
import AppTabPanel from "../components/AppTabPanel";
import PageHeader from "../components/PageHeader";
import { deleteCustomerAddress, getCustomerDetail } from "../api/customers";
import { CustomerDetails } from "../models/Customers";
import AppLoader from "../components/AppLoader";
import CustomerBookingsTable from "../customers/CustomerBookingsTable";
import CustomerDetailOverview from "../customers/CustomerDetailOverview";
import CustomerNotFound from "../customers/CustomerNotFound";
import CustomerAddressesTable from "../customers/CustomerAddressesTable/CustomerAddressesTable";
import AddIcon from "@material-ui/icons/Add";
import CreateCustomerAddressModal from "../customers/CreateCustomerAddressModal";
import { useHttpRequest } from "../api";
import { useCustomerId } from "../routes";
import EditCustomerModal from "../customers/EditCustomerModal";
import { PageContainer } from "../components/MainLayout";
import AppButton from "../components/AppButton";
import CreateBookingDrawer from "../bookings/CreateBookingDrawer/CreateBookingDrawer";
import { actions } from "../store";
import { useDispatch } from "react-redux";
import { errorToast, successToast } from "../toast";
import AppConfirmationDialog from "../components/AppConfirmationDialog";

interface HeaderProps {
  customerDetail?: CustomerDetails;
}

const useStyles = makeStyles((theme) => ({
  opaqueHeader: {
    opacity: 0.45,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
  },
  actionBtn: {
    marginTop: theme.spacing(2),
  },
  detailOverview: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: theme.spacing(2),
  },
  editIcon: {
    marginRight: theme.spacing(2),
  },
}));

const Header: React.FC<HeaderProps> = ({ customerDetail }) => {
  const classes = useStyles();

  return (
    <>
      <span className={classes.opaqueHeader}>Customer</span>{" "}
      {customerDetail?.firstName} {customerDetail?.lastName}
    </>
  );
};

const CustomerDetailPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const id = useCustomerId();
  const getCustomer = useCallback(() => getCustomerDetail(id), [id]);
  const {
    result: customerDetails,
    isLoading,
    forceRefresh,
  } = useHttpRequest(getCustomer);

  const [showCustomerAddressDialog, setShowCustomerAddressDialog] = useState(false);
  const [isEditModalOpen, setisEditModalOpen] = useState(false);
  const [isBookingModalOpen, setisBookingModalOpen] = useState(false);

  const [showDeleteAddressDialog, setShowDeleteAddressDialog] = useState(false);
  const [addressId, setAddressId] = useState(0);
  const [isEditingAddress, setIsEditingAddress] = useState(false);

  const handleCreatAddressModalClose = (addressCreated: boolean) => {
    if (addressCreated) {
      forceRefresh();
    }
    setShowCustomerAddressDialog(false);
  };

  const handleEditSelect = () => {
    setisEditModalOpen(true);
  };

  const handleEditClose = (customerEdited: boolean) => {
    if (customerEdited) {
      forceRefresh();
    }
    setisEditModalOpen(false);
  };

  const handleAddBooking = () => {
    dispatch(actions.basket.resetBasket());
    setisBookingModalOpen(true);
  };

  const handleCreateBookingClose = () => {
    forceRefresh();
    setisBookingModalOpen(false);
  };

  const handleCreateAddress = () => {
    setIsEditingAddress(false);
    setShowCustomerAddressDialog(true);
  };

  const handleEditAddress = (id: number) => {
    setAddressId(id);
    setIsEditingAddress(true);
    setShowCustomerAddressDialog(true);
  };

  const handleDeleteAddress = (id: number) => {
    setAddressId(id);
    setShowDeleteAddressDialog(true);
  };
  const handleShouldDeleteAddress = async () => {
    if (!customerDetails) {
      errorToast(`Error deleting the address, unable to locate associated customer`);
      return;
    }

    const result = await deleteCustomerAddress(customerDetails.id, addressId);

    if (!result.isError) {
      forceRefresh();
      successToast("Address has been deleted");
    }

    setShowDeleteAddressDialog(false);
  };

  return (
    <PageContainer>
      {isLoading ? (
        <AppLoader />
      ) : (
        <>
          {customerDetails ? (
            <>
              <PageHeader title={<Header customerDetail={customerDetails} />} />
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4} md={3}>
                  <div className={classes.detailOverview}>
                    <CustomerDetailOverview
                      customerDetail={customerDetails}
                      setTab={setTab}
                    />
                  </div>
                  <div className={classes.buttonContainer}>
                    <AppButton onClick={handleEditSelect} fullWidth>
                      Amend Details
                    </AppButton>
                    <AppButton
                      onClick={handleAddBooking}
                      fullWidth
                      className={classes.actionBtn}
                    >
                      Add Booking
                    </AppButton>
                  </div>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <AppTabs
                    tab={tab}
                    tabs={[
                      "Upcoming appointments",
                      "Previous appointments",
                      "Customer addresses",
                    ]}
                    handleChange={(val) => setTab(val)}
                  />
                  <AppTabPanel value={tab} index={0}>
                    <CustomerBookingsTable
                      bookings={customerDetails.upcomingBookings}
                      noBookingsMessage="There are no upcoming bookings."
                    />
                  </AppTabPanel>
                  <AppTabPanel value={tab} index={1}>
                    <CustomerBookingsTable
                      bookings={customerDetails.previousBookings}
                      noBookingsMessage="There are no previous bookings."
                    />
                  </AppTabPanel>
                  <AppTabPanel value={tab} index={2}>
                    <IconButton
                      className={classes.actionBtn}
                      onClick={handleCreateAddress}
                    >
                      <AddIcon />
                    </IconButton>
                    <CustomerAddressesTable
                      addresses={customerDetails.addresses}
                      noAddressesMessage="There are no addresses."
                      handleEditAddress={handleEditAddress}
                      handleDeleteAddress={handleDeleteAddress}
                    />
                    <CreateCustomerAddressModal
                      open={showCustomerAddressDialog}
                      address={isEditingAddress ? customerDetails.addresses.find(a => a.id == addressId) : undefined}
                      handleClose={handleCreatAddressModalClose}
                      id={customerDetails.id}
                    />
                  </AppTabPanel>
                  <EditCustomerModal
                    open={isEditModalOpen}
                    customer={customerDetails}
                    handleClose={handleEditClose}
                  />
                  <CreateBookingDrawer
                    open={isBookingModalOpen}
                    initialCustomerId={customerDetails.id}
                    onClose={handleCreateBookingClose}
                  />
                </Grid>
              </Grid>
              <AppConfirmationDialog
                  open={showDeleteAddressDialog}
                  handleCancel={() => setShowDeleteAddressDialog(false)}
                  handleConfirmation={handleShouldDeleteAddress}
                  title="Delete Customer Address"
                  caption="Please confirm you wish to delete this customer address."
                  confirmLabel="Confirm"
                  cancelLabel="Cancel"
              />
            </>
          ) : (
            <CustomerNotFound />
          )}
        </>
      )}
    </PageContainer>
  );
};

export default CustomerDetailPage;
