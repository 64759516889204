import { LayerProps } from "react-map-gl";

export { default as CoverageMap } from "./CoverageMap";

export interface LongitudeLatitude {
  longitude: number;
  latitude: number;
}

export interface Viewport {
  longitude: number;
  latitude: number;
  zoom: number;
  scrollZoom?: boolean;
  bearing?: number;
  pitch?: number;
}

// Default to center of UK
export const defaultViewPort: Viewport = {
  longitude: -2.36966957036279,
  latitude: 54.2379333607472,
  zoom: 5,
  scrollZoom: false,
  bearing: 0,
  pitch: 0,
};

type LayerType = 'fill' | 'line' | 'symbol' | 'circle' | 'fill-extrusion' | 'raster' | 'background' | 'heatmap' | 'hillshade' | 'sky';

export const mapLayerStyle = (id: string, type: LayerType): LayerProps => ({
  id,
  type,
  paint: {
    "fill-color": "#007cbf",
    "fill-opacity": 0.6,
  }
});