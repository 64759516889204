import { OptionalExtra } from "./models/AppointmentType";
import { ReactNode } from "react";

export const formatExtraName = (
  optionalExtra: OptionalExtra,
  quantity: number
): { nameLine: string; price: string } => {
  const base = "Optional extra service -";
  const displayedQuantity = quantity > 1 ? ` x${quantity}` : "";
  const displayedPrice = (optionalExtra.price * quantity).toFixed(2);
  return {
    nameLine: `${base + optionalExtra.name}${displayedQuantity}`,
    price: `£${displayedPrice}`,
  };
};
