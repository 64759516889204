import { makeStyles } from "@material-ui/core";
import { ApiBookableSlotDemoDto } from "../../api/models/BookableSlotsDemo";
import { formatTimeOnly } from "./dateFormatters";

export interface OfferedSlotsProps {
  slots: ApiBookableSlotDemoDto[];
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  slot: {
    border: "1px solid dodgerBlue",
    borderRadius: "6px",
    color: "dodgerBlue",
    background: "lightBlue",
    padding: "6px",
    margin: "5px",
  },
});

const OfferedSlots: React.FC<OfferedSlotsProps> = ({ slots }) => {
  const classNames = useStyles();

  return (
    <div className={classNames.root}>
      {slots.map((slot, index) => (
        <p key={`${slot.start}-${index}`} className={classNames.slot}>
          {formatTimeOnly(slot.start)}
        </p>
      ))}
    </div>
  );
};

export default OfferedSlots;
