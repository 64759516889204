import { makeStyles } from "@material-ui/core";
import { adminPaths } from "../routes";
import AppButton from "../components/AppButton";
import BlockIcon from "@material-ui/icons/Block";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
  icon: {
    width: "100%",
    height: "100%",
    maxWidth: "340px",
    maxHeight: "340px",
    fill: theme.palette.grey[300],
  },
}));

const CustomerNotFound: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BlockIcon className={classes.icon} />
      <h2>This customer does not exist</h2>
      <AppButton linkPath={adminPaths.customers}>View customers</AppButton>
    </div>
  );
};

export default CustomerNotFound;
