import { makeStyles, Checkbox } from "@material-ui/core";
import React from "react";
import { days } from "../../../dateFormatters";
import AppButton from "../../AppButton";
import AppTimeInput from "../AppTimeInput";

type timeName = "startTime" | "finishTime";

interface AppWorkingHoursPickerDayProps {
  dayOfWeekId: number;
  startTime: number;
  finishTime: number;
  isWorking: boolean;
  applyToAll?: boolean;
  onTimeChange: (
    dayOfWeekId: number,
    time?: number,
    name?: timeName,
    isWorking?: boolean
  ) => void;
  onIsWorkingChange: (
    dayOfWeekId: number,
    time?: number,
    name?: timeName,
    isWorking?: boolean
  ) => void;
  handleApplyToAll: (dayOfWeekId: number) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
  },
  dayRow: {
    display: "flex",
    alignItems: "center",
    padding: ".25rem 0",
  },
  dayRowNonWorking: {
    display: "flex",
    alignItems: "center",
    padding: ".25rem 0",
    color: theme.palette.grey[400],
  },
  dayLabel: {
    width: 80,
    flex: "none",
  },
  padded: {
    padding: `0 ${theme.spacing(2)}px`,
  },
  checkbox: {
    marginRight: theme.spacing(2),
  },
  applyToAllBtn: {
    marginLeft: theme.spacing(2),
  },
}));

const AppWorkingHoursPickerDay: React.FC<AppWorkingHoursPickerDayProps> = ({
  dayOfWeekId,
  startTime,
  finishTime,
  isWorking,
  applyToAll,
  onTimeChange,
  onIsWorkingChange,
  handleApplyToAll,
}) => {
  const classes = useStyles();

  const handleTimeChange = (name: timeName, value: number) => {
    onTimeChange(dayOfWeekId, value, name);
  };

  const handleIsWorkingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onIsWorkingChange(dayOfWeekId, undefined, undefined, e.target.checked);
  };

  const handleApplyAll = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleApplyToAll(dayOfWeekId);
  };

  return (
    <>
      <div className={isWorking ? classes.dayRow : classes.dayRowNonWorking}>
        <span className={classes.dayLabel}>{days[dayOfWeekId]}</span>
        <Checkbox
          className={classes.checkbox}
          name="isWorking"
          checked={isWorking}
          onChange={handleIsWorkingChange}
          color="primary"
        />
        {isWorking ? (
          <>
            <AppTimeInput
              value={startTime}
              onChange={(val) => handleTimeChange("startTime", val)}
              maxValue={1430}
              minuteIncrement={5}
            />
            <span className={classes.padded}>to</span>
            <AppTimeInput
              value={finishTime}
              onChange={(val) => handleTimeChange("finishTime", val)}
              minValue={5}
              minuteIncrement={5}
            />
          </>
        ) : (
          <span>Non-working day</span>
        )}
        {applyToAll && (
          <AppButton
            variant="text"
            className={classes.applyToAllBtn}
            onClick={(e) => handleApplyAll(e)}
          >
            Apply to all
          </AppButton>
        )}
      </div>
    </>
  );
};

export default AppWorkingHoursPickerDay;
