import { makeStyles } from "@material-ui/core";
import { useField } from "formik";
import {
  days,
  formatDateSuffix,
  formatMinutesAfterMidnightTo12HourWithAmPm,
} from "../../dateFormatters";
import { AppointmentType, OptionalExtra } from "../../models/AppointmentType";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import { BookedExtra } from "../../models/Booking";
import { useEffect, useState } from "react";
import { calculateServiceTotal, excludeVATFromPriceIfRequired } from "../../numericalUtils";
import { formatExtraName } from "../../stringFormatters";
import BookingBasket from "../../components/bookings/basket/WidgetBookingBasket";

const useStyles = makeStyles((theme) => ({
  serviceInfo: {
    lineHeight: "9px",
    paddingRight: theme.spacing(1),
    width: "65%",
  },
  container: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    marginBottom: theme.spacing(5),
  },
  dateTime: {
    alignItems: "center",
    display: "flex",
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down(599)]: {
      display: "block",
    },
  },
  date: {
    marginLeft: "auto",
    borderLeft: `2px solid ${theme.palette.grey[300]}`,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(4),
    marginRight: theme.spacing(1),
    fontSize: "32px",
    fontWeight: "bold",
    [theme.breakpoints.down(599)]: {
      borderLeft: "none",
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(1),
      borderTop: `2px solid ${theme.palette.grey[300]}`,
      display: "flex",
    },
    [theme.breakpoints.up(600)]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  appointment: {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "18px",
  },
  optional: {
    fontWeight: 300,
    marginBottom: theme.spacing(1),
    lineHeight: "18px",
  },
  optionalList: {
    paddingBottom: theme.spacing(1),
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  addressLine: {
    fontWeight: 400,
    paddingTop: theme.spacing(1),
    lineHeight: "18px",
  },
  dateLine: {
    lineHeight: "20px",
  },
  totalCostText: {
    fontSize: 16,
    fontWeight: "normal",
    flexGrow: 1,
    alignSelf: "flex-start",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
  },
  checkIcon: {
    color: theme.palette.primary.main,
    fontSize: "100px",
    stroke: theme.palette.grey[200],
    strokeWidth: "1",
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(4),
    },
  },
  summaryText: {
    marginLeft: theme.spacing(1),
  },
  headerTitle: {
    marginLeft: theme.spacing(1),
  },
  excludingVatText: {
    margin: 0,
    textAlign: "center",
    fontWeight: "normal",
    fontSize: "24px"
  }
}));

interface WidgetSummaryBoxProps {
  appointmentTypes: AppointmentType[];
  isLastStep: boolean;
  slotSelected: boolean;
  selectedOptionalExtras: OptionalExtra[];
  bookedOptionalExtras: BookedExtra[];
  currentTaxRatePercentage?: number;
}

const WidgetSummaryBox: React.FC<WidgetSummaryBoxProps> = ({
  appointmentTypes,
  isLastStep,
  slotSelected,
  selectedOptionalExtras,
  bookedOptionalExtras,
  currentTaxRatePercentage,
}) => {
  const classes = useStyles();

  let [{ value: date }, ,] = useField<Date | null>("date");
  const [{ value: start }, ,] = useField<number>("start");
  const [{ value: end }, ,] = useField<number>("end");
  const [{ value: selectedService }, ,] = useField<number>("firstJobAppointmentTypeId");
  const [{ value: addressLine1 }, ,] = useField<string>("addressLine1");
  const [{ value: addressLine2 }, ,] = useField<string>("addressLine2");
  const [{ value: addressLine3 }, ,] = useField<string>("addressLine3");
  const [{ value: town }, ,] = useField<string>("town");
  const [{ value: postcode }, ,] = useField<string>("postcode");
  const [{ value: isInviteBooking }, ,] = useField<boolean>("isInviteBooking");
  const [{ value: selectedBookedExtras }, ,] = useField<BookedExtra[]>("firstJobOptionalExtras");

  const [, setServiceTotal] = useState<number>(0);

  const selectedAppointment = appointmentTypes.find(
    (appointment) => appointment.id === selectedService
  );

  useEffect(() => {
    setServiceTotal(
      calculateServiceTotal(
        selectedOptionalExtras,
        selectedBookedExtras,
        selectedAppointment?.price ?? 0
      )
    );
  }, [selectedAppointment, selectedBookedExtras]);

  if (date) date = new Date(date);

  const selectedOptionalExtrasToRender = selectedOptionalExtras.map(
    (optionalExtra, index) => {
      const newPrice = excludeVATFromPriceIfRequired(optionalExtra.price, currentTaxRatePercentage ?? 0);
      const quantity =
        bookedOptionalExtras.find(
          (extra) => extra.optionalExtraId === optionalExtra.id
        )?.quantity ?? 1;

      const tempOptionalExtra = {
        ...optionalExtra,
        price: newPrice
      };
      const itemStrings = formatExtraName(tempOptionalExtra, quantity);
      return (
        <div className={classes.optionalList} key={index}>
          <span>{itemStrings.nameLine}</span>
          <span>{itemStrings.price} {currentTaxRatePercentage ? " (ex. VAT)" : ""}</span>
        </div>
      );
    }
  );

  function sumOfService(): number {
    const tempSelectedAppointment = appointmentTypes.find(_ => _.id === selectedService);

    if (!tempSelectedAppointment) return 0;

    const selectedAppointmentPrice = excludeVATFromPriceIfRequired(tempSelectedAppointment.price, currentTaxRatePercentage ?? 0);

    const optionalExtrasTotalPrice = selectedOptionalExtras
      .map(selectedExtra => {
        const selectedBookedExtra = selectedBookedExtras.find(_ => _.optionalExtraId === selectedExtra.id);

        return {
          price: selectedExtra.price,
          quantity: selectedBookedExtra?.quantity,
        };
      })
      .reduce((acc, item) => acc + (item.price * (item.quantity === undefined ? 1 : item.quantity)), 0);

    const optionalExtrasTotalPriceExVATIfRequired = excludeVATFromPriceIfRequired(optionalExtrasTotalPrice, currentTaxRatePercentage ?? 0);

    return parseFloat(optionalExtrasTotalPriceExVATIfRequired.toFixed(2)) + parseFloat(selectedAppointmentPrice.toFixed(2));
  };

  const dayOfTheWeek = date ? days[new Date(date).getDay()] : "";

  const renderBasket = () => {
    return (
      <BookingBasket
        currentTaxRatePercentage={currentTaxRatePercentage}>
        {!isInviteBooking &&
          <p className={classes.addressLine}>
            {addressLine1}, {addressLine2 && `${addressLine2}, `}
            {addressLine3 && `${addressLine3}, `}
            {town}, {postcode}
          </p>
        }
        {slotSelected &&
          <p className={classes.dateLine}>
            <b className={classes.dateLine}>
              {date && days[date.getDay()]}{" "}
              {date && formatDateSuffix(new Date(date))} |{" "}
              {formatMinutesAfterMidnightTo12HourWithAmPm(start)} -{" "}
              {formatMinutesAfterMidnightTo12HourWithAmPm(end)}
            </b>
          </p>
        }
      </BookingBasket>
    );
  };

  if (isLastStep) {
    return (
      <>
        <h2 className={classes.headerTitle}>Your appointment is booked!</h2>
        <p className={classes.summaryText}>
          Please check your email for your booking confirmation. We look forward
          to seeing you on{" "}
          <b>
            {date && days[date.getDay()]} {date && formatDateSuffix(date)}.
          </b>
        </p>
        <div className={classes.container}>
          <div className={classes.iconContainer}>
            <CheckCircleOutlineOutlinedIcon className={classes.checkIcon} />
          </div>
          {renderBasket()}
        </div>
      </>
    );
  }

  return (
    <>
      <div className={classes.container}>
        <div className={classes.dateTime}>
          <div className={classes.serviceInfo}>
            <p className={classes.appointment}>{selectedAppointment?.name}</p>
            {selectedBookedExtras.length > 0 && (
              <p className={classes.optional}>
                {selectedOptionalExtrasToRender}
              </p>
            )}
            {!slotSelected && (
              <p>
                <b className={classes.dateLine}>&nbsp;</b>
              </p>
            )}
            {slotSelected && (
              <p>
                {start > 0 && (
                  <b className={classes.dateLine}>
                    {dayOfTheWeek} {date && formatDateSuffix(date)}
                  </b>
                )}
                {start > 0 && (
                  <b>
                    {" "}
                    | {formatMinutesAfterMidnightTo12HourWithAmPm(start)} -{" "}
                    {formatMinutesAfterMidnightTo12HourWithAmPm(end)}
                  </b>
                )}
              </p>
            )}
          </div>
          <div className={classes.date}>
            {screen.width < 599 && (
              <p className={classes.totalCostText}>Total cost:</p>
            )}
            £{sumOfService().toFixed(2)}
            <p className={classes.excludingVatText}>{currentTaxRatePercentage ? "(ex. VAT)" : ""}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default WidgetSummaryBox;
