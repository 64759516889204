import { InputAdornment, InputBase, makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";

export interface AppSearchBoxProps {
  className?: string;
  placeholder: string;
  onSubmit: () => void;
  onChange: (value: string) => void;
  value: string;
  highlightOnFocus?: boolean;
}

const useStyles = makeStyles((theme) => ({
  search: {
    justifyContent: "flex-end",
    display: "flex",

    marginLeft: 0,
  },
  inputRoot: {
    fontSize: theme.typography.fontSize * 1,
    backgroundColor: "#ececec",
    padding: theme.spacing(0.5),
    color: "#111111",
    fontStyle: "italic",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  icon: {
    color: "#444444",
    marginRight: theme.spacing(1),
  },
}));

const AppSearchBox: React.FC<AppSearchBoxProps> = ({
  className,
  placeholder,
  onSubmit,
  onChange,
  value,
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.search, className)}>
      <InputBase
        fullWidth={false}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon className={classes.icon} />
          </InputAdornment>
        }
        value={value}
        onChange={(event) => onChange(event.target.value)}
        placeholder={placeholder}
        classes={{
          root: classes.inputRoot,
        }}
        inputProps={{ "aria-label": "search" }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onSubmit();
          }
        }}
      />
    </div>
  );
};

export default AppSearchBox;
