import { useField } from "formik";
import AppTagInput, { AppTagInputProps } from "./AppTagInput";
import { FormikInputPropsKeys } from "./common";

export interface AppFormikTagInputProps
  extends Omit<AppTagInputProps, FormikInputPropsKeys> {
  name: string;
}

const AppFormikTagInput: React.FC<AppFormikTagInputProps> = ({
    name,
    ...restOfProps
}) => {
    const [fieldProps, fieldMeta, { setValue }] = useField(name);
    const isError = fieldMeta.touched && !!fieldMeta.error;
    const helperText = fieldMeta.touched ? fieldMeta.error : undefined;

    const handleChange = (tags: string[]) => {
        tags = tags.map(tag => {
            return tag.split(/[, ]/);
        }).flat();
        setValue(tags);
    };
    
    return (
        <AppTagInput
            {...restOfProps}
            {...fieldProps}
            error={isError}
            helperText={helperText}
            onChange={handleChange}
        />
    );
};

export default AppFormikTagInput;