import { useField } from "formik";
import { ChangeEvent } from "react";
import AppComboBox from "../AppComboBox";
import { AppComboBoxProps, ComboBoxOption } from "../AppComboBox/AppComboBox";

interface AppFormikComboBoxProps extends Omit<AppComboBoxProps, "onChange" | "removeSelected"> {
    name: string;
}

const AppFormikComboBox: React.FC<AppFormikComboBoxProps> = ({
    name,
    options,
    ...restOfProps
}) => {
    const [fieldProps, fieldMeta, field] = useField(name);
    const isError = fieldMeta.touched && !!fieldMeta.error;
    const helperText = fieldMeta.touched ? fieldMeta.error : undefined;

    const selectedValue = options.find(option => option.id === fieldProps.value);

    return (
        <AppComboBox
            {...restOfProps}
            options={options}
            onChange={(option: ComboBoxOption) => field.setValue(option ? option.id : 0)}
            value={selectedValue}
            error={isError}
            helperText={helperText}
        />
    );
};

export default AppFormikComboBox;