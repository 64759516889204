import {
  add,
  endOfWeek,
  endOfMonth,
  startOfWeek,
  startOfMonth,
} from "date-fns";

export const startOfNextWeek = (): Date => {
  return add(endOfWeek(new Date(), { weekStartsOn: 1 }), { days: 1 });
};

export const endOfNextWeek = (): Date => {
  return endOfWeek(startOfNextWeek(), { weekStartsOn: 1 });
};

export const startOfNextMonth = (): Date => {
  return add(endOfMonth(new Date()), { days: 1 });
};

export const endOfNextMonth = (): Date => {
  return endOfMonth(startOfNextMonth());
};

export const startOfLastWeek = (): Date => {
  return startOfWeek(endOfLastWeek(), { weekStartsOn: 1 });
};

export const endOfLastWeek = (): Date => {
  return add(startOfWeek(new Date(), { weekStartsOn: 1 }), { days: -1 });
};

export const startOfLastMonth = (): Date => {
  return startOfMonth(endOfLastMonth());
};

export const endOfLastMonth = (): Date => {
  return add(startOfMonth(new Date()), { days: -1 });
};
