const prependZero = (number: number): string => {
  if (number < 10) {
    return `0${number}`;
  } else {
    return number.toString();
  }
};

export const formatTimeOnly = (dateString: string): string => {
  try {
    const date = new Date(dateString);
    const hour = date.getHours();
    const minute = prependZero(date.getMinutes());

    return `${hour}:${minute}`;
  } catch (error) {
    console.log("Error formatting time from date: ", error);
    throw error;
  }
};

export const formatTimeFromTotalMins = (totalMins: number): string => {
  const mins = totalMins % 60;
  const hrs = (totalMins - mins) / 60;

  return `${hrs}:${mins === 0 ? "00" : mins}`;
};

export const convertToUtcTotalMinutes = (totalMinutes: number): number => {
  const mins = totalMinutes % 60;
  const hours = (totalMinutes - mins) / 60;

  const date = new Date();
  date.setHours(hours);
  date.setMinutes(mins);
  return date.getUTCMinutes() + date.getUTCHours() * 60;
};
