import { HttpClientResponse } from "./common/httpClient";
import {
  ApiPaginatedResponse,
  ApiPaginationQueryParams,
  ApiStandardResponse,
  buildApiUrl,
  doErrorToastIfRequired,
  httpClient,
  mapHttpClientPaginatedResponse,
  mapHttpClientResponse,
  mapHttpClientListResponse,
} from "./common";
import { ApiListResponse } from "./common/ApiListResponse";
import {
  ApiCreateCustomerDto,
  ApiCustomerAddress,
  ApiCustomerDetails,
  ApiCustomerSummary,
  ApiUpdateCustomerDto,
  ApiCreateCustomerAddressDto, ApiCustomerAddressCore,
} from "./models/Customer";
import { CustomerDetails, CustomerSummary } from "../models/Customers";
import { mapFromApiCustomerDetails } from "../modelMappings/Customer";

interface CustomerAddress {
  id: number;
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  town: string;
  postcode: string;
}

export const getPaginatedCustomers = async ({
  pageIndex: pageNumber,
  pageSize,
  searchTerm,
  sortBy,
}: ApiPaginationQueryParams): Promise<
  HttpClientResponse<ApiPaginatedResponse<CustomerSummary>>
> => {
  const queryParameters = [
    `pageNumber=${pageNumber}`,
    `pageSize=${pageSize}`,
    `searchTerm=${searchTerm}`,
    `sortBy=${sortBy}`,
  ];
  const url = buildApiUrl(
    `api-admin/paginated-customers?${queryParameters.join("&")}`
  );
  const response = await httpClient.getRequest<
    ApiPaginatedResponse<ApiCustomerSummary>
  >(url);

  doErrorToastIfRequired(response);

  return mapHttpClientPaginatedResponse(response, (m) => m);
};

export const getAllCustomers = async (): Promise<
  HttpClientResponse<ApiListResponse<CustomerSummary>>
> => {
  const url = buildApiUrl("api-vendor/customers");
  const response = await httpClient.getRequest<
    ApiListResponse<ApiCustomerSummary>
  >(url);

  doErrorToastIfRequired(response);

  return mapHttpClientListResponse(response, (m) => m);
};

export const exportCustomers = async(): Promise<HttpClientResponse<string>> => {
  const url = buildApiUrl("api-admin/customers-export");
  const response = await httpClient.getRequest<ApiStandardResponse<string>>(url);

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, (m) => m);
};

export const getCustomerDetail = async (
  id: number
): Promise<HttpClientResponse<CustomerDetails>> => {
  const url = buildApiUrl(`api-vendor/customers/${id}`);
  const response = await httpClient.getRequest<
    ApiStandardResponse<ApiCustomerDetails>
  >(url);

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, mapFromApiCustomerDetails);
};

export const getCustomerAddresses = async (
  id: number
): Promise<HttpClientResponse<ApiListResponse<CustomerAddress>>> => {
  const url = buildApiUrl(`api-vendor/customers/${id}/addresses`);
  const response = await httpClient.getRequest<
    ApiListResponse<ApiCustomerAddress>
  >(url);

  doErrorToastIfRequired(response);

  return mapHttpClientListResponse(response, (m) => m);
};

export const addCustomerAddress = async (
  customerId: number,
  address: ApiCreateCustomerAddressDto
): Promise<HttpClientResponse<number>> => {
  const url = buildApiUrl(`api-admin/customers/${customerId}/addresses`);
  const response = await httpClient.postRequest<ApiStandardResponse<number>>(
    url,
    address
  );

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, (m) => m);
};

export const updateCustomerAddress = async (
    customerId: number,
    addressId: number,
    payload: ApiCustomerAddressCore
): Promise<HttpClientResponse<void>> => {
  const url = buildApiUrl(`api-vendor/customers/${customerId}/addresses/${addressId}`);
  const response = await httpClient.putRequest<ApiStandardResponse<void>>(url, payload);

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, m => m);
};

export const deleteCustomerAddress = async (
  customerId: number,
  addressId: number
): Promise<HttpClientResponse<void>> => {
  const url = buildApiUrl(`api-vendor/customers/${customerId}/addresses/${addressId}`);
  const response = await httpClient.deleteRequest<ApiStandardResponse<void>>(url);

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, (m) => m);
};

export const createCustomer = async (
  createCustomerDto: ApiCreateCustomerDto
): Promise<HttpClientResponse<number>> => {
  const url = buildApiUrl("api-admin/customers");
  const response = await httpClient.postRequest<ApiStandardResponse<number>>(
    url,
    createCustomerDto
  );

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, (m) => m);
};

export const editCustomer = async (
  editCustomerDto: ApiUpdateCustomerDto
): Promise<HttpClientResponse<void>> => {
  const url = buildApiUrl("api-admin/customers");
  const response = await httpClient.putRequest<ApiStandardResponse<void>>(
    url,
    editCustomerDto
  );

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, (m) => m);
};
