import { useState } from "react";
import { makeStyles } from "@material-ui/core";

interface Props {
  text: string;
  styles: string;
}

const useStyles = makeStyles((theme) => ({
  toggler: {
    cursor: "pointer",
    display: "inline-block",
    textDecoration: "underline",
    border: "none",
    backgroundColor: "transparent",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
}));

const ExpandableText: React.FC<Props> = ({ text, styles }) => {
  const classes = useStyles();
  const [isTextExpanded, setIsTextExpanded] = useState(false);

  const renderText = () => {
    if (text == null) {
      return;
    }

    if (
      !isTextExpanded &&
      text.indexOf(".") != text.length - 1 &&
      (text.match(/[.]/g) || []).length >= 1
    ) {
      const indexOf = text.indexOf(".");
      return text.substring(0, indexOf + 1) + "..";
    }

    return text;
  };

  const renderToggleText = () => {
    return `${!isTextExpanded ? "more" : "less"}`;
  };

  const toggleIsTextExpanded = () => {
    setIsTextExpanded(!isTextExpanded);
  };

  const renderToggler = (text: string) => {
    if (text == null) {
      return;
    }

    if ((text.match(/[.]/g) || []).length <= 1) {
      return;
    }
    return (
      <button
        className={classes.toggler}
        onClick={() => toggleIsTextExpanded()}
      >
        {renderToggleText()}
      </button>
    );
  };

  return (
    <div className={styles}>
      <p>
        {renderText()}
        {renderToggler(renderText() ?? text)}
      </p>
    </div>
  );
};

export default ExpandableText;
