import AppFormikCustomColourPicker, {
  AppFormikCustomColourPickerProps,
} from "./AppFormikCustomColourPicker";

export type CreatePreconfiguredAppFormikCustomColourPickerOptions = {
  name: string;
  label: string;
};

export type PreconfiguredAppFormikColourPickerProps = Omit<
  AppFormikCustomColourPickerProps,
  "name"
>;

export const createPreconfiguredAppFormikCustomColourPicker = ({
  name,
  label,
}: CreatePreconfiguredAppFormikCustomColourPickerOptions): React.FC<PreconfiguredAppFormikColourPickerProps> => {
  const PreConfiguredAppFormikColourPicker = (
    restOfProps: PreconfiguredAppFormikColourPickerProps
  ) => (
    <AppFormikCustomColourPicker label={label} {...restOfProps} name={name} />
  );

  return PreConfiguredAppFormikColourPicker;
};

export default createPreconfiguredAppFormikCustomColourPicker;
