import * as Yup from "yup";

export type ResetPasswordFormValues = {
  password: string;
  confirmPassword: string;
};

export const defaultResetPasswordFormValues: ResetPasswordFormValues = {
  password: "",
  confirmPassword: "",
};

export const resetPasswordFormValidationSchema: Yup.SchemaOf<ResetPasswordFormValues> =
  Yup.object().shape({
    password: Yup.string()
      .min(6, "Must be at least 6 characters.")
      .matches(
        /[^a-zA-Z\d\s]/,
        "Must contain at least one character that isn't a number or a letter"
      )
      .matches(/.*[0-9].*/, "Must contain at least one digit.")
      .matches(/.*[a-z].*/, "Must contain at least one lower case letter.")
      .matches(/.*[A-Z].*/, "Must contain at least one upper case letter.")
      .required("Required."),
    confirmPassword: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("password"), ""], "Passwords must match."),
  });
