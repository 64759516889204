import { makeStyles } from "@material-ui/core";

export const appComboBoxStyles = makeStyles((theme) => ({
    comboBoxContainer: {
        position: "relative",
        display: "flex",
        alignItems: "center",
    },
    comboBoxContainerDisabled: {
        pointerEvents: "none",
        cursor: "not-allowed",
        "& *": {
            cursor: "not-allowed",
        },
        "& $comboBoxInput": {
            borderBottom: "none",
            opacity: 0.6,
        },
    },
    comboBoxIcon: {
        position: "absolute",
        display: "flex",
        alignItems: "center",
        left: 0,
        height: "100%",
    },
    comboBoxWrapper: {
        width: "100%",
        height: "auto"
    },
    comboBoxInput: {
        paddingLeft: `${theme.spacing(4)}px !important`,
        border: "none",
        borderBottom: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 0,
        padding: "6px 30px 7px 0",
        height: 30,
        textOverflow: "ellipsis",
        "&:focus": {
            outline: "none",
            borderBottom: `1px solid ${theme.palette.primary.main}`,
        }
    },
    comboBoxOption: {
        fontWeight: theme.typography.fontWeightMedium,
    },
    comboBoxLoading: {
        position: "absolute",
        right: 0,
        display: "flex",
        alignItems: "center",
        height: "100%"
    },
    selectedOverlay: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center"
    },
    selectedRemoveBtn: {
        marginLeft: "auto",
        border: "none",
        background: "transparent",
        cursor: "pointer",
        width: 30,
        height: 30,
    },
    visuallyHidden: {
        clip: "rect(0 0 0 0)",
        clipPath: "inset(50%)",
        height: 1,
        overflow: "hidden",
        position: "absolute",
        whiteSpace: "nowrap",
        width: 1,
    },
    noOptionsFound: {
        position: "absolute",
        top: "100%",
        padding: theme.spacing(1),
        boxShadow: theme.shadows[1],
        backgroundColor: theme.palette.common.white,
        width: "100%",
        zIndex: 2001
    }
}));