import {
  Chip,
  ChipProps,
  makeStyles,
  Theme,
} from "@material-ui/core";

type AppChipTypes = "warning" | "success" | "dark";

enum ChipTypeBgColourMap {
  "warning" = "#edb845",
  "dark" = "#314755",
  "success" = "#28b853",
}

enum ChipTypeTextColourMap {
  "warning" = "#ffffff",
  "dark" = "#ffffff",
  "success" = "#ffffff",
}

interface AppChipProps extends ChipProps {
  chipType?: AppChipTypes;
}

interface AppChipStyleProps {
  chipType?: AppChipTypes;
}

const useStyles = makeStyles<Theme, AppChipStyleProps>((theme) => ({
  chip: {
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: ({ chipType }) =>
      chipType && ChipTypeBgColourMap[chipType],
    color: ({ chipType }) => chipType && ChipTypeTextColourMap[chipType],
    "& svg": {
      color: ({ chipType }) => chipType && ChipTypeTextColourMap[chipType],
    },
  },
}));

const AppChip: React.FC<AppChipProps> = ({ chipType, ...props }) => {
  const classes = useStyles({ chipType });

  return <Chip className={classes.chip} {...props} />;
};

export default AppChip;
