import AppFormikTagSelect, {
  AppFormikTagSelectProps,
} from "./AppFormikTagSelect";

export type CreatePreconfiguredAppFormTagSelectOptions = {
  name: string;
  label: string;
  pleaseSelectText: string;
};

export type CreatePreconfiguredAppFormTagSelectProps<T> = Omit<
  AppFormikTagSelectProps<T>,
  "name" | "label" | "pleaseSelectText"
>;

export const createPreconfiguredAppFormTagSelect = ({
  name,
  label,
  pleaseSelectText,
}: CreatePreconfiguredAppFormTagSelectOptions) => {
  const PreConfiguredAppFormTagSelect = <T extends { id: number }>(
    restOfProps: CreatePreconfiguredAppFormTagSelectProps<T>
  ) => (
    <AppFormikTagSelect
      {...restOfProps}
      label={label}
      name={name}
      pleaseSelectText={pleaseSelectText}
    />
  );

  return PreConfiguredAppFormTagSelect;
};

export default createPreconfiguredAppFormTagSelect;
