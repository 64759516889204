import { actions } from "../../store";
import { useDispatch } from "react-redux";
import { successToast } from "../../toast";
import AppModal from "../../components/AppModal";
import { ServiceFormValues } from "../Form/ServiceFormValues";
import { createService } from "../../api/services";
import ServiceFormContainer from "../Form/ServiceFormContainer";

interface AddServiceModalProps {
  open: boolean;
  handleClose: () => void;
}

const AddServiceModal: React.FC<AddServiceModalProps> = ({
  open,
  handleClose,
}) => {
  const dispatch = useDispatch();

  const handleAddService = async (
    serviceDetails: ServiceFormValues
  ): Promise<void> => {
    const response = await createService({
      name: serviceDetails.name,
      description: serviceDetails.description ?? "",
      price: serviceDetails.price,
      isBackOfficeOnly: serviceDetails.isBackOfficeOnly,
      duration: serviceDetails.durationMinutes,
      appointmentTypeGroupId: 0,
      noTravel: false,
      rootAppointmentTypeId: undefined,
      isAvailable: serviceDetails.isAvailable,
    });

    if (!response.isError) {
      dispatch(actions.resources.fetchAll());
      handleClose();
      successToast("Service has been added");
    }
  };

  return (
    <AppModal isOpen={open} onClose={handleClose} title="New Service">
      {
        <ServiceFormContainer
          onSubmit={(serviceDetails: ServiceFormValues) =>
            handleAddService(serviceDetails)
          }
        />
      }
    </AppModal>
  );
};

export default AddServiceModal;
