import { Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { checkIfEmailIsAvailable } from "../../api/accountManagement";
import ResourceForm from "./ResourceForm";
import {
  defaultResourceFormValues,
  emailFieldName,
  resourceFormPageValidationSchemas,
  ResourceFormValues,
} from "./ResourceFormValues";

interface ResourceFormContainerProps {
  initialValues?: ResourceFormValues;
  isEdit?: boolean;
  onSubmit: (resource: ResourceFormValues) => Promise<void>;
}

const ResourceFormContainer: React.FC<ResourceFormContainerProps> = ({
  onSubmit,
  isEdit,
  initialValues = defaultResourceFormValues,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const isLastStep =
    activeStep === resourceFormPageValidationSchemas.length - 1;

  const handlePreviousClick = () => {
    setActiveStep((previousActiveStep) => previousActiveStep - 1);
  };

  const isEmailAvailable = async (newEmail: string) => {
    const { email: currentEmail } = initialValues;

    if (newEmail === currentEmail) return true;

    const emailAvailabilityResponse = await checkIfEmailIsAvailable(newEmail);

    return !emailAvailabilityResponse.isError;
  };

  const handleSubmit = async (
    values: ResourceFormValues,
    {
      setFieldError,
      setSubmitting,
      setFieldTouched,
    }: FormikHelpers<ResourceFormValues>
  ) => {
    const emailIsAvailable = await isEmailAvailable(values.email);

    if (activeStep === 0 && !emailIsAvailable) {
      setFieldError(emailFieldName, "Email is already in use.");
      setSubmitting(false);
      return;
    }

    if (!isLastStep) {
      Object.keys(defaultResourceFormValues).forEach((key) =>
        setFieldTouched(key, false)
      );
      setActiveStep((previousActiveStep) => previousActiveStep + 1);
      return;
    }

    await onSubmit(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={resourceFormPageValidationSchemas[activeStep]}
      onSubmit={handleSubmit}
    >
      <ResourceForm
        isEdit={isEdit}
        activeStep={activeStep}
        isLastStep={isLastStep}
        onPreviousClick={handlePreviousClick}
      />
    </Formik>
  );
};

export default ResourceFormContainer;
