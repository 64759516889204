import { IconButton, TableCell, TableRow } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import ColourIndicator from "../../components/ColourIndicator";
import { ResourceSummary } from "../../models/Resource";
import { formatDateStringDateOnly } from "../../dateFormatters";

interface ResourcesTableRowProps {
  resource: ResourceSummary;
  handleEdit: () => void;
}

const ResourcesTableRow: React.FC<ResourcesTableRowProps> = ({
  resource,
  handleEdit,
}) => {
  return (
    <TableRow>
      <TableCell>{resource.name}</TableCell>
      <TableCell>{resource.email}</TableCell>
      <TableCell>{resource.basePostcode}</TableCell>
      <TableCell>
        {resource.maxTravelDistanceMiles
          ? `${resource.maxTravelDistanceMiles} miles`
          : "-"}
      </TableCell>
      <TableCell>
        {resource.bookableFrom
          ? formatDateStringDateOnly(resource.bookableFrom)
          : "-"}
      </TableCell>
      <TableCell>
        {resource.bookableUntil
          ? formatDateStringDateOnly(resource.bookableUntil)
          : "-"}
      </TableCell>
      <TableCell>
        <ColourIndicator colour={resource.colour} />
      </TableCell>
      <TableCell>
        <IconButton onClick={() => handleEdit()}>
          <Edit />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default ResourcesTableRow;
