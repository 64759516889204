import { createPreconfiguredAppFormikTextField } from "../../components/AppForm";
import createPreconfiguredAppFormikCustomColourPicker from "../../components/AppForm/createPreconfiguredAppFormikCustomColourPicker";
import createPreconfiguredAppFormTagSelect from "../../components/AppForm/createPreconfiguredAppFormikTagSelect";
import createPreconfiguredAppFormikWorkingHoursPicker from "../../components/AppForm/createPreconfiguredAppFormikWorkingHoursPicker";
import createPreconfiguredAppFormikSelect from "../../components/AppForm/createPreconfiguredAppFormSelect";
import createPreconfiguredAppFormServiceSelect from "../../components/AppForm/createPreconfiguredAppFormikServiceSelect";
import createPreconfiguredAppFormTagInput from "../../components/AppForm/createPreconfiguredAppFormTagInput";

export default {
  Name: createPreconfiguredAppFormikTextField({
    name: "name",
    label: "Mobile worker name",
  }),
  Email: createPreconfiguredAppFormikTextField({
    name: "email",
    label: "Email",
  }),
  PhoneNumber: createPreconfiguredAppFormikTextField({
    name: "phoneNumber",
    label: "Phone number",
  }),
  Colour: createPreconfiguredAppFormikCustomColourPicker({
    name: "colour",
    label: "Colour",
  }),
  Services: createPreconfiguredAppFormServiceSelect({
    name: "appointmentTypeCapabilities",
    label: "Services",
    pleaseSelectText: "-- Please select services --",
  }),
  Tags: createPreconfiguredAppFormTagSelect({
    name: "tags",
    label: "Tags",
    pleaseSelectText: "-- Please select tags --",
  }),
  BookingTags: createPreconfiguredAppFormTagSelect({
    name: "bookingTags",
    label: "Tags",
    pleaseSelectText: "-- Please select tags --",
  }),
  WorkingHours: createPreconfiguredAppFormikWorkingHoursPicker({
    name: "workingHours",
    label: "",
  }),
  BasePostcode: createPreconfiguredAppFormikTextField({
    name: "basePostcode",
    label: "Base postcode",
    autoComplete: "off",
    forceUpperCase: true,
  }),
  MaxTravelDistanceMiles: createPreconfiguredAppFormikTextField({
    name: "maxTravelDistanceMiles",
    label: "Miles travelled",
    type: "number",
    autoComplete: "off",
  }),
  CoveredOutcodes: createPreconfiguredAppFormTagInput({
    name: "coveredOutcodes",
    placeholder: "Enter an outcode",
    label: "Covered outcodes",
  }),
  typeId: createPreconfiguredAppFormikSelect({
    name: "typeId",
    label: "Mobile worker type",
    pleaseSelectText: "-- Select a mobile worker type --",
  }),
};
