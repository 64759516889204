import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  label: {
    display: "block",
    fontSize: ".75rem",
    color: theme.palette.grey[600],
    marginBottom: "0 !important",
  },
}));

interface AppFormLabelProps {
  label: string;
}

const AppFormLabel: React.FC<AppFormLabelProps> = ({ label }) => {
  const classes = useStyles();

  return <label className={classes.label}>{label}</label>;
};

export default AppFormLabel;
