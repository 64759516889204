import {
  createPreconfiguredAppFormikDatePicker,
  createPreconfiguredAppFormikTextField,
} from "../../components/AppForm";
import createPreconfiguredAppFormikSelect from "../../components/AppForm/createPreconfiguredAppFormSelect";

export const BookingFields = {
  firstname: createPreconfiguredAppFormikTextField({
    name: "firstname",
    label: "",
    multiline: false,
  }),
  lastname: createPreconfiguredAppFormikTextField({
    name: "lastname",
    label: "",
    multiline: false,
  }),
  email: createPreconfiguredAppFormikTextField({
    name: "email",
    label: "",
    multiline: false,
  }),
  phone: createPreconfiguredAppFormikTextField({
    name: "phone",
    label: "",
    multiline: false,
  }),
  companyName: createPreconfiguredAppFormikTextField({
    name: "companyName",
    label: "",
    multiline: false,
  }),
  addressLine1: createPreconfiguredAppFormikTextField({
    name: "addressLine1",
    label: "Address Line 1",
  }),
  addressLine2: createPreconfiguredAppFormikTextField({
    name: "addressLine2",
    label: "Address Line 2",
  }),
  addressLine3: createPreconfiguredAppFormikTextField({
    name: "addressLine3",
    label: "Address Line 3",
  }),
  town: createPreconfiguredAppFormikTextField({ name: "town", label: "Town" }),
  postcode: createPreconfiguredAppFormikTextField({
    name: "postcode",
    label: "Postcode",
    forceUpperCase: true,
  }),
  resourceId: createPreconfiguredAppFormikSelect({
    name: "resourceId",
    label: "",
    pleaseSelectText: "-- Select a mobile worker -- ",
  }),
  date: createPreconfiguredAppFormikDatePicker({ name: "date", label: "Date" }),
  notes: createPreconfiguredAppFormikTextField({
    name: "notes",
    label: "",
    multiline: true,
    rows: 4,
  }),
  addressNotes: createPreconfiguredAppFormikTextField({
    name: "addressNotes",
    label: "",
    multiline: true,
    rows: 4,
  }),
  description: createPreconfiguredAppFormikTextField({
    name: "description",
    label: "",
    multiline: true,
    rows: 4,
  }),
  customerId: createPreconfiguredAppFormikSelect({
    name: "customerId",
    label: "Customer",
    pleaseSelectText: "-- Select a customer -- ",
  }),
  customerAddressId: createPreconfiguredAppFormikSelect({
    name: "customerAddressId",
    label: "Customer address",
    pleaseSelectText: "-- Select a customer address --",
  }),
  tags: createPreconfiguredAppFormikSelect({
    name: "bookingTags",
    label: "Booking tags",
    pleaseSelectText: "-- Select a booking tag --",
  }),
};
