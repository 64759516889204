import { ApiOutcodeGeoJson } from "../api/models/Geocoding";
import { OutcodeGeoJson } from "../models/Geocoding";

export const mapFromApiOutcodeGeoJson = (
  outcodeGeoJson: ApiOutcodeGeoJson
): OutcodeGeoJson => {
  const geojson = JSON.parse(outcodeGeoJson.geoJson);

  if (geojson.type === "Polygon") {
    return {
      ...outcodeGeoJson,
      geoJson: [
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: JSON.parse(outcodeGeoJson.geoJson).coordinates,
          },
          properties: {},
        },
      ],
    };
  }

  return {
    ...outcodeGeoJson,
    geoJson: geojson.geometries.map((geometry: any) => ({
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: geometry.coordinates,
      },
      properties: {},
    })),
  };
};
