import { makeStyles } from "@material-ui/core";
import { LocalShipping as TravelIcon } from "@material-ui/icons";
import { useCalendar } from "../AppCalendarContext";

interface Props {
  duration: number;
  roundedDuration: number;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgba(240, 247, 252, 0.8)",
    color: theme.palette.text.primary,
    height: "100%",
  },
  containerLrg: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgba(240, 247, 252, 0.8)",
    color: theme.palette.text.primary,
    height: "100%",
    borderBottom: "15px solid #82BA0A73",
  },
  containerXSPivot: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "rgba(240, 247, 252, 0.8)",
    color: theme.palette.text.primary,
    height: "100%",
    borderBottom: "15px solid #82BA0A73",
  },
  label: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    "& svg": {
      marginRight: theme.spacing(1),
    },
  },
  labelLrg: {
    display: "flex",
    alignItems: "center",
    marginTop: "auto",
    borderBottom: `3px solid ${theme.palette.grey[300]}`,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    "& svg": {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(-0.5),
    },
  },
  labelXSPivot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    marginTop: "auto",
    borderBottom: `3px solid ${theme.palette.grey[300]}`,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    "& svg": {
      marginBottom: theme.spacing(-0.5),
    },
  },
}));

const TravelTimeEventCard: React.FC<Props> = ({
  duration,
  roundedDuration,
}) => {
  const classes = useStyles();
  const { isPivotView } = useCalendar();

  if(isPivotView) {
    if(roundedDuration < 30) {
      return (
        <div className={classes.containerXSPivot}>
          <div className={classes.labelXSPivot}>
            <TravelIcon />
          </div>
        </div>
      );
    }

    if(roundedDuration < 45) {
      return (
        <div className={classes.containerLrg}>
          <div className={classes.labelLrg}>
            <TravelIcon />
            <span>
              <b>{duration} mins</b>
            </span>
          </div>
        </div>
      );
    }

    return (
      <div className={classes.containerLrg}>
        <div className={classes.labelLrg}>
          <TravelIcon />
          <span>
            <b>{duration} mins</b> travel time
          </span>
        </div>
      </div>
    );
  }

  if (roundedDuration < 30) {
    return (
      <div className={classes.container}>
        <div className={classes.label}>
          <TravelIcon />
          <span>
            <b>{duration} mins</b> travel time
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.containerLrg}>
      <div className={classes.labelLrg}>
        <TravelIcon />
        <span>
          <b>{duration} mins</b> travel time
        </span>
      </div>
    </div>
  );
};

export default TravelTimeEventCard;
