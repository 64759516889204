import MissingConfigError from "./MissingConfigError";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const mapBoxPK = process.env.REACT_APP_MAP_BOX_PK;

if (!apiBaseUrl) throw new MissingConfigError("REACT_APP_API_BASE_URL");
if (!mapBoxPK) throw new MissingConfigError("REACT_APP_MAP_BOX_PK");

const config = {
  apiBaseUrl,
  mapBoxPK,
};

export default config;
