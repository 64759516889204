import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { HttpClientFailureResponse } from "../api";
import { createHttpClientThunk } from "./common/createHttpClientThunk";
import { getPaginatedBookings, getCalendarBookings } from "../api/bookings";
import { Pagination } from "../models/Pagination";
import { CalendarSearch } from "../models/Calendar";
import { BookingDetails, BookingSummary } from "../models/Booking";

export const sliceName = "bookings";

const fetchPaginatedAsyncThunk = createHttpClientThunk(
  `${sliceName}/fetchPaginated`,
  getPaginatedBookings
);

const fetchCalendarAsyncThunk = createHttpClientThunk(
  `${sliceName}/fetchCalendar`,
  getCalendarBookings
);

const entityAdapter = createEntityAdapter<BookingSummary>({
  selectId: (c) => c.id,
});

type SliceState = {
  entityState: EntityState<BookingSummary>;
  bookingById?: BookingDetails;
  pagination: Pagination;
  calendarSearch: CalendarSearch;
  selectedCalendarDateString: string | undefined;
  isFetching: boolean;
  isError: boolean;
  getByIdHttpError?: HttpClientFailureResponse;
  getAllHttpError?: HttpClientFailureResponse;
  searchTerm: string;
  sortBy: string;
};

const initialState: SliceState = {
  entityState: entityAdapter.getInitialState(),
  pagination: {
    pageNumber: 1,
    pageSize: 10,
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
    totalPages: 1,
  },
  calendarSearch: {
    resourceIds: [],
    start: new Date().toISOString(),
    end: new Date().toISOString(),
  },
  selectedCalendarDateString: undefined,
  isFetching: false,
  isError: false,
  searchTerm: "",
  sortBy: "",
};

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setSelectedCalendarDateString: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.selectedCalendarDateString = payload;
    },
    setSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.pagination.pageNumber = 1;
      state.searchTerm = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPaginatedAsyncThunk.pending, (state) => {
      state.isFetching = true;
      state.isError = false;
    });
    builder.addCase(fetchPaginatedAsyncThunk.rejected, (state) => {
      state.isFetching = false;
      state.isError = true;
    });
    builder.addCase(
      fetchPaginatedAsyncThunk.fulfilled,
      (state, { payload }) => {
        state.isFetching = false;
        entityAdapter.removeAll(state.entityState);
        entityAdapter.setAll(state.entityState, payload.content.items);
        state.pagination = {
          ...payload.content,
        };
      }
    );
    builder.addCase(fetchCalendarAsyncThunk.pending, (state) => {
      state.isFetching = true;
      state.isError = false;
    });
    builder.addCase(fetchCalendarAsyncThunk.rejected, (state) => {
      state.isFetching = false;
      state.isError = true;
    });
    builder.addCase(fetchCalendarAsyncThunk.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      entityAdapter.removeAll(state.entityState);
      entityAdapter.setAll(state.entityState, payload.content);
    });
    // builder.addCase(getByIdAsyncThunk.pending, state => {
    //     state.isGettingById = true;
    //     state.getByIdHttpError = undefined;
    // });
    // builder.addCase(getByIdAsyncThunk.rejected, (state, { payload }) => {
    //     state.isGettingById = false;
    //     state.getByIdHttpError = payload;
    // });
    // builder.addCase(getByIdAsyncThunk.fulfilled, (state, { payload }) => {
    //     state.isGettingById = false;
    //     state.customerById = payload;
    // });
  },
});

export const { name, reducer } = slice;

type RootReducerState = {
  [sliceName]: SliceState;
};

type BookingsSelector<T> = (state: RootReducerState) => T;

const selectSliceState = (state: RootReducerState) => state[sliceName];
const entitySelectors = entityAdapter.getSelectors();

const doPaginatedFetch = (
  dispatch: (action: unknown) => void,
  state: SliceState,
  pageNumber?: number,
  pageSize?: number,
  searchTerm?: string
) => {
  dispatch(
    fetchPaginatedAsyncThunk({
      pageIndex: pageNumber || state.pagination.pageNumber,
      pageSize: pageSize || state.pagination.pageSize,
      searchTerm: searchTerm || state.searchTerm,
    })
  );
};

const doCalendarFetch = (
  dispatch: (action: unknown) => void,
  state: SliceState,
  resourceIds?: number[],
  start?: string,
  end?: string
) => {
  dispatch(
    fetchCalendarAsyncThunk({
      resourceIds: resourceIds || state.calendarSearch.resourceIds,
      start: start || state.calendarSearch.start,
      end: end || state.calendarSearch.end,
    })
  );
};

export const actions = {
  fetchAll:
    () =>
      (
        dispatch: (action: unknown) => void,
        getState: () => RootReducerState
      ): void => {
        const state = selectSliceState(getState());
        doPaginatedFetch(dispatch, state);
      },
  setCalendarSearch:
    (resourceIds: number[], start: string, end: string) =>
      (
        dispatch: (action: unknown) => void,
        getState: () => RootReducerState
      ): void => {
        const state = selectSliceState(getState());
        doCalendarFetch(dispatch, state, resourceIds, start, end);
      },
  setPageNumber:
    (pageNumber: number) =>
      (
        dispatch: (action: unknown) => void,
        getState: () => RootReducerState
      ): void => {
        const state = selectSliceState(getState());
        doPaginatedFetch(dispatch, state, pageNumber);
      },
  setPageSize:
    (pageSize: number) =>
      (
        dispatch: (action: unknown) => void,
        getState: () => RootReducerState
      ): void => {
        const state = selectSliceState(getState());
        doPaginatedFetch(dispatch, state, undefined, pageSize);
      },
  setSelectedCalendarDateString:
    (dateString: string) =>
      (dispatch: (action: unknown) => void): void => {
        dispatch(slice.actions.setSelectedCalendarDateString(dateString));
      },
  setSearchTerm:
    (term: string) =>
      (dispatcher: (action: unknown) => void): void => {
        dispatcher(slice.actions.setSearchTerm(term));
      },
  doSearch:
    () =>
      (
        dispatcher: (action: unknown) => void,
        reducerState: () => RootReducerState
      ): void => {
        doPaginatedFetch(dispatcher, selectSliceState(reducerState()));
      },
};

const createSliceSelector = <T,>(selector: (state: SliceState) => T) => {
  return createSelector(selectSliceState, selector);
};

export const selectors = {
  apiState: createSliceSelector((state) => ({
    isFetching: state.isFetching,
    isError: state.isError,
  })),
  all: createSliceSelector((state) =>
    entitySelectors.selectAll(state.entityState)
  ),
  pagination: createSliceSelector((state) => state.pagination),
  bookingById: (id: number): BookingsSelector<BookingSummary | undefined> =>
    createSliceSelector((state) =>
      entitySelectors.selectById(state.entityState, id)
    ),
  selectedCalendarDateString: createSliceSelector(
    (state) => state.selectedCalendarDateString
  ),
  selectSearchTerm: createSelector(
    selectSliceState,
    (state) => state.searchTerm
  ),
};
