import { EventApi, EventInput } from "@fullcalendar/react";
import { ResourceInput } from "@fullcalendar/resource-common";
import {
  addDays,
  endOfDay,
  endOfWeek,
  setHours,
  startOfDay,
  startOfWeek,
} from "date-fns";
import { addMinutes } from "date-fns/esm";
import { useEffect, useState } from "react";
import {
  getCalendarEntries,
  getCalendarEntriesResource,
} from "../../api/bookings";
import { getAllResources, getResourceMe } from "../../api/resources";
import { useAuth } from "../../auth";
import {
  formatTimeStringToMinutesAfterMidnight,
  formatTimeOnly,
  toUTCISOString,
} from "../../dateFormatters";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import { CalendarEntries } from "../../models/Calendar";
import { TimeOff } from "../../models/Resource";
import { useWindowSize } from "../MainLayout/WindowSize";
import { AppCalendarContext, CALENDAR_DATE_SELECTION, CALENDAR_ORIENTATION, CALENDAR_VIEWS } from "./AppCalendarContext";
import {
  SelectedSlotTime,
  MinMaXCalendarTimes,
  SelectedSlot,
  StoredSelectedResource,
} from "./types";
import {
  mapBookingToEventObject,
  mapNonWorkingHoursToEventObjects,
  mapTimeOffToEventObject,
  mapTravelTimeToEventObject,
} from "./utils/eventUtils";
import {
  cleanStoredSelectedResources,
  getNewestSelectedResource,
  getOldestSelectedResource,
  mapResourceToCalendarResource,
  minMaxCalendarTimes,
  sortResourcesByTimeSelectedThenNameThenIdAsc,
} from "./utils/resourceUtils";

const PIVOT_VIEW_RESOURCE_LIMIT = 100;
const DAY_VIEW_RESOURCE_LIMIT = 12;
const WEEK_VIEW_RESOURCE_LIMIT = 1;

export const weekViews = [
  CALENDAR_VIEWS.WEEK_VIEW,
  CALENDAR_VIEWS.WEEK_PIVOT_VIEW
];

export const dayViews = [
  CALENDAR_VIEWS.DAY_VIEW,
  CALENDAR_VIEWS.DAY_PIVOT_VIEW
];

interface AppCalendarProviderProps {
  canCreateEvent?: boolean;
  canEditEvent?: boolean;
  canAddTimeOff?: boolean;
  initialView?: CALENDAR_VIEWS;
  allowedViews?: CALENDAR_VIEWS[];
  initialResourceId?: number;
}

const AppCalendarProvider: React.FC<AppCalendarProviderProps> = ({
  children,
  canCreateEvent = false,
  canEditEvent = false,
  canAddTimeOff = false,
  initialView,
  allowedViews,
  initialResourceId,
}) => {
  const { width } = useWindowSize();
  const { role } = useAuth();

  const [selectedDate, setSelectedDate] = useSessionStorage(
    "selected-calendar-date",
    startOfDay(new Date())
  );
  const [selectedView, setSelectedView] = useState(
    initialView || CALENDAR_VIEWS.WEEK_VIEW
  );
  const [selectedResources, setSelectedResources] = useState<string[]>([]);
  const [selectedDatePickerDate, setSelectedDatePickerDate] = useState(
    new Date()
  );
  const [selectedTimeOff, setSelectedTimeOff] = useState<TimeOff>();
  const [selectedSlot, setSelectedSlot] = useState<SelectedSlotTime>();
  const [selectedBookingId, setSelectedBookingId] = useState<string>();
  const [allResources, setAllResources] = useState<ResourceInput[]>([]);
  const [calendarEvents, setCalendarEvents] = useState<EventInput[]>([]);
  const [createBookingModal, setCreateBookingModal] = useState(false);
  const [timeOffModal, setTimeOffModal] = useState(false);
  const [minMaxCalendarTime, setMinMaxCalendarTime] =
    useState<MinMaXCalendarTimes>();
  const [calendarEntries, setCalendarEntries] = useState<CalendarEntries>();
  const [resourceLimit, setResourceLimit] = useState<number>(
    width < 768 ? WEEK_VIEW_RESOURCE_LIMIT : DAY_VIEW_RESOURCE_LIMIT
  );
  const [isLoading, setIsLoading] = useState(false);
  const [resourceId] = useState<number | undefined>(initialResourceId);

  const [storedSelectedResources, setStoredSelectedResources] = useLocalStorage<
    StoredSelectedResource[]
  >("selected-resources", []);

  const isResource = role === "resource";
  const isPivotView = (selectedView === CALENDAR_VIEWS.DAY_PIVOT_VIEW) || (selectedView === CALENDAR_VIEWS.WEEK_PIVOT_VIEW);

  const mapResourceIdToStoredSelectedResource = (
    resourceId: string
  ): StoredSelectedResource => {
    const resource = allResources.find((r) => r.id === resourceId);
    return {
      id: resourceId,
      timeSelected: resource?.timeSelected || Date.now(),
    };
  };

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
  };

  const handleViewChange = (view: CALENDAR_VIEWS) => {
    setSelectedView(view);
  };

  const handleSelectSlot = (slot?: SelectedSlot) => {
    if (slot) {
      const { start, end, resourceId } = slot;

      setSelectedSlot({
        date: new Date(start),
        start: formatTimeStringToMinutesAfterMidnight(
          formatTimeOnly(new Date(start), false)
        ),
        end: formatTimeStringToMinutesAfterMidnight(
          formatTimeOnly(new Date(end), false)
        ),
        resourceId: resourceId,
      });
    } else {
      setSelectedSlot(undefined);
    }
  };

  const handleSelectEvent = (event: EventApi) => {
    if (event.extendedProps.type === "booking") {
      setSelectedBookingId(event.id);
    } else if (event.extendedProps.type === "time-off") {
      setSelectedTimeOff({
        id: +event.id,
        start: event.start!,
        end:
          event.end?.getMinutes() === 59
            ? addMinutes(event.end, 1)
            : event.end!,
        resourceName: event.extendedProps.resourceName,
        resourceId: event.extendedProps.resourceId,
      });
    }
  };

  const updateResourcesTimeSelected = () => {
    const resources = [...allResources];
    for (const resource of resources) {
      const isSelected = selectedResources.some((id) => id === resource.id);
      const isStored = storedSelectedResources.map(r => r.id).includes(resource.id!);

      if (isSelected && !resource.timeSelected) {
        resource.timeSelected = Date.now();
      }

      if(!isSelected && !isStored && resource.timeSelected !== null) {
        resource.timeSelected = null;
      }
    }

    setAllResources(resources);
  };

  const updateStoredSelectedResources = () => {
    if (allResources.length === 0) {
      return;
    }

    if (selectedResources.length === 0) {
      if (resourceLimit !== 1 || storedSelectedResources.length === 1) {
        setStoredSelectedResources([]);
      }

      return;
    }

    if (selectedView !== CALENDAR_VIEWS.WEEK_VIEW) {
      const selectedResourcesNotStored = selectedResources.filter(r => !storedSelectedResources.find(sr => sr.id === r));

      const updatedStoredResourceList = [...storedSelectedResources];
      selectedResourcesNotStored.map(r => {
        updatedStoredResourceList.push(mapResourceIdToStoredSelectedResource(r));
      });

      setStoredSelectedResources(updatedStoredResourceList);
      return;
    }

    if (selectedView === CALENDAR_VIEWS.WEEK_VIEW) {
      const existingStoredSelectedResource = storedSelectedResources.find(
        (r) => r.id === selectedResources[0]
      );

      if (existingStoredSelectedResource) {
        return;
      }

      const newStoredSelectedResource = mapResourceIdToStoredSelectedResource(
        selectedResources[0]
      );

      const updatedStoredSelectedResources = [
        ...storedSelectedResources,
        newStoredSelectedResource,
      ];

      setStoredSelectedResources(updatedStoredSelectedResources);
    }
  };

  const initResourcesTimeSelected = (resources: ResourceInput[]) => {
    for (const resource of resources) {
      const storedSelectedResource = storedSelectedResources.find(
        (r) => r.id === resource.id
      );
      if (storedSelectedResource) {
        resource.timeSelected = storedSelectedResource.timeSelected;
      }
    }
  };

  const removeStoredResource = (resourceId: string) => {
    const updatedStoredResources = [...storedSelectedResources].filter(r => r.id !== resourceId);
    setStoredSelectedResources(updatedStoredResources);
  };

  const updateStoredResourceTimeSelected = (resourceId: string) => {
    const updatedStoredResources = [...storedSelectedResources];
    const updatedLocalResources = [...allResources];

    updatedStoredResources.map(r => {
      if(r.id === resourceId) {
        r.timeSelected = Date.now();
      }

      return r;
    });

    updatedLocalResources.map(r => {
      if(r.id === resourceId) {
        r.timeSelected = Date.now();
      }

      return r;
    });

    setStoredSelectedResources(updatedStoredResources);
    setAllResources(updatedLocalResources);
  };

  /** API REQUESTS */
  /*****************/

  const fetchResources = async () => {
    const result = await getAllResources();

    if (!result.isError) {
      const resources = result.content.content.map(
        mapResourceToCalendarResource
      );

      setAllResources(resources);

      if (storedSelectedResources?.length > 0) {
        initResourcesTimeSelected(resources);

        const resourcesToSelect = cleanStoredSelectedResources(
          storedSelectedResources,
          resources
        );

        setStoredSelectedResources(resourcesToSelect);
        setSelectedResources(resourcesToSelect.map((r) => r.id));
      } else {
        setSelectedResources(
          resources.slice(0, 4).map((resource) => resource.id ?? "")
        );
      }
    }
  };

  const fetchResource = async () => {
    const result = await getResourceMe();

    if (!result.isError) {
      const resource = mapResourceToCalendarResource(result.content);
      setAllResources([resource]);
      setSelectedResources([resource.id ?? ""]);
    }
  };

  const fetchCalendarEntries = async () => {
    setIsLoading(true);

    const isWeekView = weekViews.includes(selectedView);
    const dateSelection = {
      start: isWeekView
        ? toUTCISOString(addDays(startOfWeek(selectedDate), 1))
        : toUTCISOString(startOfDay(selectedDate)),
      end: isWeekView
        ? toUTCISOString(addDays(endOfWeek(selectedDate), 1))
        : toUTCISOString(endOfDay(selectedDate)),
    };

    const result = isResource
      ? await getCalendarEntriesResource(dateSelection.start, dateSelection.end)
      : await getCalendarEntries(
          resourceId
            ? [resourceId]
            : selectedResources.map((id) => parseInt(id)),
          dateSelection.start,
          dateSelection.end
        );

    if (!result.isError) {
      const bookings = result.content.bookings.map(mapBookingToEventObject);
      const timeOff = result.content.timeOffs.map(mapTimeOffToEventObject);
      const travelTime = result.content.travelTimes.map(
        mapTravelTimeToEventObject
      );
      const nonWorking = result.content.workingsHours
        .map((hours) =>
          mapNonWorkingHoursToEventObjects(
            hours,
            startOfWeek(selectedDate, { weekStartsOn: 1 }),
            selectedView,
            allResources
          )
        )
        .flat();

      setCalendarEvents([
        ...bookings,
        ...timeOff,
        ...nonWorking,
        ...travelTime,
      ]);
      setCalendarEntries(result.content);
    }

    setIsLoading(false);
  };

  /** END API REQUESTS */
  /*********************/

  useEffect(() => {
    if (!isResource) {
      fetchResources();
    } else {
      fetchResource();
    }
  }, []);

  useEffect(() => {
    if (selectedResources.length > 0) {
      fetchCalendarEntries();
    }

    if (!isResource) {
      updateStoredSelectedResources();
      updateResourcesTimeSelected();
    }
  }, [selectedResources, selectedDate]);

  useEffect(() => {
    if (calendarEntries) {
      setMinMaxCalendarTime(minMaxCalendarTimes(calendarEntries.workingsHours));
    }
  }, [calendarEntries, selectedView]);

  useEffect(() => {
    if (selectedView === CALENDAR_VIEWS.WEEK_VIEW) {
      setResourceLimit(1);
    } else if (selectedView === CALENDAR_VIEWS.DAY_VIEW) {

      if(width < 768 && resourceLimit !== WEEK_VIEW_RESOURCE_LIMIT) {
        setResourceLimit(WEEK_VIEW_RESOURCE_LIMIT);
        setSelectedResources(storedSelectedResources.sort((a, b) => b.timeSelected - a.timeSelected).map((r) => r.id));
      }
      
      if(width >= 768 && resourceLimit !== DAY_VIEW_RESOURCE_LIMIT) {
        setResourceLimit(DAY_VIEW_RESOURCE_LIMIT);
        setSelectedResources(storedSelectedResources.sort((a, b) => b.timeSelected - a.timeSelected).map((r) => r.id));
      }

    } else if (isPivotView) {
      setResourceLimit(PIVOT_VIEW_RESOURCE_LIMIT);
    }

    if(isPivotView && width < 768) {
      setSelectedView(selectedView === CALENDAR_VIEWS.DAY_PIVOT_VIEW ? CALENDAR_VIEWS.DAY_VIEW : CALENDAR_VIEWS.WEEK_VIEW);
    }

  }, [selectedView, width]);

  useEffect(() => {
    if (isResource) {
      if (allResources?.length > 0) {
        setSelectedResources([allResources[0].id!]);
      }
      return;
    }

    if(!isPivotView) {
      if(selectedResources.length > resourceLimit) {
        setSelectedResources(storedSelectedResources.sort((a, b) => b.timeSelected - a.timeSelected).slice(0, resourceLimit).map(r => r.id));
        return;
      }
    }

    if(isPivotView) {
      if((selectedResources.length < resourceLimit) && (selectedResources.length < storedSelectedResources.length)) {
        setSelectedResources(storedSelectedResources.map(r => r.id));
        return;
      }
    }

    if (resourceLimit !== 1 || selectedResources.length <= 1) {
      return;
    }

    const newestSelectedResource = getNewestSelectedResource(allResources);
    setSelectedResources(
      newestSelectedResource?.id
        ? [newestSelectedResource.id]
        : [selectedResources[0]]
    );
  }, [resourceLimit]);

  return (
    <AppCalendarContext.Provider
      value={{
        selectedDate,
        selectedView,
        selectedResources,
        selectedBookingId,
        selectedTimeOff,
        selectedSlot,
        availableViews: allowedViews || [],
        allResources,
        calendarEvents,
        canCreateEvent,
        canEditEvent,
        canAddTimeOff,
        timeOffModal,
        createBookingModal,
        selectedDatePickerDate,
        minMaxCalendarTime,
        resourceLimit,
        grandTotal: calendarEntries && selectedResources.length > 0
          ? calendarEntries.bookings
              .map((b) => b.totalPrice)
              .reduce((a, b) => a + b, 0)
          : 0,
        isLoading,
        isResource,
        isPivotView,
        initialView: initialView || CALENDAR_VIEWS.WEEK_VIEW,
        fetchCalendarEntries,
        setSelectedTimeOff,
        setSelectedDatePickerDate,
        setSelectedBookingId,
        setSelectedView,
        setSelectedResources,
        handleDateChange,
        handleViewChange,
        handleSelectSlot,
        handleSelectEvent,
        handleResourceChange: (resources) => setSelectedResources(resources),
        setTimeOffModal,
        setCreateBookingModal,
        removeStoredResource,
        updateStoredResourceTimeSelected
      }}
    >
      {children}
    </AppCalendarContext.Provider>
  );
};

export default AppCalendarProvider;
