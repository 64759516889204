import React, { useEffect, useState } from "react";
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  makeStyles,
} from "@material-ui/core";
import AppFormikDatePicker from "../../components/AppFormikDatePicker/AppFormikDatePicker";
import { useField } from "formik";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";

const useStyles = makeStyles((theme) => ({
  datePickerBox: {
    marginLeft: "2rem",
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
}));

export interface AppFormikOptionalDatePickerProps
  extends Omit<CheckboxProps, "onChange"> {
  label: string;
  readonly?: boolean;
  formikName: string;
  onChangeCallback?: (newValue: ParsableDate) => void;
}

const AppFormikOptionalDatePicker: React.FC<AppFormikOptionalDatePickerProps> =
  ({
    label,
    formikName,
    disabled,
    readonly,
    color = "primary",
    onChangeCallback,
    ...restOfProps
  }) => {
    const classes = useStyles();
    const [{ value }, , { setValue }] = useField(formikName);
    const [checked, setChecked] = useState(true);

    useEffect(() => {
      if (!value) setChecked(false);
      if (disabled) {
        setChecked(false);
        setValue(null);
        onChangeCallback && onChangeCallback(null);
      }
    }, [disabled]);

    const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (checked) {
        setValue(null);
        onChangeCallback && onChangeCallback(null);
      }
      setChecked(event.target.checked);
    };

    return (
      <div>
        <FormControlLabel
          disabled={disabled || readonly}
          control={
            <Checkbox
              {...restOfProps}
              disabled={disabled || readonly}
              checked={checked}
              onChange={handleCheckChange}
              color={color}
            />
          }
          label={label}
        />
        <div className={classes.datePickerBox}>
          <AppFormikDatePicker
            name={formikName}
            onChangeCallback={onChangeCallback}
            disabled={disabled || !checked}
          />
        </div>
      </div>
    );
  };

export default AppFormikOptionalDatePicker;
