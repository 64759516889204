import { makeStyles } from "@material-ui/core";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import PageHeader from "../PageHeader";

const useStyles = makeStyles((theme) => ({
  container: {
    positon: "relative",
    height: "calc(100vh - 100px)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    position: "relative",
    flex: "none",
  },
  content: {
    display: "flex",
    flex: "1",
    justifyContent: "center",
    alignItems: "center",
  },
  inner: {
    textAlign: "center",
  },
  icon: {
    width: "100%",
    height: "100%",
    maxWidth: "340px",
    maxHeight: "340px",
    fill: theme.palette.grey[300],
  },
}));

const AppCalendarEmptyState: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.inner}>
          <EventBusyIcon className={classes.icon} />
          <h2>No mobile workers selected</h2>
          <p>Please use the filter above to select a mobile worker(s)</p>
        </div>
      </div>
    </div>
  );
};

export default AppCalendarEmptyState;
