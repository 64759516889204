import * as Yup from "yup";
import { ukPostcodeRegex } from "../../constants";
import { BookedExtra, Jobs } from "../../models/Booking";
import WidgetBookingFormContainer from "./WidgetBookingFormContainer";

export interface WidgetBookingFormPage2Values {
  firstJobAppointmentTypeId: number;
  firstJobOptionalExtras: BookedExtra[];
  requiresOptionalExtra: boolean;
}

export interface WidgetBookingFormPage1Values {
  date: Date | null;
  start: number;
  end: number;
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  town: string;
  postcode: string;
  resourceId: number;
  totalPrice?: number;
}

export interface WidgetBookingFormPage3Values {
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  phoneNumber: string;
  termsAccepted: boolean;
  marketingAccepted: boolean;
  isInviteBooking: boolean;
  customerId: number;
  customerAddressId: number;
}

export interface WidgetBookingFormPage4Values {
  date: Date;
}

export type WidgetBookingFormValues = WidgetBookingFormPage1Values &
  WidgetBookingFormPage2Values &
  WidgetBookingFormPage3Values;

export const postcodeFieldName = "postcode";

export const emailFieldName = "email";

export const defaultWidgetBookingFormValues: WidgetBookingFormValues = {
  firstJobAppointmentTypeId: -1,
  date: null,
  start: 0,
  end: 1020,
  addressLine1: "",
  addressLine2: "",
  addressLine3: "",
  town: "",
  [postcodeFieldName]: "",
  resourceId: -1,
  firstName: "",
  lastName: "",
  company: "",
  [emailFieldName]: "",
  firstJobOptionalExtras: [] as BookedExtra[],

  phoneNumber: "",
  termsAccepted: false,
  marketingAccepted: false,
  isInviteBooking: false,
  requiresOptionalExtra: false,
  customerAddressId: -1,
  customerId: -1
};

const widgetBookingFormPage2ValidationSchema: Yup.SchemaOf<WidgetBookingFormPage2Values> =
  Yup.object().shape({
    firstJobAppointmentTypeId: Yup.number()
      .min(0, "Please select a service type.")
      .required(),
    firstJobOptionalExtras: Yup.array()
      .of(
        Yup.object()
          .shape({ optionalExtraId: Yup.number(), quantity: Yup.number() })
          .required()
      )
      .required()
      .when("requiresOptionalExtra", {
        is: (val: boolean) => val,
        then: Yup.array()
          .min(1, "Please select at least one optional extra")
          .required(),
        otherwise: Yup.array(),
      }),
    requiresOptionalExtra: Yup.boolean().required(),
  });

const widgetBookingFormPage1ValidationSchema: unknown = Yup.object().shape({
  addressLine1: Yup.string().required("Please provide an address."),
  addressLine2: Yup.string().notRequired(),
  addressLine3: Yup.string().notRequired(),
  town: Yup.string().required("Please provide a town or city."),
  postcode: Yup.string()
    .required("Please enter your postcode")
    .matches(ukPostcodeRegex, "Must be valid postcode including the space."),
});

const widgetBookingFormPage3ValidationSchema: unknown = Yup.object().shape({
  date: Yup.date().required("Please select a date."),
  start: Yup.number()
    .lessThan(
      Yup.ref("end"),
      "Booking start time must be before booking end time."
    )
    .required("Please provide a start time."),
  end: Yup.number()
    .moreThan(
      Yup.ref("start"),
      "Booking end time must be after booking start time."
    )
    .required("Please provide a end time."),
  resourceId: Yup.number()
    .moreThan(0, "Please select a mobile worker.")
    .required("Please select a mobile worker."),
});

const widgetBookingFormPage4ValidationSchema = Yup.object().shape({
  firstName: Yup.string()
  .when("isInviteBooking", {
    is: (val:boolean) => !val,
    then: Yup.string().required("Please provide a name.")
  }),
  
  lastName: Yup.string()
  .when("isInviteBooking", {
    is: (val:boolean) => !val,
    then: Yup.string().required("Please provide a name.")
  }),
  email: Yup.string()
  .when("isInviteBooking", {
    is: (val:boolean) => !val,
    then: Yup.string()
    .email("Please enter a valid email address.")
    .required("Please provide an email address.")
  }),
  phoneNumber: Yup.string()
  .when("isInviteBooking", {
    is: (val:boolean) => !val,
    then: Yup.string()
    .required("Please provide a phone number.")
    .min(7, "Phone number must be between 7 and 15 characters")
    .max(15, "Phone number must be between 7 and 15 characters"),
  }),  
  termsAccepted: Yup.boolean()
    .oneOf(
      [true],
      "Terms and conditions must be accepted before making a booking."
    )
    .required(),
  marketingAccepted: Yup.boolean().required(
    "Please select a marketing preference."
  ),
});

const widgetBookingFormPage5ValidationSchema = Yup.object().shape({});

const widgetBookingFormPage6ValidationSchema = Yup.object().shape({});

export const widgetBookingFormPageValidationSchemas = [
  widgetBookingFormPage1ValidationSchema,
  widgetBookingFormPage2ValidationSchema,
  widgetBookingFormPage3ValidationSchema,
  widgetBookingFormPage4ValidationSchema,
  widgetBookingFormPage5ValidationSchema,
  widgetBookingFormPage6ValidationSchema,
];
