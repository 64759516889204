import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

interface AppConfirmationDialogProps {
  open: boolean;
  handleConfirmation: () => void;
  handleCancel: () => void;
  title?: string;
  caption?: string;
  confirmLabel?: string;
  cancelLabel?: string;
}

const AppConfirmationDialog: React.FC<AppConfirmationDialogProps> = ({
  open,
  handleConfirmation,
  handleCancel,
  title,
  caption,
  confirmLabel = "Cancel booking",
  cancelLabel = "Back",
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      {caption && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {caption}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {cancelLabel}
        </Button>
        <Button onClick={handleConfirmation} color="primary" autoFocus>
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppConfirmationDialog;
