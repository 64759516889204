import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import WidgetInformationTextBox from "../../components/InformationTextBox/WidgetInformationTextBox";
import { WidgetBookingFields } from "./WidgetBookingFormFields";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up(600)]: {
      paddingRight: "2rem",
    },
  },
}));

const WidgetPersonalDetails: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <WidgetInformationTextBox>
        We&apos;ll send your confirmation email to this address, so please enter
        your email address carefully.
      </WidgetInformationTextBox>
      <Grid container>
        <Grid
          className={classes.gridItem}
          item
          xs={screen.width < 599 ? 12 : 6}
        >
          <WidgetBookingFields.firstName />
        </Grid>
        <Grid
          className={classes.gridItem}
          item
          xs={screen.width < 599 ? 12 : 6}
        >
          <WidgetBookingFields.lastName />
        </Grid>
        <Grid
          className={classes.gridItem}
          item
          xs={screen.width < 599 ? 12 : 6}
        >
          <WidgetBookingFields.company />
        </Grid>
        <Grid
          className={classes.gridItem}
          item
          xs={screen.width < 599 ? 12 : 6}
        >
          <WidgetBookingFields.email />
        </Grid>
        <Grid
          className={classes.gridItem}
          item
          xs={screen.width < 599 ? 12 : 6}
        >
          <WidgetBookingFields.phoneNumber />
        </Grid>
      </Grid>
    </>
  );
};

export default WidgetPersonalDetails;
