import React from "react";
import { CheckboxProps, InputLabel, makeStyles } from "@material-ui/core";
import AppFormikOptionalDatePicker from "./AppFormikOptionalDatePicker";
import { useField } from "formik";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";

export interface AppFormikBookableDatesPickerProps
  extends Omit<CheckboxProps, "onChange"> {
  readonly?: boolean;
  label?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column"
  }
}));

const AppFormikBookableDatesPicker: React.FC<AppFormikBookableDatesPickerProps> =
  ({
    label
  }) => {
    const classes = useStyles();
    const [{ value: fromValue }, , { setValue: setFromValue }] = useField<
      string | undefined
    >("bookableFrom");
    const [{ value: untilValue }, , { setValue: setUntilValue }] = useField<
      string | undefined
    >("bookableUntil");

    const handleBookableFromChange = (fromDate: ParsableDate) => {
      if (!fromDate || !untilValue) return;
      const parsedFromDate = Date.parse(fromDate as string);
      const parsedUntilDate = Date.parse(untilValue as string);
      if (parsedUntilDate < parsedFromDate) setUntilValue(fromDate as string);
    };

    const handleBookableUntilChange = (untilDate: ParsableDate) => {
      if (!untilDate || !fromValue) return;
      const parsedUntilDate = Date.parse(untilDate as string);
      const parsedFromDate = Date.parse(fromValue as string);
      if (parsedFromDate > parsedUntilDate) setFromValue(untilDate as string);
    };

    return (
      <div className={classes.container}>
        {label && <InputLabel>{label}</InputLabel>}
        <AppFormikOptionalDatePicker
          label="Bookable from:"
          formikName="bookableFrom"
          value={fromValue}
          onChangeCallback={handleBookableFromChange}
        />
        <AppFormikOptionalDatePicker
          label="Bookable until:"
          formikName="bookableUntil"
          value={untilValue}
          disabled={!fromValue}
          onChangeCallback={handleBookableUntilChange}
        />
      </div>
    );
  };

export default AppFormikBookableDatesPicker;
