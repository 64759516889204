import { makeStyles } from "@material-ui/core";
import { WidgetBookingFields } from "./WidgetBookingFormFields";

interface Props {
  minDate: string;
}

const WidgetDateSelect: React.FC<Props> = ({ minDate }) => {
  const useStyles = makeStyles((theme) => ({
    calendar: {
      marginBottom: theme.spacing(2),
      minWidth: "100%",
      [theme.breakpoints.up("sm")]: {
        paddingRight: theme.spacing(4),
      },
      [theme.breakpoints.down(350)]: {
        "& [class^=makeStyles-calendarContainer-], & [class*=makeStyles-calendarContainer-]":
          {
            transform: "scale(0.9)",
            display: "flex",
            justifyContent: "center",
          },
      },
    },
    heading: {
      marginTop: 0,
      marginBottom: theme.spacing(1),
    },
    text: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.calendar}>
      <h3 className={classes.heading}>Choose your preferred day</h3>
      <div className={classes.text}>
        Once we know what day you&apos;d prefer and where you live, we can
        calculate what times are available.{" "}
      </div>
      <WidgetBookingFields.date minDate={minDate} />
    </div>
  );
};

export default WidgetDateSelect;
