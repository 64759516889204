import { useRef } from "react";
import {
  ExpandLess as UpArrowIcon,
  ExpandMore as DownArrowIcon,
  AccessTime as ClockIcon,
} from "@material-ui/icons";
import { useStyles } from "./AppTimeInputStyles";
import AppFormLabel from "../AppFormLabel";
import { CHANGE_TYPE, DIRECTION, disableMinuteArrow } from ".";
import { useAppTimeInput } from "./useAppTimeInput";
import { maximumMinutesAfterMidnightValue } from "../../../dateFormatters";

interface AppTimeInputProps {
  value: number;
  showArrows?: boolean;
  label?: string;
  readOnly?: boolean;
  isEdit?: boolean;
  maxValue?: number;
  minValue?: number;
  minuteIncrement?: number;
  onChange(val: number): void;
}

const defaultMinuteIncremenet = 5;
const defaultMaxMinutesAfterMidnightMinusDefaultIncrement =
  maximumMinutesAfterMidnightValue - defaultMinuteIncremenet;

const AppTimeInput: React.FC<AppTimeInputProps> = ({
  value,
  showArrows,
  label,
  readOnly,
  isEdit,
  maxValue = defaultMaxMinutesAfterMidnightMinusDefaultIncrement,
  minValue = 0,
  minuteIncrement = defaultMinuteIncremenet,
  onChange,
}) => {
  const classes = useStyles();

  const hourInput = useRef(null);
  const minuteInput = useRef(null);

  const {
    hour,
    hourString,
    minute,
    minuteString,
    hourEdit,
    minuteEdit,
    minHour,
    maxHour,
    minMinute,
    maxMinute,
    hourValid,
    minuteValid,
    handleHourChange,
    handleMinuteChange,
    handleHourInputBlur,
    handleMinuteInputBlur,
    handleHourInputKeyPress,
    handleMinuteInputKeyPress,
    handleArrowClick,
  } = useAppTimeInput(
    value,
    minValue,
    maxValue,
    minuteIncrement,
    hourInput,
    minuteInput,
    !!isEdit,
    onChange
  );

  const hourInputIsFocused = document.activeElement === hourInput.current;
  const minuteInputIsFocused = document.activeElement === minuteInput.current;

  return (
    <div className={classes.wrapper}>
      {label && <AppFormLabel label={label} />}
      {!readOnly && showArrows && (
        <div className={classes.arrowRow}>
          <button
            className={classes.arrowButton}
            type="button"
            disabled={hour === maxHour}
            onClick={() => handleArrowClick(CHANGE_TYPE.HOUR, DIRECTION.UP)}
          >
            <UpArrowIcon />
          </button>
          <span className={classes.divider}></span>
          <button
            className={classes.arrowButton}
            type="button"
            disabled={disableMinuteArrow(
              minute + minuteIncrement,
              hour,
              minMinute,
              maxMinute,
              minHour,
              maxHour
            )}
            onClick={() => handleArrowClick(CHANGE_TYPE.MINUTE, DIRECTION.UP)}
          >
            <UpArrowIcon />
          </button>
        </div>
      )}
      <div className={classes.container}>
        <div className={classes.inputRow}>
          <input
            className={`${classes.inputItem} ${
              !hourValid && classes.inputError
            }`}
            type="number"
            min={0}
            max={23}
            value={hourInputIsFocused ? hourEdit : hourString}
            onChange={(e) => handleHourChange(e.currentTarget.value)}
            onBlur={handleHourInputBlur}
            onKeyDown={handleHourInputKeyPress}
            ref={hourInput}
            disabled={readOnly}
          />
          <span className={classes.divider}>:</span>
          <input
            className={`${classes.inputItem} ${
              !minuteValid && classes.inputError
            }`}
            type="number"
            min={0}
            max={59}
            value={minuteInputIsFocused ? minuteEdit : minuteString}
            onChange={(e) => handleMinuteChange(e.currentTarget.value)}
            onBlur={handleMinuteInputBlur}
            onKeyDown={handleMinuteInputKeyPress}
            ref={minuteInput}
            disabled={readOnly}
          />
        </div>
        <div className={classes.timePeriodPicker}>
          <ClockIcon />
        </div>
      </div>
      {!readOnly && showArrows && (
        <div className={classes.arrowRow}>
          <button
            className={classes.arrowButton}
            type="button"
            disabled={hour === minHour}
            onClick={() => handleArrowClick(CHANGE_TYPE.HOUR, DIRECTION.DOWN)}
          >
            <DownArrowIcon />
          </button>
          <span className={classes.divider}></span>
          <button
            className={classes.arrowButton}
            type="button"
            disabled={disableMinuteArrow(
              minute - minuteIncrement,
              hour,
              minMinute,
              maxMinute,
              minHour,
              maxHour
            )}
            onClick={() => handleArrowClick(CHANGE_TYPE.MINUTE, DIRECTION.DOWN)}
          >
            <DownArrowIcon />
          </button>
        </div>
      )}
    </div>
  );
};

export default AppTimeInput;
