import { setHours, setMinutes } from "date-fns";
import {
  addResourceTimeOff,
  addResourceTimeOffAdmin,
} from "../../api/resources";
import AppModal from "../../components/AppModal";
import { successToast } from "../../toast";
import {
  defaultTimeOffFormValues,
  TimeOffForm,
  TimeOffFormValues,
} from "../TimeOffForm";

interface CreateTimeOffModalProps {
  open: boolean;
  handleClose: () => void;
  userIsAdmin?: boolean;
  initialDate?: Date;
  initialResourceId?: number;
}

const CreateTimeOffModal: React.FC<CreateTimeOffModalProps> = ({
  open,
  handleClose,
  userIsAdmin,
  initialDate,
  initialResourceId,
}) => {
  const handleSubmit = async (values: TimeOffFormValues) => {
    const { start, end } = values.range;

    const timeOffDto = {
      start: start.toISOString(),
      end: end.toISOString(),
    };

    if (userIsAdmin && values.resourceId) {
      const response = await addResourceTimeOffAdmin(
        values.resourceId,
        timeOffDto
      );

      if (!response.isError) {
        handleClose();
        successToast("Time off has been submitted");
      }
    } else {
      const response = await addResourceTimeOff(timeOffDto);

      if (!response.isError) {
        handleClose();
        successToast("Time off has been submitted");
      }
    }
  };

  return (
    <>
      <AppModal isOpen={open} onClose={handleClose} title="Time Off">
        <TimeOffForm
          initialValues={
            initialDate
              ? {
                  ...defaultTimeOffFormValues,
                  range: {
                    start: setMinutes(setHours(initialDate, 9), 0),
                    end: setMinutes(setHours(initialDate, 17), 0),
                  },
                  resourceId: initialResourceId ? initialResourceId : -1,
                }
              : undefined
          }
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          userIsAdmin={userIsAdmin}
        />
      </AppModal>
    </>
  );
};

export default CreateTimeOffModal;
