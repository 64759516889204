import { makeStyles } from "@material-ui/core";
import TimePicker, { TimePickerValue } from "react-time-picker";
import AppFormError from "./AppFormError";
import AppFormLabel from "./AppFormLabel";

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: 0,
    width: "100%",
    height: "44px",
    "& .react-time-picker__wrapper": {
      border: "none",
      fontSize: "1rem",
      borderBottom: `1px solid ${theme.palette.grey[500]}`,
      "&:hover": {
        borderBottom: `2px solid ${theme.palette.grey[600]}`,
        cursor: "text",
      },
    },
  },
}));

export interface AppTimePickerProps {
  value: string;
  onChange: (value: TimePickerValue) => void;
  helperText: string | undefined;
  error: boolean;
  label: string;
  disabled?: boolean;
}

const AppTimePicker: React.FC<AppTimePickerProps> = ({
  value,
  onChange,
  helperText,
  error,
  label,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <>
      <AppFormLabel label={label} />
      <TimePicker
        value={value}
        onChange={onChange}
        className={classes.input}
        clearIcon={null}
        disableClock
        disabled={disabled}
      />
      <AppFormError show={error}>{helperText}</AppFormError>
    </>
  );
};

export default AppTimePicker;
