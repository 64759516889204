import { makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { getVendorConfiguration } from "../api/configuration";
import { useAuth } from "../auth";
import AppLoader from "../components/AppLoader";
import { ConfigContext, IConfigContext } from "./ConfigContext";

const useStyles = makeStyles(() => ({
  loaderContainer: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const ConfigProvider: React.FC = ({ children }) => {
  const classes = useStyles();
  const { isLoggedIn } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  const [vendorConfig, setVendorConfig] = useState<IConfigContext>({
    isFresh: false,
    vendorId: 0,
    currentTaxRatePercentage: 0,
    recurringBookingMaxPeriodWeeks: 0,
    recurringBookingMaxRepetitions: 0,
    hidePricesFromResource: false,
    muteBookingConfirmationEmails: false,
  });

  const fetchVendorConfiguration = async () => {
    setIsLoading(true);
    const result = await getVendorConfiguration();

    if (!result.isError) {
      setVendorConfig(result.content);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchVendorConfiguration();
    } else {
      setIsLoading(false);
    }
  }, [isLoggedIn]);

  return (
    <ConfigContext.Provider value={vendorConfig}>
      <>
        {isLoading ? (
          <div className={classes.loaderContainer}>
            <AppLoader />
          </div>
        ) : (
          <>{children}</>
        )}
      </>
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;
