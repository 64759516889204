import { IconButton, Switch } from "@material-ui/core";
import { MoreVert, Timer } from "@material-ui/icons";
import {
  KeyboardArrowDown as ExpandMoreIcon,
  KeyboardArrowUp as ShowLessIcon,
} from "@material-ui/icons";
import { useState } from "react";
import {Extra, ServiceResponse} from "../../api/models/Services";
import AdminIcon from "../AppCalendar/assets/adminIcon";
import { useStyles } from "./serviceCardStyles";
import ExpandableText from "../ExpandableText";

interface StyleProps {
  hasIcon: boolean;
  isExpanded: boolean;
}

interface Props {
    service: ServiceResponse
}

const ServiceCard: React.FC<Props> = ({
  service
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const classes = useStyles({
    hasIcon: service.isBackOfficeOnly,
    isExpanded: isExpanded,
  } as StyleProps);

  const renderBackOfficeIcon = () => {
    if (service.isBackOfficeOnly) {
      return (
          <div className={classes.accountIcon}>
            <AdminIcon></AdminIcon>
          </div>
      );
    }
  };

  const renderDuration = () => {
      if (service.duration == undefined) {
        return `Undefined`;
      }

      return (
          `${service.duration} mins`
      );
  };

  const renderExtras = () => {
      if (service.extras.length == 0) {
          return;
      }

      return (
          <div className={`${classes.extras}`}>
              <div className={classes.toggler} onClick={() => setIsExpanded(!isExpanded)}>
                  <IconButton
                      onClick={() => setIsExpanded(!isExpanded)}
                      color="primary"
                  >
                      {isExpanded ? <ShowLessIcon/> : <ExpandMoreIcon/>}
                  </IconButton>
                  <span>Extras</span>
              </div>
              <div>
                  <ul className={`${classes.extrasList} ${isExpanded ? classes.isExpanded : ''}`}>
                      {service.extras.map((extra: Extra) => (
                          <li key={extra.id}>{extra.name}</li>
                      ))}
                  </ul>
              </div>
          </div>
      );
  };

  return (
      <div className={`${classes.card}`}>
          <div className={classes.header}>
              <div className={classes.title}>
                  {renderBackOfficeIcon()}
                  <h2>{service.name}</h2>
              </div>
              <div className={classes.actions}>
                  <Switch
                      color="primary"
                      className={classes.availableSwitch}
                  ></Switch>
                  <IconButton>
                      <MoreVert></MoreVert>
                  </IconButton>
              </div>
          </div>
          <div className={classes.meta}>
              <div>
                  <Timer className={classes.timerIcon}></Timer>
                  <span>{renderDuration()}</span>
              </div>
              <div>
                  &pound;{service.price.toFixed(2)}
              </div>
          </div>
          <div className={classes.description}>
              <ExpandableText
                  text={service.description}
                  styles={classes.descriptionText}>
              </ExpandableText>
          </div>
          <div className={classes.footer}>
              {renderExtras()}
          </div>
      </div>
  );
};

export default ServiceCard;
