import React, { useEffect, useState } from "react";
import { UserRole } from "../models/AccountManagement";
import authClient from "./authClient";
import { AuthContext } from "./AuthContext";

type AuthProviderProps = {
  children: React.ReactNode;
};

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userName, setUserName] = useState<string | null>(null);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [role, setRole] = useState<UserRole>("");

  useEffect(() => {
    const sessionState = authClient.loadSession();
    if (sessionState.isLoggedIn) {
      setUserName(sessionState.userName || null);
      setFirstName(sessionState.firstName || null);
      setLastName(sessionState.lastName || null);
      setRole(sessionState.role ? sessionState.role : "");
      setIsLoggedIn(true);
    }
    setIsLoading(false);
  }, [setUserName]);

  const handleLogIn = async (email: string, password: string) => {
    const authResult = await authClient.logIn(email, password);

    if (!authResult.isError) {
      setUserName(authResult.displayName);
      setFirstName(authResult.firstName);
      setLastName(authResult.lastName);
      setRole(authResult.role);
      setIsLoggedIn(true);
    }

    return authResult;
  };

  const handleLogOut = async () => {
    const authResult = await authClient.logOut();
    if (!authResult.isError) {
      setUserName("");
      setRole("");
      setIsLoggedIn(false);
    }
    return authResult;
  };

  const handleGetAccountInformation = () => {
    const account = authClient.getAccountInformation();

    if (!account) {
      throw new Error("AuthProvider: Expected account information to exist.");
    }

    return account;
  };

  return (
    <AuthContext.Provider
      value={{
        logIn: handleLogIn,
        logOut: handleLogOut,
        userName,
        firstName,
        lastName,
        role,
        isLoggedIn,
        getToken: authClient.getToken,
        getAccountInformation: handleGetAccountInformation,
      }}
    >
      {isLoading ? "Loading..." : children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
