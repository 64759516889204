import AppFormikDatePicker, {
  AppFormikDatePickerProps,
} from "../AppFormikDatePicker/AppFormikDatePicker";

export type CreatePreconfiguredAppFormikDatePickerOptions = {
  name: string;
  label: string;
  widget?: boolean;
};

export type PreconfiguredAppFormikDatePickerProps = Omit<
  AppFormikDatePickerProps,
  "name"
>;

export const createPreconfiguredAppFormikDatePicker = ({
  name,
  label,
  widget,
}: CreatePreconfiguredAppFormikDatePickerOptions): React.FC<PreconfiguredAppFormikDatePickerProps> => {
  const PreConfiguredAppFormikDatePicker = (
    restOfProps: PreconfiguredAppFormikDatePickerProps
  ) => (
    <AppFormikDatePicker
      label={label}
      {...restOfProps}
      name={name}
      widget={widget}
    />
  );

  return PreConfiguredAppFormikDatePicker;
};

export default createPreconfiguredAppFormikDatePicker;
