import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  CustomerAddress,
  CustomerDetails,
  CustomerSummary,
} from "../models/Customers";
import { createHttpClientThunk } from "./common/createHttpClientThunk";
import { HttpClientFailureResponse } from "../api";
import { Pagination } from "../models/Pagination";
import { getCustomerAddresses, getPaginatedCustomers } from "../api/customers";

export const sliceName = "customers";

const fetchPaginatedAsyncThunk = createHttpClientThunk(
  `${sliceName}/fetchPaginated`,
  getPaginatedCustomers
);

const fetchCustomerAddressesAsyncThunk = createHttpClientThunk(
  `${sliceName}/fetchCustomerAddresses`,
  getCustomerAddresses
);

const entityAdapter = createEntityAdapter<CustomerSummary>({
  selectId: (c) => c.id,
});

const addressEntityAdapter = createEntityAdapter<CustomerAddress>({
  selectId: (c) => c.id,
});

type SliceState = {
  entityState: EntityState<CustomerSummary>;
  addressEntityState: EntityState<CustomerAddress>;
  isFetchingCustomerAddresses: boolean;
  customerById?: CustomerDetails;
  pagination: Pagination;
  isFetching: boolean;
  isError: boolean;
  getByIdHttpError?: HttpClientFailureResponse;
  getAllHttpError?: HttpClientFailureResponse;
  searchTerm: string;
  sortBy: string;
};

const initialState: SliceState = {
  entityState: entityAdapter.getInitialState(),
  addressEntityState: addressEntityAdapter.getInitialState(),
  isFetchingCustomerAddresses: false,
  pagination: {
    pageNumber: 1,
    pageSize: 10,
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
    totalPages: 1,
  },
  isFetching: false,
  isError: false,
  searchTerm: "",
  sortBy: "",
};

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setSearchTerm: (state, { payload }: PayloadAction<string>) => {
      // reset page number otherwise we will could get invalid pagination if results have less pages than current search.
      state.pagination.pageNumber = 1;
      state.searchTerm = payload;
    },
    setSortBy: (state, { payload }: PayloadAction<string>) => {
      state.pagination.pageNumber = 1;
      state.sortBy = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPaginatedAsyncThunk.pending, (state) => {
      state.isFetching = true;
      state.isError = false;
    });
    builder.addCase(fetchPaginatedAsyncThunk.rejected, (state) => {
      state.isFetching = false;
      state.isError = true;
    });
    builder.addCase(
      fetchPaginatedAsyncThunk.fulfilled,
      (state, { payload }) => {
        state.isFetching = false;
        entityAdapter.removeAll(state.entityState);
        entityAdapter.setAll(state.entityState, payload.content.items);
        state.pagination = {
          ...payload.content,
        };
      }
    );
    builder.addCase(fetchCustomerAddressesAsyncThunk.pending, (state) => {
      state.isFetchingCustomerAddresses = true;
    });
    builder.addCase(fetchCustomerAddressesAsyncThunk.rejected, (state) => {
      state.isFetchingCustomerAddresses = false;
    });
    builder.addCase(
      fetchCustomerAddressesAsyncThunk.fulfilled,
      (state, { payload }) => {
        state.isFetchingCustomerAddresses = false;
        addressEntityAdapter.removeAll(state.addressEntityState);
        addressEntityAdapter.setAll(state.addressEntityState, payload.content);
      }
    );
    // builder.addCase(getByIdAsyncThunk.pending, state => {
    //     state.isGettingById = true;
    //     state.getByIdHttpError = undefined;
    // });
    // builder.addCase(getByIdAsyncThunk.rejected, (state, { payload }) => {
    //     state.isGettingById = false;
    //     state.getByIdHttpError = payload;
    // });
    // builder.addCase(getByIdAsyncThunk.fulfilled, (state, { payload }) => {
    //     state.isGettingById = false;
    //     state.customerById = payload;
    // });
  },
});

export const { name, reducer } = slice;

type RootReducerState = {
  [sliceName]: SliceState;
};

type CustomerSelector<T> = (state: RootReducerState) => T;

const selectSliceState = (state: RootReducerState) => state[sliceName];
const entitySelectors = entityAdapter.getSelectors();
const addressEntitySelectors = addressEntityAdapter.getSelectors();

const doPaginatedFetch = (
  dispatch: (action: unknown) => void,
  state: SliceState,
  pageNumber?: number,
  pageSize?: number,
  searchTerm?: string,
  sortBy?: string
) => {
  dispatch(
    fetchPaginatedAsyncThunk({
      pageIndex: pageNumber || state.pagination.pageNumber,
      pageSize: pageSize || state.pagination.pageSize,
      searchTerm: searchTerm || state.searchTerm,
      sortBy: sortBy || state.sortBy,
    })
  );
};

const doAddressFetch = (dispatch: (action: unknown) => void, id: number) => {
  dispatch(fetchCustomerAddressesAsyncThunk(id));
};

export const actions = {
  fetchAll:
    () =>
    (
      dispatch: (action: unknown) => void,
      getState: () => RootReducerState
    ): void => {
      const state = selectSliceState(getState());
      doPaginatedFetch(dispatch, state);
    },
  setPageNumber:
    (pageNumber: number) =>
    (
      dispatch: (action: unknown) => void,
      getState: () => RootReducerState
    ): void => {
      const state = selectSliceState(getState());
      doPaginatedFetch(dispatch, state, pageNumber);
    },
  setPageSize:
    (pageSize: number) =>
    (
      dispatch: (action: unknown) => void,
      getState: () => RootReducerState
    ): void => {
      const state = selectSliceState(getState());
      doPaginatedFetch(dispatch, state, undefined, pageSize);
    },
  setSearchTerm:
    (newSearchTerm: string) =>
    (dispatch: (action: unknown) => void): void => {
      dispatch(slice.actions.setSearchTerm(newSearchTerm));
    },
  doSearch:
    () =>
    (
      dispatch: (action: unknown) => void,
      getState: () => RootReducerState
    ): void => {
      const state = selectSliceState(getState());
      doPaginatedFetch(dispatch, state);
    },
  fetchCustomerAddresses:
    (id: number) =>
    (dispatch: (action: unknown) => void): void => {
      doAddressFetch(dispatch, id);
    },
  setSortBy:
    (sortByString: string) =>
    (
      dispatch: (action: unknown) => void,
      getState: () => RootReducerState
    ): void => {
      dispatch(slice.actions.setSortBy(sortByString));
      const state = selectSliceState(getState());
      doPaginatedFetch(
        dispatch,
        state,
        undefined,
        undefined,
        undefined,
        sortByString
      );
    },
};

const createSliceSelector = <T,>(selector: (state: SliceState) => T) => {
  return createSelector(selectSliceState, selector);
};

export const selectors = {
  apiState: createSliceSelector((state) => ({
    isFetching: state.isFetching,
    isError: state.isError,
  })),
  selectSearchTerm: createSelector(
    selectSliceState,
    (state) => state.searchTerm
  ),
  all: createSliceSelector((state) =>
    entitySelectors.selectAll(state.entityState)
  ),
  pagination: createSliceSelector((state) => state.pagination),
  customerById: (id: number): CustomerSelector<CustomerSummary | undefined> =>
    createSliceSelector((state) =>
      entitySelectors.selectById(state.entityState, id)
    ),
  allAddresses: createSliceSelector((state) =>
    addressEntitySelectors.selectAll(state.addressEntityState)
  ),
  addressById: (id: number): CustomerSelector<CustomerAddress | undefined> =>
    createSliceSelector((state) =>
      addressEntitySelectors.selectById(state.addressEntityState, id)
    ),
  addressesIsLoading: createSliceSelector(
    (state) => state.isFetchingCustomerAddresses
  ),
  selectSortBy: createSelector(selectSliceState, (state) => state.sortBy),
};
