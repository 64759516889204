import { Tag } from "../models/Tag";
import {
  buildApiUrl,
  httpClient,
  doErrorToastIfRequired,
  mapHttpClientListResponse,
} from "./common";
import { ApiListResponse } from "./common/ApiListResponse";
import { HttpClientResponse } from "./common/httpClient";
import { ApiTag } from "./models/Tag";

export const getAllTagTypes = async (): Promise<
  HttpClientResponse<ApiListResponse<Tag>>
> => {
  const url = buildApiUrl("api-vendor/tags");
  const response = await httpClient.getRequest<ApiListResponse<ApiTag>>(url);

  doErrorToastIfRequired(response);

  return mapHttpClientListResponse(response, (m) => m);
};
