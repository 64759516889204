import { TimeRange } from "../../components/AppForm/AppTimeRangePicker";
import { setHours, setMinutes } from "date-fns";
import * as Yup from "yup";

export { default as TimeOffForm } from "./TimeOffFormContainer";

export interface TimeOffFormValues {
  id?: number;
  range: TimeRange;
  resourceId?: number;
}

export const defaultTimeOffFormValues: TimeOffFormValues = {
  range: {
    start: setMinutes(setHours(new Date(), 9), 0),
    end: setMinutes(setHours(new Date(), 17), 0),
  },
  resourceId: -1,
};

export const createTimeOffFormValidationSchema = (
  userIsAdmin?: boolean
): unknown => {
  return Yup.object().shape({
    resourceId: userIsAdmin
      ? Yup.number()
          .moreThan(0, "Please select a mobile worker.")
          .required("Please select a mobile worker.")
      : Yup.number().notRequired(),
  });
};
