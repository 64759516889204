import {
  customerAddressFormValidationFields,
  CustomerAddressFormValues,
} from "./CustomerAddressFormValues";
import {
  customerDetailsFormValidationFields,
  CustomerDetailsFormValues,
} from "./CustomerFormValues";
import * as Yup from "yup";

export interface CustomerCreateFormValues
  extends CustomerDetailsFormValues,
    CustomerAddressFormValues {}

export const defaultCustomerCreateFormValues: CustomerCreateFormValues = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  company: "",
  addressLine1: "",
  addressLine2: "",
  addressLine3: "",
  postcode: "",
  town: "",
};

export const customerFormValidationSchema: Yup.SchemaOf<CustomerCreateFormValues> =
  Yup.object().shape({
    ...customerDetailsFormValidationFields,
    ...customerAddressFormValidationFields,
  });
