import { FunctionComponent } from "react";
import BookingFormSummaryBox from "../BookingFormSummaryBox/BookingFormSummaryBox";
import { BookingFormValues } from "../BookingFormValues";
import BookingFormCreateReccuranceForm from "./BookingFormCreateReccuranceForm";

interface BookingFormCreateReccuranceProps{
    initialValues: BookingFormValues;
    activeStep: number;
}

const BookingFormCreateReccurance: FunctionComponent<BookingFormCreateReccuranceProps> = ({
    initialValues,
    activeStep
}) => {
    return (
        <>
          <BookingFormCreateReccuranceForm initialValues={initialValues} />
            <br />
          <BookingFormSummaryBox activeStep={activeStep}/>
        </>);
};

export default BookingFormCreateReccurance;