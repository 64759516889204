import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import ToolBar from "@material-ui/core/Toolbar";
import AccountMenu from "./AccountMenu";
import NavBarMenu from "./NavBarMenu";
import MobileNavBarMenu from "./MobileNavBarMenu";
import { useWindowSize } from "./WindowSize";

const useStyles = makeStyles((theme) => ({
  navBar: {
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 3px 6px #00000029",
  },
  toolBar: {
    height: 64,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    maxWidth: "180px",
  },
}));

const NavBar: React.FC = () => {
  const classes = useStyles();
  const { width } = useWindowSize();

  return (
    <>
      <AppBar position="fixed" className={classes.navBar}>
        <ToolBar className={classes.toolBar}>
          <img
            className={classes.logo}
            src="/assets/images/mbh-logo.svg"
            alt="My Booking Hub Logo"
          />
          {width && width >= 992 && (
            <>
              <NavBarMenu />
              <AccountMenu />
            </>
          )}
          {width && width < 992 && <MobileNavBarMenu />}
        </ToolBar>
      </AppBar>
    </>
  );
};

export default NavBar;
