import {IconButton, TableCell, TableRow} from "@material-ui/core";
import {Edit} from "@material-ui/icons";
import {differenceInMinutes} from "date-fns";
import {formatDateDateTime} from "../../dateFormatters";
import {CustomerBookingSummary} from "../../models/Customers";

interface CustomerBookingsTableRowProps {
    booking: CustomerBookingSummary;
    handleEdit: () => void;
}

const CustomerBookingsTableRow: React.FC<CustomerBookingsTableRowProps> = ({
    booking,
    handleEdit,
}) => {
    return (
        <TableRow>
            <TableCell>{booking.id}</TableCell>
            <TableCell>{formatDateDateTime(booking.start)}</TableCell>
            <TableCell>{booking.firstJobAppointmentTypeName}</TableCell>
            <TableCell>
                {differenceInMinutes(new Date(booking.end), new Date(booking.start))}{" "}
                mins
            </TableCell>
            <TableCell>{booking.resourceName}</TableCell>
            <TableCell>
                <IconButton onClick={() => handleEdit()}>
                    <Edit/>
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default CustomerBookingsTableRow;
