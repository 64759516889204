import { createContext, useContext } from "react";

export interface IConfigContext {
  isFresh: boolean;
  vendorId: number;
  currentTaxRatePercentage: number;
  recurringBookingMaxPeriodWeeks: number;
  recurringBookingMaxRepetitions: number;
  hidePricesFromResource: boolean;
  muteBookingConfirmationEmails: boolean;
}

export const ConfigContext = createContext<IConfigContext>({
  isFresh: false,
  vendorId: 0,
  currentTaxRatePercentage: 0,
  recurringBookingMaxPeriodWeeks: 0,
  recurringBookingMaxRepetitions: 0,
  hidePricesFromResource: false,
  muteBookingConfirmationEmails: false,
});

export const useConfig: () => IConfigContext = () => useContext(ConfigContext);
