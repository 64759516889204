import { createPreconfiguredAppFormikTextField } from "../../components/AppForm";

export { default } from "./LogInForm";

export const LogInFields = {
  Email: createPreconfiguredAppFormikTextField({
    name: "email",
    label: "Email address",
  }),
  Password: createPreconfiguredAppFormikTextField({
    name: "password",
    label: "Password",
    type: "password",
  }),
};
