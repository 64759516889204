import { useState, KeyboardEvent, useEffect, RefObject } from "react";
import {
  CHANGE_TYPE,
  DIRECTION,
  formatWithLeadingZero,
  useMinutesAfterMidnight,
  validateHour,
  validateMinute,
} from ".";

export const useAppTimeInput = (
  value: number,
  minValue: number,
  maxValue: number,
  minuteIncrement: number,
  hourInputRef: RefObject<HTMLInputElement>,
  minuteInputRef: RefObject<HTMLInputElement>,
  isEdit: boolean,
  onChange: (val: number) => void
) => {
  const { hour, minute, hourString, minuteString } =
    useMinutesAfterMidnight(value);

  const [hourEdit, setHourEdit] = useState(hourString);
  const [minuteEdit, setMinuteEdit] = useState(minuteString);
  const [hourValid, setHourValid] = useState(true);
  const [minuteValid, setMinuteValid] = useState(true);

  const minMinute = minValue % 60;
  const minHour = (minValue - minMinute) / 60;
  const maxMinute = maxValue % 60;
  const maxHour = (maxValue - maxMinute) / 60;

  const pushTimeInputChange = (hour = hourEdit, minute = minuteEdit) => {
    const minutesAfterMidnight = parseInt(hour) * 60 + parseInt(minute);
    onChange(minutesAfterMidnight);
  };

  const handleHourChange = (value: string) => {
    setHourEdit(value);
    const hourAsNumber = parseInt(value);
    const hourIsValid = validateHour(hourAsNumber, minHour, maxHour);
    const minuteIsValid = validateMinute(
      minute,
      hourAsNumber,
      minMinute,
      maxMinute,
      minHour,
      maxHour
    );
    setHourValid(hourIsValid);
    setMinuteValid(minuteIsValid);
  };

  const handleHourInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    const hourAsNumber = parseInt(hourEdit);

    if (e.key === "ArrowUp") {
      e.preventDefault();

      if (hourAsNumber !== maxHour) {
        handleHourChange((hourAsNumber + 1).toString());
      }
    }

    if (e.key === "ArrowDown") {
      e.preventDefault();

      if (hourAsNumber !== minHour) {
        handleHourChange((hourAsNumber - 1).toString());
      }
    }

    if (e.key === "Enter") {
      e.preventDefault();
      hourInputRef.current?.blur();
    }
  };

  const handleHourInputBlur = () => {
    // Hour and minute are both valid
    if (hourValid && minuteValid) {
      pushTimeInputChange();
    }

    // Minute is no longer valid after hour change
    // Automatically update it to min/max
    if (hourValid && !minuteValid) {
      const hourAsNumber = parseInt(hourEdit);

      if (hourAsNumber === minHour) {
        pushTimeInputChange(hourEdit, minMinute.toString());
      }

      if (hourAsNumber === maxHour) {
        pushTimeInputChange(hourEdit, maxMinute.toString());
      }

      setMinuteValid(true);
    }

    // Hour is not valid
    if (!hourValid) {
      setHourEdit(hourString);
      setHourValid(true);
    }
  };

  const handleMinuteChange = (value: string) => {
    setMinuteEdit(value);
    const minuteAsNumber = parseInt(value);
    const minuteIsValid = validateMinute(
      minuteAsNumber,
      hour,
      minMinute,
      maxMinute,
      minHour,
      maxHour
    );
    setMinuteValid(minuteIsValid);
  };

  const handleMinuteInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    let minuteAsNumber = parseInt(minuteEdit);
    const isMinuteValidIncrement = minuteAsNumber % minuteIncrement === 0;
    
    if (e.key === "ArrowUp") {
      e.preventDefault();

      if (!(hour === maxHour && minuteAsNumber === maxMinute)) {
        let newMinuteValue = isMinuteValidIncrement ?
          minuteAsNumber + minuteIncrement :
          Math.ceil(minuteAsNumber / minuteIncrement) * minuteIncrement;

        if (newMinuteValue >= 60) {
          newMinuteValue = 0;
        }

        handleMinuteChange(formatWithLeadingZero(newMinuteValue));
      }
    }

    if (!isMinuteValidIncrement) {
      minuteAsNumber =
        Math.ceil(minuteAsNumber / minuteIncrement) * minuteIncrement;
    }

    if (e.key === "ArrowDown") {
      e.preventDefault();

      if (!(hour === minHour && minuteAsNumber === minMinute)) {
        let newMinuteValue = minuteAsNumber - minuteIncrement;

        if (newMinuteValue < 0) {
          newMinuteValue = 60 - minuteIncrement;
        }
        handleMinuteChange(formatWithLeadingZero(newMinuteValue));
      }
    }

    if (e.key === "Enter") {
      e.preventDefault();
      minuteInputRef.current?.blur();
    }
  };

  const handleMinuteInputBlur = () => {
    const isValidIncrement = parseInt(minuteEdit) % minuteIncrement === 0;

    if (minuteValid && isValidIncrement) {
      pushTimeInputChange();
    }

    if (minuteValid && !isValidIncrement) {
      const nearestMinuteIncrement = (
        Math.ceil(parseInt(minuteEdit) / minuteIncrement) * minuteIncrement
      ).toString();
      setMinuteEdit(nearestMinuteIncrement);
      pushTimeInputChange(hourString, nearestMinuteIncrement);
    }

    if (!minuteValid) {
      setMinuteEdit(minuteString);
      setMinuteValid(true);
    }
  };

  const handleArrowClick = (type: CHANGE_TYPE, direction: DIRECTION) => {
    if (type === CHANGE_TYPE.HOUR) {
      if (direction === DIRECTION.UP) {
        const newHourValue = hour + 1;
        let newMinuteValue = minute;

        if (newHourValue === maxHour && newMinuteValue > maxMinute) {
          newMinuteValue = maxMinute;
        }
        pushTimeInputChange(newHourValue.toString(), newMinuteValue.toString());
      }

      if (direction === DIRECTION.DOWN) {
        const newHourValue = hour - 1;
        let newMinuteValue = minute;

        if (newHourValue === minHour && newMinuteValue < minMinute) {
          newMinuteValue = minMinute;
        }
        pushTimeInputChange(newHourValue.toString(), newMinuteValue.toString());
      }
    }

    if (type === CHANGE_TYPE.MINUTE) {
      if (direction === DIRECTION.UP) {
        let newMinuteValue = minute + minuteIncrement;

        if (newMinuteValue >= 60) {
          newMinuteValue = 0;
        }

        pushTimeInputChange(hourString, newMinuteValue.toString());
      }

      if (direction === DIRECTION.DOWN) {
        let newMinuteValue = minute - minuteIncrement;
        if (newMinuteValue < 0) {
          newMinuteValue = 60 - minuteIncrement;
        }
        pushTimeInputChange(hourString, newMinuteValue.toString());
      }
    }
  };

  useEffect(() => {
    if (hourString !== hourEdit) {
      setHourEdit(hourString);
    }

    if (minuteString !== minuteEdit) {
      setMinuteEdit(minuteString);
    }
  }, [hourString, minuteString]);

  useEffect(() => {
    if (!isEdit) {
      handleHourInputBlur();
      handleMinuteInputBlur();
    }
  }, [minuteIncrement]);

  return {
    hour,
    hourString,
    minute,
    minuteString,
    hourEdit,
    minuteEdit,
    minHour,
    maxHour,
    minMinute,
    maxMinute,
    hourValid,
    minuteValid,
    handleHourChange,
    handleMinuteChange,
    handleHourInputBlur,
    handleMinuteInputBlur,
    handleHourInputKeyPress,
    handleMinuteInputKeyPress,
    handleArrowClick,
  };
};
