import { IconButton, makeStyles } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import AppButton from "../../components/AppButton";
import AppFormikSubmitButton from "../../components/AppForm/AppFormikSubmitButton";
import {
  BOOKING_OVERVIEW_STEP,
  BOOKING_NOTES_STEP,
  CUSTOMER_SELECTION_STEP,
} from "./BookingFormContainer";

interface BookingFormNavigationProps {
  activeStep: number;
  isEdit?: boolean;
  onPreviousClick(): void;
  onClose(): void;
  handleEdit(): void;
  handleSubmit(): void;
}

const useStyles = makeStyles((theme) => ({
  bookingFormNavigationContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
  bookingFormNavigationLHS: {
    display: "flex",
    alignItems: "center",
  },
  bookingFormNavigationRHS: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },
  closeDrawerBtn: {
    borderRadius: 0,
  },
  editSaveBtn: {
    marginRight: theme.spacing(1),
  },
}));

const BookingFormNavigation: React.FC<BookingFormNavigationProps> = ({
  activeStep,
  isEdit,
  onPreviousClick,
  onClose,
  handleEdit,
  handleSubmit,
}) => {
  const classes = useStyles();

  const renderSubmitButtonText = () => {
    let text = activeStep === BOOKING_NOTES_STEP ? "Create Booking" : "Next";

    if (isEdit && activeStep === BOOKING_NOTES_STEP) {
      text = 'Save Changes';
    }

    return text;
  };

  return (
    <div className={classes.bookingFormNavigationContainer}>
      <div className={classes.bookingFormNavigationLHS}>
        <IconButton
          onClick={onClose}
          size="medium"
          className={classes.closeDrawerBtn}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.bookingFormNavigationRHS}>
        <AppButton
            variant="text"
            onClick={onPreviousClick}
            disabled={[BOOKING_OVERVIEW_STEP, CUSTOMER_SELECTION_STEP].includes(activeStep)}>
          BACK
        </AppButton>
        {isEdit && activeStep !== BOOKING_NOTES_STEP && (
          <div className={classes.editSaveBtn}>
            <AppButton color="secondary" onClick={handleEdit}>
              SAVE
            </AppButton>
          </div>
        )}
        <AppFormikSubmitButton onClick={handleSubmit}>
          {renderSubmitButtonText()}
        </AppFormikSubmitButton>
      </div>
    </div>
  );
};

export default BookingFormNavigation;
