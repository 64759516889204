import { makeStyles, useTheme } from "@material-ui/core";
import { Form, useField } from "formik";
import { useState, useEffect } from "react";
import {
  createTimeOffFormValidationSchema,
  defaultTimeOffFormValues,
  TimeOffFormValues,
} from ".";
import { useHttpRequest } from "../../api";
import {
  deleteResourceTimeOff,
  deleteResourceTimeOffAdmin,
  getResourceById,
} from "../../api/resources";
import BookingManualResourceSelection from "../../bookings/BookingForm/BookingManualResourceSelection";
import AppButton from "../../components/AppButton";
import AppDialog from "../../components/AppConfirmationDialog";
import AppForm from "../../components/AppForm";
import AppFormikSubmitButton from "../../components/AppForm/AppFormikSubmitButton";
import AppFormikTimeRangePicker from "../../components/AppForm/AppFormikTimeRangePicker";
import { TimeRange } from "../../components/AppForm/AppTimeRangePicker";
import { ResourceDetails, ResourceSummary } from "../../models/Resource";
import { successToast } from "../../toast";

interface TimeOffFormProps {
  initialValues?: TimeOffFormValues;
  handleClose: () => void;
  userIsAdmin?: boolean;
}

const useStyles = makeStyles((theme) => ({
  cancelBtn: {
    color: theme.palette.secondaryRed.main,
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const TimeOffForm: React.FC<TimeOffFormProps> = ({
  initialValues = defaultTimeOffFormValues,
  handleClose,
  userIsAdmin,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [granularity, setGranularity] = useState(15);

  const startHour = initialValues.range.start.getHours();
  const endHour = initialValues.range.end.getHours();

  const [{ value: resourceId }] = useField<number>("resourceId");

  const handleDeleteConfirmation = async () => {
    if (initialValues.id) {
      const result = userIsAdmin
        ? await deleteResourceTimeOffAdmin(initialValues.id)
        : await deleteResourceTimeOff(initialValues.id);

      if (!result.isError) {
        setDeleteDialog(false);
        successToast("Time off has been cancelled");
        handleClose();
      }
    }
  };

  const displayTimeRangePicker =
    !userIsAdmin || (userIsAdmin && resourceId !== -1);

  return (
    <>
      <Form>
        <AppForm>
          <BookingManualResourceSelection
            readOnly={Boolean(initialValues.id)}
            onGranularityChange={setGranularity}
            fetchAll
            userIsAdmin={userIsAdmin}
          />
          {displayTimeRangePicker && (
            <AppFormikTimeRangePicker
              name="range"
              granularity={granularity}
              isPartDaysInitial={
                (startHour !== 0 || endHour !== 0) && Boolean(initialValues.id)
              }
            />
          )}
          <div className={classes.actions}>
            {initialValues.id && (
              <AppButton
                variant="text"
                style={{ color: theme.palette.secondaryRed.main }}
                type="button"
                onClick={() => setDeleteDialog(true)}
              >
                Cancel
              </AppButton>
            )}
            <AppFormikSubmitButton>
              {initialValues.id ? "Update" : "Submit"}
            </AppFormikSubmitButton>
          </div>
        </AppForm>
      </Form>
      <AppDialog
        open={deleteDialog}
        title="Cancel Time Off"
        caption="Are you sure you want to cancel this time off?"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        handleCancel={() => setDeleteDialog(false)}
        handleConfirmation={handleDeleteConfirmation}
      />
    </>
  );
};

export default TimeOffForm;
