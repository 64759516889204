import { Drawer, makeStyles } from "@material-ui/core";

type DrawerAnchor = "left" | "right" | "top" | "bottom";

interface AppDrawerProps {
    anchor: DrawerAnchor;
    open: boolean;
    onClose(): void;
}

const useStyles = makeStyles(() => ({
    drawer: {
        "& .MuiDrawer-paper": {
            width: "100%",
            maxWidth: 734,
        }
    }
}));

const AppDrawer: React.FC<AppDrawerProps> = ({
    anchor,
    open,
    onClose,
    children
}) => {
    const classes = useStyles();

    return (
        <>
            <Drawer
                anchor={anchor}
                open={open}
                onClose={onClose}
                className={classes.drawer}
                disableBackdropClick
            >
                {children}
            </Drawer>
        </>
    );
};

export default AppDrawer;