import { Container, makeStyles } from "@material-ui/core";
import ForgottenPasswordForm from "../accountManagement/ForgottenPasswordForm";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  description: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const ForgottenPasswordPage: React.FC = () => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs" fixed>
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Forgotten Password
        </Typography>
        <Typography component="p" className={classes.description}>
          Enter the email you used to register with My Booking Hub and
          we&apos;ll send you a link to reset your password.
        </Typography>
        <ForgottenPasswordForm />
      </div>
    </Container>
  );
};

export default ForgottenPasswordPage;
