import PageHeader from "../components/PageHeader";
import { PageContainer } from "../components/MainLayout";
import { ResourceAccountForm } from "../resources/ResourceAccountForm";

const ResourceAccountPage: React.FC = () => {
  return (
    <PageContainer>
      <PageHeader title="My Account" />
      <ResourceAccountForm />
    </PageContainer>
  );
};

export default ResourceAccountPage;
