import { FormControl, FormLabel } from "@material-ui/core";
import { useEffect, useState } from "react";
import { defaultWorkingDaysValues } from ".";
import { WorkingHoursDay } from "../../../models/Resource";
import AppWorkingHoursPickerDay from "./AppWorkingHoursPickerDay";

export interface AppWorkingHoursPickerProps {
  value?: WorkingHoursDay[];
  onChange: (workingHoursList: WorkingHoursDay[]) => void;
  label?: string;
}

const AppWorkingHoursPicker: React.FC<AppWorkingHoursPickerProps> = ({
  value = defaultWorkingDaysValues,
  onChange,
  label,
}) => {
  const [workingHoursList, setWorkingHoursList] =
    useState<WorkingHoursDay[]>(value);

  const handleWorkingHoursChange = (
    dow: number,
    time?: number,
    name?: "startTime" | "finishTime",
    isWorking?: boolean
  ) => {
    const workingHoursListCopy: WorkingHoursDay[] = JSON.parse(
      JSON.stringify(workingHoursList)
    );
    let applyToAllIsSet = false;

    workingHoursListCopy.map((item) => {
      if (item.dayOfWeekId === dow) {
        if (time !== undefined && name) {
          item[name] = time;

          if (name === "startTime" && time >= item.finishTime) {
            // Prevents start time being the last pickable time
            // Sets start to 23:55 and finish to 23:50
            if (time === 1435) {
              item.startTime = 1430;
              item.finishTime = 1435;
            } else {
              item.finishTime = time + 5;
            }
          }

          if (name === "finishTime" && time <= item.startTime) {
            // Prevents fnish time being the first pickable time
            // Sets start to 00:00 and finish to 00:5
            if (time === 0) {
              item.startTime = 0;
              item.finishTime = 5;
            } else {
              item.startTime = time - 5;
            }
          }
        }

        if (isWorking !== undefined) {
          item.isWorking = isWorking;
        }
      }

      if (item.isWorking) {
        item.applyToAll = applyToAllIsSet === false ? true : false;
        applyToAllIsSet === false ? (applyToAllIsSet = true) : false;
      } else {
        item.applyToAll = false;
      }
    });

    setWorkingHoursList(workingHoursListCopy);
  };

  const handleApplyToAll = (dow: number) => {
    const dayToApply = workingHoursList.find(
        (workingHoursItem) => workingHoursItem.dayOfWeekId === dow
      ),
      workingHoursListCopy: WorkingHoursDay[] = JSON.parse(
        JSON.stringify(workingHoursList)
      );

    if (dayToApply) {
      workingHoursListCopy.map((item) => {
        item.startTime = dayToApply.startTime;
        item.finishTime = dayToApply.finishTime;
      });
    }

    setWorkingHoursList(workingHoursListCopy);
  };

  useEffect(() => {
    onChange(workingHoursList);
  }, [workingHoursList]);

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      {workingHoursList.map((day) => (
        <AppWorkingHoursPickerDay
          key={day.dayOfWeekId}
          {...day}
          onTimeChange={handleWorkingHoursChange}
          onIsWorkingChange={handleWorkingHoursChange}
          handleApplyToAll={handleApplyToAll}
        />
      ))}
    </FormControl>
  );
};

export default AppWorkingHoursPicker;
