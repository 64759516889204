import { lighten } from "@material-ui/core";
import { MouseEvent } from "react";
import { useCalendar } from "../AppCalendarContext";
import { Jobs, OptionalExtra } from "../../../models/Booking";
import { useAuth } from "../../../auth";
import { useConfig } from "../../../config/ConfigContext";
import { useStyles } from "./BookingEventCardStyles";
import { excludeVATFromPriceIfRequired } from "../../../numericalUtils";

interface Props {
  id: string;
  start: string;
  end: string;
  bookingPostcode: string;
  customerFirstName: string;
  customerLastName: string;
  resourceColour: string;
  totalCost: number;
  bookingStatus: number;
  duration: number;
  serviceTitle: string;
  optionalExtras: OptionalExtra[];
  taxRate: number;
  recurringBookingId: number | null;
  jobs: Jobs[];
}

const BookingEventCard: React.FC<Props> = ({
    id,
  start,
  end,
  bookingPostcode,
  customerFirstName,
  customerLastName,
  resourceColour,
  totalCost,
  duration,
  serviceTitle,
  bookingStatus,
  optionalExtras,
  taxRate,
  recurringBookingId,
  jobs,
}) => {
  const { isPivotView } = useCalendar();
  const isProvisional = bookingStatus === 0;
  const classes = useStyles({ colour: resourceColour ?? "#FFF", isProvisional });
  const { role } = useAuth();
  const vendorConfig = useConfig();
  const priceHiddenFromResource = (role === "resource") && vendorConfig.hidePricesFromResource;
  
    const handleMouseOver = (mouseEvent: MouseEvent<HTMLDivElement>) => {
    mouseEvent.currentTarget.style.backgroundColor = lighten(resourceColour, 0.7);
  };

  const handleMouseOut = (mouseEvent: MouseEvent<HTMLDivElement>) => {
    mouseEvent.currentTarget.style.backgroundColor = isProvisional ? "" : lighten(resourceColour, 0.8);
  };

  const renderCustomerName = () => {
    return (
      <span>
        <b>{customerFirstName} {customerLastName}</b>
      </span>
    );
  };

  const renderServices = (jobs: Jobs[]) => {
    return (
      jobs.map(job => {
        return (
          <div key={`appointment-${job.appointmentTypeId}`}>
            <p>{(job as any).name}</p>
            <ul className={classes.optionalExtraList}>
              {job.optionalExtras.map((extra) => {
                const optionalQuantity = extra.quantity && extra.quantity > 1 ? `  x${extra.quantity}` : "";
                return (<li key={`${(job as any).name}-extra-${extra.optionalExtraId}`}>{(extra as any).name} <b>{optionalQuantity}</b></li>);
              })}
            </ul>
          </div>
        );
      })
    );
  };

  if (isPivotView) {
    return (
      <div className={classes.eventContainerLg} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        <div className={classes.pivotEventHeaderMd}>
          {renderCustomerName()}
          {duration > 30 && (<span>{start} - {end}</span>)}
        </div>
      </div>
    );
  } else {
    if (duration <= 15) {
      return (
        <div className={classes.eventContainer} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
          <div className={classes.eventHeaderXS}>
            {renderCustomerName()}
            <span>{start} - {end}</span>
          </div>
        </div>
      );
    }

    if (duration > 15 && duration <= 30) {
      return (
        <div className={classes.eventContainer} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
          <div className={classes.eventHeaderMd}>
            {renderCustomerName()}
            <span>{bookingPostcode}</span>
          </div>
          <div className={classes.serviceInfoMd}>
            <span>{serviceTitle}</span>
            <span>{start} - {end}</span>
          </div>
        </div>
      );
    }
  }

  return (
    <div className={classes.eventContainerLg} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      <div className={classes.eventHeaderMd}>
        <span>
          <b>
            {recurringBookingId
              ? <img className={classes.ClockIcon} src="/assets/images/clock-plus.svg" alt="(R)" height="17" width="17" />
              : ""}
            {renderCustomerName()}
          </b>
        </span>
        <span>{bookingPostcode}</span>
      </div>
      {!priceHiddenFromResource && (
        <div className={classes.servicePriceLHS}>
          <span>&pound;{excludeVATFromPriceIfRequired(totalCost, taxRate).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>{taxRate ? " (ex. VAT)" : ""}
        </div>
      )}
      <div className={classes.serviceInfoLg}>
        <div className={classes.serviceInfoLgLHS}>
          {renderServices(jobs)}
        </div>
        <div className={classes.serviceInfoLgRHS}>{start} - {end}</div>
      </div>
    </div>
  );
};

export default BookingEventCard;
