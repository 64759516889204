import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@material-ui/core";
import React from "react";
import AppLoader from "../AppLoader";

export type AppSelectOption = {
  value: number;
  label: string;
  disabled?: boolean;
};

export interface AppSelectProps extends SelectProps {
  options: AppSelectOption[];
  helperText?: string;
  hidePleaseSelect?: boolean;
  pleaseSelectText?: string;
  isLoading?: boolean;
  onChangeCallback?: (optionValue: number) => void;
}

const AppSelect: React.FC<AppSelectProps> = ({
  label,
  options,
  helperText,
  error,
  hidePleaseSelect,
  pleaseSelectText = "-- Please Select --",
  disabled,
  readOnly,
  fullWidth = true,
  isLoading,
  onChangeCallback,
  onChange,
  className,
  ...restOfProps
}) => {
  const isDisabled = disabled || readOnly;

  if (isLoading) {
    return <AppLoader />;
  }

  const handleChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    child: React.ReactNode
  ) => {
    onChangeCallback && onChangeCallback(event.target.value as number);
    onChange && onChange(event, child);
  };

  return (
    <FormControl fullWidth={fullWidth} className={className}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        {...restOfProps}
        onChange={handleChange}
        disabled={isDisabled}
        fullWidth={fullWidth}
        error={error}
      >
        {!hidePleaseSelect && (
          <MenuItem value={-1} disabled>
            {pleaseSelectText}
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default AppSelect;
