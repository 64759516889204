import { Grid } from "@material-ui/core";
import WidgetAddressSelect from "./WidgetAddressSelect";

type Props = {
  setSelectedPostcode: (value: string) => void;
};

const WidgetBookingDetails: React.FC<Props> = ({ setSelectedPostcode }) => {
  return (
    <>
      <Grid item xs={12}>
        <WidgetAddressSelect setSelectedPostcode={setSelectedPostcode} />
      </Grid>
    </>
  );
};

export default WidgetBookingDetails;
