import { Timer } from "@material-ui/icons";

import ExpandableText from "../ExpandableText";
import { ExtrasResponse } from "../../api/models/Extras";
import { useStyles } from "./extraCardStyles";

const ExtraCard: React.FC<ExtrasResponse> = ({
  name,
  description,
  price,
  durationMinutes,
}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.card}`}>
      <div className={classes.header}>
        <div className={classes.title}>
          <h2>{name}</h2>
        </div>
      </div>
      <div className={classes.meta}>
        <div>
          <Timer className={classes.timerIcon}></Timer>
          <span> {durationMinutes} mins</span>
        </div>
        <div>&pound;{price.toFixed(2)}</div>
      </div>
      <div className={classes.description}>
        <ExpandableText
          text={description}
          styles={classes.descriptionText}
        ></ExpandableText>
      </div>
    </div>
  );
};

export default ExtraCard;
