import { makeStyles, List, ListItem } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { adminPaths, resourcePaths } from "../../routes";
import { RequireAdminUserRole, RequireResourceUserRole } from "../../auth";

const useStyles = makeStyles((theme) => ({
  isActive: {
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.backgrounds.blue,
    [theme.breakpoints.up(992)]: {
      backgroundColor: "transparent",
      borderLeft: "none",
      color: `${theme.palette.primary.dark} !important`,
    },
  },
  navItem: {
    display: "flex",
    alignItems: "center",
    height: 56,
    textDecoration: "none",
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    padding: "0 1.5rem",
    [theme.breakpoints.up(992)]: {
      display: "flex",
      alignItems: "center",
      height: "100%",
      textDecoration: "none",
      color: theme.palette.primary.main,
      padding: "0 1.125rem",
      fontSize: "1.125rem",
    },
  },
  navItemList: {
    width: "100%",
    maxWidth: 420,
    padding: 0,
    "& > li": {
      display: "block",
      padding: 0,
      margin: 0,
      width: "100%",
    },
    [theme.breakpoints.up(992)]: {
      display: "flex",
      flexGrow: 1,
      height: "64px",
      maxWidth: "100%",
      padding: "0",
      "& > li": {
        flex: "none",
        width: "auto",
        padding: "0",
      },
    },
  },
}));

const NavBarMenu: React.FC = () => {
  const classes = useStyles();

  return (
    <List className={classes.navItemList}>
      <RequireAdminUserRole>
        <ListItem>
          <NavLink
            exact
            activeClassName={classes.isActive}
            className={classes.navItem}
            to={adminPaths.allResourceCalendar}
          >
            Calendar
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            activeClassName={classes.isActive}
            className={classes.navItem}
            to={adminPaths.serviceProviders}
          >
            Mobile Workforce
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            activeClassName={classes.isActive}
            className={classes.navItem}
            to={adminPaths.bookings}
          >
            Bookings
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            activeClassName={classes.isActive}
            className={classes.navItem}
            to={adminPaths.customers}
          >
            Customers
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            activeClassName={classes.isActive}
            className={classes.navItem}
            to={adminPaths.dashboard}
          >
            Performance Dashboard
          </NavLink>
        </ListItem>
      </RequireAdminUserRole>
      <RequireResourceUserRole>
        <ListItem>
          <NavLink
            exact
            activeClassName={classes.isActive}
            className={classes.navItem}
            to={resourcePaths.home}
          >
            Home
          </NavLink>
        </ListItem>
      </RequireResourceUserRole>
    </List>
  );
};

export default NavBarMenu;
