import {makeStyles} from "@material-ui/core";
import {useState} from "react";
import EditBookingDrawer from "../../bookings/EditBookingDrawer/EditBookingDrawer";
import AppTable from "../../components/AppTable";
import {CustomerBookingSummary} from "../../models/Customers";
import CustomerBookingsTableRow from "./CustomerBookingsTableRow";

interface CustomerBookingsTableProps {
    bookings?: CustomerBookingSummary[];
    noBookingsMessage: string;
}

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        marginTop: theme.spacing(2),
    },
}));

const CustomerBookingsTable: React.FC<CustomerBookingsTableProps> = ({
    bookings = [],
    noBookingsMessage,
}) => {
    const classes = useStyles();
    const [isEditModalOpen, setisEditModalOpen] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState<number>();

    const handleEditSelect = (id: number) => {
        setSelectedBooking(id);
        setisEditModalOpen(true);
    };

    const handleEditClose = () => {
        setisEditModalOpen(false);
        setSelectedBooking(undefined);
    };

    return (
        <div className={classes.tableContainer}>
            <AppTable
                headers={[
                    {label: "ID"},
                    {label: "Date"},
                    {label: "Service Type"},
                    {label: "Duration"},
                    {label: "Mobile Worker"},
                    {label: ""},
                ]}
                entities={bookings}
                noEntitiesMessage={noBookingsMessage}
                renderRow={(booking) => (
                    <CustomerBookingsTableRow
                        booking={booking}
                        key={booking.id}
                        handleEdit={() => handleEditSelect(booking.id)}
                    />
                )}
            />
            <EditBookingDrawer
                open={isEditModalOpen}
                id={selectedBooking ?? 0}
                handleClose={handleEditClose}
            />
        </div>
    );
};

export default CustomerBookingsTable;
