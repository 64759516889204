import React, { useCallback, useState } from "react";
import AppButton from "../../components/AppButton";
import AppFormikSelect, {
  AppSelectOption,
} from "../../components/AppForm/AppFormikSelect";
import { OptionalExtra } from "../../models/AppointmentType";
import { boolean } from "yup";
import { makeStyles, Theme } from "@material-ui/core";
import { BookedExtra } from "../../models/Booking";
import AppSelect from "../../components/AppForm/AppSelect";
import { useConfig } from "../../config/ConfigContext";
import { useHttpRequest } from "../../api";
import { useParams } from "react-router-dom";
import { excludeVATFromPriceIfRequired } from "../../numericalUtils";

type MakeStyleProps = {
  readonly?: boolean;
};

const useStyles = makeStyles<Theme, MakeStyleProps>((theme: Theme) => ({
  header: {
    paddingLeft: theme.spacing(0.1),
  },
  optionalExtasSelector: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(0),
    width: "100%",
    "& > *": {
      borderTop: "2px solid #dcdcdc",
    },
    "& > *:last-child": {
      borderBottom: "2px solid #dcdcdc",
    },
  },
  optionalExtra: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    [theme.breakpoints.down(600)]: {
      display: "block",
    },
  },
  mobileOptionalExtraInfo: {
    display: "flex",
  },
  mobileOptionalExtraName: {
    flexGrow: 2,
  },
  optionalExtraPrice: {
    fontWeight: "bold",
    marginRight: theme.spacing(2),
  },
  spacer: {
    flexGrow: 1,
  },
  button: {
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
}));

type Props = {
  optionalExtra: OptionalExtra;
  selectedExtras: BookedExtra[];
  setSelectedExtras: (arg: BookedExtra[]) => void;
  options: AppSelectOption[];
  currentTaxRatePercentage?: number;
};


const WidgetFormikOptionalExtrasItem: React.FC<Props> = ({
  optionalExtra,
  selectedExtras,
  setSelectedExtras,
  options,
  currentTaxRatePercentage
}) => {
  const classes = useStyles({ readonly: true });

  const isSelected = selectedExtras.some(
    (element) => element.optionalExtraId === optionalExtra.id
  );

  const selectedExtra = selectedExtras.find(
    (extra) => extra.optionalExtraId === optionalExtra.id
  );

  const handleOnChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const updatedExtra = {
      optionalExtraId: optionalExtra.id,
      quantity: parseInt(e.target.value as string),
      price: optionalExtra.price,
    };
    setSelectedExtras(
      selectedExtras.map((extra) => {
        return extra.optionalExtraId === (selectedExtra?.optionalExtraId ?? 0)
          ? updatedExtra
          : extra;
      })
    );
  };

  const handleClick = (id: number) => {
    const newBookedExtra: BookedExtra = { optionalExtraId: id, quantity: 1, price: selectedExtra?.price ?? 0 };
    if (!isSelected) {
      setSelectedExtras([...(selectedExtras ?? []), newBookedExtra]);
    } else {
      setSelectedExtras(
        selectedExtras.filter(
          (extra) => extra.optionalExtraId !== optionalExtra.id ?? []
        )
      );
    }
  };

  const handleCancelButton = () => {
    const selectedExtrasMinusCancelled = selectedExtras.filter(
      (extra) => extra.optionalExtraId !== optionalExtra.id
    );
    setSelectedExtras(selectedExtrasMinusCancelled);
  };

  return (
    <div key={optionalExtra.id} className={classes.optionalExtra}>
      {screen.width <= 599 && (
        <>
          <div className={classes.mobileOptionalExtraInfo}>
            <p className={classes.mobileOptionalExtraName}>
              <b>{optionalExtra.name}</b>
            </p>
            <p>
              {optionalExtra.price > 0 && (
                <b>£{optionalExtra.price.toFixed(2)}</b>
              )}
            </p>
          </div>
          <p>{optionalExtra.description}</p>
        </>
      )}

      {screen.width >= 600 && (
        <>
          <div>
            <p>
              <b>{optionalExtra.name}</b>
            </p>
            <p>{optionalExtra.description}</p>
          </div>
          <div className={classes.spacer} />
          {optionalExtra.price > 0 && (
            <p className={classes.optionalExtraPrice}>
              £{excludeVATFromPriceIfRequired(optionalExtra.price, currentTaxRatePercentage ?? 0).toFixed(2)}
            </p>
          )}
        </>
      )}

      <div>
        {(!isSelected && !selectedExtra) || !optionalExtra.hasQuantity ? (
          <AppButton
            className={classes.button}
            color={isSelected ? "primary" : "secondary"}
            variant={isSelected ? "contained" : "outlined"}
            gradient={false}
            onClick={() => handleClick(optionalExtra.id)}
            style={{ textTransform: "capitalize" }}
          >
            {isSelected ? "Added" : "Add"}
          </AppButton>
        ) : (
          <div style={{ display: "flex" }}>
            <AppSelect
              name="paymentStatusId"
              options={options}
              isLoading={false}
              readOnly={false}
              value={selectedExtra?.quantity ?? 1}
              onChange={(e) => handleOnChange(e)}
            />
            <div style={{ width: "2rem" }} />
            <AppButton
              color={"primary"}
              style={{
                height: "2rem",
                width: ".5rem",
                paddingLeft: "0",
                paddingRight: "0",
              }}
              onClick={handleCancelButton}
            >
              X
            </AppButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default WidgetFormikOptionalExtrasItem;
