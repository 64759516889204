import { InputAdornment } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { useField } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AppTextField from "../../../components/AppForm/AppTextField";
import { selectors } from "../../../store";
import { BookingFormStyles } from "../BookingFormStyles";
import { BookedExtra } from "../../../models/Booking";
import BookingFormOptionalExtraItem from "./BookingFormOptionalExtraItem";
import { AppSelectOption } from "../../../components/AppForm/AppFormikSelect";
import {
  AppointmentType,
  OptionalExtra,
} from "../../../models/AppointmentType";
import AppFormHelperText from "../../../components/AppForm/AppFormHelperText";
import { BookingFormValues } from "../BookingFormValues";
import { useConfig } from "../../../config/ConfigContext";
import { useAuth } from "../../../auth";

interface BookingFormOptionalExtraSelectionProps {
  readOnly?: boolean;
  initialExtras: BookedExtra[];
  manualOverride: boolean;
  initialValues: BookingFormValues;
  setManualOverride: (value: boolean) => void;
}

const BookingFormOptionalExtraSelection: React.FC<BookingFormOptionalExtraSelectionProps> =
  ({ readOnly, initialExtras, manualOverride, setManualOverride, initialValues }) => {
    const classes = BookingFormStyles();

    const vendorConfig = useConfig();
    const { role } = useAuth();
    const priceHiddenFromResource = (role === "resource") && vendorConfig.hidePricesFromResource;

    const [searchTerm, setSearchTerm] = useState("");
    const [
      { value: overriddenTotalPrice },
      ,
      { setValue: setOverriddenTotalPrice },
    ] = useField<number | undefined>("overriddenTotalPrice");

    const [
      { value: selectedOptionalExtras },
      meta,
      { setValue: setSelectedOptionalExtras },
    ] = useField<BookedExtra[]>("firstJobOptionalExtras");

    useEffect(() => {
      if (initialExtras.length > 0) {
        setSelectedOptionalExtras(initialExtras);
      }
    }, []);

    const [appointmentTypeInputProps] = useField<number>(
      "firstJobAppointmentTypeId"
    );

    const appointmentTypes = useSelector(
      selectors.appointmentTypes.allAppointmentTypes
    );

    const availableOptionalExtras: OptionalExtra[] =
      appointmentTypes
        .find(
          (appointmentType) =>
            appointmentType.id == appointmentTypeInputProps.value
        )
        ?.availableOptionalExtras.filter((optionalExtra) =>
          optionalExtra.name.toLowerCase().includes(searchTerm.toLowerCase())
        ) || [];

    type availableExtrasAndBasePrice = {
      optionalExtra: OptionalExtra;
      basePrice: number;
    };

    const availableOptionalExtrasAndBasePrice = (
      appointmentTypes: AppointmentType[],
      searchTerm?: string
    ): availableExtrasAndBasePrice[] => {
      const appointmentType = appointmentTypes.find(
        (appointmentType) =>
          appointmentType.id === appointmentTypeInputProps.value
      );

      return (
        appointmentType?.availableOptionalExtras ?? ([] as OptionalExtra[])
      )
        .filter((appointmentType) =>
          appointmentType.name
            .toLowerCase()
            .includes(searchTerm?.toLowerCase() ?? "")
        )
        .map((extra) => ({
          optionalExtra: extra,
          basePrice: appointmentType?.price || 0,
        }));
    };

    if (
      appointmentTypeInputProps.value === -1 ||
      (searchTerm === "" && availableOptionalExtrasAndBasePrice.length === 0)
    )
      return null;

    const numericOptions = (optionsNumber: number): AppSelectOption[] => {
      const numbersArray: number[] = Array.from(
        Array(optionsNumber),
        (_, i) => i++
      );
      return numbersArray.map((number) => ({
        value: number + 1,
        label: (number + 1).toString(),
      }));
    };

    const handleManualChange = () => {
      setManualOverride(!manualOverride);
    };

    const handleItemChange = (extras: BookedExtra[]): void => {
      setOverriddenTotalPrice(undefined);
      setSelectedOptionalExtras(extras);
    };

    const isBookingComplete = initialValues.bookingStatus > 0;
    const vatRate = isBookingComplete ? (initialValues.taxRate ?? 0) : vendorConfig.currentTaxRatePercentage;

    return (
      <>
        <br />
        <h3 className={classes.stepContainerTitle}>Optional extras{priceHiddenFromResource ? <></> : (vatRate > 0 && ` (ex. VAT)`)}</h3>
        <div className={classes.optionalExtraSearchContainer}>
          <AppTextField
            placeholder="Search extra name to filter list"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.currentTarget.value)}
            readonly={appointmentTypes.length === 0}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={`${classes.optionalExtraOptionList}`}>
          {availableOptionalExtrasAndBasePrice(
            appointmentTypes,
            searchTerm
          ).map(({ optionalExtra, basePrice }) => {
            const isChecked = selectedOptionalExtras.some(
              (extra) => extra.optionalExtraId === optionalExtra.id
            );
            return (
              <BookingFormOptionalExtraItem
                optionalExtra={optionalExtra}
                key={optionalExtra.id}
                options={numericOptions(10)}
                basePrice={basePrice}
                availableExtras={availableOptionalExtras}
                handleItemChange={handleItemChange}
                checked={isChecked}
                initialValues={initialValues}
              />
            );
          })}
          {availableOptionalExtrasAndBasePrice.length === 0 && (
            <div>
              No optional extras found matching <b>{searchTerm}</b>
            </div>
          )}
          <AppFormHelperText
            error={!!(meta.touched && meta.error)}
            helperText={meta.error}
          />
        </div>
      </>
    );
  };

export default BookingFormOptionalExtraSelection;
