import { FormLabel, makeStyles } from "@material-ui/core";
import { CirclePicker, ColorResult, Color } from "react-color";

export interface AppColourPickerProps {
  name: string;
  label?: string;
  value: Color;
  colours?: string[];
  onChange: (colour: string) => void;
  error?: boolean;
  helperText?: string;
}

const useStyles = makeStyles((theme) => ({
  picker: {
    marginTop: theme.spacing(2),
  },
  error: {
    color: theme.palette.secondaryRed.main,
  },
}));

const AppColourPicker: React.FC<AppColourPickerProps> = ({
  label,
  value,
  colours,
  onChange,
  error,
  helperText,
}) => {
  const classes = useStyles();

  return (
    <>
      <FormLabel>{label}</FormLabel>
      <div className={classes.picker}>
        <CirclePicker
          colors={colours}
          color={value}
          onChange={(colour: ColorResult) => onChange(colour.hex)}
        />
      </div>
      {error && <p className={classes.error}>{helperText}</p>}
    </>
  );
};

export default AppColourPicker;
