import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import {
  getAllAppointmentTypes,
  getAppointmentTypesByPostcode,
} from "../api/appointmentTypes";
import { getAppointmentTypesByPostcodeWidget } from "../api/widget";
import { AppointmentType } from "../models/AppointmentType";
import { createHttpClientThunk } from "./common/createHttpClientThunk";

const sliceName = "appointmentTypes";

const entityAdapter = createEntityAdapter<AppointmentType>();

interface SliceState {
  entityState: EntityState<AppointmentType>;
  isFetching: boolean;
}

const fetchAsyncThunk = createHttpClientThunk(
  `${sliceName}/fetchByPostcode`,
  (postcode: string) => {
    if (postcode.length > 0) {
      return getAppointmentTypesByPostcode(postcode);
    }

    return getAllAppointmentTypes();
  }
);

const fetchAsyncThunkPublic = createHttpClientThunk(
  `${sliceName}/fetchByPostcodePublic`,
  (arg: { id: number; postcode: string }) => {
    return getAppointmentTypesByPostcodeWidget(arg);
  }
);

const initialState: SliceState = {
  entityState: entityAdapter.getInitialState(),
  isFetching: true,
};

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAsyncThunk.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchAsyncThunk.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchAsyncThunk.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      entityAdapter.removeAll(state.entityState);
      entityAdapter.setAll(state.entityState, payload.content);
    });
    builder.addCase(fetchAsyncThunkPublic.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchAsyncThunkPublic.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchAsyncThunkPublic.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      entityAdapter.removeAll(state.entityState);
      entityAdapter.setAll(state.entityState, payload.content);
    });
  },
});

export const { name, reducer } = slice;

export const actions = {
  fetchAll: (postcode = "") => fetchAsyncThunk(postcode),
  fetchPublic: (arg = { postcode: "", id: 1 }) => fetchAsyncThunkPublic(arg),
};

type RootReducerState = {
  [sliceName]: SliceState;
};

const selectSliceState = (state: RootReducerState) => state[sliceName];
const entitySelectors = entityAdapter.getSelectors();

export const selectors = {
  isLoading: createSelector(selectSliceState, (state) => state.isFetching),
  allAppointmentTypes: createSelector(selectSliceState, (state) =>
    entitySelectors.selectAll(state.entityState)
  ),
  makeCalculateTotalPrice: createSelector(
    selectSliceState,
    (_: RootReducerState, appointmentTypeId: number) => appointmentTypeId,
    (_: RootReducerState, _1: number, optionalExtraIds: number[]) =>
      optionalExtraIds,
    (state, appointmentTypeId, optionalExtraIds) => {
      const appointmentType = entitySelectors.selectById(
        state.entityState,
        appointmentTypeId
      );
      let totalPrice = appointmentType ? appointmentType.price : 0;

      optionalExtraIds.forEach((selectedOptionalExtraId) => {
        const optionalExtra = appointmentType?.availableOptionalExtras.find(
          (optionalExtra) => optionalExtra.id === selectedOptionalExtraId
        );

        if (optionalExtra) {
          totalPrice += optionalExtra.price;
        }
      });

      return totalPrice;
    }
  ),
};

export const useTotalPrice = (
  appointmentTypeId: number,
  optionalExtraIds: number[]
): number =>
  useSelector((state: RootReducerState) =>
    selectors.makeCalculateTotalPrice(
      state,
      appointmentTypeId,
      optionalExtraIds
    )
  );
