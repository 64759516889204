import { useParams } from "react-router";
import { AppCalendar } from "../components/AppCalendar";
import { CALENDAR_VIEWS } from "../components/AppCalendar/AppCalendarContext";
import { useConfig } from "../config/ConfigContext";

interface SingleResourceCalendarParams {
  id: string;
}

const SingleResourceCalendarPage: React.FC = () => {
  const { id } = useParams<SingleResourceCalendarParams>();
  const { isFresh } = useConfig();

  return (
    <>
      <AppCalendar
        canEditEvent
        canCreateEvent={!isFresh}
        canAddTimeOff
        initialView={CALENDAR_VIEWS.WEEK_VIEW}
        allowedViews={[CALENDAR_VIEWS.WEEK_VIEW]}
        initialResourceId={parseInt(id)}
      />
    </>
  );
};

export default SingleResourceCalendarPage;
