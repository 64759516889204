import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  overviewContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
    zIndex: 1,
    overflowY: "auto",
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
    },
  },
  opaqueHeader: {
    opacity: 0.45,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
  },
  detailOverview: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: theme.spacing(2),
  },
  overviewHeader: {
    marginTop: theme.spacing(3),
    position: "relative",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    flex: "none",
    "& a": {
      marginLeft: "auto",
    },
  },
  overviewSubheader: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(-1),
    flex: "none",
    "& a": {
      marginLeft: "auto",
    },
  },
  overviewFooter: {
    display: "flex-start",
    justifyContent: "flex-start",
    marginTop: theme.spacing(2),
  },
  overviewContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  overviewCard: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    display: "flex",
    marginTop: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  overviewCardTop: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    display: "flex",
    marginTop: theme.spacing(1),
  },
  optionalExtraList: {
    listStyleType: "none",
    padding: 0,
    margin: 0,
    "& li": {
      margin: "4px 0",
    },
  },
  ecoCard: {
    display: "flex",
    paddingTop: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    "& div": {
      padding: theme.spacing(2),
      backgroundColor: "#E7F5C8",
    },
  },
  ecoIcon: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
    "& img": {
      width: 24,
      height: "auto",
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
      marginLeft: "auto",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  furtherInfoCard: {
    flex: 1,
  },
  flexGrowSection: {
    flex: "1",
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  infoBlock: {
    width: "100%",
    "& p:first-of-type": {
      marginTop: 0,
    },
    "& p:nth-of-type(2)": {
      marginBottom: 0,
    },
  },
  infoBlockLineBreak: {
    whiteSpace: "break-spaces",
    width: "100%",
    "& p:first-of-type": {
      marginTop: 0,
    },
    "& p:nth-of-type(2)": {
      marginBottom: 0,
    },
    "& h5": {
      paddingBottom: theme.spacing(1),
    },
  },
  textAreaContainer: {
    // marginBottom: theme.spacing(4),
    backgroundColor: theme.palette.backgrounds.grey,
    "& textarea": {
      padding: theme.spacing(1),
    },
  },
  contentRight: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
  },
  chipContent: {
    marginLeft: "auto",
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    "& > *": {
      marginBottom: theme.spacing(1),
      "&:last-of-type": {
        marginBottom: 0,
      },
    },
  },
  keyInfoBlock: {
    flex: "none",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    display: "flex",
    alignItems: "center",
  },
  price: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.h4.fontSize,
    },
  },
  resource: {
    display: "flex",
    alignItems: "center",
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
      paddingLeft: theme.spacing(2),
      borderLeft: `2px solid ${theme.palette.grey[300]}`,
    },
    "& svg": {
      marginRight: theme.spacing(1),
      opacity: 0.7,
    },
  },
  cancel: {
    color: theme.palette.secondaryRed.main,
    background: "transparent",
    border: "1px transparent",
    borderRadius: "2px",
    padding: theme.spacing(1.5),
    textTransform: "uppercase",
    textDecorationThickness: "4px",
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: "auto",
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.secondaryRed.main,
      color: "white",
    },
  },
  editIcon: {
    alignSelf: "center",
    "& svg": {
      fill: "url(#BrandGradient)",
    },
  },
  overviewCloseBtn: {
    position: "absolute",
    display: "flex",
    alignItems: "left",
    top: theme.spacing(1),
    left: theme.spacing(2),
    marginBottom: theme.spacing(1),
    zIndex: 1,
  },
  actionBtn: {
    borderRadius: 0,
    marginRight: theme.spacing(2),
  },
  contactLink: {
    display: "flex",
    alignItems: "center",
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "none",
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  recurringBookingListItem: {
    display: "flex",
    margin: "0.33rem 0",
    "& p:first-child": {
      display: "flex",
      justifyContent: "space-between",
      width: "auto",
      minWidth: "45%",
      paddingRight: theme.spacing(2),
    },
    "& p": {
      display: "inline-block",
      padding: "0",
      margin: "0",
    },
    // "& span": {
    //   display: "flex",
    //   justifyContent: "center",
    // }
    // flexDirection: "column",
  },
  tagsListItem: {
    margin: "0.33rem 0",
  },
  mobileAccordion: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  accordion: {
    boxShadow: "none",
    backgroundColor: "transparent",
    "&:before": {
      display: "none",
    },
  },
  dateBlock: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  accordionHeader: {
    paddingLeft: 0,
    flexFlow: "row-reverse",
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h6.fontSize,
  },
  accordionDetails: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },
  accordionDetailsInner: {
    width: "100%",
  },
  imageUnitsContainer: {
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "flex-start",
    // width: "100%",
    // height: "100%",
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
}));
