import {
  createPreconfiguredAppFormikDatePicker,
  createPreconfiguredAppFormikTextField,
} from "../../components/AppForm";

export const WidgetBookingFields = {
  date: createPreconfiguredAppFormikDatePicker({
    name: "date",
    label: "Date",
    widget: true,
  }),
  addressLine1: createPreconfiguredAppFormikTextField({
    name: "addressLine1",
    label: "Address Line 1",
  }),
  addressLine2: createPreconfiguredAppFormikTextField({
    name: "addressLine2",
    label: "Address Line 2",
  }),
  addressLine3: createPreconfiguredAppFormikTextField({
    name: "addressLine3",
    label: "Address Line 3",
  }),
  town: createPreconfiguredAppFormikTextField({ name: "town", label: "Town" }),
  postcode: createPreconfiguredAppFormikTextField({
    name: "postcode",
    label: "Postcode",
  }),
  firstName: createPreconfiguredAppFormikTextField({
    name: "firstName",
    label: "First name",
  }),
  lastName: createPreconfiguredAppFormikTextField({
    name: "lastName",
    label: "Last name",
  }),
  company: createPreconfiguredAppFormikTextField({
    name: "company",
    label: "Company name (optional)",
  }),
  email: createPreconfiguredAppFormikTextField({
    name: "email",
    label: "Email",
  }),
  phoneNumber: createPreconfiguredAppFormikTextField({
    name: "phoneNumber",
    label: "Phone Number",
  }),
};
