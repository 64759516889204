import { useTheme } from "@material-ui/core";
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from "@material-ui/pickers";
import { useStyles } from "../AppFormikDatePicker/AppFormikDatePickerStyles";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { useField, useFormikContext } from "formik";
import { FormikInputPropsKeys } from "../AppForm/common";

export interface AppFormikDatePickerProps
  extends Omit<
    KeyboardDatePickerProps,
    | FormikInputPropsKeys
    | "helperText"
    | "error"
    | "variant"
    | "InputLabelProps"
    | "InputProps"
    | "style"
  > {
  name: string;
  readonly?: boolean;
  widget?: boolean;
  onChangeCallback?: (date: ParsableDate) => void;
}

const AppFormikDatePicker: React.FC<AppFormikDatePickerProps> = ({
  name,
  fullWidth = true,
  disabled,
  readonly,
  keyboardIcon,
  widget,
  onChangeCallback,
  ...restOfProps
}) => {
  const theme = useTheme();
  const { isSubmitting } = useFormikContext();
  const [fieldProps, fieldMeta, { setValue }] = useField<ParsableDate>(name);
  const isError = fieldMeta.touched && !!fieldMeta.error;
  const helperText = fieldMeta.touched ? fieldMeta.error : undefined;

  const format = "dd/MM/yyyy";

  const handleChange = (date: MaterialUiPickersDate) => {
    setValue(date);
    onChangeCallback && onChangeCallback(date);
  };

  const variantStyle = widget ? "static" : "dialog";
  const classNames = useStyles();
  const onKeyDown = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
 };

  return (
    <div className={classNames.calendarContainer}>
      <KeyboardDatePicker
        {...restOfProps}
        {...fieldProps}
        onChange={handleChange}
        value={
          readonly && !fieldProps.value ? new Date() : fieldProps.value || null
        }
        format={format}
        variant={variantStyle}
        placeholder="dd/mm/yyyy"
        helperText={helperText}
        error={isError}
        disabled={readonly || isSubmitting || disabled}
        autoOk
        fullWidth={fullWidth}
        InputLabelProps={{
          style: {
            color: readonly ? theme.palette.text.secondary : undefined,
          },
        }}
        InputProps={{
          disableUnderline: readonly || undefined,
          style: {
            color: readonly ? "currentColor" : undefined,
          },
        }}
        keyboardIcon={
          keyboardIcon ? keyboardIcon : readonly ? false : undefined
        }
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default AppFormikDatePicker;
