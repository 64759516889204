import { useState } from "react";
import CreateResourceModal from "../resources/CreateResourceModal";
import ResourcesTable from "../resources/ResourcesTable";
import PageHeader from "../components/PageHeader";
import { PageContainer } from "../components/MainLayout";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "../store";
import { AppSearchBox } from "../components/AppSearchBox";

const useStyles = makeStyles({
  header: {},
  search: {
    width: "100%",
  },
});

const ServiceProvidersPage: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [createResourceModal, setCreateResourceModal] = useState(false);
  const searchTerm = useSelector(selectors.resources.selectSearchTerm);

  const handleSearchChange = (value: string) => {
    dispatch(actions.resources.setSearchTerm(value));
  };

  const handleSubmitSearch = () => {
    dispatch(actions.resources.doSearch());
  };

  return (
    <PageContainer>
      <PageHeader
        title="Mobile Workforce"
        action={() => setCreateResourceModal(true)}
        actionLabel="Create new mobile worker"
      >
        <AppSearchBox
          className={classes.search}
          placeholder={"Search for a mobile worker..."}
          onSubmit={handleSubmitSearch}
          onChange={handleSearchChange}
          value={searchTerm}
        ></AppSearchBox>
      </PageHeader>
      <ResourcesTable />
      <CreateResourceModal
        open={createResourceModal}
        handleClose={() => setCreateResourceModal(false)}
      />
    </PageContainer>
  );
};

export default ServiceProvidersPage;
