import { makeStyles } from "@material-ui/core";
import { setMinutes } from "date-fns";
import startOfDay from "date-fns/startOfDay";
import { VendorOpeningHours } from "../api/models/Configuration";
import { days, formatTimeOnly } from "../dateFormatters";

interface MicrositeOpeningHoursProps {
  openingHours: VendorOpeningHours[];
}

interface MicrositeOpeningHoursRowProps {
  dayOfWeekName: string;
  time: string;
}

const useStyles = makeStyles(() => ({
  openingHours: {
    display: "flex",
    justifyContent: "space-between",
    margin: ".5rem 0",
  },
}));

const MicrositeOpeningHoursRow: React.FC<MicrositeOpeningHoursRowProps> = ({
  dayOfWeekName,
  time,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.openingHours}>
      <span>{dayOfWeekName}</span>
      <span>{time}</span>
    </div>
  );
};

const MicrositeOpeningHours: React.FC<MicrositeOpeningHoursProps> = ({
  openingHours,
}) => {
  const dayIdList = [1, 2, 3, 4, 5, 6, 0];

  const openingTimesList = dayIdList.map((dayId) => {
    const dayOfWeek = openingHours.find((day) => day.dayOfweekId === dayId);

    if (dayOfWeek) {
      const open = formatTimeOnly(
        setMinutes(
          startOfDay(new Date()),
          dayOfWeek.openingTimeMinutesPastMidnight
        )
      );
      const close = formatTimeOnly(
        setMinutes(
          startOfDay(new Date()),
          dayOfWeek.closingTimeMinutesPastMidnight
        )
      );
      const openingTime = `${open} - ${close}`;

      return (
        <MicrositeOpeningHoursRow
          dayOfWeekName={dayOfWeek.dayOfWeekName}
          time={openingTime}
          key={dayId}
        />
      );
    }

    return (
      <MicrositeOpeningHoursRow
        dayOfWeekName={days[dayId]}
        time="Closed"
        key={dayId}
      />
    );
  });

  return <>{openingTimesList}</>;
};

export default MicrositeOpeningHours;
