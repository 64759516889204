import React, { useEffect, useReducer, useRef, useState } from "react";
import AppFormikDatePicker from "../../../components/AppFormikDatePicker/AppFormikDatePicker";
import AppSelect, {
  AppSelectOption,
} from "../../../components/AppForm/AppSelect";
import { Button, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import AppLoader from "../../../components/AppLoader";
import {
  formatTimeOnly,
  formatTimeStringToMinutesAfterMidnight,
  toUTCISOString,
} from "../../../dateFormatters";
import { useAuth } from "../../../auth";
import {
  getAllResources,
  getAvailableResources,
  getResourceMe,
} from "../../../api/resources";
import { useField, useFormikContext } from "formik";
import { ResourceSummary } from "../../../models/Resource";
import {
  ApiBookableSlotDto,
  ApiBookableSlotsParameters,
} from "../../../api/models/BookableSlots";
import {
  getBookableSlots,
  getBookableSlotsResource,
} from "../../../api/bookableSlots";
import { useSelector } from "react-redux";
import { selectors } from "../../../store";
import { BookingFormValues } from "../BookingFormValues";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { BookingFields } from "../BookingFormFields";
import AppFormError from "../../../components/AppForm/AppFormError";
import BookingTimeSelect from "./BookingTimeSelect";
import { BookingFormStyles } from "../BookingFormStyles";
import { splitRecommendedBookingSlotFromBookingSlots } from "./BookingFormSlotSelectionUtil";
import ecoIcon from "../../../eco-icon.svg";
import AppFormHelperText from "../../../components/AppForm/AppFormHelperText";
import InformationTextBox from "../../../components/InformationTextBox";
import { BookedExtra, Jobs, OptionalExtra } from "../../../models/Booking";
import { isBefore } from "date-fns/esm";
import { startOfDay } from "date-fns";
import { Tag } from "../../../models/Tag";
import {parseOptionalExtras} from "../../utilities";

interface FormikValues {
  date: string;
  customerAddressId: number;
  firstJobAppointmentTypeId: number;
  firstJobOptionalExtras: OptionalExtra[];
  validBookableDates: boolean;
  tags?: Tag[];
  jobs: Jobs[];
}

interface BookableSlotsProps {
  readOnly?: boolean;
  isEdit?: boolean;
  manualOverride: boolean;
  initialValues: BookingFormValues;
  setManualOverride: (value: boolean) => void;
}

interface SelectedSlot {
  index: number;
  timeSlot: ApiBookableSlotDto;
}

interface BookableDates {
  from: string;
  until: string | undefined;
}

type BookableSlotsState = {
  resources: ResourceSummary[];
  bookableDates: BookableDates | null;
  availableSlots: ApiBookableSlotDto[];
  selectedSlot: SelectedSlot | null;
  isSearching: boolean;
  manualResourceVisible: boolean;
  searchPerformed: boolean;
};

const bookableSlotsInitialState: BookableSlotsState = {
  resources: [],
  bookableDates: null,
  availableSlots: [],
  selectedSlot: null,
  isSearching: false,
  manualResourceVisible: false,
  searchPerformed: false,
};

enum ActionType {
  SET_RESOURCES = "set_resources",
  SET_BOOKABLE_DATES = "set_bookable_dates",
  SET_AVAILABLE_SLOTS = "set_available_slots",
  SET_SELECTED_SLOT = "set_selected_slot",
  SET_MANUAL_RESOURCE_VISIBLE = "set_manual_resource_visible",
  TOGGLE_SEARCH_PERFORMED = "set_search_performed",
  TOGGLE_IS_SEARCHING = "set_is_searching",
}

const BookingFormSlotSelection: React.FC<BookableSlotsProps> = ({
  setManualOverride,
  isEdit,
  readOnly,
  manualOverride,
}) => {
  const isMounted = useRef(false);
  const TODAYS_DATE = startOfDay(new Date());
  const classes = BookingFormStyles();
  const { role } = useAuth();
  const { values } = useFormikContext<FormikValues>();
  const [{ value: start }, , { setValue: setStart }] =
    useField<number>("start");
  const [{ value: end }, , { setValue: setEnd }] = useField<number>("end");
  const [{ value: selectedDate }, , { setValue: setSelectedDate }] =
    useField("date");

  const isFetchResourcesRequired = !isBefore(selectedDate, TODAYS_DATE);

  const [
    { value: resourceId },
    ,
    { setValue: setResourceId, setError: setResourceIdError },
  ] = useField<number | undefined>("resourceId");
  const [
    ,
    validBookableDatesMeta,
    { setValue: setValidBookableDates, setError: setValidBookableDatesError },
  ] = useField<boolean>("validBookableDates");
  const [, hasSelectedTimeSlotMeta, { setValue: setHasSelectedTimeSlot }] =
    useField<boolean>("hasSelectedTimeSlot");
  const [
    { value: requiresOptionalExtra },
    fieldMeta,
    { setValue: setRequiresOptionalExtra },
  ] = useField("requiresOptionalExtra");

  const [
    { value: selectedOptionalExtras },
    meta,
    { setValue: setSelectedOptionalExtras },
  ] = useField<BookedExtra[]>("firstJobOptionalExtras");

  const [, , { setValue: setRecurringBookableSlots }] = useField(
    "recurringBookableSlots"
  );

  const [, , recurringBookableSlotsHelpers] = useField(
    "recurringBookableSlots"
  );

  const [forceManualOverride, setForceManualOverride] =
    useState<boolean>(false);

  type BookableSlotsAction =
    | { type: ActionType.SET_RESOURCES; payload: Required<ResourceSummary[]> }
    | { type: ActionType.SET_BOOKABLE_DATES; payload: Required<BookableDates> }
    | {
        type: ActionType.SET_AVAILABLE_SLOTS;
        payload: Required<ApiBookableSlotDto[]>;
      }
    | { type: ActionType.SET_SELECTED_SLOT; payload: SelectedSlot | null }
    | {
        type: ActionType.SET_MANUAL_RESOURCE_VISIBLE;
        payload: Required<boolean>;
      }
    | { type: ActionType.TOGGLE_SEARCH_PERFORMED }
    | { type: ActionType.TOGGLE_IS_SEARCHING };

  const bookableSlotsReducer = (
    state: BookableSlotsState,
    action: BookableSlotsAction
  ) => {
    switch (action.type) {
      case ActionType.SET_RESOURCES: {
        return { ...state, resources: action.payload };
      }
      case ActionType.SET_BOOKABLE_DATES: {
        return { ...state, bookableDates: action.payload };
      }
      case ActionType.SET_AVAILABLE_SLOTS: {
        return { ...state, availableSlots: action.payload };
      }
      case ActionType.SET_SELECTED_SLOT: {
        return { ...state, selectedSlot: action.payload };
      }
      case ActionType.SET_MANUAL_RESOURCE_VISIBLE: {
        return { ...state, manualResourceVisible: action.payload };
      }
      case ActionType.TOGGLE_SEARCH_PERFORMED: {
        return { ...state, searchPerformed: !state.searchPerformed };
      }
      case ActionType.TOGGLE_IS_SEARCHING: {
        return { ...state, isSearching: !state.isSearching };
      }
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(
    bookableSlotsReducer,
    bookableSlotsInitialState
  );

  const customerAddress = useSelector(
    selectors.customers.addressById(values.customerAddressId)
  );

  const bookableSlotsMessage = state.searchPerformed
    ? `No slots available on this date${
        resourceId === 0 ? "" : " for selected mobile worker"
      }.`
    : "No search performed.";

  const filterAvailableResourcesByDate = (
    resources: ResourceSummary[],
    selectedDate: ParsableDate
  ) => {
    return resources.filter((res) => {
      const bookableFrom =
        res.bookableFrom && new Date(res.bookableFrom).setHours(0, 0, 0, 0);
      const bookableUntil =
        res.bookableUntil && new Date(res.bookableUntil).setHours(0, 0, 0, 0);
      const selectedDateMidnight =
        selectedDate && new Date(selectedDate as string).setHours(0, 0, 0, 0);

      if (!selectedDateMidnight || res.id === resourceId) return true;
      if (bookableUntil && bookableFrom) {
        return (
          bookableUntil >= selectedDateMidnight &&
          bookableFrom <= selectedDateMidnight
        );
      }
      if (bookableFrom) return bookableFrom <= selectedDateMidnight;
      if (bookableUntil) return bookableUntil >= selectedDateMidnight;
      return true;
    });
  };

  const fetchAvailableResources = async (isManual: boolean) => {
    if (isFetchResourcesRequired === false) {
      if (!resourceId) return;

      const result = await getAllResources();

      if (result.isError == false) {
        const resourceList = result.content.content;
        dispatch({ type: ActionType.SET_RESOURCES, payload: resourceList });
      }

      return;
    }

    const result = await (isManual
      ? getAllResources()
      : getAvailableResources(basketStoreServices.map(service => service.appointmentTypeId).join(",")));

    if (!result.isError) {
      const resContent = result.content.content;
      const resourcesList = filterAvailableResourcesByDate(
        resContent,
        selectedDate ?? null
      );
      dispatch({ type: ActionType.SET_RESOURCES, payload: resourcesList });
    }
  };

  const fetchCurrentResource = async () => {
    const result = await getResourceMe();

    if (!result.isError) {
      const resContent = result.content;
      dispatch({
        type: ActionType.SET_RESOURCES,
        payload: [resContent as ResourceSummary],
      });
    }
  };

  const formatOptions = (
    resources: ResourceSummary[],
    removeAnyOption?: boolean
  ): AppSelectOption[] => {
    const anyOption = { value: 0, label: "Any" };
    const resourcesOptions = resources.map((resSummary) => ({
      value: resSummary.id,
      label: resSummary.name,
    }));

    if (removeAnyOption) {
      return [...resourcesOptions];
    }

    return [anyOption, ...resourcesOptions];
  };

  const createBookableDates = (
    resource: ResourceSummary | undefined
  ): BookableDates => {
    const currentDate = new Date().toISOString();
    if (!resource) {
      return { from: currentDate, until: undefined };
    }
    const fromDate =
      !resource.bookableFrom || resource.bookableFrom < currentDate
        ? currentDate
        : resource.bookableFrom;
    return { from: fromDate, until: resource.bookableUntil };
  };

  const isBookableDatesValid = (
    resource: ResourceSummary | undefined,
    date: ParsableDate
  ): boolean => {
    if (!resource) return true;

    const { bookableFrom, bookableUntil } = resource;
    const bookableFromMidnight =
      bookableFrom && new Date(bookableFrom).setHours(0, 0, 0, 0);
    const bookableUntilMidnight =
      bookableUntil && new Date(bookableUntil).setHours(0, 0, 0, 0);
    const dateMidnight = new Date(date as string).setHours(0, 0, 0, 0);

    if (
      bookableFromMidnight &&
      bookableUntilMidnight &&
      bookableFromMidnight <= dateMidnight &&
      bookableUntilMidnight >= dateMidnight
    ) {
      return true;
    } else if (bookableFromMidnight && bookableFromMidnight <= dateMidnight)
      return true;
    else if (!bookableFromMidnight) return true;
    return false;
  };

  const handleSelectChange = (e: React.FormEvent<{ value: unknown }>) => {
    const element = e.target as HTMLSelectElement;
    const idValue = parseInt(element.value);

    if (state.searchPerformed) {
      dispatch({ type: ActionType.TOGGLE_SEARCH_PERFORMED });
    }

    setResourceId(idValue);
  };

  const basketStoreServices = useSelector(selectors.basket.services);

  const handleSearchClick = async () => {
    if (!customerAddress) return;

    if (isFetchResourcesRequired === false) {
      return;
    }

    dispatch({ type: ActionType.TOGGLE_IS_SEARCHING });
    dispatch({ type: ActionType.SET_SELECTED_SLOT, payload: null });

    const params: ApiBookableSlotsParameters = {
      date: toUTCISOString(new Date(values.date)),
      customerAddressId: customerAddress.id,
      appointmentTypeId: values.firstJobAppointmentTypeId,
      resourceId: resourceId || null,
      postcode: customerAddress.postcode,
      optionalExtras: parseOptionalExtras(values.firstJobOptionalExtras),
      jobs: basketStoreServices,
      tagIds: values.tags,
    };
    const response =
      role === "admin"
        ? await getBookableSlots(params)
        : await getBookableSlotsResource(params);

    if (!response.isError) {
      dispatch({
        type: ActionType.SET_AVAILABLE_SLOTS,
        payload: response.content.content,
      });
    }

    dispatch({ type: ActionType.TOGGLE_IS_SEARCHING });

    if (!state.searchPerformed) {
      dispatch({ type: ActionType.TOGGLE_SEARCH_PERFORMED });
    }
  };

  const handleSlotClick = (timeSlot: ApiBookableSlotDto, index: number) => {
    setManualOverride(false);
    const newSelectedSlot = {
      index,
      timeSlot,
    };
    dispatch({ type: ActionType.SET_SELECTED_SLOT, payload: newSelectedSlot });
    const timeSlotAsTimeNumber = formatTimeStringToMinutesAfterMidnight(
      formatTimeOnly(new Date(timeSlot.start), false)
    );
    const endTime = timeSlotAsTimeNumber + timeSlot.durationMinutes;
    setStart(timeSlotAsTimeNumber, false);
    setEnd(endTime, false);
    setResourceId(timeSlot.resourceId);
  };

  const handleCheckClick = () => {
    setResourceIdError(undefined);
    recurringBookableSlotsHelpers.setValue([]);

    if (isFetchResourcesRequired === false) {
      setSelectedDate(TODAYS_DATE);
    }

    setManualOverride(!manualOverride);
  };

  useEffect(() => {
    // If editing existing booking display as override with pre-selected start and end dates,
    // allowing user to continue without having to select new ones.
    setTimeout(() => {
      if (readOnly && start && end) {
        setManualOverride(true);
        if (role === "admin") {
          fetchAvailableResources(true);
        }
      }
    }, 500);
  }, []);

  useEffect(() => {
    if (role === "admin") {
      fetchAvailableResources(manualOverride ?? false);
    } else {
      fetchCurrentResource();
    }
  }, [role, selectedDate, manualOverride]);

  useEffect(() => {
    if (resourceId !== -1) {
      handleSearchClick();
    }
  }, [selectedDate]);

  useEffect(() => {
    setHasSelectedTimeSlot(state.selectedSlot !== null || manualOverride);

    if ((!resourceId || resourceId === -1) && !manualOverride) setResourceId(0);

    if (resourceId === 0 && manualOverride) setResourceId(-1);
  }, [state.selectedSlot, manualOverride]);

  useEffect(() => {
    if ((!resourceId || resourceId === -1) && !manualOverride) setResourceId(0);

    // Check if selected resource has valid date range according to their trading dates.
    setValidBookableDatesError(false);
    const isValidDates = isBookableDatesValid(
      state.resources.find((res) => res.id === resourceId),
      selectedDate
    );
    setValidBookableDates(isValidDates);

    // When new resource id selected (including initial) produce and save new bookable dates.
    const resource = state.resources.find((res) => res.id === resourceId);
    const bookableDates: BookableDates = createBookableDates(resource);
    dispatch({ type: ActionType.SET_BOOKABLE_DATES, payload: bookableDates });
  }, [state.resources, resourceId]);

  useEffect(() => {
    // If the selected times do not match those of the selected slot times, then deselect slot (means user has chosen custom times)
    const selectedSlotStart = state.selectedSlot
      ? formatTimeStringToMinutesAfterMidnight(
          formatTimeOnly(new Date(state.selectedSlot?.timeSlot.start), false)
        )
      : null;
    const selectedSlotEnd =
      state.selectedSlot && selectedSlotStart
        ? selectedSlotStart + state.selectedSlot?.timeSlot.durationMinutes
        : null;
    if (
      (selectedSlotStart && selectedSlotStart !== start) ||
      selectedSlotEnd !== end
    ) {
      dispatch({ type: ActionType.SET_SELECTED_SLOT, payload: null });
    }
  }, [start, end]);

  const {
    bookingSlotWithMostTravelMinutesSaved: recommendedBookingSlot,
    regularBookingSlots,
  } = splitRecommendedBookingSlotFromBookingSlots(state.availableSlots);

  const resourceMinuteIncrement = state.resources.find(
    (r) => r.id === resourceId
  )?.timeSlotGranularityMinutes;

  useEffect(() => {
    if (requiresOptionalExtra && selectedOptionalExtras.length === 0) {
      setForceManualOverride(true);
      setManualOverride(true);
    } else {
      setForceManualOverride(false);
    }
  }, [requiresOptionalExtra, selectedOptionalExtras]);

  useEffect(() => {
    if (isMounted.current) {
      setRecurringBookableSlots([]);
    }

    isMounted.current = true;
  }, [selectedDate, start]);

  return (
    <>
      <InformationTextBox>
        Please select a date and mobile worker:
      </InformationTextBox>
      <div className={classes.stepContainer}>
        <h3 className={classes.stepContainerTitle}>Date</h3>
        <div className={classes.optionalExtraSearchContainer}>
          <AppFormikDatePicker
            label=""
            name="date"
            widget={false}
            minDate={
              manualOverride
                ? new Date("2010-01-01")
                : state.bookableDates?.from
            }
            maxDate={state.bookableDates?.until ?? new Date("2100-01-01")}
            autoFocus
          />
        </div>
        <FormControlLabel
          control={
            <Checkbox
              name="manualResourceVisible"
              checked={forceManualOverride ? true : manualOverride}
              onChange={handleCheckClick}
              color="primary"
              disabled={forceManualOverride}
            />
          }
          label="Manually allocate booking"
        />
        {!manualOverride && (
          <>
            <div>
              <div className={classes.resourceSelectContainer}>
                <h3 className={classes.stepContainerTitle}>Mobile worker</h3>
                <div className={classes.searchInputWithButton}>
                  <AppSelect
                    options={formatOptions(state.resources)}
                    onChange={handleSelectChange}
                    value={resourceId}
                    hidePleaseSelect
                    readOnly={readOnly && role !== "admin"}
                  />
                  <Button
                    onClick={handleSearchClick}
                    disabled={!!validBookableDatesMeta.error}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </div>
            <AppFormError show={!!validBookableDatesMeta.error}>
              {validBookableDatesMeta.error}
            </AppFormError>
            {state.isSearching ? (
              <AppLoader />
            ) : (
              <>
                {recommendedBookingSlot && (
                  <div className={classes.recommendedBookingContainer}>
                    <h4 className={classes.stepContainerTitle}>
                      Recommended
                      <img
                        className={classes.ecoIcon}
                        src={ecoIcon}
                        alt="Eco icon"
                      />
                    </h4>
                    <div className={classes.bookingSlotGrid}>
                      <Button
                        className={
                          state.selectedSlot?.index === -1
                            ? classes.recommendedSlotSelected
                            : classes.recommendedSlot
                        }
                        onClick={() =>
                          handleSlotClick(recommendedBookingSlot, -1)
                        }
                        key={`${recommendedBookingSlot.start}-${recommendedBookingSlot.resourceId}`}
                      >
                        {formatTimeOnly(new Date(recommendedBookingSlot.start))}{" "}
                        - {recommendedBookingSlot.resourceName}
                      </Button>
                    </div>
                  </div>
                )}
                {regularBookingSlots.length >= 1 ? (
                  <div className={classes.bookingSlotContainer}>
                    <div className={classes.bookingSlotGrid}>
                      {regularBookingSlots.map((timeSlot, i) => (
                        <Button
                          className={
                            state.selectedSlot?.index === i
                              ? classes.bookingSlotSelected
                              : classes.bookingSlot
                          }
                          onClick={() => handleSlotClick(timeSlot, i)}
                          key={`${timeSlot.start}-${timeSlot.resourceId}`}
                        >
                          {formatTimeOnly(new Date(timeSlot.start))} -{" "}
                          {timeSlot.resourceName}
                        </Button>
                      ))}
                    </div>
                  </div>
                ) : (
                  <>
                    {!recommendedBookingSlot &&
                      regularBookingSlots.length === 0 && (
                        <div className={classes.bookingSlotMessageBox}>
                          {bookableSlotsMessage}
                        </div>
                      )}
                  </>
                )}
              </>
            )}
          </>
        )}
        {manualOverride && (
          <div className={classes.resourceSelectContainer}>
            <Grid item xs={12}>
              <h3 className={classes.stepContainerTitle}>Mobile worker</h3>
              <div className={classes.serviceProviderContainer}>
                <BookingFields.resourceId
                  pleaseSelectText="Please select a mobile worker"
                  options={formatOptions(state.resources, true)}
                  readOnly={readOnly && role !== "admin"}
                />
              </div>
              {!!resourceId && resourceId > 0 && (
                <BookingTimeSelect
                  minuteIncrement={resourceMinuteIncrement ?? 5}
                  isEdit={isEdit}
                />
              )}
            </Grid>
          </div>
        )}
        <AppFormHelperText
          error={
            !!(hasSelectedTimeSlotMeta.touched && hasSelectedTimeSlotMeta.error)
          }
          helperText="Please select a time slot or manually override the algorithm."
        />
      </div>
    </>
  );
};

export default BookingFormSlotSelection;