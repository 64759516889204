import { AppWorkingHoursPickerProps } from "./AppWorkingHoursPicker/AppWorkingHoursPicker";
import { FormikInputPropsKeys } from "./common";
import { AppWorkingHoursPicker } from "./AppWorkingHoursPicker";
import { useField } from "formik";
import { WorkingHoursDay } from "../../models/Resource";

export interface AppFormikWorkingHoursPickerProps
  extends Omit<AppWorkingHoursPickerProps, FormikInputPropsKeys> {
  name: string;
}

const AppFormikWorkingHoursPicker: React.FC<AppFormikWorkingHoursPickerProps> =
  ({ name, ...restOfProps }) => {
    const [fieldProps, , { setValue }] = useField(name);

    const handleChange = (workingHours: WorkingHoursDay[]) => {
      setValue(workingHours);
    };

    return (
      <AppWorkingHoursPicker
        {...restOfProps}
        {...fieldProps}
        onChange={handleChange}
      />
    );
  };

export default AppFormikWorkingHoursPicker;
