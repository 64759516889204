import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  eventTimeLabel: {
    fontSize: "80%",
    paddingRight: "10px",
  },
  resourceFilter: {
    position: "absolute",
    right: 0,
  },
  grandTotalCounter: {
    right: 0,
    top: "50px",
    margin: theme.spacing(0.5),
  },
  calendarContainer: {
    position: "relative",
    height: "calc(100vh - 230px)",
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 176px)",
    },
    "& td": {
      backgroundClip: "padding-box",
    },
    "& .fc-scrollgrid": {
      border: "none",
    },
    "& .fc-col-header-cell": {
      border: "none",
    },
    "& .fc-timegrid-slot-minor": {
      borderTop: "none",
    },
    "& .fc-timegrid-slot-lane": {
      backgroundColor: "#fff",
    },
    "& .fc-timegrid-slot-label": {
      borderTop: "none",
      textTransform: "uppercase",
      fontWeight: "500",
    },
    "& .fc-bg-event": {
      cursor: "not-allowed",
      border: "none",
      opacity: "1",
    },
    "& .fc-bg-event > *": {
      display: "none",
    },
    "& .fc-event-main": {
      padding: "0",
      border: "none !important",
    },
    "& .fc-timegrid-event": {
      border: "none",
      background: "none !important",
      boxShadow: "none",
      color: "#1a283d",
    },
    "& .fc-timegrid-event-harness": {
      overflow: "hidden",
    },
    "& .fc-timegrid-col": {
      border: "none",
    },
    "& .fc-timegrid-col.fc-day": {
      position: "relative",
      "&::after": {
        content: '""',
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        zIndex: "1",
        borderRight: `1px solid ${theme.palette.grey[100]}`,
        pointerEvents: "none",
      },
    },
    "& .fc-col-header-cell-cushion": {
      display: "block",
    },
    "& .fc-timegrid-now-indicator-line": {
      borderColor: theme.palette.primary.main,
    },
    "& .fc-timegrid-now-indicator-arrow": {
      borderColor: theme.palette.primary.main,
      borderTopColor: "transparent",
      borderBottomColor: "transparent",
    },
    "& .fc-timeline-now-indicator-line": {
      borderColor: theme.palette.primary.main,
    },
    "& .fc-timeline-now-indicator-arrow": {
      borderColor: theme.palette.primary.main,
      borderRightColor: "transparent",
      borderLeftColor: "transparent",
    },
    "& .fc-scrollgrid-sync-inner": {
      minWidth: "290px",
    },
    "& .fc-resource-timeline .fc-datagrid-cell-frame .fc-scrollgrid-sync-inner": {
      minWidth: "auto"
    },
    "& .fc-timeline-lane .fc-scrollgrid-sync-inner": {
      minWidth: "auto"
    },
    "& .fc-scroller": {
      overflowY: "hidden !important",
    },
    "& .fc-scroller.fc-scroller-liquid-absolute": {
      overflowY: "visible !important",
      scrollbarWidth: "thin" /* Firefox */,
      scrollbarColor: theme.palette.grey[500],
      "-ms-overflow-style": "none" /* Internet Explorer 10+ */,
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "8px",
        padding: "4px",
      },
      "&::-webkit-scrollbar-track": {
        width: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.grey[500],
        borderRadius: "16px",
        width: "8px",
      },
    },
    "& .fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid > td:first-of-type > .fc-scroller-harness.fc-scroller-harness-liquid > .fc-scroller.fc-scroller-liquid-absolute":
      {
        overflowX: "hidden !important",
      },
    "& .fc-media-screen": {
      borderTop: "none",
      height: "100%",
      minHeight: "400px",
    },
    "& .fc-timegrid-slots tr": {
      height: 35,
    },
    "& .fc-timegrid-axis-chunk tr": {
      height: 35,
    },
    "& .fc-col-header-cell.fc-day": {
      height: 40,
      textAlign: "left",
      paddingLeft: theme.spacing(2),
      verticalAlign: "middle",
    },
    "& .fc-col-header-cell.fc-day-today": {
      color: theme.palette.primary.main,
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    "& .fc-col-header-cell.fc-resource": {
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
    },
    "& .fc-license-message": {
      // Temporary until we have sorted out the license
      display: "none !important",
    },
    "& .non-working-event": {
      background: `repeating-linear-gradient(
                135deg,
                #D7D4CE,
                #D7D4CE 20px,
                #EBEAE7 20px,
                #EBEAE7 35px
              )`,
      opacity: 0.6,
    },
    "& .fc-scrollgrid-section-body > *": {
      overflowX: "hidden !important",
    },
    "& .fc-timeline-lane-misc": {
      display: "none"
    },
    "& .fc-timeline-event": {
      border: "none !important",
      background: "none !important",
      "& .fc-event-main": {
        height: 46
      }
    },
    "& .fc-timeline-lane-frame": {
      overflow: "hidden"
    },
    "& .fc-timeline-slot.fc-timeline-slot-label": {
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
    },
    "& th[role='columnheader'] > .fc-datagrid-cell-frame": {
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
    }
  },
  "& .fc-scrollgrid-section.fc-scrollgrid-section-header": {
    '& > [role="presentation"]': {
      borderBottom: "none",
    },
  },
  "& .fc-scrollgrid-section-body > td": {
    borderBottom: "none !important",
  },
  "& .fc-scrollgrid-section-body > td:nth-of-type(2)": {
    borderRight: "none",
  },
  "& .fc-timegrid-axis": {
    border: "none",
  },
  "& .fc-timegrid-axis-chunk > table tr": {
    position: "relative",
    top: "-18px",
  },
  "& .fc-timegrid-axis-chunk > table tr:first-of-type": {
    opacity: 0,
  },
}));

export const useStylesWeek = makeStyles(() => ({
  calendarContainer: {
    "& .rbc-calendar": {
      "& .rbc-time-header-content": {
        border: "none",
        minWidth: "1400px",
      },
      "& .rbc-header": {
        minWidth: "200px",
      },
      "& .rbc-row-resource": {
        minWidth: "1400px",
      },
      "& .rbc-time-content": {
        maxHeight: "calc(100vh - 240px)",
      },
    },
  },
}));
