import { Grid, FormControl, TextField, makeStyles } from "@material-ui/core";
import { useField, useFormikContext } from "formik";
import { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { postcodeCoverage, postcodeExists } from "../../../api/geocoding";
import { getAllAddressesFromPostcode } from "../../../api/widget";
import AppButton from "../../../components/AppButton";
import AppFormError from "../../../components/AppForm/AppFormError";
import AppSelect, { AppSelectOption } from "../../../components/AppForm/AppSelect";
import { AddressSearchResult } from "../../../models/AddressSearchResult.";
import { toNormalised } from "../../../postcodeUtils";
import { BookingFields } from "../BookingFormFields";
import { Search as SearchIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    button: {
      alignItems: "center",
      marginTop: theme.spacing(1.5),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    extraField: {
      display: "flex",
      [theme.breakpoints.down(599)]: {
        display: "block",
      },
    },
    fieldsPadding: {
      marginBottom: theme.spacing(2),
    },
    addressListButton: {
      display: "flex",
      alignItems: "left",
      backgroundColor: "transparent",
      border: "none",
      color: "#4a74b8",
      fontSize: theme.typography.h6.fontSize,
      cursor: "pointer", 
      marginLeft: theme.spacing(-1),

  },
  stepContainerTitle: {
    margin: 0,
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
  }));

interface PageParams {
    vendorId: string;
  }

const BookingFormCustomerAddressCreation: React.FC = () => {
  const classes = useStyles();
  const { vendorId } = useParams<PageParams>();
  const { validateForm } = useFormikContext();
  const [{ value: addressLine1 }, , { setValue: setAddressLine1 }] = useField<string>("addressLine1");
  const [{ value: addressLine2 }, , { setValue: setAddressLine2 }] = useField<string>("addressLine2");
  const [, , { setValue: setAddressLine3 }] = useField<string>("addressLine3");
  const [, , { setValue: setTown }] = useField<string>("town");
  const [{ value: postcodeFormValue }, postcodeMeta, { setValue: setFormikPostcode }] = useField<string>("postcode");
  const [postcode, setPostcode] = useState<string>();
  const [enterAddressManually, setEnterAddressManually] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(-1);
  const [label, setLabel] = useState("Choose address");
  const [errorMessage, setErrorMessage] = useState(false);
  const [postcodeCovered, setPostcodeCovered] = useState(true);
  const [selectedPostcode, setSelectedPostcode] = useState<string>("");
  const [addressSelected, setAddressSelected] = useState(false);
  const [postcodeSearchPerformed, setPostcodeSearchPerformed] = useState(false);
  const [addressResults, setAddressResults] = useState<AddressSearchResult[]>(
    []
  );
  const addressListRef = useRef<HTMLInputElement>(null);
  const options: AppSelectOption[] = addressResults.map(
    (address: AddressSearchResult, index: number) => ({
      value: index,
      label: `${address.addressLine1}, ${address.addressLine2}`,
    })
  );
  const addressPreviouslySelected = addressLine1 !== "";

  const resetAddress = () => {
    setAddressLine1("");
    setAddressLine2("");
    setAddressLine3("");
    setTown("");
    setFormikPostcode("");
    setAddressResults([]);
    setSelectedAddressId(-1);
  };

  const postcodeIsCovered = async (postcode: string) => {
    setErrorMessage(false);
    const response = await postcodeCoverage(postcode, parseInt(vendorId));

    if (!response.isError) {
      const isCovered = response.content.postcodeIsCovered;
      const result = await getAllAddressesFromPostcode(postcode);
      if (result.isError) {
        return;
      }
      setAddressResults(result.content.content);
      setPostcode(postcode);
      if (!isCovered) {
        setPostcodeCovered(false);
      }
    }
      if (response.isError) {
        setErrorMessage(true);
      }
  };

  
  const doAddressSearch = async (
    providedPostcode?: string,
    skipReset?: boolean
  ) => {
    const postcodeToSearch = providedPostcode
      ? toNormalised(providedPostcode)
      : toNormalised(postcode);
    if (postcodeToSearch) {
      !skipReset && resetAddress();
      setAddressSelected(false);
      setErrorMessage(false);
      setPostcodeCovered(true);
      setPostcodeSearchPerformed(true);

      const response = await postcodeExists(postcodeToSearch);
      if (response.statusCode === 404) {
        setErrorMessage(true);
      } else {
      if (response.statusCode === 200) {
        postcodeIsCovered(postcodeToSearch);
      }
      }
    }
  };

  const handleAddressSelect = (value: number) => {
    const address = addressResults[value];
    setErrorMessage(false);
    if (!address)
      throw new Error(
        `AppFormikSelect: Expected to find option for value ${value}.`
      );
    setSelectedAddressId(value);
    setLabel(`${address.addressLine1}, ${address.addressLine2}`);
    setAddressLine1(address.addressLine1);
    setAddressLine2(address.addressLine2);
    setAddressLine3(address.addressLine3 ?? "");
    setTown(address.town);
    setFormikPostcode(address.postcode);
    setSelectedPostcode(address.postcode);
    setAddressSelected(true);
  };

  useEffect(() => {
    if (addressPreviouslySelected) {
      setPostcode(postcodeFormValue);
      doAddressSearch(postcodeFormValue, true);
      setAddressSelected(true);
    }
  }, []);

  useEffect(() => {
    if (addressPreviouslySelected) {
      const option = options.find(
        (opt) => opt.label === `${addressLine1}, ${addressLine2}`
      );
      setSelectedAddressId(-1);

      if (option) {
        setSelectedAddressId(option.value);
        setAddressSelected(true);
      } else {
        setAddressSelected(false);
      }
    }

    if (options.length > 0) {
      addressListRef.current?.focus();
    }
  }, [addressResults]);

  useEffect(() => {
    if (addressSelected) {
      validateForm();
    }
  }, [addressSelected]);

if (enterAddressManually === false) return (
  <>
    <h3 className={classes.stepContainerTitle}>Address</h3>
    <FormControl >
    <Grid container>
            <Grid item>
              <TextField 
                    name="postcodedomain" 
                    label="Enter postcode" 
                    value={postcode} 
                    onChange={(e) => setPostcode(e.currentTarget.value)}
                          onKeyDown={(e) => { if (e.code === "Enter") {
                            e.preventDefault();
                              doAddressSearch();
                        }}}/>
              <AppButton 
                    className={classes.button} 
                    variant="text" 
                    onClick={() => doAddressSearch()
                    }>
                Search</AppButton>
              <AppFormError show={errorMessage}>
                Please enter a valid postcode
              </AppFormError>
              <AppFormError show={!postcodeCovered}>
                Postcode is currently not covered
              </AppFormError>
              {postcodeSearchPerformed && <AppSelect
                    pleaseSelectText={label}
                    label={" "}
                    disabled={addressResults.length === 0}
                    options={options}
                    value={selectedAddressId}
                    onChangeCallback={handleAddressSelect}
                    inputRef={addressListRef}
              />}
              {postcodeSearchPerformed && <AppButton
               variant="text" 
                className={classes.addressListButton} 
                onClick={() => { setEnterAddressManually(!enterAddressManually); 
                }}>
                I can&apos;t find my address in the list
              </AppButton>}
          </Grid>

    {addressSelected && (
      <div>
        <BookingFields.addressLine1
            className={classes.fieldsPadding}
            />
        <BookingFields.addressLine2
            className={classes.fieldsPadding}
        />
        <BookingFields.addressLine3
            className={classes.fieldsPadding}
            />
            <BookingFields.town className={classes.fieldsPadding}/>
            <BookingFields.postcode
            readonly
            />
        </div>
    )}
</Grid>
    </FormControl>
    </>
);

return (
    <>
    <h3 className={classes.stepContainerTitle}>Address</h3>
    <button className={classes.addressListButton} onClick={() => { setEnterAddressManually(!enterAddressManually); }}><SearchIcon/> Use address finder</button>
    <FormControl fullWidth={true}>
        <Grid>
        <div>
        <BookingFields.addressLine1
            className={classes.fieldsPadding}
        />
        <BookingFields.addressLine2
            className={classes.fieldsPadding}
        />
        <BookingFields.addressLine3
            className={classes.fieldsPadding}
        />
        <BookingFields.town className={classes.fieldsPadding}/>
        <BookingFields.postcode
        />
        </div>
        </Grid>
    </FormControl>
    </>
    );
};

export default BookingFormCustomerAddressCreation;

