import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Jobs, OptionalExtra } from "../../../models/Booking";
import { calculateBasketTotal, excludeVATFromPriceIfRequired } from "../../../numericalUtils";
import { actions, selectors } from "../../../store";
import { ServicesListView } from "../services";
import { BookingFormValues } from "../../../bookings/BookingForm/BookingFormValues";


const useStyles = makeStyles((theme) => ({
    serviceInfo: {
        lineHeight: "9px",
        paddingRight: theme.spacing(1),
        width: "95%",
        [theme.breakpoints.up(600)]: {
            // width: "65%",
        }
    },
    container: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[200],
        marginBottom: theme.spacing(5),
    },
    dateTime: {
        alignItems: "center",
        display: "flex",
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down(599)]: {
            display: "block",
        },
    },
    date: {
        marginLeft: "auto",
        borderLeft: `2px solid ${theme.palette.grey[300]}`,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
        marginRight: theme.spacing(1),
        fontSize: "32px",
        fontWeight: "bold",
        [theme.breakpoints.down(599)]: {
            borderLeft: "none",
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
            paddingTop: theme.spacing(1),
            borderTop: `2px solid ${theme.palette.grey[300]}`,
            display: "flex",
        },
        [theme.breakpoints.up(600)]: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
        },
    },
    appointment: {
        fontSize: "16px",
        fontWeight: "bold",
        lineHeight: "18px",
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    optional: {
        fontWeight: 300,
        marginBottom: theme.spacing(1),
        lineHeight: "18px",
    },
    optionalList: {
        paddingBottom: theme.spacing(1),
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
    },
    addressLine: {
        fontWeight: 400,
        paddingTop: theme.spacing(1),
        lineHeight: "18px",
    },
    dateLine: {
        lineHeight: "20px",
    },
    totalCostText: {
        fontSize: 16,
        fontWeight: "normal",
        flexGrow: 1,
        alignSelf: "flex-start",
    },
    iconContainer: {
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            justifyContent: "center",
        },
    },
    checkIcon: {
        color: theme.palette.primary.main,
        fontSize: "100px",
        stroke: theme.palette.grey[200],
        strokeWidth: "1",
        [theme.breakpoints.up("sm")]: {
            marginRight: theme.spacing(4),
        },
    },
    summaryText: {
        marginLeft: theme.spacing(1),
    },
    headerTitle: {
        marginLeft: theme.spacing(1),
    },
    excludingVatText: {
        margin: 0,
        textAlign: "center",
        fontWeight: "normal",
        fontSize: "24px"
    },
    basketTotal: {
        display: "flex",
        flexDirection: "column",
        "& span": {
            fontWeight: "normal",
            fontSize: "14px;",
            lineHeight: "14px",
        }
    }
}));

interface Props {
    bookingDetails?: BookingFormValues;
    currentTaxRatePercentage?: number;
    canRemoveServices?: boolean;
    canEditServices?: boolean;
    handleEditService?: (service: Jobs) => void;
    children?: React.ReactNode;
}

const WidgetBookingBasket: React.FC<Props> = ({
    bookingDetails,
    currentTaxRatePercentage,
    canRemoveServices = false,
    canEditServices = false,
    handleEditService,
    children,
}: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const bookingBasketServices = useSelector(selectors.basket.services);

    const handleRemoveService = (service: Jobs) => {
        dispatch(actions.basket.removeService(service.key ?? 0));
    };

    const appointmentTypes = useSelector(
        selectors.appointmentTypes.allAppointmentTypes
    );

    const renderPricing = () => {
        const displayPrice = excludeVATFromPriceIfRequired(
            calculateBasketTotal(bookingBasketServices),
            currentTaxRatePercentage ?? 0)
            .toFixed(2);
        return (
            <>
                &pound;{displayPrice}{currentTaxRatePercentage ? ` (ex. VAT)` : ``}
            </>
        );
    };

    // TODO: below is hack to push optional extra info that is no longer associated with appointment-type but WAS at time booking was made
    // more times needs put in to implement a better solution to this
    if (bookingDetails) {
        const originalFirstJobOptionalExtras = bookingDetails.firstJobOptionalExtras as OptionalExtra[];

        for (const appointmentType of (appointmentTypes ?? [])) {
            if (appointmentType.id === bookingDetails.firstJobAppointmentTypeId) {
                const availableOptionalExtraIds = appointmentType.availableOptionalExtras.map(availableOptionalExtra => availableOptionalExtra.id);

                originalFirstJobOptionalExtras.forEach(originalFirstJobOptionalExtra => {
                    if (!availableOptionalExtraIds.includes(originalFirstJobOptionalExtra.id)) {
                        // we have a missing optional extra detail present when booking was originally made
                        appointmentType.availableOptionalExtras.push({
                            ...originalFirstJobOptionalExtra,
                            description: "",
                            hasQuantity: false,
                            price: originalFirstJobOptionalExtra.price
                        });
                    }
                });
            }
        }
    }

    return (
        <div className={classes.container}>
            <div className={classes.dateTime}>
                <div className={classes.serviceInfo}>
                    <ServicesListView
                        services={bookingBasketServices}
                        appointmentTypes={appointmentTypes ?? []}
                        currentTaxRatePercentage={currentTaxRatePercentage ?? 0}
                        canRemoveServices={canRemoveServices}
                        handleRemoveService={handleRemoveService}
                        canEditServices={canEditServices}
                        handleEditService={handleEditService}
                    />
                    {children}
                </div>
                <div className={classes.date}>
                    <p className={classes.basketTotal}>
                        {renderPricing()}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default WidgetBookingBasket;