import { HttpClientResponse } from "./common/httpClient";
import {
  ApiStandardResponse,
  buildApiUrl,
  doErrorToastIfRequired,
  httpClient,
  mapHttpClientResponse,
} from "./common";
import { ApiVendorConfiguration } from "./models/Configuration";
import { VendorConfiguration } from "../models/Configuration";

export const getVendorConfiguration = async (): Promise<
  HttpClientResponse<VendorConfiguration>
> => {
  const url = buildApiUrl("api-vendor/current-configuration");
  const response = await httpClient.getRequest<
    ApiStandardResponse<ApiVendorConfiguration>
  >(url);

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, (m) => m);
};
