import * as Yup from "yup";
import { ukPostcodeRegex } from "../../constants";
import { postcodeExists } from "../../api/geocoding";
import {
  AppointmentTypeCapabilities,
  WorkingHoursDay,
} from "../../models/Resource";
import { defaultWorkingDaysValues } from "../../components/AppForm/AppWorkingHoursPicker";
import { Tag } from "../../models/Tag";

export const emailFieldName = "email";

export type ResourceFormPage1Values = {
  name: string;
  typeId?: number;
  email: string;
  phoneNumber?: string;
  colour: string;
};
export type ResourceFormPage2Values = {
  appointmentTypeCapabilities: AppointmentTypeCapabilities[];
  tags: Tag[];
};

export type ResourceFormPage3Values = {
  workingHours: WorkingHoursDay[];
};

export type ResourceFormPage4Values = {
  coverageType: string;
  basePostcode: string;
  maxTravelDistanceMiles?: number;
  coveredOutcodes?: string[];
  bookableFrom?: string | null;
  bookableUntil?: string | null;
};

export type ResourceFormValues = ResourceFormPage1Values &
  ResourceFormPage2Values &
  ResourceFormPage3Values &
  ResourceFormPage4Values;

export const defaultResourceFormValues: ResourceFormValues = {
  name: "",
  typeId: -1,
  [emailFieldName]: "",
  phoneNumber: "",
  colour: "",
  workingHours: defaultWorkingDaysValues,
  coverageType: "radius",
  basePostcode: "",
  maxTravelDistanceMiles: 0,
  coveredOutcodes: [],
  tags: [],
  appointmentTypeCapabilities: [],
};

const validatePostcodeRegex = (postcode: string) => {
  const matches = postcode.match(ukPostcodeRegex);
  return !!matches && matches.length !== 0;
};

export const validateOutcodeRegex = (outcode: string) => {
  const regex = /^[A-Z]{1,2}\d[A-Z\d]?$/;
  return regex.test(outcode);
};

const validatePostcodeExists = async (postcode: string) => {
  if (!postcode || !validatePostcodeRegex(postcode)) return true;

  const response = await postcodeExists(postcode);

  return !response.isError && response.statusCode === 200;
};

export const resourceFormPage1ValidationSchema: Yup.SchemaOf<ResourceFormPage1Values> =
  Yup.object().shape({
    name: Yup.string().required("Please provide a name."),
    typeId: Yup.number().notRequired(),
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("Please provide an email address."),
    phoneNumber: Yup.string().notRequired().max(20, "Phone number can only be a maximum of 20 characters."),
    colour: Yup.string().required("Please provide a colour."),
  });

export const resourceFormPage2ValidationSchema: Yup.SchemaOf<ResourceFormPage2Values> =
  Yup.object().shape({
    appointmentTypeCapabilities: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number(),
          name: Yup.string(),
        })
      )
      .notRequired(),

    tags: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number(),
          name: Yup.string(),
          value: Yup.string(),
          isResourceRequired: Yup.boolean(),
        })
      )
      .notRequired(),
  });

export const resourceFormPage3ValidationSchema: Yup.SchemaOf<ResourceFormPage3Values> =
  Yup.object().shape({
    workingHours: Yup.array(),
  });

export const resourceFormPage4ValidationSchema: Yup.SchemaOf<ResourceFormPage4Values> =
  Yup.object().shape({
    coverageType: Yup.string().required(),
    basePostcode: Yup.string()
      .required("Please enter your postcode")
      .matches(ukPostcodeRegex, "Must be a valid postcode including the space.")
      .test(
        "validatePostcodeFormat",
        "Please check you have entered a valid postcode",
        (postcode) => {
          if (!postcode) return true;
          // Postcode regex taken from fresh norse site. It's ok for this as we are cloning that site but don't copy it without testing elsewhere.
          // If valid then proceed to perform async postcode exists check
          const validRegexResult = validatePostcodeRegex(postcode);

          if (validRegexResult) {
            return validatePostcodeExists(postcode);
          } else {
            return validRegexResult;
          }
        }
      ),
    maxTravelDistanceMiles: Yup.number().when("coverageType", {
      is: (val: string) => val === "radius",
      then: Yup.number()
        .min(1, "Miles travelled must be greater than 0")
        .required("Please enter a maximum travel distance (miles)")
        .test("is-decimal", "Invalid Decimal", (distance) => {
          const regex = /^\d*\.{1}\d*$/;
          let valid = true;

          if (distance) {
            if (regex.test(`${distance}`)) {
              valid = false;
            }
          }
          return valid;
        }),
      otherwise: Yup.number().notRequired(),
    }),
    coveredOutcodes: Yup.array().when("coverageType", {
      is: (val: string) => val === "outcodeList",
      then: Yup.array()
        .of(Yup.string().required())
        .min(1, "Please provide a list of outcodes")
        .required("Please provide a list of outcodes")
        .test(
          "validateOutcodeFormat",
          "Please check you have entered valid outcodes",
          (outcodes) => {
            let valid = true;

            if (outcodes) {
              outcodes.forEach((outcode) => {
                if (outcode) {
                  valid = validateOutcodeRegex(outcode);
                }
              });
            }

            return valid;
          }
        ),
      otherwise: Yup.array().of(Yup.string()).notRequired(),
    }),
    bookableFrom: Yup.string().nullable().notRequired(),
    bookableUntil: Yup.string().nullable().notRequired(),
  });

export const resourceFormPageValidationSchemas = [
  resourceFormPage1ValidationSchema,
  resourceFormPage2ValidationSchema,
  resourceFormPage3ValidationSchema,
  resourceFormPage4ValidationSchema,
];
