import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AdminRoutes from "./admin/AdminRoutes";
import PublicRoutes from "./public/PublicRoutes";
import ResourceRoutes from "./resource/ResourceRoutes";

const AppRoutes: React.FC = () => {
  return (
    <Router>
      <PublicRoutes />
      <AdminRoutes />
      <ResourceRoutes />
    </Router>
  );
};

export default AppRoutes;
