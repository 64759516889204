import { Formik } from "formik";
import {
  createTimeOffFormValidationSchema,
  defaultTimeOffFormValues,
  TimeOffFormValues,
} from ".";
import TimeOffForm from "./TimeOffForm";

interface TimeOffFormProps {
  initialValues?: TimeOffFormValues;
  handleSubmit: (values: TimeOffFormValues) => void;
  handleClose: () => void;
  userIsAdmin?: boolean;
}

const TimeOffFormContainer: React.FC<TimeOffFormProps> = ({
  initialValues = defaultTimeOffFormValues,
  handleSubmit,
  handleClose,
  userIsAdmin,
}) => {
  const TimeOffFormPageValidationSchemas =
    createTimeOffFormValidationSchema(userIsAdmin);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={TimeOffFormPageValidationSchemas}
      >
        <TimeOffForm
          initialValues={initialValues}
          handleClose={handleClose}
          userIsAdmin={userIsAdmin}
        />
      </Formik>
    </>
  );
};

export default TimeOffFormContainer;
