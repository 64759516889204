import {createSelector, createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";
import { Jobs } from "../models/Booking";

export const sliceName = "basket";

type SliceState = {
    basket: {
        key: number;
        services: Jobs[];
        total: number;
    }
};

const initialState: SliceState = {
    basket: {
        key: 0,
        services: [],
        total: 0,
    },
};

// Slice
const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        addService: (state, { payload }: PayloadAction<Jobs>) => {
            payload.key = state.basket.key++;
            state.basket.services.push(payload);
        },
        removeService: (state, { payload }: PayloadAction<number>) => {
          const index = state.basket.services.findIndex(_ => _.key === payload);
          state.basket.services.splice(index, 1);
          state.basket.total = state.basket.services.reduce((acc, service) => {

              if (service.overriddenPrice != null) {
                  return service.overriddenPrice;
              }

              acc += service.optionalExtras.reduce((acc, extra) => {
                  return acc += extra.price * extra.quantity;
              }, 0);
              return acc + service.price;
          }, 0);
        },
        setServices: (state, { payload }: PayloadAction<Jobs[]>) => {
            state.basket.key = 0;
            state.basket.services = payload.map(service => {
                const copy = {...service};
                copy.key = state.basket.key++;
                return copy;
            });

            // state.basket.services = payload;
        },
        clearServices: (state) => {
            state.basket.services = [];
        },
        setBasketTotal: (state, { payload }: PayloadAction<number>) => {
            state.basket.total = payload;
        },
        clearTotal: (state) => {
            state.basket.total = 0;
        },
        resetBasket: (state) => {
            state.basket.services = [];
            state.basket.total = 0;
        }
    },
    extraReducers: {}
});

export const { name, reducer } = slice;

type RootReducerState = {
    [sliceName]: SliceState;
};

const selectSliceState = (state: RootReducerState) => state[sliceName];

// Selectors
export const selectors = {
    services: createSelector(
        selectSliceState,
        (state) => state.basket.services
    ),
    total: createSelector(
        selectSliceState,
        (state) => state.basket.total
    ),
};

// Actions
export const actions = {
    addService: (service: Jobs) => (
        dispatch: (action: unknown) => void
    ): void => {
        dispatch(slice.actions.addService(service));
    },
    removeService: (id: number) => (
        dispatch: (action: unknown) => void
    ): void => {
      dispatch(slice.actions.removeService(id));
    },
    setServices: (services: Jobs[]) => (
        dispatch: (action: unknown) => void
    ): void => {
      dispatch(slice.actions.setServices(services));
    },
    clearServices: () => (
        dispatch: (action: unknown) => void
    ): void => {
        dispatch(slice.actions.clearServices());
    },
    setBasketTotal: (total: number) => (
        dispatch: (action: unknown) => void
    ): void => {
        dispatch(slice.actions.setBasketTotal(total));
    },
    clearTotal: () => (
        dispatch: (action: unknown) => void
    ): void => {
        dispatch(slice.actions.clearTotal());
    },
    resetBasket: () => (
        dispatch: (action: unknown) => void
    ): void => {
        dispatch(slice.actions.resetBasket());
    }
};