import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    position: "relative",
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(4),
  },
  tableWrapper: {
    padding: theme.spacing(2.5),
    backgroundColor: theme.palette.common.white,
    height: "100%",
    maxHeight: 510,
    display: "flex",
    flexDirection: "column",
  },
  tableTitleHeader: {
    flex: "none",
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    "& svg": {
      fill: "#93D700",
      marginRight: theme.spacing(1),
      width: 30,
      height: 30,
    },
  },
  tableContainer: {
    flex: 1,
    overflow: "auto",
    scrollbarWidth: "thin" /* Firefox */,
    scrollbarColor: theme.palette.grey[500],
    "-ms-overflow-style": "none" /* Internet Explorer 10+ */,
    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px",
      padding: "4px",
    },
    "&::-webkit-scrollbar-track": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[500],
      borderRadius: "16px",
      width: "8px",
    },
  },
}));
