import React, { Dispatch, SetStateAction, useState } from "react";
import { add, endOfWeek, format, isAfter, isBefore } from "date-fns";
import { InputLabel, MenuItem, Select, makeStyles } from "@material-ui/core";
import { AppSelectOption } from "../components/AppForm/AppFormikSelect";
import { endOfMonth, startOfMonth, startOfWeek } from "date-fns/esm";
import {
  startOfNextWeek,
  endOfNextWeek,
  endOfNextMonth,
  startOfLastWeek,
  endOfLastWeek,
  startOfLastMonth,
  endOfLastMonth,
  startOfNextMonth,
} from "../dateUtils";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

const useStyles = makeStyles((theme) => ({
  detailsPanel: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: "1rem 0",
    borderBottom: "1px solid black",
    marginBottom: "1rem",
  },
  controlContainer: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridTemplateColumns: "repeat(5, 1fr)",
    },
  },
  dateRangeContainer: {
    background: theme.palette.grey[50],
    display: "grid",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    gridTemplateColumns: "repeat(2, 1fr)",
    gridColumn: "span 2",
    width: "100%",
  },
  dateRangeDropdown: {
    gridColumn: "span 2",
  },
  dateRangeItem: {
    padding: "0.5rem",
  },
  label: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.dark,
  },
}));

interface DateRange {
  label: string;
  startDate: string;
  endDate: string;
}

const dateRanges: DateRange[] = [
  {
    label: "This week",
    startDate: format(
      startOfWeek(new Date(), { weekStartsOn: 1 }),
      "yyyy-MM-dd"
    ),
    endDate: format(endOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd"),
  },
  {
    label: "This month",
    startDate: format(startOfMonth(new Date()), "yyyy-MM-dd"),
    endDate: format(endOfMonth(new Date()), "yyyy-MM-dd"),
  },
  {
    label: "Next week",
    startDate: format(startOfNextWeek(), "yyyy-MM-dd"),
    endDate: format(endOfNextWeek(), "yyyy-MM-dd"),
  },
  {
    label: "Next month",
    startDate: format(startOfNextMonth(), "yyyy-MM-dd"),
    endDate: format(endOfNextMonth(), "yyyy-MM-dd"),
  },
  {
    label: "Last week",
    startDate: format(startOfLastWeek(), "yyyy-MM-dd"),
    endDate: format(endOfLastWeek(), "yyyy-MM-dd"),
  },
  {
    label: "Last month",
    startDate: format(startOfLastMonth(), "yyyy-MM-dd"),
    endDate: format(endOfLastMonth(), "yyyy-MM-dd"),
  },
  {
    label: "Last 3 months",
    startDate: format(
      add(startOfMonth(new Date()), { months: -3 }),
      "yyyy-MM-dd"
    ),
    endDate: format(endOfLastMonth(), "yyyy-MM-dd"),
  },
  {
    label: "Custom",
    startDate: format(
      startOfWeek(new Date(), { weekStartsOn: 1 }),
      "yyyy-MM-dd"
    ),
    endDate: format(endOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd"),
  },
];

interface DashboardControlsProps {
  setStartDate: Dispatch<SetStateAction<string>>;
  setEndDate: Dispatch<SetStateAction<string>>;
  startDate: string;
  endDate: string;
}

const DashboardControls: React.FC<DashboardControlsProps> = ({
  setStartDate,
  setEndDate,
  startDate,
  endDate,
}) => {
  const classes = useStyles();

  const [isCustomDateRange, setIsCustomDateRange] = useState(false);

  const generateDateRangeOptions = (): AppSelectOption[] => {
    return dateRanges.map((dateRange, index) => {
      return { value: index + 1, label: dateRange.label };
    });
  };

  const handleDateRangeSelect = (e: React.ChangeEvent<{ value: unknown }>) => {
    const index = parseInt(e.target.value as string);
    const dateRange = dateRanges[index - 1];
    setStartDate(dateRange.startDate);
    setEndDate(dateRange.endDate);
    setIsCustomDateRange(dateRange.label === "Custom");
  };

  const handleCustomStartDateSelect = (date: MaterialUiPickersDate) => {
    if (!date) {
      return;
    }

    const formattedDate = format(date, "yyyy-MM-dd");
    setStartDate(formattedDate);

    if (isAfter(date, new Date(endDate))) {
      setEndDate(formattedDate);
    }
  };

  const handleCustomEndDateSelect = (date: MaterialUiPickersDate) => {
    if (!date) {
      return;
    }

    const formattedDate = format(date, "yyyy-MM-dd");
    setEndDate(formattedDate);

    if (isBefore(date, new Date(startDate))) {
      setStartDate(formattedDate);
    }
  };

  return (
    <div className={classes.detailsPanel}>
      <div className={classes.controlContainer}>
        <div className={`${isCustomDateRange && classes.dateRangeContainer}`}>
          <div
            className={`${classes.dateRangeItem} ${
              isCustomDateRange && classes.dateRangeDropdown
            }`}
          >
            <InputLabel className={classes.label}>Date range</InputLabel>
            <Select onChange={handleDateRangeSelect} defaultValue={1} fullWidth>
              {generateDateRangeOptions().map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </div>
          {isCustomDateRange && (
            <>
              <div className={classes.dateRangeItem}>
                <InputLabel className={classes.label}>From</InputLabel>
                <DatePicker
                  value={startDate}
                  onChange={handleCustomStartDateSelect}
                  showTodayButton
                  format="dd/MM/yyyy"
                  autoOk
                />
              </div>
              <div className={classes.dateRangeItem}>
                <InputLabel className={classes.label}>To</InputLabel>
                <DatePicker
                  value={endDate}
                  onChange={handleCustomEndDateSelect}
                  showTodayButton
                  format="dd/MM/yyyy"
                  autoOk
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardControls;
