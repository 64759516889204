import { EventApi, EventInput } from "@fullcalendar/react";
import { ResourceInput } from "@fullcalendar/resource-common";
import { createContext, useContext } from "react";
import { TimeOff } from "../../models/Resource";
import { MinMaXCalendarTimes, SelectedSlot, SelectedSlotTime } from "./types";

export enum CALENDAR_DATE_SELECTION{
  DAILY,
  WEEKLY
}

export enum CALENDAR_ORIENTATION {
  VERTICAL,
  HORIZONTAL
}

export enum CALENDAR_VIEWS {
  DAY_VIEW = "resourceTimeGridDay",
  WEEK_VIEW = "resourceTimeGridWeek",
  DAY_PIVOT_VIEW = "resourceTimelineDay",
  WEEK_PIVOT_VIEW = "resourceTimelineWeek"
}

export interface IAppCalendarContext {
  selectedDate: Date;
  selectedView: CALENDAR_VIEWS;
  selectedResources: string[];
  selectedDatePickerDate: Date;
  selectedTimeOff?: TimeOff;
  selectedSlot?: SelectedSlotTime;
  selectedBookingId?: string;
  availableViews: CALENDAR_VIEWS[];
  allResources: ResourceInput[];
  calendarEvents: EventInput[];
  canCreateEvent: boolean;
  canEditEvent: boolean;
  canAddTimeOff: boolean;
  timeOffModal: boolean;
  createBookingModal: boolean;
  isResource?: boolean;
  isPivotView: boolean,
  resourceLimit: number;
  minMaxCalendarTime?: MinMaXCalendarTimes;
  grandTotal: number;
  isLoading: boolean;
  initialView: CALENDAR_VIEWS;
  handleDateChange: (date: Date) => void;
  handleViewChange: (view: CALENDAR_VIEWS) => void;
  handleResourceChange: (resources: string[]) => void;
  handleSelectEvent: (event: EventApi) => void;
  setCreateBookingModal: (open: boolean) => void;
  setSelectedView: (view: CALENDAR_VIEWS) => void;
  setTimeOffModal: (open: boolean) => void;
  setSelectedResources: (resources: string[]) => void;
  handleSelectSlot: (slot: SelectedSlot | undefined) => void;
  fetchCalendarEntries: () => void;
  setSelectedTimeOff: (state: TimeOff | undefined) => void;
  setSelectedBookingId: (bookingId: string | undefined) => void;
  setSelectedDatePickerDate: (date: Date) => void;
  removeStoredResource: (resourceId: string) => void;
  updateStoredResourceTimeSelected: (resourceId: string) => void;
}

const placeholderFunc = () => {
  throw new Error("Not implemented.");
};

export const AppCalendarContext = createContext<IAppCalendarContext>({
  selectedDate: new Date(),
  selectedView: CALENDAR_VIEWS.DAY_VIEW,
  selectedResources: [],
  selectedDatePickerDate: new Date(),
  availableViews: [CALENDAR_VIEWS.DAY_VIEW, CALENDAR_VIEWS.WEEK_VIEW],
  allResources: [],
  calendarEvents: [],
  canCreateEvent: false,
  canEditEvent: false,
  canAddTimeOff: false,
  timeOffModal: false,
  createBookingModal: false,
  resourceLimit: 12,
  grandTotal: 0,
  isPivotView: false,
  isLoading: false,
  initialView: CALENDAR_VIEWS.DAY_VIEW,
  handleDateChange: placeholderFunc,
  handleViewChange: placeholderFunc,
  handleResourceChange: placeholderFunc,
  handleSelectSlot: placeholderFunc,
  handleSelectEvent: placeholderFunc,
  setCreateBookingModal: placeholderFunc,
  setTimeOffModal: placeholderFunc,
  setSelectedView: placeholderFunc,
  setSelectedTimeOff: placeholderFunc,
  setSelectedDatePickerDate: placeholderFunc,
  setSelectedBookingId: placeholderFunc,
  setSelectedResources: placeholderFunc,
  fetchCalendarEntries: placeholderFunc,
  removeStoredResource: placeholderFunc,
  updateStoredResourceTimeSelected: placeholderFunc,
});

export const useCalendar: () => IAppCalendarContext = () =>
  useContext(AppCalendarContext);
