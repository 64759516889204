import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { postcodeExists } from "../../api/geocoding";
import {
  CustomerAddressFormValues,
  defaultCustomerAddressFormValues,
  customerAddressFormValidationFields,
} from "./CustomerAddressFormValues";
import { postcodeFieldName } from "./CustomerAddressFormValues";
import * as Yup from "yup";

interface CreateCustomerAddressFormProps {
  initialValues?: CustomerAddressFormValues;
  onSubmit: (address: CustomerAddressFormValues) => Promise<void>;
}

const CreateCustomerAddressForm: React.FC<CreateCustomerAddressFormProps> = ({
  initialValues,
  onSubmit,
  children,
}) => {
  const handleSubmit = async (
    values: CustomerAddressFormValues,
    { setFieldError }: FormikHelpers<CustomerAddressFormValues>
  ) => {
    const postcodeIsLocateable = await postcodeExists(values.postcode);

    if (postcodeIsLocateable.statusCode === 404) {
      setFieldError(postcodeFieldName, "Could not find this postcode.");
      return;
    }

    await onSubmit(values);
  };

  const FormValidationSchema: Yup.SchemaOf<CustomerAddressFormValues> =
    Yup.object().shape(customerAddressFormValidationFields);

  return (
    <Formik
      initialValues={initialValues || defaultCustomerAddressFormValues}
      validationSchema={FormValidationSchema}
      onSubmit={handleSubmit}
    >
      <Form>{children}</Form>
    </Formik>
  );
};

export default CreateCustomerAddressForm;
