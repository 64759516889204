import { makeStyles, List, ListItem } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { adminPaths } from "../../routes";
import { RequireAdminUserRole } from "../../auth";

const useStyles = makeStyles((theme) => ({
  isActive: {
    backgroundColor: "transparent",
    borderLeft: "none",
    color: `${theme.palette.primary.dark} !important`,
  },
  navItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    height: 56,
    marginLeft: 20,
    marginRight: 20,
    textDecoration: "none",
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    padding: "0 4rem",
    [theme.breakpoints.down("md")]: {
      padding: "0 0.75rem",
    },
  },
  navItemList: {
    marginLeft: -20,
    paddingTop: 36,
    marginTop: -50,
    marginBottom: 50,
    width: "110%",
    paddingLeft: "5%",
    display: "flex",
    flexDirection: "row",
    height: 100,
    justifyContent: "flex-start",
    align: "top",
    backgroundColor: theme.palette.backgrounds.lightBlue,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
    "& > li": {
      flex: "none",
      padding: 0,
      margin: 0,
      width: "auto",
    },
  },
}));

const ServicePageNavBar: React.FC = () => {
  const classes = useStyles();

  return (
    <List className={classes.navItemList}>
      <RequireAdminUserRole>
        <ListItem>
          <NavLink
            activeClassName={classes.isActive}
            className={classes.navItem}
            to={adminPaths.settings.services}
          >
            Services
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            activeClassName={classes.isActive}
            className={classes.navItem}
            to={adminPaths.settings.extras}
          >
            Extras
          </NavLink>
        </ListItem>
      </RequireAdminUserRole>
    </List>
  );
};

export default ServicePageNavBar;
