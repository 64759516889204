import { PageContainer } from "../components/MainLayout";
import BookingWidget from "../widget/BookingWidget";
import { darken, lighten } from "@material-ui/core";
import { useCallback } from "react";
import { useParams } from "react-router";
import { useHttpRequest } from "../api";
import { getVendorMicrositeConfig } from "../api/microsite";
import AppTheme from "../theme/AppTheme";
import AppLoader from "../components/AppLoader";

interface PageParams {
  vendorId: string;
}

const WidgetPage: React.FC = () => {
  const { vendorId } = useParams<PageParams>();
  const getConfig = useCallback(
    () => getVendorMicrositeConfig(parseInt(vendorId)),
    [vendorId]
  );
  const { result, isLoading } = useHttpRequest(getConfig);

  const appThemeConfig = {
    primaryColourMain: result?.primaryColour,
    primaryColourLight: result?.primaryColour
      ? lighten(result.primaryColour, 0.1)
      : undefined,
    primaryColourDark: result?.primaryColour
      ? darken(result.primaryColour, 0.1)
      : undefined,
    secondaryColourMain: result?.secondaryColour,
    secondaryColourLight: result?.secondaryColour
      ? lighten(result.secondaryColour, 0.1)
      : undefined,
    secondaryColourDark: result?.secondaryColour
      ? darken(result.secondaryColour, 0.1)
      : undefined,
  };

  document.title = result?.name
    ? `${result.name} | Powered by MyBookingHub`
    : "MyBookingHub - Automate your bookings, minimise your travel time";

  return (
    <AppTheme {...appThemeConfig}>
      {isLoading && <AppLoader />}
      {!isLoading && (
        <PageContainer>
          <BookingWidget />
        </PageContainer>
      )}
    </AppTheme>
  );
};

export default WidgetPage;
