import { makeStyles } from "@material-ui/core";
import { Form, useFormikContext } from "formik";
import AppButton from "../../components/AppButton";
import AppForm from "../../components/AppForm";
import AppButtonsSpacer from "../../components/AppForm";
import AppFormButtons from "../../components/AppForm/AppFormButtons";
import AppFormikSubmitButton from "../../components/AppForm/AppFormikSubmitButton";
import { useWindowSize } from "../../components/MainLayout/WindowSize";
import ServiceFormFields from "./ServiceFormFields";
import { useCallback } from "react";
import { useHttpRequest } from "../../api";
import { getAllExtras } from "../../api/appointmentTypes";

export type ServiceFormProps = {
  activeStep: number;
  isLastStep: boolean;
  onPreviousClick: () => void;
};

const useStyles = makeStyles((theme) => ({
  stepper: {
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: 450,
    [theme.breakpoints.down("sm")]: {
      minWidth: 350,
    },
  },
  label: {
    "& span": {
      fontSize: "10px",
      [theme.breakpoints.up("sm")]: {
        fontSize: theme.typography.fontSize,
      },
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "space-between",
  },
  rowFields: {
    paddingRight: 20,
  },
  textAreaContainer: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.backgrounds.grey,
    "& textarea": {
      padding: theme.spacing(1),
    },
  },
  stepContainerTitle: {
    margin: 0,
    color: theme.palette.grey[700],
    fontWeight: 400,
    fontSize: 10.5,
  },
  firstItem: {
    marginTop: 20,
  },
}));

const ServiceForm: React.FC<ServiceFormProps> = ({
  activeStep,
  isLastStep,
  onPreviousClick,
}) => {
  const { width } = useWindowSize();
  const classes = useStyles();
  const { submitForm } = useFormikContext();

  const getOptionalExtras = useCallback(() => getAllExtras(), []);
  const { result: services } = useHttpRequest(getOptionalExtras);

  const formatServiceLabel = (name: string, price: number) => {
    if (price != 0) {
      return `${name} (£${price.toFixed(2)})`;
    }

    return name;
  };
  const timeOptions = [0, 15, 30, 45, 60, 75, 90, 105, 120];

  const stepContent = [
    <>
      <div className={classes.row}>
        <ServiceFormFields.Name className={classes.firstItem} />
      </div>
      <div>
        <h5 className={classes.stepContainerTitle}>Description</h5>
        <ServiceFormFields.Description className={classes.textAreaContainer} />
      </div>

      {/* <ServiceFormFields.OptionalExtras
        services={services ? services.content.items : []}
        serviceMapper={(s: OptionalExtra) => ({
          value: s.id,
          label: formatServiceLabel(s.name, s.price),
        })}
        serviceMatcher={(id) =>
          services?.content.items.find((s) => s.id === id)
        }
        serviceLabel={(s: OptionalExtra) => formatServiceLabel(s.name, s.price)}
      /> */}
      <div className={classes.row}>
        <ServiceFormFields.Price className={classes.rowFields} />
        <ServiceFormFields.Duration
          options={timeOptions.map((time) => ({
            value: time,
            label: `${time} minutes`,
          }))}
        />
      </div>
      <div className={classes.row}>
        <ServiceFormFields.isBackOffice />
      </div>
      <div className={classes.row}>
        <ServiceFormFields.isAvailable />
      </div>
    </>,
  ];

  return (
    <Form>
      <div>
        <AppForm>{stepContent[activeStep]}</AppForm>
        <AppFormButtons>
          {activeStep !== 0 && (
            <AppButton color="secondary" onClick={onPreviousClick}>
              Previous
            </AppButton>
          )}
          <AppButtonsSpacer />
          {!isLastStep && <AppButton onClick={submitForm}>Next</AppButton>}
          {isLastStep && (
            <>
              <AppFormikSubmitButton>{"Save"}</AppFormikSubmitButton>
            </>
          )}
        </AppFormButtons>
      </div>
    </Form>
  );
};

export default ServiceForm;
