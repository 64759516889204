import { useEffect } from "react";
import { Layer, LayerProps, Source } from "react-map-gl";
import * as turf from "@turf/turf";
import { mapLayerStyle, Viewport } from ".";

interface CoverageMapOutcodeDistrictProps {
  outcode: string;
  geoJson: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[];
  handleViewportChange: (viewport: Viewport) => void;
}

const CoverageMapOutcodeDistrict: React.FC<CoverageMapOutcodeDistrictProps> = ({
  outcode,
  geoJson,
  handleViewportChange,
}) => {
  const style = (id: number) =>
    mapLayerStyle(
      `${outcode}-polygon-${id}-${new Date().toTimeString()}`,
      "fill"
    );

  return (
    <>
      {geoJson.map((gj, idx) => (
        <Source
          key={`${outcode}-${idx}-${new Date().toTimeString()}`}
          id={`${outcode}-${idx}-district-${new Date().toTimeString()}`}
          type="geojson"
          data={gj}
        >
          <Layer {...style(idx)} key={outcode} />
        </Source>
      ))}
    </>
  );
};

export default CoverageMapOutcodeDistrict;
