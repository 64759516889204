import ResourceForm from "../Form";
import { editResource, getResourceById } from "../../api/resources";
import { actions } from "../../store";
import { useDispatch } from "react-redux";
import { successToast } from "../../toast";
import AppModal from "../../components/AppModal";
import { ResourceFormValues } from "../Form/ResourceFormValues";
import { useCallback, useEffect } from "react";
import ApiResponseWrapper from "../../components/ApiResponseWrapper";
import { mapToApiWorkingHours } from "../../modelMappings/Resource";
import { useHttpRequest } from "../../api";
import { attachServices } from "../../api/services";

interface EditResourceModalProps {
  open: boolean;
  id: number;
  handleClose: () => void;
}

const EditResourceModal: React.FC<EditResourceModalProps> = ({
  open,
  handleClose,
  id,
}) => {
  const dispatch = useDispatch();
  const getResource = useCallback(() => getResourceById(id, true), [id]);
  const { result: resource, isLoading, isError } = useHttpRequest(getResource);

  const handleEditResource = async (
    resourceDetails: ResourceFormValues
  ): Promise<void> => {
    const response = await editResource({
      ...resourceDetails,
      id,
      bookableFrom: resourceDetails.bookableFrom ?? undefined,
      bookableUntil: resourceDetails.bookableUntil ?? undefined,
      maxTravelDistanceMiles:
        resourceDetails.coverageType === "radius"
          ? resourceDetails.maxTravelDistanceMiles
          : undefined,
      basePostcode: resourceDetails.basePostcode,
      coveredOutcodes:
        resourceDetails.coverageType === "outcodeList" &&
        resourceDetails.coveredOutcodes
          ? resourceDetails.coveredOutcodes
          : undefined,
      workingHours: mapToApiWorkingHours(resourceDetails.workingHours),
      tagIds: resourceDetails.tags.map((t) => t.id),
    });

    const serviceResponse = await attachServices(
      resourceDetails.appointmentTypeCapabilities.map((s) => s.id) ?? [],
      resource?.id ?? 0
    );

    if (!response.isError && !serviceResponse.isError) {
      dispatch(actions.resources.fetchAll());
      handleClose();
      successToast("Mobile worker has been updated");
    }
  };

  useEffect(() => {
    if (resource?.outcodesGeoJson) {
      dispatch(
        actions.outcodes.addMultipleOutcodeGeoJson(resource.outcodesGeoJson)
      );
    }
  }, [resource]);

  return (
    <AppModal isOpen={open} onClose={handleClose} title="Edit Mobile Worker">
      <ApiResponseWrapper isError={isError} isFetching={isLoading}>
        {resource && (
          <ResourceForm
            onSubmit={(resourceDetails: ResourceFormValues) =>
              handleEditResource(resourceDetails)
            }
            isEdit
            initialValues={resource}
          />
        )}
      </ApiResponseWrapper>
    </AppModal>
  );
};

export default EditResourceModal;
