import { IconButton, InputAdornment, makeStyles } from "@material-ui/core";
import { AddCircle as AddIcon } from "@material-ui/icons";
import { ChangeEvent, KeyboardEvent, useRef, useState, useEffect } from "react";
import { sanitize } from "../../postcodeUtils";
import AppChip from "../AppChip";
import AppFormError from "./AppFormError";
import AppTextField from "./AppTextField";

export interface AppTagInputProps {
    value: string[];
    placeholder: string;
    label: string;
    error?: boolean;
    helperText?: string;
    validation?: (value: string) => (Promise<boolean> | boolean);
    onChange(value: string[]): void;
}

const useStyles = makeStyles((theme) => ({
    container: {
        
    },
    tagList: {
        listStyle: "none",
        margin: 0,
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        backgroundColor: theme.palette.backgrounds.grey,
        minHeight: 100,
        maxHeight: 168,
        overflowY: "auto",
        flexWrap: "wrap",
        "& > li": {
            display: "inline-block",
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1)
        }
    },
}));

const AppTagInput: React.FC<AppTagInputProps> = ({
    value,
    placeholder,
    label,
    error,
    helperText,
    validation,
    onChange
}) => {
    const classes = useStyles();
    const endOfList = useRef<HTMLLIElement>(null);
    const [inputValue, setInputValue] = useState("");
    const [inputError, setInputError] = useState<string>();

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputError(undefined);
        setInputValue(e.currentTarget.value.toUpperCase());
    };

    const handleAddTag = async () => {
        setInputError(undefined);
        const sanitizedInput = sanitize(inputValue);

        if(sanitizedInput === "") {
            setInputError("Input value cannot be empty");
        }

        if(sanitizedInput !== "") {
            if(validation) {
                const isValid = await validation(sanitizedInput);

                if(!isValid) {
                    setInputError("Please enter a valid value");
                    return;
                }
            }

            if(value.includes(sanitizedInput)) {
                setInputError(`A duplicate value already exists matching "${inputValue}""`);
            }

            if(!value.includes(sanitizedInput)) {
                const newValue = [...value, sanitizedInput];
                setInputValue("");
                onChange(newValue);
            }
        }
    };

    const handleRemoveTag = (tag: string) => {
        const newValue = [...value].filter(val => val !== tag);
        onChange(newValue);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
        
        if(e.key === "Enter") {
            e.preventDefault();
            handleAddTag();
        }

        if(e.key === "Escape") {
            e.preventDefault();
            setInputError("");
            setInputValue("");
        }

    };

    const scrollToBottom = () => {
        endOfList.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [value]);

    return (
        <div className={classes.container}>
            <AppTextField
                    placeholder={placeholder}
                    label={label}
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    error={error}
                    helperText={helperText}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleAddTag}>
                                <AddIcon />
                            </IconButton>
                        </InputAdornment>
                    )}} 
                />
            <AppFormError show={!!inputError}>{inputError}</AppFormError>
            <ul className={classes.tagList}>
                {value.map(tag =>
                    <li key={tag}>
                        <AppChip size="small" color="primary" label={tag} onDelete={() => handleRemoveTag(tag)} />
                    </li>
                )}
                <li ref={endOfList}></li>
            </ul>
        </div>
    );
};

export default AppTagInput;