import { makeStyles, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AlgorithmDemoResource } from "../../models/AlgorithmDemo/Resource";
import { actions, selectors } from "../../store";
import ResourceCalendar from "./ResourceCalendar";
import OfferedSlots from "./OfferedSlots";
import AlgorithmDemoConfigControls from "./ConfigControls";
import BookingEditor from "./BookingEditor";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "flex-start",
    margin: "30px",
    "& > *:not(:last-child)": {
      marginRight: "10px",
    },
  },
  leftColumn: {
    "& > *:first-child": {
      marginBottom: "10px",
    },
  },
  resourceCalendars: {
    display: "flex",
    "& > *:not(:first-child)": {
      marginLeft: "10px",
    },
  },
  testControls: {
    marginLeft: "1rem",
    "& > *:not(:last-child)": {
      marginBottom: "1rem",
    },
  },
});

const resources: AlgorithmDemoResource[] = [
  { id: 1, name: "Ben", postcode: "EH4 2TT" },
  { id: 2, name: "Gavin", postcode: "EH12 0AA" },
];

const startTimeMins = 8.75 * 60;
const finishTimeMins = 17.5 * 60;

const AlgorithmDemoPage: React.FC = () => {
  const classNames = useStyles();
  const dispatch = useDispatch();
  const offeredSlots = useSelector(selectors.algorithmDemo.allOfferedSlots);
  const logs = useSelector(selectors.algorithmDemo.logs);

  useEffect(() => {
    dispatch(
      actions.algorithmDemo.reset(resources, startTimeMins, finishTimeMins)
    );
  }, []);

  return (
    <>
      <Typography align="center" variant="h3">
        Algorithm Demo
      </Typography>
      <div className={classNames.root}>
        <div className={classNames.leftColumn}>
          <BookingEditor />
          <div className={classNames.resourceCalendars}>
            {resources.map((resource) => (
              <ResourceCalendar key={resource.id} resourceId={resource.id} />
            ))}
          </div>
        </div>
        <div className={classNames.testControls}>
          <Typography variant="h6">Test Algorithm</Typography>
          <AlgorithmDemoConfigControls
            startTimeMins={startTimeMins}
            finishTimeMins={finishTimeMins}
            resources={resources}
          />
          <div>
            <Typography>{resources[0].name}</Typography>
            <OfferedSlots
              slots={offeredSlots.filter(
                (s) => s.resourceId === resources[0].id
              )}
            />
          </div>
          <div>
            <Typography>{resources[1].name}</Typography>
            <OfferedSlots
              slots={offeredSlots.filter(
                (s) => s.resourceId === resources[1].id
              )}
            />
          </div>
          <div>
            <Typography>
              Logs - please note that all times below are GMT i.e. not
              accounting for daylight savings
            </Typography>
            <pre>{logs}</pre>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlgorithmDemoPage;
