import { useTheme } from "@material-ui/core";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  LabelList,
} from "recharts";

interface AppBarChartProps {
  data: BarChartDatum[];
}

export interface BarChartDatum {
  name: string;
  value: number;
  color: string;
  percent: number;
}

const AppBarChart: React.FC<AppBarChartProps> = ({ data }) => {
  const theme = useTheme();

  const renderLabel = (props: any) => {
    const { x, y, width, height, value, name } = props;
    const percent = data.find((d) => d.name === name)?.percent;

    const labelText = `${name} - ${value}hrs (${percent}%)`;

    const showTextOutsideBar = (percent || 0) < 50;
    const offset = showTextOutsideBar ? -10 : 10;

    return (
      <text
        x={x + width - offset}
        y={y + height - 12.5}
        textAnchor={showTextOutsideBar ? "start" : "end"}
        color={theme.palette.primary.dark}
        fontSize="0.875rem"
      >
        {labelText}
      </text>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart layout="vertical" data={data}>
        <XAxis type="number" />
        <YAxis type="category" dataKey="name" hide={true} />
        <Bar barSize={34} radius={15} dataKey="value">
          <LabelList
            dataKey="value"
            position="insideRight"
            content={renderLabel}
          />
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default AppBarChart;
