import {
  makeStyles,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import AppButton from "../../components/AppButton";
import { BookedExtra, job, Jobs } from "../../models/Booking";
import { AppButtonsSpacer } from "../../components/AppForm";
import WidgetCustomerDetails from "./WidgetCustomerDetails";
import WidgetBookingDetails from "./WidgetBookingDetails";
import AppFormikSubmitButton from "../../components/AppForm/AppFormikSubmitButton";
import { Form, useField } from "formik";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import WidgetStepOne from "./WidgetStepOne";
import WidgetDateAndTime from "./WidgetDateAndTime";
import WidgetSummary from "./WidgetSummary";
import WidgetSummaryBox from "./WidgetSummaryBox";
import { useWindowSize } from "../../components/MainLayout/WindowSize";
import AppFormError from "../../components/AppForm/AppFormError";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "../../store";
import {
  calculateBasketTotal,
} from "../../numericalUtils";
import {
  days,
  formatDateSuffix,
  formatMinutesAfterMidnightTo12HourWithAmPm,
} from "../../dateFormatters";
import BookingBasket from "../../components/bookings/basket/WidgetBookingBasket";

export interface WidgetBookingFormProps {
  activeStep: number;
  isLastStep: boolean;
  isSubmitStep: boolean;
  onPreviousClick: () => void;
  vendorId: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  currentTaxRatePercentage?: number;
}

const useStyles = makeStyles((theme) => ({
  actions: {
    marginRight: theme.spacing(2),
  },
  addressLine: {
    fontWeight: 400,
    paddingTop: theme.spacing(1),
    lineHeight: "18px",
  },
  container: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    marginBottom: theme.spacing(5),
  },
  dateTime: {
    alignItems: "center",
    display: "flex",
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down(601)]: {
      display: "block",
    },
  },
  dateLine: {
    lineHeight: "20px",
  },
  title: {
    [theme.breakpoints.down(350)]: {
      fontSize: "1.15rem",
    },
    [theme.breakpoints.up(350)]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down(601)]: {
      textAlign: "center",
    },
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
    [theme.breakpoints.up(500)]: {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.down(601)]: {
      display: "block",
    },
  },
  root: {
    width: "90%",
  },
  mobileStepper: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    minWidth: "100%",
    "& .MuiStep-horizontal:last-of-type": {
      paddingRight: 0,
    },
  },
  stepper: {
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
  },
  lastStep: {
    paddingRight: 0,
  },
  line: {
    borderColor: theme.palette.grey[400],
    borderWidth: "4px",
    marginRight: theme.spacing(-2),
    marginLeft: theme.spacing(-2),
  },
  connectorActive: {
    "& $line": {
      borderColor: theme.palette.primary.main,
    },
  },
  connectorCompleted: {
    "& $line": {
      borderColor: theme.palette.primary.main,
    },
  },
  inline: {
    display: "flex",
  },
  firstConnectorBorder: {
    width: "60px",
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    margin: "0 0 4px -9px",
    [theme.breakpoints.down("sm")]: {
      width: "25px",
    },
  },
  lastConnector: {
    width: "60px",
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    margin: "0 0 4px -10px",
    [theme.breakpoints.down("sm")]: {
      width: "25px",
    },
  },
  lastConnectorBorder: {
    width: "60px",
    borderBottom: `4px solid ${theme.palette.grey[400]}`,
    margin: "0 0 4px -9px",
    [theme.breakpoints.down("sm")]: {
      width: "25px",
    },
  },
  iconComplete: {
    color: theme.palette.primary.main,
    fontSize: "12px",
    borderRadius: "100%",
  },
  stepIconRoot: {
    color: theme.palette.grey[400],
    fontSize: "12px",
    // Below styling required to remove tick from stepper
    "&.MuiStepIcon-completed": {
      borderRadius: "100%",
      backgroundColor: theme.palette.primary.main,
    },
  },

  cancel: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.grey[400]}`,
    "& button": {
      padding: 0,
      border: "none",
      background: "transparent",
      color: theme.palette.primary.main,
      cursor: "pointer",
      textDecoration: "underline",
      "&:hover": {
        textDecoration: "none",
      },
    },
  },
  nextSubmitButton: {
    [theme.breakpoints.down(601)]: {
      width: "100%",
    },
  },
  prevButton: {
    [theme.breakpoints.down(601)]: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
  },
  logo: {
    width: "200px",
    marginTop: theme.spacing(2),
  },
  logoDiv: {
    marginTop: theme.spacing(2),
    borderTop: `2px solid ${theme.palette.grey[300]}`,
    [theme.breakpoints.up(600)]: {
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.down(601)]: {
      borderTop: `1px solid ${theme.palette.grey[500]}`,
      textAlign: "center",
      width: "100%",
    },
  },
  outerContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  header: {
    flex: "none",
    backgroundColor: theme.palette.common.white,
    zIndex: 1,
  },
  main: {
    position: "relative",
    flex: 1,
    overflow: "hidden",
  },
  mainContent: {
    position: "relative",
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(6),
    scrollbarWidth: "thin",
    scrollbarColor: theme.palette.grey[300],
    "&::-webkit-scrollbar": {
      width: 8,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 10,
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.5)",
      backgroundColor: theme.palette.grey[300],
    },
  },
  stepContent: {
    marginLeft: theme.spacing(1),
  },
  footer: {
    flex: "none",
    backgroundColor: theme.palette.common.white,
    zIndex: 1,
  },
  scrollShadowTop: {
    position: "absolute",
    zIndex: 1,
    height: 20,
    width: "100%",
    top: 0,
    left: 0,
    backgroundImage:
      "radial-gradient(farthest-side at 50% 0%, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%)",
    pointerEvents: "none",
  },
  scrollShadowBottom: {
    height: 20,
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    backgroundImage:
      "radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%)",
    zIndex: 1,
    pointerEvents: "none",
  },
  errorMsg: {
    paddingTop: theme.spacing(2),
    textAlign: "center",
  },
  mobileErrorMsg: {
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
}));

const WidgetBookingForm: React.FC<WidgetBookingFormProps> = ({
  activeStep,
  isLastStep,
  onPreviousClick,
  setActiveStep,
  isSubmitStep,
  vendorId,
  currentTaxRatePercentage,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  // #region useField hooks
  const [appointmentTypeInputProps, appointmentTypeMeta] = useField<number>(
    "firstJobAppointmentTypeId"
  );
  const [requiresExtras, , optionalExtrasHelpers] = useField("requiresOptionalExtra");
  const [{ value: isInviteBooking }, ,] = useField<boolean>("isInviteBooking");
  let [{ value: date }, ,] = useField<Date | null>("date");
  const [{ value: start }, ,] = useField<number>("start");
  const [{ value: end }, ,] = useField<number>("end");
  const [{ value: addressLine1 }, ,] = useField<string>("addressLine1");
  const [{ value: addressLine2 }, ,] = useField<string>("addressLine2");
  const [{ value: addressLine3 }, ,] = useField<string>("addressLine3");
  const [{ value: town }, ,] = useField<string>("town");
  const [{ value: postcode }, ,] = useField<string>("postcode");
  const [selectedServicePrice, , setSelectedServicePrice] = useField<number>(
    "selectedServicePrice"
  );
  const [
    { value: selectedOptionalExtras },
    selectedOptionalExtrasMeta,
    setSelectedOptionalExtras,
  ] = useField<BookedExtra[]>("firstJobOptionalExtras");
  const [
    { value: selectedService },
    ,
    { setValue: setFirstJobAppointmentTypeId },
  ] = useField<number>("firstJobAppointmentTypeId");
  const [, resourceIdMeta] = useField<number>("resourceId");
  const [{value: selectedServiceBasketKey}, , {setValue: setSelectedServiceBasketKey}] = useField<number>("selectedServiceBasketKey");

  const [isUpdatingExistingService, setIsUpdatingExistingService] = useState<boolean>(false);
  const handleEditService = (service: Jobs) => {
    setIsUpdatingExistingService(true);
    setSelectedServiceBasketKey(service.key ?? 0);
    setSelectedOptionalExtras.setValue(service.optionalExtras);
    setFirstJobAppointmentTypeId(service.appointmentTypeId);
    setSelectedServicePrice.setValue(service.price);
  };

  // #endregion

  if (date) date = new Date(date);

  // #region useState hooks
  const [bottomScrollShadow, setBottomScrollShadow] = useState(false);
  const [selectedPostcode, setSelectedPostcode] = useState<string>("");
  const [slotSelected, setSlotSelected] = useState(false);
  const [topScrollShadow, setTopScrollShadow] = useState(false);
  // #endregion

  // #region useRef hooks
  const mainContentRef = useRef<HTMLDivElement>(null);
  // #endregion

  // #region useSelector hooks
  const appointmentTypes = useSelector(
    selectors.appointmentTypes.allAppointmentTypes
  );
  // #endregion

  // #region useEffect hooks
  useEffect(() => {
    if (!selectedService) {
      return;
    }

    const selectedAppointmentType = appointmentTypes.find(
      (_) => _.id == appointmentTypeInputProps.value
    );
    optionalExtrasHelpers.setValue(
      selectedAppointmentType?.optionalExtraRequired
    );
    setSelectedServicePrice.setValue(selectedAppointmentType?.price ?? 0);
    setService({
      appointmentTypeId: appointmentTypeInputProps.value,
      optionalExtras: selectedOptionalExtras,
      price: selectedAppointmentType?.price ?? 0,
      overriddenPrice: null,
      key: selectedServiceBasketKey
    });
  }, [selectedService]);

  useEffect(() => {
    if (selectedPostcode)
      dispatch(
        actions.appointmentTypes.fetchPublic({
          postcode: selectedPostcode,
          id: vendorId,
        })
      );
  }, [selectedPostcode]);

  useEffect(() => {
    scrollToTop();

    if (activeStep < 2) {
      setSlotSelected(false);
    }
  }, [activeStep]);

  useEffect(() => {
    const handleScrollPosition = () => {
      const viewPortHeight = mainContentRef.current?.offsetHeight;
      const totalHeight = mainContentRef.current?.scrollHeight;
      const position = mainContentRef.current?.scrollTop;

      if (position && totalHeight && viewPortHeight) {
        const hasNoScroll = totalHeight <= viewPortHeight;

        if (hasNoScroll) {
          setTopScrollShadow(false);
          setBottomScrollShadow(false);
        }

        if (!hasNoScroll) {
          if (position > 10) {
            setTopScrollShadow(true);
          }

          if (position < 10) {
            setTopScrollShadow(false);
          }

          if (position < totalHeight - 10) {
            setBottomScrollShadow(true);
          }

          if (position > totalHeight - (viewPortHeight + 10)) {
            setBottomScrollShadow(false);
          }
        }
      }
    };

    if (mainContentRef && mainContentRef.current) {
      mainContentRef.current.addEventListener(
        "scroll",
        handleScrollPosition,
        false
      );

      return () =>
        mainContentRef.current?.removeEventListener(
          "scroll",
          handleScrollPosition,
          false
        );
    }
  }, []);
  // #endregion

  // #region form event handlers
  const handleSlotSelected = (bool: boolean) => {
    setSlotSelected(bool);
  };

  const onPreviousClickOptionalExtras = () => {
    setFirstJobAppointmentTypeId(0);
    setActiveStep(0);
    setSelectedOptionalExtras.setValue([]);
  };

  const handleOnNextClick = () => {
    if (activeStep !== 1) {
      return;
    }

    if (requiresExtras.value && selectedOptionalExtras.length == 0) {
      return;
    }

    // if ((requiresExtras.value && selectedOptionalExtras.length > 0) && activeStep === 1) {
      handleSaveService();
      resetBasketJobState();
      if (basketStoreServices.length > 0) {
        setFirstJobAppointmentTypeId(service.appointmentTypeId);
      }
    // }
  };

  const handleOnPreviousClick = () => {
    if (activeStep === 2) {
      setFirstJobAppointmentTypeId(0);
      setSelectedOptionalExtras.setValue([]);
      resetBasketJobState();
    }

    const selectedAppointmentType = appointmentTypes.find(
      (appointmentType) => appointmentType.id === selectedService
    );

    if (
      selectedService !== -1 &&
      selectedAppointmentType &&
      selectedAppointmentType.availableOptionalExtras.length > 0 &&
      activeStep === 1
    ) {
      setFirstJobAppointmentTypeId(0);
      setSelectedOptionalExtras.setValue([]);
    } else {
      onPreviousClick();
    }
  };

  const [service, setService] = useState<Jobs>(job);

  const shallowCopyService = (service: Jobs): Jobs => {
    const serviceCopy = { ...service };
    // service prices cannot be overridden via the widget
    // remove this once happy things work
    serviceCopy.overriddenPrice = null;

    serviceCopy.optionalExtras = selectedOptionalExtras.map((extra) => {
      const t = availableOptionalExtras.find(_ => _.id === extra.optionalExtraId);
      const extraCopy = {...extra};
      extraCopy.price = t ? t.price : 1;
      return extraCopy;
    });

    return serviceCopy;
  };

  const calculateServiceTotal = (service: Jobs) => {
    let serviceTotal = 0;
    const isServicePriceOverridden = service.overriddenPrice != null;

    service.optionalExtras.forEach(extra => {
      serviceTotal += extra.price * extra.quantity;
    });

    return isServicePriceOverridden ? service.overriddenPrice ?? 0 : serviceTotal + (service.price ?? 0);
  };

  const basketStoreServices = useSelector(selectors.basket.services);
  const basketStoreTotal = useSelector(selectors.basket.total);


  const handleSaveService = () => {
    if (requiresExtras.value && selectedOptionalExtras.length == 0) {
      setSelectedOptionalExtras.setTouched(true, true);
      return;
    }
    setSelectedOptionalExtras.setTouched(false, false);

    if (selectedService <= 0) {
      return;
    }

    const serviceCopy = shallowCopyService(service);
    const serviceTotal = calculateServiceTotal(serviceCopy);

    if (isUpdatingExistingService) {
      const services = basketStoreServices.filter(service => service.key != serviceCopy.key);
      services.push(serviceCopy);
      dispatch(actions.basket.setServices(services));
    } else {
      dispatch(actions.basket.addService(serviceCopy));
    }

    dispatch(actions.basket.setBasketTotal(basketStoreTotal + serviceTotal));
    setFirstJobAppointmentTypeId(0);
    setSelectedOptionalExtras.setValue([]);

    setService(job);
    setIsUpdatingExistingService(false);
  };
  // #endregion

  useEffect(() => {
    if (basketStoreServices.length === 0) {
      setFirstJobAppointmentTypeId(-1);
      if (activeStep >= 2) {
        setActiveStep(1);
      }
    }
  }, [basketStoreServices.length]);

  const availableOptionalExtras =
      appointmentTypes.find((_) => _.id == appointmentTypeInputProps.value)
          ?.availableOptionalExtras || [];

  const selectedOptionalExtrasList = availableOptionalExtras.filter(
      (optionalExtra) =>
          selectedOptionalExtras.some(
              (extra) => extra.optionalExtraId === optionalExtra.id
          )
  );

  const resetBasketJobState = () => {
    setService(job);
    optionalExtrasHelpers.setValue(false);
    setSelectedOptionalExtras.setValue([]);
    setSelectedServicePrice.setValue(0);
  };

  // #region helpers
  const stepperActiveStep = isInviteBooking ? activeStep - 2 : activeStep;
  const dayOfTheWeek = date ? days[new Date(date).getDay()] : "";

  const scrollToTop = () => {
    mainContentRef.current?.scrollTo(0, 0);
    setTopScrollShadow(false);
  };

  const scrollToChildElement = (childPosition: number) => {
    mainContentRef.current?.scrollTo(0, childPosition);
    setTopScrollShadow(false);
  };

  const shouldRenderBasket = () => {
    return (
      (activeStep == 1 &&
        selectedService <= 0 &&
        calculateBasketTotal(basketStoreServices) > 0) ||
      activeStep === 2
    );
  };

  const shouldRenderSummary = () => {
    return activeStep === 1 && selectedService > 0;
  };

  const shouldRenderAddress = () => {
    return activeStep >= 2 && !isInviteBooking;
  };

  const bookingStepTitle = [
    selectedService > 0 && availableOptionalExtras.length != 0
      ? "Optional Extra Services"
      : "Book an Appointment",
    "What services do you need?",
    "Select your preferred date & time",
    "Your Details",
    "Your Booking",
    "Booking Confirmation",
  ];

  const stepContent = [
    <>
      <WidgetBookingDetails setSelectedPostcode={setSelectedPostcode} />
    </>,
    <>
      <WidgetStepOne
        appointmentTypes={appointmentTypes}
        scrollToTop={scrollToTop}
        currentTaxRatePercentage={currentTaxRatePercentage}
        isUpdatingExistingService={isUpdatingExistingService}
        setIsUpdatingExistingService={setIsUpdatingExistingService}
      />
    </>,
    <>
      <WidgetDateAndTime
        handleSlotSelected={handleSlotSelected}
        scrollToSlots={scrollToChildElement}
      />
    </>,
    <>
      <WidgetCustomerDetails />
    </>,
    <>
      <WidgetSummary
        appointmentTypes={appointmentTypes}
        selectedOptionalExtras={selectedOptionalExtrasList}
        bookedExtras={selectedOptionalExtras}
        currentTaxRatePercentage={currentTaxRatePercentage}
        basket={basketStoreServices}
      />
    </>,
    <>
      <WidgetSummaryBox
        appointmentTypes={appointmentTypes}
        isLastStep={isLastStep}
        slotSelected={slotSelected}
        selectedOptionalExtras={selectedOptionalExtrasList}
        bookedOptionalExtras={selectedOptionalExtras}
        currentTaxRatePercentage={currentTaxRatePercentage}
      />
    </>,
  ];
  // #endregion

  return (
    <Form>
      <div className={classes.outerContainer}>
        <div className={classes.header}>
          <h1 className={classes.title}>{bookingStepTitle[activeStep]}</h1>
          <Stepper
            activeStep={stepperActiveStep}
            className={
              width && width < 768 ? classes.mobileStepper : classes.stepper
            }
            connector={
              <StepConnector
                classes={{
                  active: classes.connectorActive,
                  completed: classes.connectorCompleted,
                  line: classes.line,
                }}
              ></StepConnector>
            }
          >
            {!isInviteBooking && (
              <Step>
                <span className={classes.inline}>
                  <div className={classes.firstConnectorBorder}></div>
                  <StepLabel
                    StepIconProps={{
                      classes: { root: classes.iconComplete },
                    }}
                    icon={" "}
                  ></StepLabel>
                </span>
              </Step>
            )}
            {!isInviteBooking && (
              <Step>
                <StepLabel
                  StepIconProps={{
                    classes: { root: classes.stepIconRoot },
                  }}
                  icon={" "}
                ></StepLabel>
              </Step>
            )}
            <Step>
              <StepLabel
                StepIconProps={{
                  classes: { root: classes.stepIconRoot },
                }}
                icon={" "}
              ></StepLabel>
            </Step>
            <Step>
              <StepLabel
                StepIconProps={{
                  classes: { root: classes.stepIconRoot },
                }}
                icon={" "}
              ></StepLabel>
            </Step>
            <Step>
              <StepLabel
                StepIconProps={{
                  classes: { root: classes.stepIconRoot },
                }}
                icon={" "}
              ></StepLabel>
            </Step>
            <Step className={classes.lastStep}>
              <span className={classes.inline}>
                <StepLabel
                  StepIconProps={
                    isLastStep
                      ? {
                          classes: { root: classes.iconComplete },
                        }
                      : {
                          classes: { root: classes.stepIconRoot },
                        }
                  }
                  icon={" "}
                  className={
                    isLastStep ? classes.iconComplete : classes.stepIconRoot
                  }
                ></StepLabel>
                {isLastStep ? (
                  <div className={classes.lastConnector}></div>
                ) : (
                  <div className={classes.lastConnectorBorder}></div>
                )}
              </span>
            </Step>
          </Stepper>
        </div>
        <div className={classes.main}>
          {topScrollShadow && <div className={classes.scrollShadowTop}></div>}
          {bottomScrollShadow && (
            <div className={classes.scrollShadowBottom}></div>
          )}
          <div className={classes.mainContent} ref={mainContentRef}>
            {shouldRenderBasket() && (
              <BookingBasket
                currentTaxRatePercentage={currentTaxRatePercentage}
                canRemoveServices={[1].includes(activeStep) && !isUpdatingExistingService}
                canEditServices={[1].includes(activeStep) && !isUpdatingExistingService}
                handleEditService={handleEditService}
              >
                {shouldRenderAddress() && (
                  <p className={classes.addressLine}>
                    {addressLine1}, {addressLine2 && `${addressLine2}, `}
                    {addressLine3 && `${addressLine3}, `}
                    {town}, {postcode}
                  </p>
                )}
                {slotSelected && (
                  <p>
                    {start > 0 && (
                      <b className={classes.dateLine}>
                        {dayOfTheWeek} {date && formatDateSuffix(date)}
                      </b>
                    )}
                    {start > 0 && (
                      <b>
                        {" "}
                        | {formatMinutesAfterMidnightTo12HourWithAmPm(
                          start
                        )} - {formatMinutesAfterMidnightTo12HourWithAmPm(end)}
                      </b>
                    )}
                  </p>
                )}
              </BookingBasket>
            )}
            {shouldRenderSummary() && (
              <WidgetSummaryBox
                appointmentTypes={appointmentTypes}
                isLastStep={isLastStep}
                slotSelected={slotSelected}
                selectedOptionalExtras={selectedOptionalExtrasList}
                bookedOptionalExtras={selectedOptionalExtras}
                currentTaxRatePercentage={currentTaxRatePercentage}
              />
            )}
            <div className={classes.stepContent}>{stepContent[activeStep]}</div>
          </div>
        </div>
        <div className={classes.footer}>
          {screen.width < 601 && (
            <div className={classes.buttonGroup}>
              {activeStep === 1 && selectedService > 0 && (
                <>
                  <AppButton className={classes.nextSubmitButton} onClick={handleSaveService}>
                    Save Service
                  </AppButton>
                  <br /><br />
                </>
              )}
              {!isSubmitStep && !isLastStep && (
                <AppFormikSubmitButton className={classes.nextSubmitButton} onClick={handleOnNextClick}>
                  Next
                </AppFormikSubmitButton>
              )}
              {isSubmitStep && (
                <AppFormikSubmitButton className={classes.nextSubmitButton}>
                  Book now
                </AppFormikSubmitButton>
              )}
              <AppButtonsSpacer />
              {activeStep !== 0 && !isLastStep && (
                <AppButton
                  variant="text"
                  onClick={onPreviousClick}
                  className={classes.prevButton}
                >
                  Previous
                </AppButton>
              )}
              {activeStep === 0 &&
                selectedService > 0 &&
                availableOptionalExtras.length != 0 && (
                  <AppButton
                    variant="text"
                    onClick={onPreviousClickOptionalExtras}
                    className={classes.prevButton}
                  >
                    Previous
                  </AppButton>
                )}
            </div>
          )}

          <AppFormError
            show={
              !!(appointmentTypeMeta.touched && appointmentTypeMeta.error)
            }
          >
            <div
              className={
                width && width < 768 ? classes.mobileErrorMsg : classes.errorMsg
              }
            >
              {" "}
              {appointmentTypeMeta.error}
            </div>
          </AppFormError>

          <AppFormError
            show={
              !!(selectedOptionalExtrasMeta.touched &&
                  selectedOptionalExtrasMeta.error)
            }
          >
            <div
              className={
                width && width < 768 ? classes.mobileErrorMsg : classes.errorMsg
              }
            >
              {" "}
              Please select at least one extra
            </div>
          </AppFormError>

          <AppFormError
            show={!!(resourceIdMeta.touched && resourceIdMeta.error)}
          >
            <div
              className={
                width && width < 768 ? classes.mobileErrorMsg : classes.errorMsg
              }
            >
              {" "}
              Please select a booking slot
            </div>
          </AppFormError>

          {screen.width > 600 && (
            <div className={classes.buttonGroup}>
              {activeStep !== 0 &&
                !isLastStep &&
                (isInviteBooking ? activeStep > 2 : true) && (
                  <AppButton
                    variant="text"
                    onClick={handleOnPreviousClick}
                    className={classes.nextSubmitButton}
                  >
                    Previous
                  </AppButton>
                )}
              {activeStep === 0 &&
                selectedService > 0 &&
                availableOptionalExtras.length != 0 && (
                  <AppButton
                    variant="text"
                    onClick={onPreviousClickOptionalExtras}
                    className={classes.nextSubmitButton}
                  >
                    Previous
                  </AppButton>
                )}
              <AppButtonsSpacer />

              {activeStep === 1 && selectedService > 0 && (
                  <div className={classes.actions}>
                    <AppButton onClick={handleSaveService}>
                      Save Service
                    </AppButton>
                  </div>
              )}

              {!isSubmitStep && !isLastStep && (
                <AppFormikSubmitButton
                  gradient={false}
                  className={classes.nextSubmitButton}
                  onClick={handleOnNextClick}
                >
                  Next
                </AppFormikSubmitButton>
              )}
              {isSubmitStep && (
                <AppFormikSubmitButton>Book now</AppFormikSubmitButton>
              )}
            </div>
          )}

          <div className={classes.logoDiv}>
            <Link
              to={{ pathname: "https://www.mybookinghub.co.uk" }}
              target="_blank"
            >
              <img
                className={classes.logo}
                src="/assets/images/powered-by-logo.svg"
                alt="My Booking Hub Logo"
              />
            </Link>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default WidgetBookingForm;
