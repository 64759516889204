import { Route, Switch, Redirect, useParams } from "react-router-dom";
import { RequireNonAuthenticatedUser } from "../../auth";
import ForgottenPasswordPage from "../../pages/ForgottenPassword";
import InviteToBookPage from "../../pages/InviteToBook";
import LoginPage from "../../pages/Login";
import MicrositePage from "../../pages/Microsite";
import ResetPasswordPage from "../../pages/ResetPassword";
import WidgetPage from "../../pages/Widget";

export const publicPaths = {
  home: "/",
  forgottenPassword: "/forgotten-password",
  resetPassword: "/reset-password",
  widget: (vendorId: string | number): string => `/widget/${vendorId}`,
  microsite: (vendorId: string | number): string => `/microsite/${vendorId}`,
  inviteToBook: (vendorId: string | number, guid : string): string => `/invite/${vendorId}/${guid}`,
};

export const useWidgetVendorId = (): number => {
  const { vendorId } = useParams<{ vendorId: string }>();
  return parseInt(vendorId);
};

export const useInviteToBookGuid = (): string => {
  const { guid } = useParams<{ guid: string }>();
  return guid;
};

// Routes available only when logged out
const PublicRoutes: React.FC = () => {
  return (
    <RequireNonAuthenticatedUser>
      <Switch>
        <Route path={publicPaths.home} exact>
          <LoginPage />
        </Route>
        <Route path={publicPaths.forgottenPassword} exact>
          <ForgottenPasswordPage />
        </Route>
        <Route path={publicPaths.resetPassword} exact>
          <ResetPasswordPage />
        </Route>
        <Route path={publicPaths.widget(":vendorId")} exact>
          <WidgetPage />
        </Route>
        <Route path={publicPaths.microsite(":vendorId")} exact>
          <MicrositePage />
        </Route>
        <Route path={publicPaths.inviteToBook(":vendorId", ":guid")} exact>
          <InviteToBookPage />
        </Route>
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </RequireNonAuthenticatedUser>
  );
};

export default PublicRoutes;
