import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { editCustomer } from "../../api/customers";
import AppForm from "../../components/AppForm";
import AppFormikSubmitButton from "../../components/AppForm/AppFormikSubmitButton";
import AppModal from "../../components/AppModal";
import { actions } from "../../store";
import { successToast } from "../../toast";
import { CustomerFields } from "../Form/Fields";
import CustomerEditForm from "../Form/CustomerEditForm";
import { CustomerDetailsFormValues } from "../Form/CustomerFormValues";
import { ApiUpdateCustomerDto } from "../../api/models/Customer";
import { CustomerDetails } from "../../models/Customers";
import InformationTextBox from "../../components/InformationTextBox/InformationTextBox";

interface EditCustomerModalProps {
  open: boolean;
  customer: CustomerDetails;
  handleClose: (customerEdited: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const EditCustomerModal: React.FC<EditCustomerModalProps> = ({
  open,
  handleClose,
  customer,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleEditCustomer = async (
    customerDetails: CustomerDetailsFormValues
  ): Promise<void> => {
    const customerDto: ApiUpdateCustomerDto = {
      id: customer.id,
      firstName: customerDetails.firstName,
      lastName: customerDetails.lastName,
      email: customerDetails.email,
      company: customerDetails.company,
      phoneNumber: customerDetails.phoneNumber,
    };

    const response = await editCustomer(customerDto);

    if (!response.isError) {
      dispatch(actions.customers.fetchAll());
      handleClose(true);
      successToast("Customer has been updated");
    }
  };

  return (
    <>
      <AppModal
        isOpen={open}
        onClose={() => handleClose(false)}
        title="Edit Customer"
      >
        <InformationTextBox>
          Please edit the customer details you wish to change:
        </InformationTextBox>
        {customer && (
          <CustomerEditForm
            initialValues={customer}
            onSubmit={handleEditCustomer}
          >
            <AppForm>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <CustomerFields.firstName autoFocus />
                </Grid>
                <Grid item xs={6}>
                  <CustomerFields.lastName />
                </Grid>
                <Grid item xs={6}>
                  <CustomerFields.company />
                </Grid>
                <Grid item xs={6}>
                  <CustomerFields.email />
                </Grid>
                <Grid item xs={6}>
                  <CustomerFields.phoneNumber />
                </Grid>
              </Grid>
            </AppForm>
            <div className={classes.buttonGroup}>
              <AppFormikSubmitButton>Update Customer</AppFormikSubmitButton>
            </div>
          </CustomerEditForm>
        )}
      </AppModal>
    </>
  );
};

export default EditCustomerModal;
