import AppFormikSelect, { AppFormikSelectProps } from "./AppFormikSelect";

export type CreatePreconfiguredAppFormikSelectOptions = {
  name: string;
  label: string;
  pleaseSelectText?: string;
};

export type PreconfiguredAppFormikSelectProps = Omit<
  AppFormikSelectProps,
  "name"
>;

export const createPreconfiguredAppFormikSelect = ({
  name,
  label,
  pleaseSelectText,
}: CreatePreconfiguredAppFormikSelectOptions): React.FC<PreconfiguredAppFormikSelectProps> => {
  const PreConfiguredAppFormikSelect = (
    restOfProps: PreconfiguredAppFormikSelectProps
  ) => (
    <AppFormikSelect
      label={label}
      pleaseSelectText={pleaseSelectText}
      {...restOfProps}
      name={name}
    />
  );

  return PreConfiguredAppFormikSelect;
};

export default createPreconfiguredAppFormikSelect;
