import { makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { selectors } from "../../store";
import ResourceCalendarSlot from "./ResourceCalendarSlot";

export interface ResourceCalendarProps {
  resourceId: number;
}

const useStyles = makeStyles({
  root: {
    "& > *:not(:first-child)": {
      borderTop: "1px solid dodgerBlue",
    },
  },
});

const ResourceCalendar: React.FC<ResourceCalendarProps> = ({ resourceId }) => {
  const classNames = useStyles();
  const slots = useSelector(
    selectors.algorithmDemo.makeSlotsForResourceSelector(resourceId)
  );
  const resource = useSelector(
    selectors.algorithmDemo.makeSelectResourceById(resourceId)
  );

  return (
    <div className={classNames.root}>
      <Typography align="center" variant="h6" gutterBottom>
        {resource?.name} ({resource?.postcode})
      </Typography>
      {slots.map((slot) => (
        <ResourceCalendarSlot
          key={slot.startTotalMins}
          resourceId={resourceId}
          {...slot}
        />
      ))}
    </div>
  );
};

export default ResourceCalendar;
