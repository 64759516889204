import { makeStyles, Theme } from "@material-ui/core";
import { useField } from "formik";
import { OptionalExtra } from "../../models/AppointmentType";
import AppLoader from "../../components/AppLoader";
import AppButton from "../../components/AppButton";
import { boolean } from "yup";
import { useState } from "react";
import AppFormikSelect, {
  AppSelectOption,
} from "../../components/AppForm/AppFormikSelect";
import WidgetFormikOptionalExtrasItem from "./WidgetFormikOptionalExtrasItem";
import { BookedExtra } from "../../models/Booking";
import AppFormError from "../../components/AppForm/AppFormError";

type MakeStyleProps = {
  readonly?: boolean;
};

const useStyles = makeStyles<Theme, MakeStyleProps>((theme: Theme) => ({
  header: {
    paddingLeft: theme.spacing(0.1),
  },
  optionalExtasSelector: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(0),
    width: "100%",
    "& > *": {
      borderTop: "2px solid #dcdcdc",
    },
    "& > *:last-child": {
      borderBottom: "2px solid #dcdcdc",
    },
  },
  optionalExtra: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    [theme.breakpoints.down(599)]: {
      display: "block",
    },
  },
  mobileOptionalExtraInfo: {
    display: "flex",
  },
  mobileOptionalExtraName: {
    flexGrow: 2,
  },
  optionalExtraPrice: {
    fontWeight: "bold",
    marginRight: theme.spacing(2),
  },
  spacer: {
    flexGrow: 1,
  },
  button: {
    [theme.breakpoints.down(599)]: {
      width: "100%",
    },
  },
}));

export interface WidgetFormikOptionalExtrasSelectProps {
  availableOptionalExtras: OptionalExtra[];
  isLoading?: boolean;
  readonly?: boolean;
  currentTaxRatePercentage?: number;
}

const WidgetFormikOptionalExtrasSelect: React.FC<WidgetFormikOptionalExtrasSelectProps> =
  ({ availableOptionalExtras: availableOptions, isLoading, readonly,currentTaxRatePercentage }) => {
    const classes = useStyles({ readonly });

    const [
      { value: selectedOptionalExtras },
      meta,
      { setValue: setSelectedOptionalExtras },
    ] = useField<BookedExtra[]>("firstJobOptionalExtras");

    if (isLoading) {
      return <AppLoader />;
    }

    const numericOptions = (optionsNumber: number): AppSelectOption[] => {
      const numbersArray: number[] = Array.from(
        Array(optionsNumber),
        (_, i) => i++
      );
      return numbersArray.map((number) => ({
        value: number + 1,
        label: (number + 1).toString(),
      }));
    };

    return (
      <div>
        <div className={classes.optionalExtasSelector}>
          {availableOptions.map((optionalExtra) => (
            <WidgetFormikOptionalExtrasItem
              key={optionalExtra.id}
              optionalExtra={optionalExtra}
              selectedExtras={selectedOptionalExtras}
              setSelectedExtras={setSelectedOptionalExtras}
              options={numericOptions(10)}
              currentTaxRatePercentage={currentTaxRatePercentage}
            />
          ))}
        </div>
      </div>
    );
  };

export default WidgetFormikOptionalExtrasSelect;
