import { Layer, Source, LayerProps } from "react-map-gl";
import * as turf from "@turf/turf";
import { mapLayerStyle } from ".";

interface CoverageMapRadiusProps {
  longitude: number;
  latitude: number;
  radiusMiles: number;
}

interface RadiusOptions {
  steps: number;
  units: turf.Units;
  properties: { [x: string]: string };
}

const CoverageMapRadius: React.FC<CoverageMapRadiusProps> = ({
  longitude,
  latitude,
  radiusMiles: radius,
}) => {
  const center = [longitude, latitude];
  const options: RadiusOptions = { steps: 64, units: "miles", properties: {} };
  const circle = turf.circle(center, radius, options);

  const radiusCircle:
    | GeoJSON.Feature<GeoJSON.Geometry>
    | GeoJSON.FeatureCollection<GeoJSON.Geometry>
    | string = {
    type: "FeatureCollection",
    features: [circle],
  };

  const style = mapLayerStyle("mileRadius", "fill");

  return (
    <>
      <Source id="radius" type="geojson" data={radiusCircle}>
        <Layer {...style} />
      </Source>
    </>
  );
};

export default CoverageMapRadius;
