import { makeStyles, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { demoBookableSlotsQuery } from "../../api/bookableSlots";
import {
  ApiBookableSlotsDemoConfig,
  ApiBookableSlotsDemoRequestBooking,
  ApiBookableSlotsDemoRequestResource,
} from "../../api/models/BookableSlotsDemo";
import AppFormikSubmitButton from "../../components/AppForm/AppFormikSubmitButton";
import AppFormikTextField from "../../components/AppForm/AppFormikTextField";
import { chunkSizeMins } from "../../config/algorithmDemoConstants";
import { AlgorithmDemoResource } from "../../models/AlgorithmDemo/Resource";
import { AlgorithmDemoTimeSlot } from "../../models/AlgorithmDemo/TimeSlot";
import { actions, selectors } from "../../store";
import { convertToUtcTotalMinutes } from "./dateFormatters";
import { postcodeExists } from "../../api/geocoding";
import React, { useEffect, useState } from "react";
import AppFormikDatePicker from "../../components/AppFormikDatePicker/AppFormikDatePicker";
import AppFormikSelect, {
  AppSelectOption,
} from "../../components/AppForm/AppFormikSelect";
import { getAllAppointmentTypes } from "../../api/appointmentTypes";
import { ukPostcodeRegex } from "../../constants";

export interface AlgorithmDemoConfigControls {
  resources: AlgorithmDemoResource[];
  startTimeMins: number;
  finishTimeMins: number;
}

const initialValues: ApiBookableSlotsDemoConfig = {
  offeredSlotOffsetMins: 150,
  requestedDurationMins: 60,
  postcode: "",
  date: new Date(new Date().setDate(new Date().getDate() + 7)),
  appointmentTypeId: 1,
};

const useStyles = makeStyles({
  form: {
    "& > *:not(:last-child)": {
      marginBottom: "1rem",
    },
  },
});

const buildDemoResource = (
  resource: AlgorithmDemoResource,
  startTimeMins: number,
  finishTimeMins: number,
  slots: AlgorithmDemoTimeSlot[],
  getBookingId: () => number
): ApiBookableSlotsDemoRequestResource => {
  const bookings: ApiBookableSlotsDemoRequestBooking[] = [];

  slots.forEach((slot, index) => {
    const previousSlot = index === 0 ? null : slots[index - 1];

    if (slot.isBooked) {
      if (previousSlot?.isBooked) {
        bookings[bookings.length - 1].durationMins += chunkSizeMins;
        return;
      }

      bookings.push({
        id: getBookingId(),
        startTimeMins: convertToUtcTotalMinutes(slot.startTotalMins),
        durationMins: chunkSizeMins,
        postcode: slot.postcode,
      });
    }
  });

  return {
    id: resource.id,
    startTimeMins: convertToUtcTotalMinutes(startTimeMins),
    finishTimeMins: convertToUtcTotalMinutes(finishTimeMins),
    bookings,
    basePostcode: resource.postcode,
  };
};

const validatePostcodeRegex = (postcode: string) => {
  const matches = postcode.match(ukPostcodeRegex);
  return !!matches && matches.length !== 0;
};

const validatePostcodeExists = async (postcode: string) => {
  const response = await postcodeExists(postcode);

  return !response.isError;
};

const validationSchema = Yup.object().shape({
  offeredSlotOffsetMins: Yup.number().required(),
  requestedDurationMins: Yup.number().required(),
  postcode: Yup.string()
    .required("Please enter your postcode")
    .min(5, "Missing part of your postcode")
    .max(8, "Too many characters")
    .test(
      "validatePostcodeFormat",
      "Please check you have entered a valid postcode",
      (postcode) => {
        if (!postcode) return true;
        // Postcode regex taken from fresh norse site. It's ok for this as we are cloning that site but don't copy it without testing elsewhere.
        // If valid then proceed to perform async postcode exists check
        const validRegexResult = validatePostcodeRegex(postcode);

        if (validRegexResult) {
          return validatePostcodeExists(postcode);
        } else {
          return validRegexResult;
        }
      }
    ),
  date: Yup.date()
    .min(
      new Date(new Date().setDate(new Date().getDate() - 1)),
      "You cannot make bookings in the past."
    )
    .required("You must enter a requested date."),
  appointmentTypeId: Yup.number().required(),
});

const AlgorithmDemoConfigControls: React.FC<AlgorithmDemoConfigControls> = ({
  resources,
  startTimeMins,
  finishTimeMins,
}) => {
  const classNames = useStyles();
  const dispatch = useDispatch();
  const resourceASlots = useSelector(
    selectors.algorithmDemo.makeSlotsForResourceSelector(resources[0].id)
  );
  const resourceBSlots = useSelector(
    selectors.algorithmDemo.makeSlotsForResourceSelector(resources[1].id)
  );
  const allBookingsHavePostcode = useSelector(
    selectors.algorithmDemo.allBookingsHavePostcodes
  );
  const [appointmentTypeOptions, setAppointmentTypeOptions] = useState<
    AppSelectOption[]
  >([]);

  const populateAppointmentTypes = async () => {
    const response = await getAllAppointmentTypes();

    if (!response.isError) {
      const options = response.content.content.map((appointmentType) => {
        return { label: `${appointmentType.name}`, value: appointmentType.id };
      });
      setAppointmentTypeOptions(options);
    }
  };

  useEffect(() => {
    populateAppointmentTypes();
  }, []);

  const makeRequest = async (config: ApiBookableSlotsDemoConfig) => {
    if (!allBookingsHavePostcode) return;
    let bookingId = 1;
    const getBookingId = () => bookingId++;
    dispatch(actions.algorithmDemo.setBookableSlots([]));
    dispatch(actions.algorithmDemo.setLogs(""));
    const response = await demoBookableSlotsQuery({
      offeredSlotOffsetMins: config.offeredSlotOffsetMins,
      requestedDurationMins: config.requestedDurationMins,
      postcode: config.postcode,
      date: new Date(config.date.setHours(12)),
      appointmentTypeId: config.appointmentTypeId,
      existingResourceBookings: [
        buildDemoResource(
          resources[0],
          startTimeMins,
          finishTimeMins,
          resourceASlots,
          getBookingId
        ),
        buildDemoResource(
          resources[1],
          startTimeMins,
          finishTimeMins,
          resourceBSlots,
          getBookingId
        ),
      ],
    });

    if (response.isError) {
      console.error("error getting slots.");
      return;
    }

    dispatch(actions.algorithmDemo.setBookableSlots(response.content.content));
    dispatch(actions.algorithmDemo.setLogs(response.content.message));
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        await makeRequest(values);
      }}
      validationSchema={validationSchema}
    >
      <Form className={classNames.form}>
        <AppFormikTextField
          type="number"
          name="offeredSlotOffsetMins"
          label="Offset"
        />
        <AppFormikTextField
          type="number"
          name="requestedDurationMins"
          label="Duration"
        />
        <AppFormikSelect
          name="appointmentTypeId"
          options={appointmentTypeOptions}
          label="Service Type Id"
        />
        <AppFormikTextField type="string" name="postcode" label="Postcode" />
        <AppFormikDatePicker name="date" label="Requested Date" />
        <AppFormikSubmitButton>Test Algorithm</AppFormikSubmitButton>
        {!allBookingsHavePostcode && (
          <Typography color="error">
            Please specify a postcode by clicking the pencil and entering one
            for all mobile worker bookings
          </Typography>
        )}
      </Form>
    </Formik>
  );
};

export default AlgorithmDemoConfigControls;
