import { makeStyles } from "@material-ui/core/styles";
import AppTable from "../../components/AppTable";
import { CustomerAddress } from "../../models/Customers";
import CustomerAddressesTableRow from "./CustomerAddressesTableRow";

interface CustomerAddressesTableProps {
  addresses?: CustomerAddress[];
  noAddressesMessage: string;
  handleEditAddress: (id: number) => void;
  handleDeleteAddress: (id: number) => void;
}

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: theme.spacing(2),
  },
  addressNotesContainer: {
    marginTop: theme.spacing(2),
  },
}));

const CustomerAddressesTable: React.FC<CustomerAddressesTableProps> = ({
    addresses = [],
    noAddressesMessage,
    handleEditAddress,
    handleDeleteAddress,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.tableContainer}>
      <AppTable
        headers={[
          { label: "", },
          { label: "Address Line 1" },
          { label: "Address Line 2" },
          { label: "Address Line " },
          { label: "Town" },
          { label: "Postcode" },
          { label: "" },
        ]}
        entities={addresses}
        noEntitiesMessage={noAddressesMessage}
        renderRow={(address) => (
          <CustomerAddressesTableRow
            key={address.id}
            address={address}
            handleEdit={handleEditAddress}
            handleDelete={() => handleDeleteAddress(address.id)}
          />
        )}
      />
    </div>
  );
};

export default CustomerAddressesTable;
