import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
  },
  //this is styling the image
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-center",
    justifyContent: "center",
    width: "64px",
    height: "64px",
    marginRight: theme.spacing(2),
    borderRadius: theme.spacing(1),
    objectFit: "cover",
  },
  fileImageContainer: {
    width: "64px",
    height: "64px",
    marginRight: theme.spacing(2),
    borderRadius: theme.spacing(1),
    objectFit: "cover",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flex: 1,
    "& p": {
      margin: 0,
    },
  },
  title: {
    fontSize: "16px",
    marginBottom: theme.spacing(1),
  },
  fileSize: {
    fontSize: "14px",
    marginBottom: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
}));
