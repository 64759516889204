import TodayIcon from "@material-ui/icons/Today";
import { makeStyles, Theme, IconButton, Tooltip } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CALENDAR_VIEWS, useCalendar } from "./AppCalendarContext";
import { ResourceLabelContentArg } from "@fullcalendar/resource-common";
import FullCalendar from "@fullcalendar/react";
import { RefObject } from "@fullcalendar/core";
import { useEffect } from "react";

interface AppResourceHeaderProps {
  calendar: RefObject<FullCalendar>;
  resource?: ResourceLabelContentArg | undefined;
}

interface StyleProps {
  colour?: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  resourceHeader: {
    display: "flex",
    alignItems: "center",
    lineHeight: 1.85,
    width: "100%",
    color: theme.palette.primary.dark,
  },
  name: {
    paddingLeft: theme.spacing(1),
    maxWidth: "250px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: 500,
  },
  icon: {
    fill: ({ colour }) => colour,
    marginLeft: theme.spacing(1),
    width: "18px",
    height: "20px",
    cursor: "pointer",
  },
  closeBtn: {
    marginLeft: "auto",
    marginRight: theme.spacing(1),
    width: "32px",
    height: "32px",
    borderRadius: 0,
    color: theme.palette.primary.dark,
  },
  tooltip: {
    maxWidth: "500px",
  },
}));

const AppCalendarResourceHeader: React.FC<AppResourceHeaderProps> = ({
  calendar,
  resource,
}) => {
  const classes = useStyles({ colour: resource?.resource.extendedProps.color });
  const {
    isResource,
    isPivotView,
    selectedView,
    setSelectedResources,
    selectedResources,
    handleViewChange,
    removeStoredResource,
  } = useCalendar();

  const removeSelectedResource = () => {
    removeStoredResource(resource?.resource.id ?? "");
    setSelectedResources(
      selectedResources.filter((id) => id !== resource?.resource.id)
      );
      handleViewChange(isPivotView ? CALENDAR_VIEWS.DAY_PIVOT_VIEW : CALENDAR_VIEWS.DAY_VIEW);
    };
    
    const viewDayForSelectedResource = () => {      
      const id = [resource?.resource.id ?? ''];
      setSelectedResources(id);
      const action = calendar.current?.getApi();
      handleViewChange(selectedView);
      action?.changeView(selectedView);
    };
    
  return (
    <>
      <div className={classes.resourceHeader}>
        {selectedView === CALENDAR_VIEWS.DAY_VIEW ? (
          <TodayIcon
            className={classes.icon}
            onClick={viewDayForSelectedResource}
          />
        ) : (
          <TodayIcon className={classes.icon} style={{ cursor: "default" }} />
        )}
        <Tooltip
          title={resource?.resource.title ?? ""}
          classes={{ tooltip: classes.tooltip }}
        >
          <span className={classes.name}>{resource?.resource.title}</span>
        </Tooltip>
        {!isResource && selectedResources.length > 1 && (
          <IconButton
            className={classes.closeBtn}
            onClick={removeSelectedResource}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </div>
    </>
  );
};

export default AppCalendarResourceHeader;
