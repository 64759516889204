import { makeStyles } from "@material-ui/core";

export interface AppFormErrorProps {
  show: boolean;
}

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.secondaryRed.main,
    fontSize: ".75rem",
    marginTop: "3px",
  },
}));

const AppFormError: React.FC<AppFormErrorProps> = ({ show, children }) => {
  const classes = useStyles();

  return <>{show && <p className={classes.error}>{children}</p>}</>;
};

export default AppFormError;
