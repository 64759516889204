import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { addDays, isAfter, isBefore } from "date-fns";
import { startOfDay } from "date-fns/esm";
import { useEffect, useState } from "react";
import {
  createIsoDateFromDateAndTime,
  formatMinutesAfterMidnightToTimeString,
  formatTimeOnly,
  formatTimeStringToMinutesAfterMidnight,
  maximumMinutesAfterMidnightValue,
  roundUpMinutesAfterMidnightToNearestQuarter,
} from "../../dateFormatters";
import AppTimeInput from "./AppTimeInput";

export interface TimeRange {
  start: Date;
  end: Date;
}

export interface TimeRangeHelperText {
  start?: string;
  end?: string;
}

export interface AppTimeRangePickerProps {
  value: TimeRange;
  isPartDaysInitial?: boolean;
  granularity: number;
  onChange: (timeRange: TimeRange) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    width: "100%",
  },
  dateRow: {
    display: "block",
    marginBottom: theme.spacing(1),
  },
  dateTimeRow: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    gridGap: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  error: {
    color: theme.palette.secondaryRed.main,
  },
}));

const AppTimeRangePicker: React.FC<AppTimeRangePickerProps> = ({
  value,
  isPartDaysInitial,
  granularity,
  onChange,
}) => {
  const classes = useStyles();
  const [partDays, setPartDays] = useState(isPartDaysInitial);
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(value.start);
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(value.end);
  const [selectedStartTime, setSelectedStartTime] = useState<number>(
    roundUpMinutesAfterMidnightToNearestQuarter(
      formatTimeStringToMinutesAfterMidnight(formatTimeOnly(value.start, false))
    )
  );
  const [selectedEndTime, setSelectedEndTime] = useState<number>(
    roundUpMinutesAfterMidnightToNearestQuarter(
      formatTimeStringToMinutesAfterMidnight(formatTimeOnly(value.end, false))
    )
  );

  const handleDateChange = (
    date: MaterialUiPickersDate,
    property: "startDate" | "endDate"
  ) => {
    if (date) {
      if (property === "startDate") {
        setSelectedStartDate(date);

        if (isBefore(selectedEndDate, date)) {
          setSelectedEndDate(date);
        }
      }
      if (property === "endDate") {
        setSelectedEndDate(date);

        if (isAfter(selectedStartDate, date)) {
          setSelectedStartDate(date);
        }
      }
    }
  };

  const handleStartTimeConflicts = (time: number) => {
    if (selectedStartDate.getDate() === selectedEndDate.getDate()) {
      if (time === maximumMinutesAfterMidnightValue - granularity) {
        setSelectedEndDate(addDays(selectedEndDate, 1));
        setSelectedEndTime(0);
      } else if (time >= selectedEndTime) {
        setSelectedEndTime(time + granularity);
      }
    }
  };

  const handleEndTimeConflicts = (time: number) => {
    if (selectedStartDate.getDate() === selectedEndDate.getDate()) {
      if (time === 0) {
        setSelectedStartTime(1425);
        setSelectedStartDate(addDays(selectedStartDate, -1));
      } else if (time <= selectedStartTime) {
        setSelectedStartTime(time - granularity);
      }
    }
  };

  const handleTimeChange = (name: "startTime" | "endTime", value: number) => {
    if (name === "startTime") {
      handleStartTimeConflicts(value);
      setSelectedStartTime(value);
    }

    if (name === "endTime") {
      handleEndTimeConflicts(value);
      setSelectedEndTime(value);
    }
  };

  const returnTimeRange = () => {
    const startTimeString =
      formatMinutesAfterMidnightToTimeString(selectedStartTime);
    const endTimeString =
      formatMinutesAfterMidnightToTimeString(selectedEndTime);
    let startDateTime = createIsoDateFromDateAndTime(
      selectedStartDate,
      startTimeString
    );
    let endDateTime = createIsoDateFromDateAndTime(
      selectedEndDate,
      endTimeString
    );

    if (!partDays) {
      const endTimeIsMidnight = (endDateTime.getHours() === 0 && endDateTime.getMinutes() === 0); 
      startDateTime = startOfDay(startDateTime);
      endDateTime = endTimeIsMidnight ? endDateTime : startOfDay(addDays(endDateTime, 1));
    }

    onChange({
      start: startDateTime,
      end: endDateTime,
    });
  };

  useEffect(() => {
    returnTimeRange();
  }, [selectedStartTime, selectedEndTime, partDays]);

  useEffect(() => {
    handleStartTimeConflicts(selectedStartTime);
    returnTimeRange();
  }, [selectedStartDate, selectedEndDate]);

  return (
    <div className={classes.container}>
      <div className={partDays ? classes.dateTimeRow : classes.dateRow}>
        <KeyboardDatePicker
          label="Start date"
          value={selectedStartDate}
          fullWidth
          autoOk
          onChange={(date) => handleDateChange(date, "startDate")}
        />
        {partDays && (
          <AppTimeInput
            label="Start time"
            value={selectedStartTime}
            minuteIncrement={granularity}
            onChange={(val) => handleTimeChange("startTime", val)}
          />
        )}
      </div>
      <div className={partDays ? classes.dateTimeRow : classes.dateRow}>
        <KeyboardDatePicker
          label="End date"
          value={selectedEndDate}
          fullWidth
          autoOk
          onChange={(date) => handleDateChange(date, "endDate")}
        />
        {partDays && (
          <AppTimeInput
            label="End time"
            value={selectedEndTime}
            minuteIncrement={granularity}
            onChange={(val) => handleTimeChange("endTime", val)}
          />
        )}
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={partDays}
              onChange={() => setPartDays(!partDays)}
              name="partDays"
              color="primary"
            />
          }
          label="Part days?"
        />
      </div>
    </div>
  );
};

export default AppTimeRangePicker;
