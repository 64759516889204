import { Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { createCustomer } from "../../api/customers";
import {errorToast, successToast} from "../../toast";
import BookingForm from "./BookingForm";
import BookingFormOverview from "./BookingFormOverview/BookingFormOverview";
import { BookingFormStyles } from "./BookingFormStyles";
import { createBookingFormPageValidationSchemas } from "./BookingFormValidation";
import {
  BookingFormValues,
  defaultBookingFormValues,
} from "./BookingFormValues";

interface BookingFormContainerProps {
  isEdit?: boolean;
  initialValues: BookingFormValues;
  initialStep?: number;
  hideSelectResource?: boolean;
  initialCustomerId?: number;
  preSelectOverride?: boolean;
  id?: number;
  onSubmit(
    values: BookingFormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ): void;
  onClose(): void;
  handleEditBooking?: (bookingFormDetails: BookingFormValues) => Promise<void>;
}


export const BOOKING_OVERVIEW_STEP = -1;
export const CUSTOMER_SELECTION_STEP = 0;
const CUSTOMER_CREATION_STEP = 1;
const CUSTOMER_ADDRESS_CREATION_STEP = 2;
export const APPOINTMENT_TYPE_SELECTION_STEP = 3;
const SLOT_SELECTION_STEP = 4;
export const CREATE_RECURRENCE_STEP = 5;
export const RECURRING_SLOT_SELECTION_STEP = 6;
export const BOOKING_NOTES_STEP = 7;

const BookingFormContainer: React.FC<BookingFormContainerProps> = ({
  isEdit,
  initialValues,
  initialStep = 0,
  hideSelectResource,
  initialCustomerId,
  preSelectOverride,
  id,
  onSubmit,
  onClose,
  handleEditBooking,
}) => {
  const classes = BookingFormStyles();
  const [activeStep, setActiveStep] = useState(initialStep ?? 0);

  const bookingFormPageValidationSchemas =
    createBookingFormPageValidationSchemas(hideSelectResource, isEdit);
  const isLastStep = activeStep === bookingFormPageValidationSchemas.length - 1;
  const [isCreateCustomer, setCreateCustomer] = useState(false);
  const [newCustomerId, setNewCustomerId] = useState<number>();

  const handleSubmit = (
    values: BookingFormValues,
    { setSubmitting, setFieldTouched }: FormikHelpers<BookingFormValues>
  ) => {
    if (!isLastStep) {
      Object.keys(defaultBookingFormValues).forEach((key) =>
        setFieldTouched(key, false)
      );
      setSubmitting(false);

      if (isEdit && activeStep === SLOT_SELECTION_STEP) {
        setActiveStep(BOOKING_NOTES_STEP);
        return;
      }

      if (!values.isRecurringBooking && activeStep === CREATE_RECURRENCE_STEP) {
        setActiveStep(BOOKING_NOTES_STEP);
        return;
      }

      if (activeStep == CUSTOMER_ADDRESS_CREATION_STEP && isCreateCustomer) {
        const customerDto = {
          firstName: values.firstname ?? "",
          lastName: values.lastname ?? "",
          email: values.email?.trim() ?? "",
          company: values.companyName ?? "",
          phoneNumber: values.phone ?? "",
          address: {
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            addressLine3: values.addressLine3,
            town: values.town,
            postcode: values.postcode,
            notes: values.notes,
          },
        };

        createCustomer(customerDto).then(response => {
          if (!response.isError) {
            setNewCustomerId(response.content);
            successToast("Customer has been created");
          }
        }).catch(error => {
          console.log(error);
          errorToast("Failed to create customer");
        });
      }

      if (
        isCreateCustomer === false &&
        activeStep === CUSTOMER_SELECTION_STEP
      ) {
        setActiveStep(APPOINTMENT_TYPE_SELECTION_STEP);
        return;
      }

      setActiveStep((previousActiveStep) => previousActiveStep + 1);
      return;
    }

    if (isLastStep) {
      setSubmitting(true);
      onSubmit(values, setSubmitting);
    }
  };

  const handlePreviousClick = (isRecurring: boolean) => {

    if (isEdit && activeStep === BOOKING_NOTES_STEP) {
      setActiveStep(SLOT_SELECTION_STEP);
      return;
    }

    if (!isRecurring && activeStep === BOOKING_NOTES_STEP) {
      setActiveStep(CREATE_RECURRENCE_STEP);
      return;
    }

    if (activeStep === APPOINTMENT_TYPE_SELECTION_STEP) {
      setActiveStep(CUSTOMER_SELECTION_STEP);
      setCreateCustomer(false);
      return;
    }

    if (activeStep === CUSTOMER_CREATION_STEP) {
      setActiveStep(CUSTOMER_SELECTION_STEP);
      setCreateCustomer(false);
      return;
    }

    setActiveStep((previousActiveStep) => previousActiveStep - 1);
  };

  useEffect(() => {
    setActiveStep(initialStep);
  }, [initialStep]);

  const isBookingOverview = activeStep === BOOKING_OVERVIEW_STEP;

  return (

        <Formik
          initialValues={initialValues ?? defaultBookingFormValues}
          validationSchema={bookingFormPageValidationSchemas[activeStep]}
          onSubmit={handleSubmit}
        >
          <>
            {isBookingOverview && !!id && (
              <BookingFormOverview
                setInitialStep={(step: number) => setActiveStep(step)}
                bookingDetails={initialValues}
                handleClose={onClose}
                id={id}
              />
            )}
            <div className={classes.mainContainer}>
              <BookingForm
                activeStep={activeStep}
                initialValues={initialValues}
                isEdit={isEdit}
                initialCustomerId={initialCustomerId}
                preSelectOverride={preSelectOverride}
                onPreviousClick={handlePreviousClick}
                onClose={onClose}
                handleEditBooking={handleEditBooking}
                isCreateCustomer={isCreateCustomer}
                setCreateCustomer={setCreateCustomer}
                newCustomerId={newCustomerId}
              />
            </div>
          </>
        </Formik>

  );
};

export default BookingFormContainer;
