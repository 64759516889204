import { MouseEvent } from "react";
import { makeStyles, IconButton, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

interface PageHeaderProps {
  title: string | React.ReactNode;
  subtitle?: string;
  action?: (e: MouseEvent<HTMLButtonElement>) => void;
  actionLabel?: string;
}

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      gap: theme.spacing(1),
    },
    "& h1": {
      marginBottom: 0,
      marginTop: 0,
    },
    "& p": {
      marginTop: 0,
      marginBottom: 0,
    },
    "& > :not(:last-child)": {
      marginRight: theme.spacing(4),
    },
  },
  pageHeaderInner: {
    display: "flex",
    maxWidth: "100%",
    flexDirection: "column",
    justifyContent: "center",
    flex: "none",
  },
  pageHeaderSubtitle: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    fontSize: "1rem",
  },
  pageHeaderChildren: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      order: 1,
      marginTop: "0.5rem",
      marginRight: "0 !important",
    },
  },
  actionBtn: {
    width: 48,
    height: 48,
    background:
      "transparent linear-gradient(45deg, #4570B6 0%, #5CB4DB 100%) 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: 0,
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      fill: "#FFFFFF",
      width: 36,
      height: 36,
    },
  },
}));

const PageHeader: React.FC<PageHeaderProps> = ({
  children,
  title,
  subtitle,
  action,
  actionLabel,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.pageHeader}>
      <div className={classes.pageHeaderInner}>
        <Typography variant="h1">{title}</Typography>
        {subtitle && <p className={classes.pageHeaderSubtitle}>{subtitle}</p>}
      </div>
      {children && <div className={classes.pageHeaderChildren}>{children}</div>}
      {action && (
        <IconButton
          onClick={action}
          aria-label={actionLabel}
          size="medium"
          className={classes.actionBtn}
        >
          <AddIcon />
        </IconButton>
      )}
    </div>
  );
};

export default PageHeader;
