import { successToast } from "../../toast";
import {
  editBooking,
  editBookingResource,
  getBookingById,
  getBookingByIdResource,
} from "../../api/bookings";
import React, { useEffect, useState } from "react";
import {
  createIsoDateFromDateAndTime,
  formatMinutesAfterMidnightToTimeString,
  formatTimeOnly,
  formatTimeStringToMinutesAfterMidnight,
} from "../../dateFormatters";
import { BookedExtra, Jobs, UpdateBookingDto } from "../../models/Booking";
import { useAuth } from "../../auth";
import AppDrawer from "../../components/AppDrawer";
import { BookingFormValues } from "../BookingForm/BookingFormValues";
import { BookingForm } from "../BookingForm";
import { OptionalExtra } from "../../models/Booking";
import { useSelector } from "react-redux";
import { selectors } from "../../store";

interface EditBookingDrawerProps {
  open: boolean;
  id: number;
  handleClose: () => void;
  userIsResource?: boolean;
}

const EditBookingDrawer: React.FC<EditBookingDrawerProps> = ({
  open,
  handleClose,
  id,
  userIsResource,
}) => {
  const { role } = useAuth();
  const [bookingDetails, setBookingDetails] = useState<BookingFormValues>();
  const [confirmCancelBooking, setConfirmCancelBooking] = useState(false);
  const [initialStep, setInitialStep] = useState<number>(-1);

  const fetchBooking = async () => {
    const response = userIsResource
      ? await getBookingByIdResource(id)
      : await getBookingById(id, true, true);

    if (!response.isError) {
      setBookingDetails({
        validBookableDates: false,
        hasSelectedTimeSlot: false,
        ...response.content,
        date: response.content.start,
        start: formatTimeStringToMinutesAfterMidnight(
          formatTimeOnly(new Date(response.content.start), false)
        ),
        end: formatTimeStringToMinutesAfterMidnight(
          formatTimeOnly(new Date(response.content.end), false)
        ),
        firstJobAppointmentTypeId: response.content.firstJobAppointmentTypeId
          ? response.content.firstJobAppointmentTypeId
          : -1,
        firstJobOptionalExtraIds: response.content.firstJobOptionalExtraIds,
        requiresOptionalExtra: false,
        recurringBookableSlots: [],
      });
    }
  };

  const jobs = useSelector(selectors.basket.services);

  const handleEditBooking = async (
    bookingFormDetails: BookingFormValues,
    setSubmitting?: (isSubmitting: boolean) => void
  ): Promise<void> => {
    // firstJobAppointmentTypeId is undefined (or nullable) to handle historic MBH bookings, some of which are missing jobs / appointment types.
    // All bookings created with MBH v2 will have an appointment type and job
    const bookingDto: UpdateBookingDto = {
      id: id,
      resourceId: bookingFormDetails.resourceId,
      paymentStatusId: bookingFormDetails.paymentStatusId,
      start: createIsoDateFromDateAndTime(
        bookingFormDetails.date,
        formatMinutesAfterMidnightToTimeString(bookingFormDetails.start)
      ),
      end: createIsoDateFromDateAndTime(
        bookingFormDetails.date,
        formatMinutesAfterMidnightToTimeString(bookingFormDetails.end)
      ),
      jobs: jobs, //bookingFormDetails.jobs,
      notes: bookingFormDetails.notes,
      description: bookingFormDetails.description,
      customerId: bookingFormDetails.customerId,
      customerAddressId: bookingFormDetails.customerAddressId,
      firstJobAppointmentTypeId:
        bookingFormDetails.firstJobAppointmentTypeId !== -1
          ? bookingFormDetails.firstJobAppointmentTypeId
          : undefined,
      firstJobOptionalExtras: bookingFormDetails.firstJobOptionalExtras.map(
        (extra: OptionalExtra | BookedExtra): BookedExtra => {
          if ((extra as OptionalExtra).id) {
            return {
              optionalExtraId: (extra as OptionalExtra).id,
              quantity: extra.quantity ?? 1,
              price: extra.price,
            };
          } else return extra as BookedExtra;
        }
      ),
      bookingStatus: bookingFormDetails.bookingStatus,
      overriddenTotalPrice: undefined,
      tagIds: bookingFormDetails.tags?.map((tag) => tag.id),
    };

    const response = userIsResource
      ? await editBookingResource(bookingDto)
      : await editBooking(bookingDto);

    if (!response.isError) {
      handleClose();
      successToast("Booking has been updated");
    } else {
      setSubmitting && setSubmitting(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchBooking();
    }

    if (!id) {
      setBookingDetails(undefined);
    }
  }, [id]);

  return (
    <>
      <AppDrawer open={open} onClose={handleClose} anchor="right">
        {bookingDetails && (
          <BookingForm
            isEdit={true}
            onSubmit={(bookingFormDetails, setSubmitting) =>
              handleEditBooking(bookingFormDetails, setSubmitting)
            }
            onClose={handleClose}
            initialValues={{
              ...bookingDetails,
              notes: bookingDetails.notes || "",
              description: bookingDetails.description || "",
            }}
            id={id}
            handleEditBooking={handleEditBooking}
            hideSelectResource={userIsResource}
            initialStep={initialStep}
          />
        )}
      </AppDrawer>
    </>
  );
};

export default EditBookingDrawer;
