import "mapbox-gl/dist/mapbox-gl.css";
import config from "../../config";
import ReactMapGL, { FlyToInterpolator } from "react-map-gl";
import { useEffect, useState } from "react";
import { defaultViewPort, LongitudeLatitude, Viewport } from ".";
import { makeStyles } from "@material-ui/core";
import { longLatLookup } from "../../api/geocoding";
import CoverageMapMarker from "./CoverageMapMarker";
import CoverageMapRadius from "./CoverageMapRadius";
import CoverageMapOutcodeDistrict from "./CoverageMapOutcodeDistrict";
import { OutcodeGeoJson } from "../../models/Geocoding";

export interface CoverageMapProps {
  basePostcode?: string;
  maxTravelDistanceMiles: number;
  coverageType: string;
  outcodeGeoJson?: OutcodeGeoJson[];
}

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    width: "100%",
    height: "220px",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      width: 440,
      height: "100%",
      marginRight: theme.spacing(2),
      marginBottom: 0,
    },
  },
}));

const CoverageMap: React.FC<CoverageMapProps> = ({
  basePostcode,
  maxTravelDistanceMiles,
  coverageType,
  outcodeGeoJson = [],
}) => {
  const classes = useStyles();
  const [viewport, setViewport] = useState<Viewport>(defaultViewPort);
  const [postcodeLongLat, setPostcodeLongLat] = useState<LongitudeLatitude>();

  useEffect(() => {
    const searchLongLat = async (postcode: string) => {
      const response = await longLatLookup(postcode, true);

      if (!response.isError && response.statusCode === 200) {
        const { lng: longitude, lat: latitude } = response.content;

        setViewport({
          ...viewport,
          longitude,
          latitude,
          zoom: 8,
        });

        setPostcodeLongLat({ longitude, latitude });
      }
    };

    if (basePostcode) {
      searchLongLat(basePostcode);
    }
  }, [basePostcode]);

  const displayOutcodeDistricts =
    outcodeGeoJson.length > 0 && coverageType === "outcodeList";

  return (
    <div className={classes.mapContainer}>
      <ReactMapGL
        {...viewport}
        transitionInterpolator={new FlyToInterpolator()}
        transitionDuration={0}
        width="100%"
        height="100%"
        mapboxApiAccessToken={config.mapBoxPK}
        onViewportChange={setViewport}
      >
        {postcodeLongLat && (
          <>
            {coverageType === "radius" && (
              <CoverageMapRadius
                {...postcodeLongLat}
                radiusMiles={maxTravelDistanceMiles}
              />
            )}
            <CoverageMapMarker {...postcodeLongLat} />
          </>
        )}
        {displayOutcodeDistricts &&
          outcodeGeoJson.map((o, idx) => {
            return (
              <CoverageMapOutcodeDistrict
                outcode={o.outcode}
                geoJson={o.geoJson}
                handleViewportChange={setViewport}
                key={o.outcode + idx}
              />
            );
          })}
      </ReactMapGL>
    </div>
  );
};

export default CoverageMap;
