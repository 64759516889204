import { makeStyles, Theme } from "@material-ui/core";

interface ColourIndicatorProps {
  colour?: string;
  size?: "xs" | "md";
}

const useStyles = makeStyles<Theme, ColourIndicatorProps>(() => ({
  indicator: {
    width: ({ size }) => (size === "md" ? "28px" : "14px"),
    height: ({ size }) => (size === "md" ? "28px" : "14px"),
    borderRadius: "50%",
    backgroundColor: ({ colour }) => (colour ? colour : "white"),
    border: ({ colour }) => (colour ? undefined : "2px solid black"),
  },
}));

const ColourIndicator: React.FC<ColourIndicatorProps> = ({
  colour,
  size = "md",
}) => {
  const classes = useStyles({ colour, size });

  return <div className={classes.indicator}></div>;
};

export default ColourIndicator;
