import { makeStyles } from "@material-ui/core";
import { useField } from "formik";
import AppTimeInput from "../../../components/AppForm/AppTimeInput";
import { maximumMinutesAfterMidnightValue } from "../../../dateFormatters";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
  },
}));

interface BookingTimeSelectProps {
  minuteIncrement: number;
  readOnly?: boolean;
  isEdit?: boolean;
}

const BookingTimeSelect: React.FC<BookingTimeSelectProps> = ({
  minuteIncrement,
  readOnly,
  isEdit,
}) => {
  const classNames = useStyles();
  const [{ value: selectedStartTime }, , { setValue: setSelectedStartTime }] =
    useField<number>("start");
  const [{ value: selectedEndTime }, , { setValue: setSelectedEndTime }] =
    useField<number>("end");

  minuteIncrement = minuteIncrement === 0 ? 5 : minuteIncrement;

  const maxTimeValue = maximumMinutesAfterMidnightValue - minuteIncrement;

  const handleStartTimeConflicts = (time: number) => {
    if (time === maxTimeValue) {
      setSelectedEndTime(0);
    } else if (time >= selectedEndTime) {
      setSelectedEndTime(time + minuteIncrement);
    }
  };

  const handleEndTimeConflicts = (time: number) => {
    if (time === 0) {
      setSelectedStartTime(maxTimeValue);
    } else if (time <= selectedStartTime) {
      setSelectedStartTime(time - minuteIncrement);
    }
  };

  const handleTimeChange = (name: "start" | "end", value: number) => {
    if (name === "start") {
      handleStartTimeConflicts(value);
      setSelectedStartTime(value);
    }

    if (name === "end") {
      handleEndTimeConflicts(value);
      setSelectedEndTime(value);
    }
  };

  return (
    <>
      <div className={classNames.container}>
        <AppTimeInput
          label="Start"
          value={selectedStartTime}
          onChange={(val) => handleTimeChange("start", val)}
          maxValue={maxTimeValue - minuteIncrement}
          minuteIncrement={minuteIncrement}
          readOnly={readOnly}
          isEdit={isEdit}
        />
        <AppTimeInput
          label="End"
          value={selectedEndTime}
          onChange={(val) => handleTimeChange("end", val)}
          maxValue={maxTimeValue}
          minValue={minuteIncrement}
          minuteIncrement={minuteIncrement}
          readOnly={readOnly}
          isEdit={isEdit}
        />
      </div>
    </>
  );
};

export default BookingTimeSelect;
