import { makeStyles } from "@material-ui/core";
import { Info as InfoIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.backgrounds.blue,
    padding: theme.spacing(1),
    fontSize: ".75rem",
    borderColor: theme.palette.primary.main,
    border: "solid",
    borderWidth: "thin",
    marginBottom: theme.spacing(1),
    minWidth: "100%",
    width: "0",
  },
  infoIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main
  }
}));

const InformationTextBox: React.FC = ({ children }) => {
  const classNames = useStyles();

  return <div className={classNames.box}>
      <InfoIcon className={classNames.infoIcon} />
      {children}
    </div>;
};

export default InformationTextBox;
