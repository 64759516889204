import { createContext, useContext } from "react";
import { UserRole } from "../models/AccountManagement";
import { AuthResult } from "./authClient";
import { AccountInformation } from "./sessionStorageClient";

export interface IAuthContext {
  logIn: (email: string, password: string) => Promise<AuthResult>;
  logOut: () => Promise<AuthResult>;
  userName: string | null;
  firstName: string | null;
  lastName: string | null;
  role: UserRole;
  isLoggedIn: boolean;
  getToken: () => string | null;
  getAccountInformation: () => AccountInformation | undefined;
}

const placeholderFunc = () => {
  throw new Error("Not implemented.");
};

export const AuthContext = createContext<IAuthContext>({
  logIn: placeholderFunc,
  logOut: placeholderFunc,
  userName: null,
  firstName: null,
  lastName: null,
  role: "",
  isLoggedIn: false,
  getToken: placeholderFunc,
  getAccountInformation: placeholderFunc,
});

export const useAuth: () => IAuthContext = () => useContext(AuthContext);
