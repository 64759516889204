import { InputAdornment, makeStyles } from "@material-ui/core";
import { useField } from "formik";
import { ChangeEvent, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useAuth } from "../../../auth";
import AppTextField from "../../../components/AppForm/AppTextField";
import { PaymentStatus } from "../../../models/PaymentStatus";
import { selectors, useTotalPrice } from "../../../store";
import { BookingFormStyles } from "../BookingFormStyles";
import { BookedExtra } from "../../../models/Booking";
import { BookingFormValues } from "../BookingFormValues";
import { useSelector } from "react-redux";
import { useConfig } from "../../../config/ConfigContext";
import { excludeVATFromPriceIfRequired } from "../../../numericalUtils";

interface Props {
  paymentStatuses: PaymentStatus[];
  initialValues: BookingFormValues;
  onPriceOverride(override: number): void;
  isUpdatingExistingService?: boolean;
}

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatCustom = (props: NumberFormatCustomProps) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.target.value,
          },
        });
      }}
      allowNegative={false}
      isNumericString
      decimalScale={2}
      fixedDecimalScale
    />
  );
};

const BookingFormTotalPrice: React.FC<Props> = ({
  paymentStatuses,
  initialValues,
  onPriceOverride,
  isUpdatingExistingService = false,
}) => {
  const classes = BookingFormStyles();

  const { role } = useAuth();
  const vendorConfig = useConfig();

  const [{ value: totalPrice }] = useField<number>("totalPrice");
  const [{ value: overriddenTotalPrice },,{ setValue: setOverriddenTotalPrice }] = useField<number | undefined>("overriddenTotalPrice");
  const [{ value: selectedAppointmentTypeId }] = useField<number>("firstJobAppointmentTypeId");
  const [{ value: selectedOptionalExtras }] = useField<BookedExtra[]>("firstJobOptionalExtras");
  const [{ value: paymentStatusId }] = useField<number>("paymentStatusId");

  const [priceValue, setPriceValue] = useState<number>(totalPrice);

  const isBookingComplete = initialValues.bookingStatus > 0;

  const vatRate = isBookingComplete ? initialValues.taxRate ?? 0 : vendorConfig.currentTaxRatePercentage;

  const appointmentTypes = useSelector(selectors.appointmentTypes.allAppointmentTypes);

  const selectedAppointmentType = appointmentTypes.find(
    (appointmentType) => appointmentType.id === selectedAppointmentTypeId
  );

  const totalAppointmentTypePriceExVat = excludeVATFromPriceIfRequired(
    selectedAppointmentType?.price ?? 0,
    vatRate
  );

  const selectedOptionalExtraInfo = selectedOptionalExtras.map(
    (optionalExtra) => {
      const optionalExtraInfo =
        selectedAppointmentType?.availableOptionalExtras.find(
          (o) => o.id === optionalExtra.optionalExtraId
        );
      return {
        id: optionalExtra.optionalExtraId,
        quantity: optionalExtra.quantity,
        price: optionalExtraInfo?.price ?? 0,
      };
    }
  );

  const totalOptionalExtraPriceExVat = selectedOptionalExtraInfo.reduce(
    (a, b) => a + excludeVATFromPriceIfRequired(b.price, vatRate) * b.quantity, 0
  );

  const totalPackagePrice =
    parseFloat(totalOptionalExtraPriceExVat.toFixed(2)) +
    parseFloat(totalAppointmentTypePriceExVat.toFixed(2));

  const handleOverridePriceChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOverriddenPrice(parseFloat(event.target.value));
  };

  const setOverriddenPrice = (value: number) => {
    if (!isNaN(value)) {
      setOverriddenTotalPrice(value);
      onPriceOverride(value);
    }
  };

  useEffect(() => {
    // setPriceValue(appointmentTypes.find(_ => _.id == selectedAppointmentTypeId)?.price ?? 0);

    // if (!isUpdatingExistingService) {
      // setPriceValue(totalPackagePrice);
      // setPriceValue(appointmentTypes.find(_ => _.id == selectedAppointmentTypeId)?.price ?? 0);
      setPriceValue(
          parseFloat(totalOptionalExtraPriceExVat.toFixed(2)) +
          parseFloat(totalAppointmentTypePriceExVat.toFixed(2))
      );
      // return;
    // }

    // totalPrice > 0 && setPriceValue(totalPrice);
    // setOverriddenTotalPrice(totalPackagePrice);

    if (overriddenTotalPrice && isUpdatingExistingService) {
      setOverriddenPrice(overriddenTotalPrice);
    }
  }, [selectedAppointmentTypeId, totalPrice, totalPackagePrice]);

  // useEffect(() => {
  //   console.log("total price triggered");
  //
  //   totalPrice > 0 && setPriceValue(totalPrice);
  //
  //   if (overriddenTotalPrice) {
  //     setOverriddenPrice(overriddenTotalPrice);
  //   }
  //
  //   console.log(totalPrice);
  //   console.log(overriddenTotalPrice);
  // }, [totalPrice]);
  //
  // useEffect(() => {
  //   console.log("total package price triggered");
  //
  //   setPriceValue(totalPackagePrice);
  //
  //   if (overriddenTotalPrice) {
  //     setOverriddenPrice(overriddenTotalPrice);
  //   }
  //
  //   console.log(totalPrice);
  //   console.log(overriddenTotalPrice);
  // }, [totalPackagePrice]);

  const displayValue = (overriddenTotalPrice ?? priceValue) || 0;

  const paymentStatus = paymentStatuses.find(
    (status) => status.id === paymentStatusId
  );

  const isDisabled = role === "resource" && paymentStatus && !paymentStatus.resourceCanEditPrice;

  return (
    <div>
      <h3 className={classes.stepContainerTitle}>
        Total price{vatRate ? " (ex. VAT)" : ""}
      </h3>
      <AppTextField
        value={displayValue.toLocaleString("en")}
        onChange={handleOverridePriceChange}
        InputProps={{
          inputComponent: NumberFormatCustom as any,
          startAdornment: <InputAdornment position="start">£</InputAdornment>,
        }}
        readonly={isDisabled}
      />
    </div>
  );
};

export default BookingFormTotalPrice;
