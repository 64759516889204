import { HttpClientResponse } from ".";
import { AddressSearchResult } from "../models/AddressSearchResult.";
import { AppointmentType } from "../models/AppointmentType";
import { CreateWidgetBookingDto } from "../models/Booking";
import {
  ApiStandardResponse,
  buildApiUrl,
  doErrorToastIfRequired,
  httpClient,
  mapHttpClientListResponse,
  mapHttpClientResponse,
} from "./common";
import { ApiListResponse } from "./common/ApiListResponse";
import { ApiAddressSearchResult } from "./models/AddressSearchResult";
import { ApiAppointmentType } from "./models/AppointmentType";
import {
  ApiBookableSlotDto,
  ApiBookableSlotsWidgetParameters,
} from "./models/BookableSlots";
import { useSelector } from "react-redux";
import { selectors } from "../store";

export const getAllAppointmentTypesWidget = async (
  vendorId: number
): Promise<HttpClientResponse<ApiListResponse<AppointmentType>>> => {
  const url = buildApiUrl(`api-public/appointment-types?vendorId=${vendorId}`);
  const response = await httpClient.getRequest<
    ApiListResponse<ApiAppointmentType>
  >(url);
  doErrorToastIfRequired(response);
  return mapHttpClientListResponse(response, (m) => m);
};

export const getAppointmentTypesByPostcodeWidget = async (arg: {
  id: number;
  postcode: string;
}): Promise<HttpClientResponse<ApiListResponse<AppointmentType>>> => {
  const url = buildApiUrl(
    `api-public/appointment-types/${arg.postcode}?vendorId=${arg.id}`
  );
  const response = await httpClient.getRequest<
    ApiListResponse<ApiAppointmentType>
  >(url);
  doErrorToastIfRequired(response);
  return mapHttpClientListResponse(response, (m) => m);
};

export const getAllAppointmentTypesWidgetTwo = async (): Promise<
  HttpClientResponse<ApiListResponse<AppointmentType>>
> => {
  const url = buildApiUrl("api-vendor/appointment-types?vendorId=${vendorId}");
  const response = await httpClient.getRequest<
    ApiListResponse<ApiAppointmentType>
  >(url);
  doErrorToastIfRequired(response);
  return mapHttpClientListResponse(response, (m) => m);
};

export const getAllAddressesFromPostcode = async (
  postcode: string
): Promise<HttpClientResponse<ApiListResponse<AddressSearchResult>>> => {
  const url = buildApiUrl(`api-public/addresses?Postcode=${postcode}`);
  const response = await httpClient.getRequest<
    ApiListResponse<ApiAddressSearchResult>
  >(url);
  doErrorToastIfRequired(response);
  return mapHttpClientListResponse(response, (m) => m);
};

export const getBookableSlotsWidget = async ({
  postcode,
  vendorId,
  date,
  jobs,
}: ApiBookableSlotsWidgetParameters): Promise<
  HttpClientResponse<ApiListResponse<ApiBookableSlotDto>>
> => {
  const params = {
    date: date,
    postcode: postcode,
    appointmentTypes: jobs,
  };

  const queryParameters = [`vendorId=${vendorId}`];
  const url = buildApiUrl(
    `api-public/bookable-slots?${queryParameters.join("&")}`
  );
  const response = await httpClient.postRequest<
    ApiListResponse<ApiBookableSlotDto>
  >(url, params);
  if (response.statusCode !== 500) {
    doErrorToastIfRequired(response);
  }
  return mapHttpClientListResponse(response, (m) => m);
};

export const createWidgetBooking = async (
  vendorId: number,
  createWidgetBookingDto: CreateWidgetBookingDto
): Promise<HttpClientResponse<number>> => {
  const url = buildApiUrl(`api-public/bookings?vendorId=${vendorId}`);
  const response = await httpClient.postRequest<ApiStandardResponse<number>>(
    url,
    createWidgetBookingDto
  );

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, (bookingId) => bookingId);
};
