import { AppCalendar } from "../components/AppCalendar";
import { CALENDAR_VIEWS } from "../components/AppCalendar/AppCalendarContext";

const ResourceUserCalendarPage: React.FC = () => {
  return (
    <>
      <AppCalendar
        canEditEvent
        canAddTimeOff
        initialView={CALENDAR_VIEWS.WEEK_VIEW}
        allowedViews={[CALENDAR_VIEWS.WEEK_VIEW, CALENDAR_VIEWS.DAY_VIEW]}
      />
    </>
  );
};

export default ResourceUserCalendarPage;
