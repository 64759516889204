import { Column, useTable, useSortBy } from "react-table";
import { useStyles, AppTableSortIcon } from "./index";

interface AppTableProps<T> {
  columns: Array<Column>;
  data: Array<T>;
  onHeaderClick?: () => void;
}

const AppTableAlt = <T extends Record<string, any>>({
  columns,
  data,
  onHeaderClick,
}: AppTableProps<T>) => {
  const classes = useStyles();

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        manualSortBy: false,
        sortTypes: {
          alphanumeric: (row1, row2, columnName) => {
              const rowOneColumn = row1.values[columnName];
              const rowTwoColumn = row2.values[columnName];
              if (isNaN(rowOneColumn)) {
                  return rowOneColumn.toUpperCase() >
                      rowTwoColumn.toUpperCase()
                      ? 1
                      : -1;
              }
              return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
          }
      }
      },
      useSortBy
    );

  return (
    <table {...getTableProps()} className={classes.table} cellSpacing={0}>
      <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            key={`header-row-${i}`}
            onClick={onHeaderClick}
          >
            {headerGroup.headers.map((column, i) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                key={`header-col-${i}`}
                className={classes.tableHeader}
              >
                {column.render("Header")}
                {column.isSorted &&
                  <span>
                    <AppTableSortIcon
                      direction={
                        column.isSorted
                          ? column.isSortedDesc
                            ? "desc"
                            : "asc"
                          : undefined
                      }
                    />
                  </span>
                }
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              key={`body-row-${i}`}
              className={classes.tableRow}
            >
              {row.cells.map((cell, i) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    key={`body-cell-${i}`}
                    className={classes.tableData}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default AppTableAlt;
