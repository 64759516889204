import {useState} from "react";
import {
    IconButton,
    Typography,
    Grid,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from "@material-ui/core";
import AppChip from "../../../components/AppChip";
import {
    Edit as EditIcon,
    AccountCircle as ResourceIcon,
    ExpandMore as ExpandIcon,
} from "@material-ui/icons";
import {BookingFormValues} from "../BookingFormValues";
import {useDispatch, useSelector} from "react-redux";
import {actions, selectors, useDispatchEffect} from "../../../store";
import {useAuth} from "../../../auth";
import {useHttpRequest} from "../../../api";
import {getAllCustomers} from "../../../api/customers";
import {getAllResources, getResourceMe} from "../../../api/resources";
import {useEffect} from "react";
import {
    days,
    formatDateSuffix,
    formatMinutesAfterMidnightTo12HourWithAmPm,
    formatTimeOnly,
} from "../../../dateFormatters";
import {useStyles} from "./BookingFormOverviewStyles";
import {ResourceSummary} from "../../../models/Resource";
import CloseIcon from "@material-ui/icons/Close";
import AppConfirmationDialog from "../../../components/AppConfirmationDialog";
import {
    cancelBooking,
    cancelBookingResource,
    cancelRecurringBooking,
} from "../../../api/bookings";
import {successToast} from "../../../toast";
import AppLoader from "../../../components/AppLoader";
import {useField} from "formik";
import {Jobs, OptionalExtra} from "../../../models/Booking";
import {useConfig} from "../../../config/ConfigContext";
import BookingFormCancelRecurringAppointmentDialog
    from "../BookingFormCancelRecurringAppointmentDialog/BookingFormCancelRecurringAppointmentDialog";
import {ServicesListView} from "../../../components/bookings/services";
import {parseOptionalExtras} from "../../utilities";
import AppTabPanel from "../../../components/AppTabPanel";
import AppTabs from "../../../components/AppTabs";
import {BookingFields} from "../BookingFormFields";
import {excludeVATFromPriceIfRequired} from "../../../numericalUtils";
import {getAllAppointmentTypes} from "../../../api/appointmentTypes";
import BookingFormFileDisplayUnit from "./BookingFormFileDisplayUnit/BookingFormFileDisplayUnit";

interface BookingFormOverviewProps {
    setInitialStep: (step: number) => void;
    bookingDetails: BookingFormValues;
    id: number;
    handleClose: () => void;
}

const BookingFormOverview: React.FC<BookingFormOverviewProps> = ({
                                                                     setInitialStep,
                                                                     bookingDetails,
                                                                     id,
                                                                     handleClose,
                                                                 }) => {
    const classes = useStyles();
    const {role} = useAuth();
    const dispatch = useDispatch();
    const vendorConfig = useConfig();
    const [tab, setTab] = useState(0);

    console.log("bookingDetails", bookingDetails);

    const basketStoreServices = useSelector(selectors.basket.services);
    const basketStoreTotal = useSelector(selectors.basket.total);
    const vatRate = bookingDetails.taxRate;
    const priceHiddenFromResource =
        role === "resource" && vendorConfig.hidePricesFromResource;
    const formattedTotalPrice = excludeVATFromPriceIfRequired(
        basketStoreTotal,
        vatRate ?? 0
    ).toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const unpackBookingStatuses = function (bookingStatus: number) {
        switch (bookingStatus) {
            case 0:
                return "Provisional";
            case 1:
                return "Confirmed";
            case 2:
                return "Cancelled";
            case 3:
                return "Completed";
            default:
                return "Booking status not found";
        }
    };

    const isRecurringBooking =
        bookingDetails.recurringBookingId &&
        bookingDetails.recurringBookings &&
        bookingDetails.recurringBookings.length > 0;
    const [resources, setResources] = useState<ResourceSummary[]>([]);
    const [confirmCancelBooking, setConfirmCancelBooking] = useState(false);

    const customerAddresses = useSelector(selectors.customers.allAddresses);
    const customerAddressesAreLoading = useSelector(
        selectors.customers.addressesIsLoading
    );
    const {result: customers, isLoading} = useHttpRequest(getAllCustomers);
    const customer = customers?.content.find(
        (customer) => customer.id === bookingDetails.customerId
    );
    const customerAddress = customerAddresses.find(
        (address) => address.id === bookingDetails.customerAddressId
    );
    const appointmentTypesAreLoading = useSelector(
        selectors.appointmentTypes.isLoading
    );
    const [, ,] = useField<OptionalExtra[]>("firstJobOptionalExtras");
    const resource = resources.find(
        (resource) => resource.id === bookingDetails.resourceId
    );

    const {
        result: allAppointmentTypes,
        isLoading: isLoadingAllAppointmentTypes,
    } = useHttpRequest(getAllAppointmentTypes);

    // TODO: below is hack to push optional extra info that is no longer associated with appointment-type but WAS at time booking was made
    // more times needs put in to implement a better solution to this
    const originalFirstJobOptionalExtras =
        bookingDetails.firstJobOptionalExtras as OptionalExtra[];

    for (const appointmentType of allAppointmentTypes?.content ?? []) {
        if (appointmentType.id === bookingDetails.firstJobAppointmentTypeId) {
            const availableOptionalExtraIds =
                appointmentType.availableOptionalExtras.map(
                    (availableOptionalExtra) => availableOptionalExtra.id
                );

            originalFirstJobOptionalExtras.forEach(
                (originalFirstJobOptionalExtra) => {
                    if (
                        !availableOptionalExtraIds.includes(
                            originalFirstJobOptionalExtra.id
                        )
                    ) {
                        // we have a missing optional extra detail present when booking was originally made
                        appointmentType.availableOptionalExtras.push({
                            ...originalFirstJobOptionalExtra,
                            description: "",
                            hasQuantity: false,
                            price: originalFirstJobOptionalExtra.price,
                        });
                    }
                }
            );
        }
    }

    const contentIsLoading =
        isLoading ||
        customerAddressesAreLoading ||
        appointmentTypesAreLoading ||
        isLoadingAllAppointmentTypes;

    useEffect(() => {
        dispatch(
            actions.basket.setServices(
                bookingDetails.jobs.map((job: Jobs) => {
                    return {
                        ...job,
                        optionalExtras: parseOptionalExtras(job.optionalExtras),
                    };
                })
            )
        );
        dispatch(actions.basket.setBasketTotal(bookingDetails.totalPrice ?? 0));
    }, [bookingDetails.jobs]);

    useDispatchEffect(actions.appointmentTypes.fetchAll);
    dispatch(() =>
        actions.customers.fetchCustomerAddresses(bookingDetails.customerId)
    );

    const fetchAllResources = async () => {
        const response = await getAllResources();
        if (!response.isError) {
            setResources(response.content.content);
        }
    };

    const fetchCurrentResource = async () => {
        const result = await getResourceMe();

        if (!result.isError) {
            setResources([result.content]);
        }
    };

    const handleCancelBooking = async () => {
        setConfirmCancelBooking(false);
        const result =
            role === "admin"
                ? await cancelBooking(id)
                : await cancelBookingResource(id);

        if (!result.isError) {
            successToast("Booking has been cancelled");
            handleClose();
        }
    };

    const handleCancelRecurringBookings = async () => {
        setConfirmCancelBooking(false);
        const result = await cancelRecurringBooking(id);

        if (!result.isError) {
            successToast("Recurring Booking has been cancelled");
            handleClose();
        }
    };

    useEffect(() => {
        if (bookingDetails.customerId > 0) {
            dispatch(
                actions.customers.fetchCustomerAddresses(bookingDetails.customerId)
            );
        }

        if (bookingDetails.resourceId > 0) {
            if (role === "admin") {
                fetchAllResources();
            } else {
                fetchCurrentResource();
            }
        }
    }, [bookingDetails]);

    const totalPriceExVat =
        bookingDetails.overriddenTotalPrice?.toFixed(2) ??
        bookingDetails.totalPrice?.toFixed(2) ??
        0;
    const formattedTotalPriceExVat = totalPriceExVat.toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const renderServiceLabel = () => {
        return `Service${basketStoreServices.length > 1 ? "s" : ""}`;
    };

    const renderAdditionalInformation = () => {
        return bookingDetails.description.trim() == ""
            ? "No additional information"
            : bookingDetails.description;
    };

    const renderCalendarTime = () => {
        return `${formatMinutesAfterMidnightTo12HourWithAmPm(bookingDetails.start)} 
      - 
      ${formatMinutesAfterMidnightTo12HourWithAmPm(bookingDetails.end)}`;
    };

    return (
        <>
            <div className={classes.overviewContainer}>
                {contentIsLoading && <AppLoader/>}
                {!contentIsLoading && (
                    <>
                        <div className={classes.overviewCloseBtn}>
                            <IconButton
                                onClick={handleClose}
                                size="small"
                                className={classes.actionBtn}
                            >
                                <CloseIcon/>
                            </IconButton>
                        </div>
                        <div className={classes.overviewHeader}>
                            <Typography className={classes.overviewSubheader} variant="h5">
                                Booking ID: {bookingDetails.id}
                            </Typography>
                            <br/>
                            <Typography variant="h4">
                                {customer?.firstName} {customer?.lastName} -{" "}
                                {formatDateSuffix(bookingDetails.date, true)}
                            </Typography>
                            <div className={classes.chipContent}>
                                <AppChip
                                    label={bookingDetails.paymentStatusName}
                                    size="small"
                                    chipType="dark"
                                />
                                <AppChip
                                    label={unpackBookingStatuses(bookingDetails.bookingStatus)}
                                    size="small"
                                    chipType="dark"
                                />
                            </div>
                        </div>
                        {/* Tabs go here */}
                        <Grid item xs={12} sm={12} md={12}>
                            <AppTabs
                                tab={tab}
                                tabs={["OVERVIEW", "NOTES", "IMAGES", "DOCUMENTS"]}
                                handleChange={(val) => setTab(val)}
                            />
                            <AppTabPanel value={tab} index={0}>
                                <div className={classes.overviewCardTop}>
                                    <div className={classes.flexGrowSection}>
                                        <div style={{width: "100%"}}>
                                            <Typography variant="h5">
                                                {renderServiceLabel()}
                                            </Typography>
                                            <ServicesListView
                                                services={basketStoreServices}
                                                appointmentTypes={allAppointmentTypes?.content ?? []}
                                                currentTaxRatePercentage={vatRate ?? 0}
                                                showOptionalExtras={true}
                                            />
                                        </div>
                                    </div>
                                    {!priceHiddenFromResource && (
                                        <div className={classes.keyInfoBlock}>
                                            <div className={classes.price}>
                                                &pound;{formattedTotalPrice}
                                                <div>
                                                    <Typography variant="subtitle1">
                                                        {vatRate ? "(ex. VAT)" : ""}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <IconButton
                                        className={classes.editIcon}
                                        onClick={() => setInitialStep(3)}
                                    >
                                        <EditIcon fill="url(#BrandGradient)"/>
                                    </IconButton>
                                </div>
                                <div className={classes.overviewCard}>
                                    <div className={classes.flexGrowSection}>
                                        <div className={classes.infoBlock}>
                                            <Typography variant="h5">Customer Details</Typography>
                                            <Typography variant="subtitle1">
                                                {customer?.firstName} {customer?.lastName}
                                            </Typography>
                                            <Typography variant="subtitle1">
                                                {customer?.phoneNumber && (
                                                    <div className={classes.contactLink}>
                                                        <a href={`tel:${customer.phoneNumber}`}>
                                                            {customer.phoneNumber}
                                                        </a>
                                                    </div>
                                                )}
                                                {customer?.email && (
                                                    <div className={classes.contactLink}>
                                                        <a href={`mailto:${customer.email}`}>
                                                            {customer.email}
                                                        </a>
                                                    </div>
                                                )}
                                                <div>{customer?.company ?? ""}</div>
                                                {customerAddress?.addressLine1},{" "}
                                                {customerAddress?.addressLine2
                                                    ? `${customerAddress.addressLine2}, `
                                                    : ""}
                                                {customerAddress?.addressLine3
                                                    ? `${customerAddress.addressLine3}, `
                                                    : ""}
                                                {customerAddress?.town}, {customerAddress?.postcode}
                                                <div>{customerAddress?.notes}</div>
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={
                                        bookingDetails.isEcoSlot
                                            ? classes.ecoCard
                                            : classes.overviewCard
                                    }
                                >
                                    <div className={classes.flexGrowSection}>
                                        <div className={classes.infoBlock}>
                                            <Typography variant="h5">Calendar</Typography>
                                            <Typography variant="subtitle1">
                                                {days[bookingDetails.date.getDay()]}{" "}
                                                {formatDateSuffix(bookingDetails.date)} |{" "}
                                                {renderCalendarTime()}
                                            </Typography>
                                        </div>
                                        <div className={classes.ecoIcon}>
                                            {bookingDetails.isEcoSlot && (
                                                <img src="/assets/images/eco-icon.svg" alt="Eco icon"/>
                                            )}
                                        </div>
                                        <div className={classes.resource}>
                                            <ResourceIcon/>
                                            <Typography variant="h5">{resource?.name}</Typography>
                                        </div>
                                        <IconButton
                                            className={classes.editIcon}
                                            onClick={() => setInitialStep(4)}
                                        >
                                            <EditIcon/>
                                        </IconButton>
                                    </div>
                                </div>
                                {isRecurringBooking && (
                                    <div className={classes.overviewCard}>
                                        <div className={classes.infoBlock}>
                                            <Typography variant="h5">Recurring Timeslots</Typography>
                                            <Typography variant="subtitle1">
                                                <Accordion className={classes.accordion}>
                                                    <AccordionSummary
                                                        className={classes.accordionHeader}
                                                        expandIcon={<ExpandIcon/>}
                                                    >
                                                        Occurs {bookingDetails.recurringBookings?.length}{" "}
                                                        {bookingDetails.recurringBookings?.length === 1
                                                            ? "Time"
                                                            : "Times"}{" "}
                                                    </AccordionSummary>
                                                    <AccordionDetails
                                                        className={classes.accordionDetails}
                                                    >
                                                        {bookingDetails.recurringBookings?.map(
                                                            (element) => {
                                                                const recurringBookingStartDate = new Date(
                                                                    element.start
                                                                );
                                                                const recurringBookingEndDate = new Date(
                                                                    element.end
                                                                );
                                                                return (
                                                                    <div
                                                                        className={classes.recurringBookingListItem}
                                                                        key={element.id}
                                                                    >
                                                                        <p>
                                      <span>
                                        {
                                            days[
                                                recurringBookingStartDate.getDay()
                                                ]
                                        }{" "}
                                          {formatDateSuffix(
                                              recurringBookingStartDate
                                          )}
                                      </span>
                                                                            <span> | </span>
                                                                        </p>
                                                                        <p>
                                                                            {formatTimeOnly(
                                                                                recurringBookingStartDate
                                                                            )}{" "}
                                                                            -{" "}
                                                                            {formatTimeOnly(recurringBookingEndDate)}
                                                                        </p>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Typography>
                                        </div>
                                    </div>
                                )}
                                <div className={classes.overviewCard}>
                                    <div className={classes.infoBlockLineBreak}>
                                        <Typography variant="h5">Additional Information</Typography>
                                        <Typography variant="subtitle1">
                                            {renderAdditionalInformation()}
                                        </Typography>
                                    </div>
                                </div>
                                {bookingDetails.tags?.length != 0 && (
                                    <div
                                        className={`${classes.overviewCard} ${classes.furtherInfoCard}`}
                                    >
                                        <div className={classes.flexGrowSection}>
                                            <div className={classes.infoBlockLineBreak}>
                                                <Typography variant="h5">Booking Tags</Typography>
                                                <Typography variant="subtitle1">
                                                    {bookingDetails.tags?.map((element) => {
                                                        return (
                                                            <p
                                                                className={classes.tagsListItem}
                                                                key={element.id}
                                                            >
                                                                {element?.name}: {element?.value}
                                                            </p>
                                                        );
                                                    })}
                                                </Typography>
                                                <br/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className={classes.overviewCard}>
                                    <div className={classes.overviewFooter}>
                                        <button
                                            className={classes.cancel}
                                            onClick={() => setConfirmCancelBooking(true)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </AppTabPanel>
                            <AppTabPanel value={tab} index={1}>
                                <br/>
                                {/*<div className={classes.overviewCard}>*/}
                                <div className={classes.infoBlockLineBreak}>
                                    <Typography variant="h5">Booking Notes</Typography>
                                    {/*<Typography variant="subtitle1">*/}
                                    {/*  {bookingDetails.notes !== "" ? bookingDetails.notes : "-"}*/}
                                    {/*</Typography>*/}
                                    <div className={classes.textAreaContainer}>
                                        <BookingFields.notes/>
                                    </div>
                                </div>
                                {/*</div>*/}
                                <br/>
                            </AppTabPanel>
                            <AppTabPanel value={tab} index={2}>
                                <div className={classes.imageUnitsContainer}>
                                    {bookingDetails.images &&
                                        bookingDetails?.images.map(({id, fullImageName, thumbnailBase64, createdBy, createdAt}) => (
                                            <div key={id}>
                                                <BookingFormFileDisplayUnit
                                                    fileName={fullImageName}
                                                    fileType={`image/jpeg`}
                                                    dateAdded={createdAt}
                                                    addedBy={createdBy}
                                                    base64={thumbnailBase64}
                                                />
                                            </div>
                                        ))}
                                </div>
                            </AppTabPanel>
                            <AppTabPanel value={tab} index={3}>
                                <div className={classes.imageUnitsContainer}>
                                    {bookingDetails.documents &&
                                        bookingDetails?.documents.map(
                                            ({id, fileName, fileURL, createdBy, createdAt, base64}) => (
                                                <div key={id}>
                                                    <BookingFormFileDisplayUnit
                                                        fileName={fileName}
                                                        fileType={`application/pdf`}
                                                        dateAdded={createdAt}
                                                        addedBy={createdBy}
                                                        fileUrl={fileURL}
                                                        base64={base64}
                                                    />
                                                </div>
                                            )
                                        )}
                                </div>
                            </AppTabPanel>
                        </Grid>
                    </>
                )}
            </div>
            {isRecurringBooking && role === "admin" ? (
                <BookingFormCancelRecurringAppointmentDialog
                    open={confirmCancelBooking}
                    handleCancel={() => setConfirmCancelBooking(false)}
                    handleCancelSingleBooking={handleCancelBooking}
                    handleCancelRecurringBooking={handleCancelRecurringBookings}
                    title="Cancel recurring booking"
                    caption="This is one booking in a series. You can cancel this or this and all following events in the series."
                />
            ) : (
                <AppConfirmationDialog
                    open={confirmCancelBooking}
                    handleCancel={() => setConfirmCancelBooking(false)}
                    handleConfirmation={handleCancelBooking}
                    title="Cancel booking"
                    caption="Cancelling this booking will remove it from your calendar and the time will become available for other bookings"
                />
            )}
        </>
    );
};

export default BookingFormOverview;
