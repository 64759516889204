import { TextField, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "../../store";
import { formatTimeFromTotalMins } from "./dateFormatters";

const BookingEditor: React.FC = () => {
  const dispatch = useDispatch();
  const postcode = useSelector(selectors.algorithmDemo.selectedSlotPostcode);

  const selectedSlot = useSelector(selectors.algorithmDemo.selectedSlot);
  const selectedResource = useSelector(
    selectors.algorithmDemo.selectedResource
  );

  if (!selectedSlot) {
    return <Typography>Select a slot to edit</Typography>;
  }

  return (
    <div>
      <Typography>
        Edit slot: {selectedResource?.name}{" "}
        {formatTimeFromTotalMins(selectedSlot.startTotalMins)}
      </Typography>
      <TextField
        label="Postcode"
        value={postcode}
        onChange={(e) =>
          dispatch(
            actions.algorithmDemo.setSelectedSlotPostcode(e.target.value)
          )
        }
      />
    </div>
  );
};

export default BookingEditor;
