import { useField } from "formik";
import { FormikInputPropsKeys } from "./common";
import AppServiceSelect, { AppServiceSelectProps } from "./AppServiceSelect";

export interface AppFormikServiceSelectProps<T>
  extends Omit<AppServiceSelectProps<T>, FormikInputPropsKeys> {
  name: string;
}

const AppFormikServiceSelect = <T extends { id: number }>({
  name,
  label,
  ...restOfProps
}: AppFormikServiceSelectProps<T>) => {
  const [fieldProps, fieldMeta, { setValue }] = useField(name);
  const isError = fieldMeta.touched && !!fieldMeta.error;
  const helperText = fieldMeta.touched ? fieldMeta.error : undefined;

  const handleChange = (Services: T[]) => {
    setValue(Services);
  };

  return (
    <AppServiceSelect
      {...restOfProps}
      {...fieldProps}
      error={isError}
      helperText={helperText}
      onChange={handleChange}
      label={label}
    />
  );
};

export default AppFormikServiceSelect;
