import { useField } from "formik";
import AppTimeRangePicker, {
  AppTimeRangePickerProps,
  TimeRange,
} from "./AppTimeRangePicker";
import { FormikInputPropsKeys } from "./common";

export interface AppFormikTimeRangePickerProps
  extends Omit<AppTimeRangePickerProps, FormikInputPropsKeys> {
  name: string;
}

const AppFormikTimeRangePicker: React.FC<AppFormikTimeRangePickerProps> = ({
  name,
  ...restOfProps
}) => {
  const [fieldProps, , { setValue }] = useField(name);

  const handleChange = (timeRange: TimeRange) => {
    setValue(timeRange);
  };

  return (
    <AppTimeRangePicker
      {...restOfProps}
      {...fieldProps}
      onChange={handleChange}
    />
  );
};

export default AppFormikTimeRangePicker;
