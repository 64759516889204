import { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import {
  RequireAdminUserRole,
  RequireResourceUserRole,
  useAuth,
} from "../../auth";
import { Link } from "react-router-dom";
import { adminPaths, publicPaths, resourcePaths } from "../../routes";
import { makeStyles, Typography } from "@material-ui/core";
import { useWindowSize } from "./WindowSize";
import { useConfig } from "../../config/ConfigContext";

const useStyles = makeStyles((theme) => ({
  userName: {
    paddingRight: theme.spacing(1),
    whiteSpace: "nowrap",
    fontSize: "1.125rem",
    color: theme.palette.primary.dark,
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    width: 32,
    height: 32,
  },
}));

const AccountMenu: React.FC = () => {
  const { width } = useWindowSize();
  const classes = useStyles();
  const auth = useAuth();
  const { vendorId } = useConfig();
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {width && width >= 1140 && (
        <Typography className={classes.userName}>
          {auth.firstName}
          {auth.role === "admin" && <> {auth.lastName}</>}
        </Typography>
      )}
      <div>
        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
          onClick={handleClick}
        >
          <AccountCircle color="primary" className={classes.icon} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          open={Boolean(anchorEl)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={handleClose}
        >
          <RequireAdminUserRole>
            <MenuItem component={Link} to={adminPaths.widgetConfig}>
              Widget
            </MenuItem>
            <MenuItem
              component={Link}
              target="_blank"
              to={publicPaths.microsite(vendorId)}
            >
              Microsite
            </MenuItem>
            <MenuItem component={Link} to={adminPaths.settings.services}>
              Settings
            </MenuItem>
          </RequireAdminUserRole>
          <RequireResourceUserRole>
            <MenuItem component={Link} to={resourcePaths.account}>
              My account
            </MenuItem>
          </RequireResourceUserRole>
          <MenuItem onClick={() => auth.logOut()}>Logout</MenuItem>
        </Menu>
      </div>
    </>
  );
};

export default AccountMenu;
