import { useSelector } from "react-redux";
import { createWidgetBooking } from "../api/widget";
import { createIsoDateFromDateAndTime, formatMinutesAfterMidnightToTimeString } from "../dateFormatters";
import { CreateWidgetBookingDto } from "../models/Booking";
import { useWidgetVendorId } from "../routes/public/PublicRoutes";
import { selectors } from "../store";
import { successToast } from "../toast";
import WidgetBookingFormContainer from "./Form/WidgetBookingFormContainer";
import { defaultWidgetBookingFormValues, WidgetBookingFormValues } from "./Form/WidgetBookingFormValues";

const BookingWidget: React.FC = () => {
  const vendorId = useWidgetVendorId();
  const basketStoreServices = useSelector(selectors.basket.services);

  const handleAddBooking = async (
    booking: WidgetBookingFormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const bookingDto: CreateWidgetBookingDto = {
      resourceId: booking.resourceId,
      start: !booking.date
        ? null
        : createIsoDateFromDateAndTime(
          booking.date,
          formatMinutesAfterMidnightToTimeString(booking.start)
        ),
      end: !booking.date
        ? null
        : createIsoDateFromDateAndTime(
          booking.date,
          formatMinutesAfterMidnightToTimeString(booking.end)
        ),


      customerAddress: {
        addressLine1: booking.addressLine1,
        addressLine2: booking.addressLine2,
        addressLine3: booking.addressLine3,
        town: booking.town,
        postcode: booking.postcode,
      },

      customer: {
        firstName: booking.firstName,
        lastName: booking.lastName,
        company: booking.company,
        email: booking.email,
        phoneNumber: booking.phoneNumber,
        termsAccepted: booking.termsAccepted,
        marketingAccepted: booking.marketingAccepted,
      },

      /**
       * jobs represents the appointmentTypes json body parameter
       */
      jobs: basketStoreServices,
      //TODO: Why are tags not here?!?!?!
      // tags: booking.tags,

      /**
       * Obsolete parameters from single booking functionality
       *  leaving them here for now to avoid having to refactor lots of this
       *  TODO - Remove obsolete parameters once everything works
       */
      firstJobAppointmentTypeId: booking.firstJobAppointmentTypeId,
      firstJobOptionalExtras: booking.firstJobOptionalExtras,
    };

    const response = await createWidgetBooking(vendorId, bookingDto);

    if (response.isError) {
      setSubmitting(false);
    }
  };

  const initialValues: WidgetBookingFormValues = defaultWidgetBookingFormValues;

  return (
    <>
      <WidgetBookingFormContainer
        initialValues={initialValues}
        onSubmit={handleAddBooking}
      />
    </>
  );
};

export default BookingWidget;