import { darken, lighten, makeStyles } from "@material-ui/core";
import { useCallback } from "react";
import { useParams } from "react-router";
import { useHttpRequest } from "../api";
import { getVendorMicrositeConfig } from "../api/microsite";
import AppTheme from "../theme/AppTheme";
import AppLoader from "../components/AppLoader";
import { PageContainer } from "../components/MainLayout";
import MicrositeHeader from "../microsite/MicrositeHeader";
import MicrositeSidebar from "../microsite/MicrositeSidebar";
import BookingWidget from "../widget/BookingWidget";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h4.fontSize,
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.h3.fontSize,
    },
  },
  pageLayout: {
    position: "relative",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      gridGap: theme.spacing(4),
    },
  },
  mainContent: {
    position: "relative",
    flex: 1,
    paddingBottom: theme.spacing(4),
  },
}));

interface PageParams {
  vendorId: string;
}

const MicrositePage: React.FC = () => {
  const classes = useStyles();
  const { vendorId } = useParams<PageParams>();
  const getConfig = useCallback(
    () => getVendorMicrositeConfig(parseInt(vendorId)),
    [vendorId]
  );
  const { result, isLoading } = useHttpRequest(getConfig);

  const appThemeConfig = {
    primaryColourMain: result?.primaryColour,
    primaryColourLight: result?.primaryColour
      ? lighten(result.primaryColour, 0.1)
      : undefined,
    primaryColourDark: result?.primaryColour
      ? darken(result.primaryColour, 0.1)
      : undefined,
    secondaryColourMain: result?.secondaryColour,
    secondaryColourLight: result?.secondaryColour
      ? lighten(result.secondaryColour, 0.1)
      : undefined,
    secondaryColourDark: result?.secondaryColour
      ? darken(result.secondaryColour, 0.1)
      : undefined,
  };

  document.title = result?.name
    ? `${result.name} | Powered by MyBookingHub`
    : "MyBookingHub - Automate your bookings, minimise your travel time";

  return (
    <AppTheme {...appThemeConfig}>
      {isLoading && <AppLoader />}
      {!isLoading && (
        <>
          {result?.headerImageUrl && (
            <MicrositeHeader
              imageUrl={result?.headerImageUrl}
              imageDesc={`${result?.name} header image`}
            />
          )}
          <PageContainer>
            <h1 className={classes.title}>{result?.name}</h1>
            <div className={classes.pageLayout}>
              <MicrositeSidebar
                openingHours={result?.openingHours}
                logoUrl={result?.logoUrl}
                logoDesc={`${result?.name} logo`}
                primaryColour={appThemeConfig.primaryColourMain}
                contactNumber={result?.contactNumber}
                contactEmail={result?.contactEmail}
              />
              <div className={classes.mainContent}>
                <BookingWidget />
              </div>
            </div>
          </PageContainer>
        </>
      )}
    </AppTheme>
  );
};

export default MicrositePage;
