import { useField } from "formik";
import { useEffect } from "react";
import { useAuth } from "../../../auth";
import AppFormikSelect from "../../../components/AppForm/AppFormikSelect";
import { AppSelectOption } from "../../../components/AppForm/AppSelect";
import { PaymentStatus } from "../../../models/PaymentStatus";
import { BookingFormStyles } from "../BookingFormStyles";

interface Props {
  paymentStatuses: PaymentStatus[];
  isLoading: boolean;
}

const mapResponseToSelectOptions = ({
  name,
  id,
}: PaymentStatus): AppSelectOption => {
  return { label: `${name}`, value: id };
};

const BookingFormPaymentStatus: React.FC<Props> = ({
  paymentStatuses,
  isLoading,
}) => {
  const classes = BookingFormStyles();
  const { role } = useAuth();
  const [
    { value: selectedPaymentStatusId },
    ,
    { setValue: setSelectedPaymentStatusId },
  ] = useField<number>("paymentStatusId");
  const defaultPaymentStatusId =
    paymentStatuses.filter((status) => status.isDefault)[0]?.id ?? -1;
  const paymentStatusDetail = paymentStatuses.find(
    (status) => status.id === selectedPaymentStatusId
  );

  useEffect(() => {
    if (selectedPaymentStatusId === -1 && defaultPaymentStatusId) {
      setSelectedPaymentStatusId(defaultPaymentStatusId);
    }
  }, [isLoading]);

  const isResource = role === "resource";
  const isDisabled =
    isResource &&
    paymentStatusDetail &&
    !paymentStatusDetail.resourceCanEditStatus;

  const options =
    isResource && !isDisabled
      ? paymentStatuses.filter((status) => status.resourceCanEditStatus)
      : paymentStatuses;

  return (
    <div>
      <h3 className={classes.stepContainerTitle}>Payment status</h3>
      <AppFormikSelect
        name="paymentStatusId"
        options={options ? options.map(mapResponseToSelectOptions) : []}
        isLoading={isLoading}
        readOnly={isDisabled}
      />
    </div>
  );
};

export default BookingFormPaymentStatus;
