import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  useTheme,
  Divider
} from "@material-ui/core";

import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
    textTransform: "none",
    fontSize: "1.5rem"
  },
  underline: {
    margin: ".5rem 0"
  },
  submitButton: {
    background: theme.palette.secondaryRed.main,
    color: "white"
  }
}));


interface AppConfirmationDialogProps {
  open: boolean;
  handleCancelSingleBooking: () => void;
  handleCancelRecurringBooking: () => void;
  handleCancel: () => void;
  title?: string;
  caption?: string;
  confirmLabel?: string;
  cancelLabel?: string;
}

const BookingFormCancelRecurringAppointmentDialog: React.FC<AppConfirmationDialogProps> = ({
  open,
  handleCancelSingleBooking,
  handleCancelRecurringBooking,
  handleCancel,
  title,
  caption,
  confirmLabel = "Confirm",
  cancelLabel = "Back",
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [cancelRecurringBookings, setCancelRecurringBookings] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value == "recurring") {
      setCancelRecurringBookings(true);
      return;
    }
    setCancelRecurringBookings(false);
  };

  const handleSubmit = () => {
    if (cancelRecurringBookings) {
      handleCancelRecurringBooking();
      return;
    }
    handleCancelSingleBooking();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && <DialogTitle disableTypography={true} className={classes.title} id="alert-dialog-title">{title}</DialogTitle>}
      {caption && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {caption}
          </DialogContentText>
          <RadioGroup
            onChange={handleChange}
            value={cancelRecurringBookings ? "recurring" : "single"}
            name="recurring-booking-cancelation-options">
            <FormControlLabel labelPlacement="end" value="single" control={<Radio color="primary" />} label="This only" />
            <Divider color="default" className={classes.underline} />
            <FormControlLabel value="recurring" control={<Radio color="primary" />} label="This and future" />
            <Divider color="default" className={classes.underline} />
          </RadioGroup>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleCancel} color="default">
          {cancelLabel}
        </Button>
        <Button onClick={handleSubmit} className={classes.submitButton} variant="contained" autoFocus>
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BookingFormCancelRecurringAppointmentDialog;
