import { UserRole } from "../models/AccountManagement";
import storageKeys from "../sessionStorageKeys";

export interface AccountInformation {
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
}

const getAccountInformation = (): AccountInformation | undefined => {
  const jsonData = sessionStorage.getItem(storageKeys.accountInformation);
  if (!jsonData) return undefined;

  return JSON.parse(jsonData) as AccountInformation;
};

const setAccountInformation = (
  accountInformation: AccountInformation
): void => {
  // Ensure that no extra properties are saved.
  const newAccountInformation: AccountInformation = {
    firstName: accountInformation.firstName,
    lastName: accountInformation.lastName,
    email: accountInformation.email,
    role: accountInformation.role,
  };

  sessionStorage.setItem(
    storageKeys.accountInformation,
    JSON.stringify(newAccountInformation)
  );
};

const sessionStorageClient = {
  setToken: (token: string): void =>
    sessionStorage.setItem(storageKeys.authToken, token),
  getToken: (): string | null => sessionStorage.getItem(storageKeys.authToken),
  clearToken: (): void => sessionStorage.removeItem(storageKeys.authToken),
  setAccountInformation,
  getAccountInformation,
  clearAccountInformation: (): void =>
    sessionStorage.removeItem(storageKeys.accountInformation),
};

export default sessionStorageClient;
