import React, { Dispatch, SetStateAction } from "react";

const getStorageValue = (key: string, defaultValue: any) => {
  const saved = localStorage.getItem(key);
  if (!saved) {
    return defaultValue;
  }

  const storedValue = JSON.parse(saved);
  return storedValue || defaultValue;
};

export const useLocalStorage = <T extends unknown>(
  key: string,
  defaultValue: T
): [T, Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = React.useState<T>(() => {
    const storageValue = getStorageValue(key, defaultValue);

    if (defaultValue instanceof Date) {
      return new Date(storageValue);
    }

    return storageValue;
  });

  React.useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
