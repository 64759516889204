import { Layer, Source, LayerProps } from "react-map-gl";

interface CoverageMapMarkerProps {
  longitude: number;
  latitude: number;
}

const CoverageMapMarker: React.FC<CoverageMapMarkerProps> = ({
  longitude,
  latitude,
}) => {
  const geojson:
    | GeoJSON.Feature<GeoJSON.Geometry>
    | GeoJSON.FeatureCollection<GeoJSON.Geometry>
    | string = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: { type: "Point", coordinates: [longitude, latitude] },
        properties: {},
      },
    ],
  };

  const layerStyle: LayerProps = {
    id: "point",
    type: "circle",
    paint: {
      "circle-radius": 5,
      "circle-color": "#000",
    },
  };

  return (
    <>
      <Source id="my-data" type="geojson" data={geojson}>
        <Layer {...layerStyle} />
      </Source>
    </>
  );
};

export default CoverageMapMarker;
