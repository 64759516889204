import AppFormikCheckBox from "./AppFormikCheckbox";
import AppFormikSelect, { AppFormikSelectProps } from "./AppFormikSelect";

export type CreatePreconfiguredAppFormikCheckboxOptions = {
  name: string;
  label: string;
};

export const createPreconfiguredAppFormikCheckbox = ({
  name,
  label,
}: CreatePreconfiguredAppFormikCheckboxOptions): React.FC => {
  const PreConfiguredAppFormikCheckbox = () => (
    <AppFormikCheckBox label={label} formikName={name} />
  );

  return PreConfiguredAppFormikCheckbox;
};

export default createPreconfiguredAppFormikCheckbox;
