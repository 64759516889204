import {Typography} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import {useCallback, useMemo} from "react";
import {useHttpRequest} from "../../api";
import {getResourceSummary} from "../../api/Dashboard";
import {AppTableAlt} from "../../components/AppTableAlt";
import {convertMinsToHrsMins} from "../../dateFormatters";
import {formatNumber} from "../../numericalUtils";
import {useStyles} from "./";

interface DashboardResourceSummaryTableProps {
    startDate: string;
    endDate: string;
    resourceIds: number[];
}

interface CellProps<T> {
    value: T;
}

export const DashboardResourceSummaryTable: React.FC<DashboardResourceSummaryTableProps> =
    ({startDate, endDate, resourceIds}) => {
        const classes = useStyles();

        const getResourceSummaryData = useCallback(
            () => getResourceSummary(startDate, endDate, resourceIds),
            [startDate, endDate]
        );
        const {result: resourceSummaryList, isLoading} = useHttpRequest(
            getResourceSummaryData
        );

        const columns = useMemo(
            () => [
                {
                    Header: "Name",
                    accessor: "name", // accessor is the "key" in the data
                },
                {
                    Header: "Booking value",
                    accessor: "totalRevenue",
                    Cell: (cell: CellProps<number>) =>
                        `${formatNumber(cell.value, {currency: true})}`,
                },
                {
                    Header: "Average hour",
                    accessor: "averageRevenuePerHour",
                    Cell: (cell: CellProps<number>) =>
                        `${formatNumber(cell.value, {currency: true})}`,
                },
                {
                    Header: "Total time driving",
                    accessor: "totalTravelTimeMinutes",
                    Cell: (cell: CellProps<number>) =>
                        convertMinsToHrsMins(cell.value, true),
                },
                {
                    Header: "Total time idle",
                    accessor: "totalIdleTimeMinutes",
                    Cell: (cell: CellProps<number>) =>
                        convertMinsToHrsMins(cell.value, true),
                },
                {
                    Header: "Completed bookings",
                    accessor: "totalCompleteBookings",
                },
                {
                    Header: "Cancelled bookings",
                    accessor: "totalCancelledBookings",
                },
                {
                    Header: "Efficiency rating",
                    accessor: "efficiencyRating",
                    Cell: (cell: CellProps<number>) => `${formatNumber(cell.value)}%`,
                },
            ],
            []
        );

        const resourcePerformanceList = resourceSummaryList?.content.sort(
            (a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
            }
        );

        return (
            <div className={classes.container}>
                <div className={classes.tableWrapper}>
                    <div className={classes.tableTitleHeader}>
                        <AccountCircle/>
                        <Typography variant="h2">Mobile worker performance</Typography>
                    </div>
                    <div className={classes.tableContainer}>
                        {resourcePerformanceList && (
                            <AppTableAlt columns={columns} data={resourcePerformanceList}/>
                        )}
                    </div>
                </div>
            </div>
        );
    };
