import { HttpClientResponse } from ".";
import { mapFromApiOutcodeGeoJson } from "../modelMappings/Geocoding";
import { OutcodeGeoJson } from "../models/Geocoding";
import {
  ApiStandardResponse,
  buildApiUrl,
  doErrorToastIfRequired,
  httpClient,
  mapHttpClientResponse,
} from "./common";
import {
  ApiLatLngLookupResponse,
  ApiOutcodeGeoJson,
  ApiPostcodeCoverage,
} from "./models/Geocoding";

export const postcodeExists = async (
  postcode: string
): Promise<HttpClientResponse<void>> => {
  const url = buildApiUrl(`api-public/valid-postcodes/${postcode}`);
  const response = await httpClient.getRequest<ApiStandardResponse<void>>(url, [
    404,
  ]);

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, (r) => r);
};

export const postcodeCoverage = async (
  postcode: string,
  vendorId: number
): Promise<HttpClientResponse<ApiPostcodeCoverage>> => {
  const url = buildApiUrl(
    `api-public/postcode-coverage?postcode=${postcode}&vendorId=${vendorId}`
  );
  const response = await httpClient.getRequest<
    ApiStandardResponse<ApiPostcodeCoverage>
  >(url);

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, (r) => r);
};

export const longLatLookup = async (
  postcode: string,
  muteFailure?: boolean
): Promise<HttpClientResponse<ApiLatLngLookupResponse>> => {
  const url = buildApiUrl(`api-public/lat-lngs?postcode=${postcode}`);
  const response = await httpClient.getRequest<
    ApiStandardResponse<ApiLatLngLookupResponse>
  >(url, muteFailure ? [400, 404] : []);

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, (r) => r);
};

export const getGeoJsonByOutcode = async (outcode: string): Promise<HttpClientResponse<OutcodeGeoJson>> => {
  const url = buildApiUrl(`api-admin/outcodes/${outcode}/geojson`);
  const response = await httpClient.getRequest<ApiStandardResponse<ApiOutcodeGeoJson>>(url);
  
  return mapHttpClientResponse(response, mapFromApiOutcodeGeoJson);
};