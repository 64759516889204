import { addMinutes } from "date-fns";
import { setDay, startOfDay } from "date-fns/esm";
import { ApiCalendarEntry } from "../api/models/Booking";
import {
  formatTimeOnly,
  formatTimeStringToMinutesAfterMidnight,
} from "../dateFormatters";
import { CalendarEntries, CalendarTravelTime } from "../models/Calendar";
import { ResourceWorkingHours } from "../models/Resource";
import { excludeVATFromPriceIfRequired } from "../numericalUtils";

import { Jobs } from "../models/Booking";

const createNonWorkingDays = (
  workingsDayIds: number[],
  resourceId: number
): ResourceWorkingHours[] => {
  const dayIds = [1, 2, 3, 4, 5, 6, 0].filter(
    (id) => !workingsDayIds.includes(id)
  );

  return dayIds.map((dayId) => {
    return {
      dayOfWeekId: dayId,
      startTimeMinutesPastMidnight: formatTimeStringToMinutesAfterMidnight(
        formatTimeOnly(
          startOfDay(
            setDay(new Date(), dayId, {
              weekStartsOn: 1,
            })
          ),
          false
        )
      ),
      finishTimeMinutesPastMidnight: formatTimeStringToMinutesAfterMidnight(
        formatTimeOnly(
          startOfDay(
            setDay(new Date(), dayId, {
              weekStartsOn: 1,
            })
          ),
          false
        )
      ),
      resourceId: resourceId,
      nonWorking: true,
    };
  });
};

export const mapFromApiCalendarEntries = (
  apiCalendarEntries: ApiCalendarEntry
): CalendarEntries => {
  let allWorkingHours: ResourceWorkingHours[] = [];
  const travelTimes: CalendarTravelTime[] = [];
  const resourceIds = Array.from(
    new Set(
      apiCalendarEntries.resourceWorkingHours.map((item) => item.resourceId)
    )
  );

  resourceIds.map((id) => {
    const resourceSpecificWorkingDays =
      apiCalendarEntries.resourceWorkingHours.filter(
        (item) => item.resourceId === id
      );
    const nonWorkingDays = createNonWorkingDays(
      resourceSpecificWorkingDays.map((item) => item.dayOfWeekId),
      id
    );

    allWorkingHours = [
      ...allWorkingHours,
      ...resourceSpecificWorkingDays,
      ...nonWorkingDays,
    ];
  });

  apiCalendarEntries.bookings.forEach((booking) => {
    if (booking.travelTimeToNextBookingMinutes) {
      const roundUpTravelTime =
        Math.ceil(booking.travelTimeToNextBookingMinutes / 5) * 5;

      travelTimes.push({
        id: booking.id,
        start: new Date(booking.end),
        end: addMinutes(new Date(booking.end), roundUpTravelTime),
        initialDuration: booking.travelTimeToNextBookingMinutes,
        resourceId: booking.resourceId,
      });
    }
  });

  return {
    bookings: apiCalendarEntries.bookings.map((booking) => {
      return {
        ...booking,
        start: new Date(booking.start),
        end: new Date(booking.end),
        totalPriceExVat: excludeVATFromPriceIfRequired(booking.totalPrice, booking.taxRate),
        jobs: booking.jobs,
      };
    }),
    timeOffs: apiCalendarEntries.timeOffs.map((timeOff) => {
      return {
        ...timeOff,
        start: new Date(timeOff.start),
        end: new Date(timeOff.end),
      };
    }),
    workingsHours: allWorkingHours,
    travelTimes,
  };
};
