import { FormLabel, makeStyles } from "@material-ui/core";
import AppCustomColourButton from ".";

export interface AppCustomColourPickerProps {
  name: string;
  label?: string;
  value: string;
  colours?: string[];
  onChange: (colour: string) => void;
  error?: boolean;
  helperText?: string;
}

const useStyles = makeStyles((theme) => ({
  picker: {
    marginTop: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "repeat(6, 25px)",
    gridGap: theme.spacing(2),
  },
  error: {
    color: theme.palette.secondaryRed.main,
  },
}));

const AppCustomColourPicker: React.FC<AppCustomColourPickerProps> = ({
  label,
  value,
  colours,
  onChange,
  error,
  helperText,
}) => {
  const classes = useStyles();

  return (
    <>
      <FormLabel>{label}</FormLabel>
      <div className={classes.picker}>
        {colours?.map((colour, index) => (
          <AppCustomColourButton
            key={index}
            onChange={onChange}
            colour={colour}
            selectedColour={value}
          />
        ))}
      </div>
      {error && <p className={classes.error}>{helperText}</p>}
    </>
  );
};

export default AppCustomColourPicker;
