import { IconButton } from "@material-ui/core";
import {
  GetApp as DownloadIcon,
  Close,
  InsertDriveFileOutlined,
} from "@material-ui/icons";

import { useStyles } from "./BookingFormFileDisplayUnitStyles";

type BookingFormFileDisplayUnitProps = {
  fileName: string;
  fileType: string;
  dateAdded: Date;
  addedBy: string;
  base64?: string;
  fileUrl?: string;
  fileSize?: number;
};

export const BookingFormFileDisplayUnit: React.FC<BookingFormFileDisplayUnitProps> =
  ({ fileName, fileType, dateAdded, addedBy, base64, fileUrl }) => {
    const classes = useStyles();

    const base64ToImage = (base64: string, fileType: string) => {
      return (
        <img
          className={classes.imageContainer}
          src={`data:${fileType};base64,${base64}`}
          alt="file"
        />
      );
    };

    function base64FileSize(base64: string): string {
      // Split the base64 string on comma
      const splitStr = base64.split(",");

      let base64Str;

      // Check if the split string has at least two parts
      if (splitStr.length < 2) {
        // Base64 string doesn't have the MIME type prefix
        base64Str = splitStr[0];
      } else {
        // Base64 string has the MIME type prefix
        base64Str = splitStr[1];
      }

      // Each base64 character contains 6 bits of data
      const byteCount = base64Str.length * 6;

      // Base64 encodes data in 8 bit groups, so we'll divide by 8
      const fileSize = byteCount / 8;

      // Base64 increases file size by about 33%, adjust for that
      const originalSize = fileSize * 0.75;

      // Convert to MB (1 Byte = 1 / 1,000,000 MB)
      const sizeInMB = originalSize / 1_000_000;

      // Convert to KB if size in MB is less than 1
      if (sizeInMB < 1) {
        // 1 MB = 1000 KB
        const sizeInKB = sizeInMB * 1000;
        // Round to 2 decimal places and return size in KB
        return sizeInKB.toFixed(2) + " KB";
      } else {
        // Round to 2 decimal places and return size in MB
        return sizeInMB.toFixed(2) + " MB";
      }
    }

    const downloadBase64File = (
      contentType: string,
      base64Data: string,
      fileName: string
    ) => {
      const linkSource = `data:${contentType};base64,${base64Data}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    };

    const downloadDocument = () => {
      if (fileUrl) {
        window.open(fileUrl, "_blank");
      }
    };

    const downloadFile = () => {
      if (fileUrl) {
        console.log("fileUrl", fileUrl);
        downloadDocument();
      }
      if (base64) {
        downloadBase64File(fileType, base64, fileName);
      }
    };

    return (
      <div className={classes.container}>
        <div>
          {!fileUrl ? (
            base64ToImage(base64 ?? "", fileType)
          ) : (
            <div className={classes.fileImageContainer}>
              <InsertDriveFileOutlined
                style={{
                  color: "#C9C6BE",
                  width: "32px",
                  height: "32px",
                }}
              />
            </div>
          )}
        </div>

        <div className={classes.infoContainer}>
          <p className={classes.title}>{fileName}</p>
          <p className={classes.fileSize}>
            {base64 ? base64FileSize(base64) : "?MB"}
          </p>
          <p>
            Added by {addedBy} on{" "}
            {new Date(dateAdded).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
          </p>
        </div>
        <div className={classes.actionsContainer}>
          <IconButton>
            <DownloadIcon
              onClick={downloadFile}
              style={{
                color: "#1B283D",
                width: "24px",
                height: "24px",
              }}
            />
          </IconButton>
          {/* <IconButton>
            <Close
              style={{
                color: "#1B283D",
                width: "24px",
                height: "24px",
              }}
            />
          </IconButton> */}
        </div>
      </div>
    );
  };

export default BookingFormFileDisplayUnit;
