import { HttpClientResponse } from ".";
import { PaymentStatus } from "../models/PaymentStatus";
import {
  buildApiUrl,
  doErrorToastIfRequired,
  httpClient,
  mapHttpClientListResponse,
} from "./common";
import { ApiListResponse } from "./common/ApiListResponse";
import { ApiPaymentStatus } from "./models/PaymentStatus";

export const getAllPaymentStatuses = async (): Promise<
  HttpClientResponse<ApiListResponse<PaymentStatus>>
> => {
  const url = buildApiUrl("api-vendor/payment-statuses");
  const response = await httpClient.getRequest<
    ApiListResponse<ApiPaymentStatus>
  >(url);
  doErrorToastIfRequired(response);
  return mapHttpClientListResponse(response, (m) => m);
};
