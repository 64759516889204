import ResourceForm from "../Form";
import { createResource } from "../../api/resources";
import { actions } from "../../store";
import { useDispatch } from "react-redux";
import { successToast } from "../../toast";
import AppModal from "../../components/AppModal";
import { ResourceFormValues } from "../Form/ResourceFormValues";
import { mapToApiWorkingHours } from "../../modelMappings/Resource";

interface CreateResourceModalProps {
  open: boolean;
  handleClose: () => void;
}

const CreateResourceModal: React.FC<CreateResourceModalProps> = ({
  open,
  handleClose,
}) => {
  const dispatch = useDispatch();

  const handleAddResource = async (
    resource: ResourceFormValues
  ): Promise<void> => {
    const response = await createResource({
      name: resource.name,
      resourceTypeId: resource.typeId,
      email: resource.email.trim(),
      phoneNumber: resource.phoneNumber?.trim(),
      colour: resource.colour,
      maxTravelDistanceMiles:
        resource.coverageType === "radius"
          ? resource.maxTravelDistanceMiles
          : undefined,
      basePostcode: resource.basePostcode.trim(),
      coveredOutcodes:
        resource.coverageType === "outcodeList" && resource.coveredOutcodes
          ? resource.coveredOutcodes
          : undefined,
      workingHours: mapToApiWorkingHours(resource.workingHours),
      bookableFrom: resource.bookableFrom ?? undefined,
      bookableUntil: resource.bookableUntil ?? undefined,
      tagIds: resource.tags.map((t) => t.id),
      appointmentTypeIds: resource.appointmentTypeCapabilities
        .map((a) => a.id)
        .toString(),
    });

    if (!response.isError) {
      dispatch(actions.resources.fetchAll());
      handleClose();
      successToast("Mobile worker has been created");
    }
  };

  return (
    <>
      <AppModal
        isOpen={open}
        onClose={handleClose}
        title="Create Mobile Worker"
      >
        <ResourceForm
          onSubmit={(resource: ResourceFormValues) =>
            handleAddResource(resource)
          }
        />
      </AppModal>
    </>
  );
};

export default CreateResourceModal;
