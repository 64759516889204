import { makeStyles } from "@material-ui/core";
import { useRef, useState } from "react";
import AppChip from "../AppChip";
import AppFormError from "./AppFormError";
import AppSelect, { AppSelectOption } from "./AppSelect";

export interface AppTagSelectProps<T> {
  value: T[];
  label: string;
  error?: boolean;
  helperText?: string;
  tags: T[];
  pleaseSelectText: string;
  tagMapper: (tag: T) => AppSelectOption;
  tagMatcher: (value: number) => T | undefined;
  tagLabel: (tag: T) => string;
  validation?: (value: string) => Promise<boolean> | boolean;
  onChange(tags: T[]): void;
}

const useStyles = makeStyles((theme) => ({
  container: {},
  tagList: {
    listStyle: "none",
    margin: 0,
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.backgrounds.grey,
    minHeight: 100,
    maxHeight: 168,
    overflowY: "auto",
    flexWrap: "wrap",
    "& > li": {
      display: "inline-block",
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
}));

const AppTagSelect = <T extends { id: number }>({
  value,
  label,
  pleaseSelectText,
  error,
  helperText,
  tags,
  tagMapper,
  tagMatcher,
  tagLabel,
  validation,
  onChange,
}: AppTagSelectProps<T>) => {
  const classes = useStyles();
  const endOfList = useRef<HTMLLIElement>(null);
  const [inputError, setInputError] = useState<string>();

  const handleRemoveTag = (tag: T) => {
    const newValue = [...value].filter((val) => val.id !== tag.id);
    onChange(newValue);
  };

  const handleSelectTag = (v: number) => {
    const tag = tagMatcher(v);

    if (tag) {
      const tagList = [...value, tag];
      onChange(tagList);
    }
  };

  const selectOptions = tags
    .map(tagMapper)
    .map((t) => ({ ...t, disabled: value.map((v) => v.id).includes(t.value) }));

  return (
    <div className={classes.container}>
      <AppSelect
        value={-1}
        options={selectOptions}
        onChangeCallback={handleSelectTag}
        label={label}
        pleaseSelectText={pleaseSelectText}
      />
      <AppFormError show={!!inputError}>{inputError}</AppFormError>
      <ul className={classes.tagList}>
        {value.map((tag) => (
          <li key={tag.id}>
            <AppChip
              size="small"
              color="primary"
              label={tagLabel(tag)}
              onDelete={() => handleRemoveTag(tag)}
            />
          </li>
        ))}
        <li ref={endOfList}></li>
      </ul>
    </div>
  );
};

export default AppTagSelect;
