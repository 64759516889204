import { createPreconfiguredAppFormikTextField } from "../../components/AppForm";

export { default } from "./ResetPasswordForm";

export const ResetPasswordFormFields = {
  Password: createPreconfiguredAppFormikTextField({
    name: "password",
    label: "Password",
    type: "password",
  }),
  ConfirmPassword: createPreconfiguredAppFormikTextField({
    name: "confirmPassword",
    label: "Confirm Password",
    type: "password",
  }),
};
