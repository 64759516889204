import {
    createEntityAdapter,
    createSelector,
    createSlice,
    EntityState,
    PayloadAction,
  } from "@reduxjs/toolkit";
import { OutcodeGeoJson } from "../models/Geocoding";
  
  const sliceName = "outcodes";
  
  const entityAdapter = createEntityAdapter<OutcodeGeoJson>({
      selectId: (c) => c.outcode
  });
  
  interface SliceState {
    entityState: EntityState<OutcodeGeoJson>;
  }
  
  const initialState: SliceState = {
    entityState: entityAdapter.getInitialState(),
  };
  
  const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        addOutcodeGeoJsonItem: (state, { payload }: PayloadAction<OutcodeGeoJson>) => {
            entityAdapter.addOne(state.entityState, payload);
        },
        addOutcodeGeoJsonItems: (state, { payload }: PayloadAction<OutcodeGeoJson[]>) => {
          entityAdapter.upsertMany(state.entityState, payload);
        },
    },
  });
  
  export const { name, reducer } = slice;
  
  export const actions = {
    addOutcodeGeoJson: (outcodeGeoJson: OutcodeGeoJson) => (dispatch: (action: unknown) => void): void => {
        dispatch(slice.actions.addOutcodeGeoJsonItem(outcodeGeoJson));
    },
    addMultipleOutcodeGeoJson: (outcodeGeoJsonItems: OutcodeGeoJson[]) => (dispatch: (action: unknown) => void): void => {
      dispatch(slice.actions.addOutcodeGeoJsonItems(outcodeGeoJsonItems));
    }
  };
  
  type RootReducerState = {
    [sliceName]: SliceState;
  };
  
  const selectSliceState = (state: RootReducerState) => state[sliceName];
  const entitySelectors = entityAdapter.getSelectors();

  const createSliceSelector = <T,>(selector: (state: SliceState) => T) => {
    return createSelector(selectSliceState, selector);
  };
  
  export const selectors = {
    all: createSliceSelector((state) =>
      entitySelectors.selectAll(state.entityState)
    ),
  };
  