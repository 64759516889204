import AppFormikWorkingHoursPicker, {
  AppFormikWorkingHoursPickerProps,
} from "./AppFormikWorkingHoursPicker";

export type CreatePreconfiguredAppFormikWorkingHoursPickerOptions = {
  name: string;
  label: string;
};

export type PreconfiguredAppFormikWorkingHoursPickerProps = Omit<
  AppFormikWorkingHoursPickerProps,
  "name"
>;

export const createPreconfiguredAppFormikWorkingHoursPicker = ({
  name,
  label,
}: CreatePreconfiguredAppFormikWorkingHoursPickerOptions): React.FC<PreconfiguredAppFormikWorkingHoursPickerProps> => {
  const PreConfiguredAppFormikWorkingHoursPicker = (
    restOfProps: PreconfiguredAppFormikWorkingHoursPickerProps
  ) => (
    <AppFormikWorkingHoursPicker {...restOfProps} label={label} name={name} />
  );

  return PreConfiguredAppFormikWorkingHoursPicker;
};

export default createPreconfiguredAppFormikWorkingHoursPicker;
