import { ApiResourceDetails, ApiWorkingHoursDay } from "../api/models";
import {
  defaultFinish,
  defaultStart,
  defaultWorkingDaysValues,
} from "../components/AppForm/AppWorkingHoursPicker";
import {
  formatMinutesAfterMidnightToTimeString,
  formatTimeStringToMinutesAfterMidnight,
} from "../dateFormatters";
import { ResourceDetails, WorkingHoursDay } from "../models/Resource";
import { mapFromApiOutcodeGeoJson } from "./Geocoding";

const createNonWorkingDays = (workingsDayIds: number[]): WorkingHoursDay[] => {
  const dayIds = [1, 2, 3, 4, 5, 6, 0].filter(
    (id) => !workingsDayIds.includes(id)
  );

  return dayIds.map((dayId) => {
    return {
      dayOfWeekId: dayId,
      startTime: defaultStart,
      finishTime: defaultFinish,
      isWorking: false,
    };
  });
};

const setApplyAll = (
  workingHoursList: WorkingHoursDay[]
): WorkingHoursDay[] => {
  let isApplied = false;

  workingHoursList.map((workingHoursDay) => {
    if (workingHoursDay.isWorking && !isApplied) {
      workingHoursDay.applyToAll = true;
      isApplied = true;
    }
  });

  return workingHoursList;
};

export const mapFromApiResource = ({
  workingHours,
  maxTravelDistanceMiles,
  coveredOutcodes,
  outcodesGeoJson,
  ...restOfProperties
}: ApiResourceDetails): ResourceDetails => {
  let workingHoursList: WorkingHoursDay[] = [];

  if (workingHours && workingHours.length > 0) {
    workingHoursList = workingHours.map((workingDaysItem) => {
      return {
        dayOfWeekId: workingDaysItem.dayOfWeekId,
        startTime: formatTimeStringToMinutesAfterMidnight(
          workingDaysItem.startTime
        ),
        finishTime: formatTimeStringToMinutesAfterMidnight(
          workingDaysItem.finishTime
        ),
        isWorking: true,
      };
    });

    const nonWorkingDays = createNonWorkingDays(
      workingHoursList.map((item) => item.dayOfWeekId)
    );

    workingHoursList = workingHoursList
      .concat(nonWorkingDays)
      .sort((a, b) => a.dayOfWeekId - b.dayOfWeekId);

    const [sun, ...restOfDays] = workingHoursList;

    workingHoursList = setApplyAll([...restOfDays, sun]);
  } else {
    workingHoursList = defaultWorkingDaysValues;
  }

  return {
    ...restOfProperties,
    coverageType: maxTravelDistanceMiles ? "radius" : "outcodeList",
    coveredOutcodes: coveredOutcodes,
    maxTravelDistanceMiles: maxTravelDistanceMiles ? maxTravelDistanceMiles : 0,
    workingHours: workingHoursList,
    outcodesGeoJson: outcodesGeoJson?.flatMap((o) =>
      mapFromApiOutcodeGeoJson(o)
    ),
  };
};

export const mapToApiWorkingHours = (
  workingHours: WorkingHoursDay[]
): ApiWorkingHoursDay[] =>
  workingHours
    .filter((workingHourItem) => workingHourItem.isWorking)
    .map((workingHourItem) => {
      return {
        dayOfWeekId: workingHourItem.dayOfWeekId,
        startTime: formatMinutesAfterMidnightToTimeString(
          workingHourItem.startTime
        ),
        finishTime: formatMinutesAfterMidnightToTimeString(
          workingHourItem.finishTime
        ),
      };
    });
