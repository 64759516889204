import {
  InputProps,
  StandardTextFieldProps,
  TextField,
  useTheme,
} from "@material-ui/core";
import AppLoader from "../AppLoader";

export interface AppTextFieldProps extends StandardTextFieldProps {
  readonly?: boolean;
  isLoading?: boolean;
  InputProps?: Partial<InputProps>;
}

const AppTextField: React.FC<AppTextFieldProps> = ({
  fullWidth = true,
  readonly,
  InputLabelProps,
  InputProps,
  isLoading = false,
  ...restOfProps
}) => {
  const theme = useTheme();

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <TextField
      {...restOfProps}
      fullWidth={fullWidth}
      variant="standard"
      disabled={readonly}
      InputLabelProps={{
        ...InputLabelProps,
        style: {
          ...InputLabelProps?.style,
          color: readonly ? theme.palette.text.secondary : undefined,
        },
      }}
      InputProps={{
        ...InputProps,
        disableUnderline: readonly || undefined,
        style: {
          ...InputProps?.style,
          color: readonly ? "currentColor" : undefined,
        },
      }}
    />
  );
};

export default AppTextField;
