import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  Phone as PhoneIcon,
  AlternateEmail as EmailIcon,
  ExpandMore as ExpandIcon,
} from "@material-ui/icons";
import { VendorOpeningHours } from "../api/models/Configuration";
import MicrositeOpeningHours from "./MicrositeOpeningHours";

interface MicrositeSidebarProps {
  openingHours?: VendorOpeningHours[];
  logoUrl?: string;
  logoDesc?: string;
  primaryColour?: string;
  contactNumber?: string;
  contactEmail?: string;
}

interface StyleProps {
  primaryColour?: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  sidebar: {
    position: "relative",
    backgroundColor: theme.palette.grey[100],
    height: "calc(100% - 1.5rem)",
    marginTop: "1.5rem",
    padding: theme.spacing(4),
    width: "100%",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      maxWidth: 360,
    },
  },
  logo: {
    width: "100%",
    height: "160px",
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
    "& > img": {
      position: "relative",
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
  openingHoursContainer: {
    display: "none",
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  openingHours: {
    display: "flex",
    justifyContent: "space-between",
    margin: ".5rem 0",
  },
  contactContainer: {
    display: "none",
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  contactDetail: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
    "& > svg": {
      marginRight: theme.spacing(2),
    },
    "& > a": {
      textDecoration: "none",
      color: ({ primaryColour }) => primaryColour,
    },
  },
  mobileAccordion: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  accordion: {
    boxShadow: "none",
    backgroundColor: "transparent",
    "&:before": {
      display: "none",
    },
  },
  accordionHeader: {
    paddingLeft: 0,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h6.fontSize,
  },
  accordionDetails: {
    padding: 0,
  },
  accordionDetailsInner: {
    width: "100%",
  },
}));

const MicrositeSidebar: React.FC<MicrositeSidebarProps> = ({
  openingHours,
  logoUrl,
  logoDesc,
  primaryColour,
  contactNumber,
  contactEmail,
}) => {
  const classes = useStyles({ primaryColour });

  return (
    <div className={classes.sidebar}>
      {logoUrl && (
        <div className={classes.logo}>
          <img src={logoUrl} alt={logoDesc} />
        </div>
      )}
      <div className={classes.mobileAccordion}>
        {openingHours && (
          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandIcon />}
              className={classes.accordionHeader}
            >
              Operational Hours
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <div className={classes.accordionDetailsInner}>
                <MicrositeOpeningHours openingHours={openingHours} />
              </div>
            </AccordionDetails>
          </Accordion>
        )}
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandIcon />}
            className={classes.accordionHeader}
          >
            Contact Details
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <div className={classes.accordionDetailsInner}>
              <div className={classes.contactDetail}>
                <PhoneIcon />
                <a href={`tel:${contactNumber}`}>{contactNumber}</a>
              </div>
              <div className={classes.contactDetail}>
                <EmailIcon />
                <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      {openingHours && openingHours.length > 0 && (
        <div className={classes.openingHoursContainer}>
          <Typography variant="h6" component="h3">
            <b>Operational hours</b>
          </Typography>
          <MicrositeOpeningHours openingHours={openingHours} />
        </div>
      )}
      {(contactNumber || contactEmail) && (
        <div className={classes.contactContainer}>
          <Typography variant="h6" component="h3">
            <b>Contact us</b>
          </Typography>
          {contactNumber && (
            <div className={classes.contactDetail}>
              <PhoneIcon />
              <a href={`tel:${contactNumber}`}>{contactNumber}</a>
            </div>
          )}
          {contactEmail && (
            <div className={classes.contactDetail}>
              <EmailIcon />
              <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MicrositeSidebar;
