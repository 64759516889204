import {
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  SelectProps,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import AppLoader from "../AppLoader";

export type AppCheckboxSelectOption = {
  value: number;
  label: string;
  disabled?: boolean;
};

export interface AppCheckboxSelectProps extends SelectProps {
  options: AppCheckboxSelectOption[];
  helperText?: string;
  hidePleaseSelect?: boolean;
  pleaseSelectText?: string;
  isLoading?: boolean;
  onChangeCallback?: (optionValue: number, option?: string) => void;
}

const useStyles = makeStyles((theme) => ({
  button: {
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
  checkBox: {
    color: theme.palette.primary.main,
    "&.Mui-checked": {
      color: theme.palette.primary.main,
    },
  },
  selectableOption: {
    maxHeight: "700px",
    overflowY: "scroll",
  },
}));

const AppCheckboxSelect: React.FC<AppCheckboxSelectProps> = ({
  label,
  options,
  helperText,
  error,
  hidePleaseSelect,
  pleaseSelectText = "-- Please Select --",
  disabled,
  readOnly,
  fullWidth = true,
  isLoading,
  onChangeCallback,
  onChange,
  className,

  ...restOfProps
}) => {
  const isDisabled = disabled || readOnly;
  const classes = useStyles();
  if (isLoading) {
    return <AppLoader />;
  }

  const handleIconClick = (option?: string) => {
    onChangeCallback && onChangeCallback(-1, option);
  };

  const handleChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    child: React.ReactNode
  ) => {
    onChangeCallback && onChangeCallback(event.target.value as number);
    onChange && onChange(event, child);
  };

  return (
    <FormControl fullWidth={fullWidth} className={className}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        {...restOfProps}
        onChange={handleChange}
        disabled={isDisabled}
        fullWidth={fullWidth}
        error={error}
      >
        <div>
          <div className={classes.row}>
            <button
              onClick={() => handleIconClick("clear")}
              className={classes.button}
            >
              Clear
            </button>

            <button
              onClick={() => handleIconClick("all")}
              className={classes.button}
            >
              Select All
            </button>

            <IconButton onClick={() => handleIconClick("close")}>
              <Close></Close>
            </IconButton>
          </div>
        </div>
        {!hidePleaseSelect && (
          <MenuItem value={-1} disabled>
            {pleaseSelectText}
          </MenuItem>
        )}

        {options.map((option) => (
          <MenuItem
            className={`${option.disabled ? classes.disabled : ""}`}
            key={option.value}
            value={option.value}
          >
            <Checkbox
              checked={option.disabled}
              className={classes.checkBox}
              onClick={() => onChangeCallback && onChangeCallback(option.value)}
            />

            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default AppCheckboxSelect;
