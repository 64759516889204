import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppTheme from "./theme/AppTheme";
import store from "./store";
import { AuthProvider } from "./auth";
import { AppRoutes } from "./routes";
import ConfigProvider from "./config/ConfigProvider";

const App: React.FC = () => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Provider store={store}>
        <AuthProvider>
          <ConfigProvider>
            <AppTheme>
              <ToastContainer />
              <AppRoutes />
            </AppTheme>
          </ConfigProvider>
        </AuthProvider>
      </Provider>
    </MuiPickersUtilsProvider>
  );
};

export default App;
