import { OptionalExtra } from "./models/AppointmentType";
import { BookedExtra, Jobs } from "./models/Booking";

type formatNumberOptions = {
  currency?: boolean;
  fractionDigits?: number;
};

export const formatNumber = (
  number: number,
  options?: formatNumberOptions
): string => {
  return number.toLocaleString(undefined, {
    minimumFractionDigits: options?.fractionDigits ?? 2,
    maximumFractionDigits: options?.fractionDigits ?? 2,
    style: options?.currency ? "currency" : "decimal",
    currency: "GBP",
  });
};

export const excludeVATFromPriceIfRequired = (
  totalPrice: number,
  taxRatePercentage: number
) => {
  if (!taxRatePercentage) return totalPrice;

  const taxRateDecimal = (taxRatePercentage / 100) + 1;
  return (totalPrice - (totalPrice - (totalPrice / taxRateDecimal)));
};


export const calculateTotalPrice = (
  availableExtras: OptionalExtra[],
  selectedOptionalExtras: BookedExtra[],
  basePrice: number
): number => {
  if (availableExtras.length === 0) return basePrice;

  const filteredOptionalExtras = availableExtras.filter((extra) => {
    return selectedOptionalExtras.some(
      (bookedExtra) => extra.id === bookedExtra.optionalExtraId
    );
  });

  const priceList = filteredOptionalExtras.map((extra) => {
    const selectedExtra = selectedOptionalExtras.find(
      (selectedExtra) => extra.id === selectedExtra.optionalExtraId
    );
    return extra.price * (selectedExtra?.quantity ?? 1);
  });

  if (priceList.length === 0) return 0;
  return basePrice + priceList.reduce((a, b) => a + b);
};

export const calculateServiceTotal = (
  availableExtras: OptionalExtra[],
  selectedOptionalExtras: BookedExtra[],
  basePrice: number
): number => {
  if (availableExtras.length === 0) return basePrice;

  const filteredOptionalExtras = availableExtras.filter((extra) => {
    return selectedOptionalExtras.some(
      (bookedExtra) => extra.id === bookedExtra.optionalExtraId
    );
  });

  const priceList = filteredOptionalExtras.map((extra) => {
    const selectedExtra = selectedOptionalExtras.find(
      (selectedExtra) => extra.id === selectedExtra.optionalExtraId
    );
    return extra.price * (selectedExtra?.quantity ?? 1);
  });

  if (priceList.length === 0) return 0;
  return basePrice + priceList.reduce((a, b) => a + b);
};

export const calculateBasketTotal = (basket: Jobs[]): number => {
  return basket.reduce((acc, job) => {
    const extrasTotal = job.optionalExtras.reduce((acc, extra) => {
      const multiplier = extra.quantity !== undefined ? extra.quantity : 1;
      return acc + extra.price * multiplier;
    }, 0);
    if (job.overriddenPrice != null) {
      return acc + (job.overriddenPrice);
    }
    return acc + (job.price + extrasTotal);
  }, 0);
};