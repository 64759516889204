import { makeStyles } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHttpRequest } from "../api";
import { createInviteToBook, getInviteToBookDetails } from "../api/inviteToBook";
import AppLoader from "../components/AppLoader";
import { createIsoDateFromDateAndTime, formatMinutesAfterMidnightToTimeString } from "../dateFormatters";
import { CreateInviteToBookDto } from "../models/Booking";
import { useInviteToBookGuid, useWidgetVendorId } from "../routes/public/PublicRoutes";
import { actions } from "../store";
import WidgetBookingFormContainer from "../widget/Form/WidgetBookingFormContainer";
import { defaultWidgetBookingFormValues, WidgetBookingFormValues } from "../widget/Form/WidgetBookingFormValues";

const useStyles = makeStyles(() => ({
    loaderContainer: {
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

const InviteToBookWidget: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const vendorId  = useWidgetVendorId();
    const inviteToBookGuid = useInviteToBookGuid();

    const [initialValues, setInitialValues] = useState<WidgetBookingFormValues>(defaultWidgetBookingFormValues);

    const bookingDetails = useCallback(
        () => getInviteToBookDetails(inviteToBookGuid),
        [inviteToBookGuid]
    );
    const { result, isLoading } = useHttpRequest(bookingDetails);

    const handleAddBooking = async (
        booking: WidgetBookingFormValues,
        setSubmitting: (isSubmitting: boolean) => void
      ) => {
        const bookingDto: CreateInviteToBookDto = {
          resourceId: booking.resourceId,
          inviteGUID: inviteToBookGuid,
          start: !booking.date
            ? null
            : createIsoDateFromDateAndTime(
                booking.date,
                formatMinutesAfterMidnightToTimeString(booking.start)
              ),
          end: !booking.date
            ? null
            : createIsoDateFromDateAndTime(
                booking.date,
                formatMinutesAfterMidnightToTimeString(booking.end)
              ),
          firstJobOptionalExtras: booking.firstJobOptionalExtras,
          customerAddressId: booking.customerAddressId,
          customerId: booking.customerId
        };
    
        const response = await createInviteToBook(bookingDto, vendorId);
    
        if (response.isError) {
          setSubmitting(false);
        }
      };

    useEffect(() => {
        if(result && !isLoading){
            dispatch(
                actions.appointmentTypes.fetchPublic({
                  postcode: result.customerPostcode,
                  id: vendorId,
                })
            );

            setInitialValues({
                ...initialValues,
                firstJobAppointmentTypeId: result.appointmentId,
                postcode: result.customerPostcode,
                isInviteBooking: true,
                customerId: result.customerId,
                customerAddressId: result.customerAddressId
            });
            
        }
    }, [result, isLoading]);

    if (!result || isLoading) {
        return (
            <div className={classes.loaderContainer}>
                <AppLoader />
            </div>
        );
    }

    return (
        <>
            <WidgetBookingFormContainer
                initialValues={initialValues}
                onSubmit={handleAddBooking}
                isInviteBooking={true}
            />
        </>
    );
};

export default InviteToBookWidget;
