import { mapFromApiRecurringBookableSlot } from "../modelMappings/RecurringBookableSlot";
import { RecurringBookableSlot } from "../models/RecurringBookableSlot";
import {
  buildApiUrl,
  doErrorToastIfRequired,
  httpClient,
  mapHttpClientListResponse,
} from "./common";
import { ApiListResponse } from "./common/ApiListResponse";
import { HttpClientResponse } from "./common/httpClient";
import {
  ApiBookableSlotDto,
  ApiBookableSlotsParameters,
} from "./models/BookableSlots";
import {
  ApiBookableSlotDemoDto,
  ApiBookableSlotsDemoRequest,
} from "./models/BookableSlotsDemo";
import {
  ApiRecurringBookableSlot,
  ApiRecurringBookableSlotsDto,
} from "./models/RecurringBookings";

export const demoBookableSlotsQuery = async (
  query: ApiBookableSlotsDemoRequest
): Promise<HttpClientResponse<ApiListResponse<ApiBookableSlotDemoDto>>> => {
  const url = buildApiUrl("api-admin/bookable-slots-demo");
  const response = await httpClient.postRequest<
    ApiListResponse<ApiBookableSlotDemoDto>
  >(url, query);

  doErrorToastIfRequired(response);

  return mapHttpClientListResponse(response, (_: ApiBookableSlotDemoDto) => _);
};

export const getBookableSlots = async ({
  customerAddressId,
  appointmentTypeId,
  resourceId,
  date,
  optionalExtras,
  postcode,
  tagIds,
  jobs,
}: ApiBookableSlotsParameters): Promise<
  HttpClientResponse<ApiListResponse<ApiBookableSlotDto>>
> => {
  const params = {
    date: date,
    customerAddressId: customerAddressId,
    tagId: tagIds,
    postcode: postcode,
    appointmentTypes: jobs,
    resourceId: resourceId
  };
  const url = buildApiUrl(`api-admin/bookable-slots`);
  const response = await httpClient.postRequest<
      ApiListResponse<ApiBookableSlotDto>
      >(url, params);

  doErrorToastIfRequired(response);

  return mapHttpClientListResponse(response, (m) => m);
};

export const getBookableSlotsResource = async ({
  customerAddressId,
  appointmentTypeId,
  date,
  optionalExtras,
  postcode,
  tagIds,
  jobs,
  resourceId,
}: ApiBookableSlotsParameters): Promise<
  HttpClientResponse<ApiListResponse<ApiBookableSlotDto>>
> => {
  const params = {
    date: date,
    tagIds: tagIds,
    resourceId: resourceId,
    customerAddressId: customerAddressId,
    postcode: postcode,
    appointmentTypes: jobs
  };

  const url = buildApiUrl(`api-resource/bookable-slots`);
  const response = await httpClient.postRequest<
      ApiListResponse<ApiBookableSlotDto>
      >(url, params);

  doErrorToastIfRequired(response);

  return mapHttpClientListResponse(response, (m) => m);
};

export const getRecurringBookableSlots = async (
  recurringBookableSlotsDto: ApiRecurringBookableSlotsDto
): Promise<HttpClientResponse<ApiListResponse<RecurringBookableSlot>>> => {
  const url = buildApiUrl("api-admin/bookable-slots/recurring");
  const response = await httpClient.postRequest<
    ApiListResponse<ApiRecurringBookableSlot>
  >(url, recurringBookableSlotsDto);

  doErrorToastIfRequired(response);

  return mapHttpClientListResponse(response, mapFromApiRecurringBookableSlot);
};
