import { useField } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomerDetail,
  getPaginatedCustomers,
} from "../../../api/customers";
import { ComboBoxOption } from "../../../components/AppComboBox/AppComboBox";
import AppFormikComboBox from "../../../components/AppForm/AppFormikComboBox";
import InformationTextBox from "../../../components/InformationTextBox";
import { CustomerSummary } from "../../../models/Customers";
import { actions, selectors } from "../../../store";
import { BookingFormStyles } from "../BookingFormStyles";
import { defaultBookingFormValues } from "../BookingFormValues";
import AddIcon from "@material-ui/icons/Add";

interface BookingFormCustomerSelectionProps {
  readOnly?: boolean;
  setCreateCustomer: (createCustomer: boolean) => void;
}

const BookingFormCustomerSelection: React.FC<BookingFormCustomerSelectionProps> =
  ({ readOnly, setCreateCustomer }) => {
    const classes = BookingFormStyles();
    const dispatch = useDispatch();
    const [{ value: selectedCustomerId }, , { setValue: setCustomerId }] =
      useField<number>("customerId");
    const [, , { setValue: setCustomerAddressId }] =
      useField<number>("customerAddressId");

    const [, , { setValue: setIsCreateCustomer }] =
      useField<boolean>("isCreateCustomer");

    const [customers, setCustomers] = useState<CustomerSummary[]>([]);
    const [isSearching, setIsSearching] = useState(false);

    const customerAddresses = useSelector(selectors.customers.allAddresses);
    const fetchCustomersBySearchTerm = async (searchTerm: string) => {
      setIsSearching(true);
      const paginatedParams = {
        pageIndex: 1,
        pageSize: 100,
        searchTerm,
        sortBy: "firstName",
      };
      const result = await getPaginatedCustomers(paginatedParams);

      if (!result.isError) {
        setCustomers(result.content.content.items);
      }
      setIsSearching(false);
    };
    const fetchSingularCustomer = async () => {
      const result = await getCustomerDetail(selectedCustomerId);
      if (!result.isError) {
        setCustomers([result.content]);
      }
    };
    const customerOptions: ComboBoxOption[] =
      customers?.map((customer) => {
        return {
          label: `${customer.firstName} ${customer.lastName} (${customer.email})`,
          id: customer.id,
        };
      }) || [];
    const customerAddressListOptions = customerAddresses.map((address) => {
      return {
        label: `${address.addressLine1}, ${address.town}, ${address.postcode}`,
        id: address.id,
      };
    });
    const clearSelectedAddress = () => {
      setCustomerAddressId(defaultBookingFormValues.customerAddressId, false);
    };
    useEffect(() => {
      if (selectedCustomerId > 0) {
        dispatch(actions.customers.fetchCustomerAddresses(selectedCustomerId));
      }
      if (selectedCustomerId < 0) {
        clearSelectedAddress();
        fetchCustomersBySearchTerm("");
      }
    }, [selectedCustomerId]);
    useEffect(() => {
      if (selectedCustomerId > 0) {
        fetchSingularCustomer();
        setCustomerId(selectedCustomerId);
      }
      if (selectedCustomerId < 0) {
        fetchCustomersBySearchTerm("");
      }
    }, []);

    return (
      <>
        <InformationTextBox>Please select a customer:</InformationTextBox>
        <div className={classes.stepContainer}>
          <h3 className={classes.stepContainerTitle}>Customer</h3>
          <AppFormikComboBox
            options={customerOptions}
            placeholder="Search for customer name"
            name="customerId"
            readOnly={readOnly}
            isLoading={isSearching}
            debouncedAction={fetchCustomersBySearchTerm}
          />
          {readOnly ?? (
            <button
              className={classes.createCustomerButton}
              onClick={() => {
                setIsCreateCustomer(true);
                setCreateCustomer(true);
              }}
            >
              <AddIcon className={classes.plusIcon} /> Create customer
            </button>
          )}
          <br />
          {selectedCustomerId !== -1 && customerAddressListOptions && (
            <>
              <h3 className={classes.stepContainerTitle}>Address</h3>
              <AppFormikComboBox
                options={customerAddressListOptions}
                placeholder="Search for customer address"
                name="customerAddressId"
                readOnly={readOnly}
              />
            </>
          )}
        </div>
      </>
    );
  };
export default BookingFormCustomerSelection;
