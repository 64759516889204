import FullCalendar, {
  DateSelectArg,
  DatesSetArg,
  EventClickArg,
  EventContentArg,
} from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import scrollgridPlugin from "@fullcalendar/scrollgrid";
import { createRef, useEffect, useState } from "react";
import { AppCalendarToolbar } from "./AppCalendarToolbar";
import { CALENDAR_VIEWS, useCalendar } from "./AppCalendarContext";
import { CreateTimeOffModal } from "../../resources/CreateTimeOffModal";
import EditTimeOffModal from "../../resources/EditTimeOffModal/EditTimeOffModal";
import { roundedDownHourDate } from "../../dateFormatters";
import AppCalendarResourceHeader from "./AppCalendarResourceHeader";
import AppCalendarEmptyState from "./AppCalendarEmptyState";
import { useStyles } from "./AppCalendarStyles";
import AppRelativelyPositionedLoader from "../AppRelativelyPositionLoader";
import { differenceInMinutes, format } from "date-fns";
import { EVENT_TYPE } from "./utils/eventUtils";
import BookingEventCard from "./EventCards/BookingEventCard";
import TimeOffEventCard from "./EventCards/TimeOffEventCard";
import TravelTimeEventCard from "./EventCards/TravelTimeEventCard";
import CreateBookingDrawer from "../../bookings/CreateBookingDrawer/CreateBookingDrawer";
import EditBookingDrawer from "../../bookings/EditBookingDrawer/EditBookingDrawer";
import { OptionalExtra } from "../../models/AppointmentType";
import { useConfig } from "../../config/ConfigContext";
import {useDispatch} from "react-redux";
import {actions} from "../../store";

export const AppCalendar: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    minMaxCalendarTime,
    selectedSlot,
    selectedDate,
    selectedResources,
    selectedDatePickerDate,
    selectedBookingId,
    selectedTimeOff,
    selectedView,
    allResources,
    calendarEvents,
    createBookingModal,
    timeOffModal,
    isResource,
    canEditEvent,
    canCreateEvent,
    isLoading,
    initialView,
    handleSelectSlot,
    setCreateBookingModal,
    setTimeOffModal,
    setSelectedBookingId,
    setSelectedTimeOff,
    setSelectedView,
    fetchCalendarEntries,
    handleSelectEvent,
    setSelectedDatePickerDate,
    handleDateChange,
  } = useCalendar();

  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  const calendar = createRef<FullCalendar>();

  const resources = allResources.filter((resource) =>
    selectedResources.includes(resource.id ?? "")
  );

  const roundedMinCalendarTime = minMaxCalendarTime
    ? roundedDownHourDate(minMaxCalendarTime.start)
    : undefined;

  const handleCreateBookingModalClose = () => {
    handleSelectSlot(undefined);
    setCreateBookingModal(false);
    fetchCalendarEntries();
  };

  const handleEditTimeOffModalClose = () => {
    setSelectedTimeOff(undefined);
    fetchCalendarEntries();
  };

  const handleTimeOffModalClose = () => {
    setTimeOffModal(false);
    fetchCalendarEntries();
  };

  const handleEditModalClose = () => {
    setSelectedBookingId(undefined);
    fetchCalendarEntries();
  };

  const handleCalendarOpen = (bool: boolean) => {
    setCalendarIsOpen(bool);
  };

  const handleNavLinkDayClick = (date: Date) => {
    const action = calendar.current?.getApi();

    setSelectedView(CALENDAR_VIEWS.DAY_VIEW);
    handleDateChange(date);

    action?.changeView(CALENDAR_VIEWS.DAY_VIEW, date);
  };

  const vendorConfig = useConfig();

  useEffect(() => {
    const action = calendar.current?.getApi();

    action?.changeView(selectedView);
  }, [selectedView]);

  const handleCalendarSlotSelected = (slot: DateSelectArg) => {
    dispatch(actions.basket.resetBasket());
    handleSelectSlot({
      start: slot.start.toISOString(),
      end: slot.end.toISOString(),
      resourceId: parseInt(slot.resource?._resource.id ?? "-1"),
    });
  };

  const renderEventContent = (event: EventContentArg) => {
    const eventType = event.event.extendedProps.type as EVENT_TYPE;
    const start = format(event.event.start!, "h:mm aaaaa'm'").toUpperCase();
    const end = format(event.event.end!, "h:mm aaaaa'm'").toUpperCase();
    const duration = differenceInMinutes(event.event.end!, event.event.start!);

    if (eventType === EVENT_TYPE.BOOKING) {
      const {
        resourceColour,
        customerFirstName,
        customerLastName,
        bookingPostcode,
        bookingStatus,
        optionalExtras,
        taxRate,
        totalCost,
        recurringBookingId,
        jobs
      } = event.event.extendedProps;
      return (
        <BookingEventCard
          id={event.event.id}
          start={start}
          end={end}
          resourceColour={resourceColour}
          customerFirstName={customerFirstName}
          customerLastName={customerLastName}
          bookingPostcode={bookingPostcode}
          totalCost={totalCost}
          bookingStatus={bookingStatus}
          duration={duration}
          serviceTitle={event.event.title}
          optionalExtras={optionalExtras as OptionalExtra[]}
          taxRate={taxRate}
          recurringBookingId={recurringBookingId}
          jobs={jobs}
        />
      );
    }

    if (eventType === EVENT_TYPE.TIME_OFF) {
      return <TimeOffEventCard start={start} end={end} duration={duration} />;
    }

    if (eventType === EVENT_TYPE.TRAVEL_TIME) {
      const { initialDuration } = event.event.extendedProps;

      return (
        <TravelTimeEventCard
          duration={initialDuration}
          roundedDuration={duration}
        />
      );
    }
  };

  return (
    <>
      <AppCalendarToolbar
        calendarIsOpen={calendarIsOpen}
        isAnyResourceSelected={selectedResources.length > 0}
        isResource={isResource}
        calendar={calendar}
        date={selectedDate}
        handleCalendarOpen={handleCalendarOpen}
        captureSelectedDate={setSelectedDatePickerDate}
        userIsResource={isResource}
      />
      {selectedResources.length > 0 && (
        <div className={classes.calendarContainer}>
          <FullCalendar
            ref={calendar}
            initialDate={selectedDate}
            datesSet={(datesSetArg: DatesSetArg) => {
              handleDateChange(datesSetArg.start);
            }}
            plugins={[
              resourceTimelinePlugin,
              resourceTimeGridPlugin,
              interactionPlugin,
              scrollgridPlugin,
            ]}
            selectable={canCreateEvent}
            selectOverlap={false}
            select={(slot) =>
                handleCalendarSlotSelected(slot)
            }
            initialView={initialView}
            allDaySlot={false}
            dayHeaderFormat={{ day: "numeric", weekday: "short" }}
            dayMinWidth={290}
            slotMinTime={
              roundedMinCalendarTime
                ? roundedMinCalendarTime.toTimeString()
                : "02:00"
            }
            slotMaxTime={
              minMaxCalendarTime
                ? minMaxCalendarTime.end.toTimeString()
                : "23:00"
            }
            slotDuration="00:15:00"
            firstDay={1}
            slotEventOverlap={false}
            expandRows={false}
            headerToolbar={false}
            nowIndicator
            resources={resources}
            resourceLaneContent={(resource) => <p>{resource.resource.title}</p>}
            resourceOrder={"-timeSelected,name,-id"}
            events={calendarEvents}
            eventContent={renderEventContent}
            resourceAreaWidth={200}
            resourceAreaHeaderContent=" "
            resourceLabelContent={(resource) => (
              <AppCalendarResourceHeader
                calendar={calendar}
                resource={resource}
              />
            )}
            eventClick={(eventClickArg: EventClickArg) =>
              canEditEvent ? handleSelectEvent(eventClickArg.event) : undefined
            }
            navLinks={true}
            navLinkDayClick={handleNavLinkDayClick}
          />
          <EditBookingDrawer
            open={Boolean(selectedBookingId)}
            id={parseInt(selectedBookingId ?? "")}
            userIsResource={isResource}
            handleClose={handleEditModalClose}
          />
          <CreateBookingDrawer
            open={selectedSlot || createBookingModal ? true : false}
            onClose={handleCreateBookingModalClose}
            initialDate={selectedDatePickerDate}
            initialResource={
              selectedSlot?.resourceId
                ? +selectedSlot.resourceId
                : selectedResources.length === 1
                ? +selectedResources[0]
                : -1
            }
            initialTimeSlotSelection={selectedSlot}
          />
          {!(vendorConfig.hidePricesFromResource && isResource) && (
            <CreateTimeOffModal
              initialResourceId={
                selectedResources.length === 1
                  ? +selectedResources[0]
                  : undefined
              }
              initialDate={selectedDatePickerDate}
              open={timeOffModal}
              userIsAdmin={!isResource}
              handleClose={handleTimeOffModalClose}
            />
          )}
          {!(vendorConfig.hidePricesFromResource && isResource) && (
            <EditTimeOffModal
              initialValues={selectedTimeOff}
              handleClose={handleEditTimeOffModalClose}
            />
          )}
        </div>
      )}
      {!isLoading &&
        allResources.length > 0 &&
        selectedResources.length < 1 && <AppCalendarEmptyState />}
      <AppRelativelyPositionedLoader isLoading={isLoading} />
    </>
  );
};
