import { makeStyles } from "@material-ui/core";
import { useFormikContext } from "formik";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { ServicesListView } from "../../../components/bookings/services";
import { useConfig } from "../../../config/ConfigContext";
import {
  days,
  formatDateSuffix,
  formatMinutesAfterMidnightTo12HourWithAmPm,
  formatTimeOnly,
} from "../../../dateFormatters";
import { RecurringBookableSlotWithSelection } from "../../../models/RecurringBookableSlot";
import {calculateBasketTotal, excludeVATFromPriceIfRequired} from "../../../numericalUtils";
import { selectors } from "../../../store";
import { CREATE_RECURRENCE_STEP } from "../BookingFormContainer";
import { BookingFormValues } from "../BookingFormValues";

const useStyles = makeStyles((theme) => ({
  serviceInfo: {
    lineHeight: "9px",
    paddingRight: theme.spacing(1),
    width: "65%",
  },
  container: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    marginBottom: theme.spacing(5),
  },
  dateTime: {
    alignItems: "center",
    display: "flex",
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down(599)]: {
      display: "block",
    },
  },
  date: {
    marginLeft: "auto",
    borderLeft: `2px solid ${theme.palette.grey[300]}`,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(4),
    marginRight: theme.spacing(1),
    fontSize: "32px",
    fontWeight: "bold",
    [theme.breakpoints.down(599)]: {
      borderLeft: "none",
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(1),
      borderTop: `2px solid ${theme.palette.grey[300]}`,
      display: "flex",
    },
    [theme.breakpoints.up(600)]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  appointment: {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "18px",
  },
  optional: {
    fontWeight: 300,
    marginBottom: theme.spacing(1),
    lineHeight: "18px",
  },
}));

interface BookingFormSummaryBoxProps {
  activeStep: number;
}

const BookingFormSummaryBox: FunctionComponent<BookingFormSummaryBoxProps> = ({
  activeStep,
}) => {
  const classes = useStyles();
  const vendorConfig = useConfig();

  const { values } = useFormikContext<BookingFormValues>();

  const isBookingComplete = values.bookingStatus > 0;
  const vatRate = isBookingComplete
    ? values.taxRate ?? 0
    : vendorConfig.currentTaxRatePercentage;

  const selectedAppointment = useSelector(
    selectors.appointmentTypes.allAppointmentTypes
  ).find(
    (appointmentType) => appointmentType.id == values.firstJobAppointmentTypeId
  );

  const earliestRecurringBookableSlot:
    | RecurringBookableSlotWithSelection
    | undefined = values.recurringBookableSlots
    .filter((s) => s.isSelected && s.start)
    .sort((a, b) => {
      const c = new Date(a.start ?? "").getTime();
      const d = new Date(b.start ?? "").getTime();
      return c - d;
    })[0];

  const basketStoreServices = useSelector(selectors.basket.services);
  const basketTotal = calculateBasketTotal(basketStoreServices);

  const getPriceAfterTax = (): number => {
    return excludeVATFromPriceIfRequired(basketTotal, vatRate);
  };

  const displayRecurringFromLabel =
    earliestRecurringBookableSlot ||
    (values.isRecurringBooking && activeStep === CREATE_RECURRENCE_STEP);

  const labelDay =
    days[
      new Date(
        earliestRecurringBookableSlot
          ? earliestRecurringBookableSlot.start ?? ""
          : values.date
      ).getDay()
    ];
  const labelDateString = formatDateSuffix(
    new Date(
      earliestRecurringBookableSlot
        ? earliestRecurringBookableSlot.start ?? ""
        : values.date
    )
  );
  let labelTimeSlotString = `${formatMinutesAfterMidnightTo12HourWithAmPm(
    values.start
  )} - ${formatMinutesAfterMidnightTo12HourWithAmPm(values.end)}`;

  if (
    earliestRecurringBookableSlot &&
    earliestRecurringBookableSlot.start &&
    earliestRecurringBookableSlot.end
  ) {
    labelTimeSlotString = `${formatTimeOnly(
      new Date(earliestRecurringBookableSlot.start)
    )} - ${formatTimeOnly(new Date(earliestRecurringBookableSlot.end))}`;
  }

  const appointmentTypes = useSelector(selectors.appointmentTypes.allAppointmentTypes);
  const dateTimeLabel = `${labelDay} ${labelDateString} | ${labelTimeSlotString}`;

  return (
    <div className={classes.container}>
      <div className={classes.dateTime}>
        <div className={classes.serviceInfo}>
          <p className={classes.appointment}>{selectedAppointment?.name}</p>
          <p className={classes.optional}>
            <ServicesListView
                services={basketStoreServices}
                appointmentTypes={appointmentTypes}
                currentTaxRatePercentage={vatRate}
            />
          </p>
          <p className={classes.optional}>
            {displayRecurringFromLabel && "Recurring from "}
            <br />
            <b>
              {values.isRecurringBooking && earliestRecurringBookableSlot && (
                <p>{dateTimeLabel}</p>
              )}
              {!earliestRecurringBookableSlot && activeStep !== 4 && (
                <p>{dateTimeLabel}</p>
              )}
            </b>
          </p>
        </div>
        <div className={classes.date}>£{getPriceAfterTax().toFixed(2)}</div>
      </div>
    </div>
  );
};

export default BookingFormSummaryBox;
