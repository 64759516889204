import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import {addCustomerAddress, updateCustomerAddress} from "../../api/customers";
import AppForm from "../../components/AppForm";
import AppFormikSubmitButton from "../../components/AppForm/AppFormikSubmitButton";
import AppModal from "../../components/AppModal";
import { successToast } from "../../toast";
import CreateCustomerAddressForm from "../Form/CustomerAddressForm";
import { CustomerAddressFormValues } from "../Form/CustomerAddressFormValues";
import { CustomerAddressFields } from "../Form/Fields";
import {CustomerAddress} from "../../models/Customers";

interface CreateCustomerAddressModalProps {
  address?: CustomerAddress;
  id: number;
  open: boolean;
  handleClose: (addressCreated: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const CreateCustomerAddressModal: React.FC<CreateCustomerAddressModalProps> = ({
  address,
  id,
  open,
  handleClose,
}) => {
  const classes = useStyles();

  const handleSubmit = async (
    customerAddress: CustomerAddressFormValues
  ): Promise<void> => {
    const response = !address
        ? await addCustomerAddress(id, customerAddress)
        : await updateCustomerAddress(id, address?.id, customerAddress);

    if (!response.isError) {
      handleClose(true);
      successToast(`Address has been ${!address ? 'added' : 'updated'}`);
    }
  };

  const renderFormSubmitButtonText = () => {
    return `${address ? 'Update' : 'Add'} address`;
  };

  return (
    <>
      <AppModal
        isOpen={open}
        onClose={() => handleClose(false)}
        title="Add Address"
      >
        <CreateCustomerAddressForm onSubmit={handleSubmit} initialValues={address}>
          <AppForm>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomerAddressFields.addressLine1 autoFocus />
              </Grid>
              <Grid item xs={12}>
                <CustomerAddressFields.addressLine2 />
              </Grid>
              <Grid item xs={12}>
                <CustomerAddressFields.addressLine3 />
              </Grid>
              <Grid item xs={6}>
                <CustomerAddressFields.town />
              </Grid>
              <Grid item xs={6}>
                <CustomerAddressFields.postcode />
              </Grid>
            </Grid>
          </AppForm>
          <div className={classes.buttonGroup}>
            <AppFormikSubmitButton>{renderFormSubmitButtonText()}</AppFormikSubmitButton>
          </div>
        </CreateCustomerAddressForm>
      </AppModal>
    </>
  );
};

export default CreateCustomerAddressModal;
