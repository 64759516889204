import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { createHttpClientThunk } from "./common/createHttpClientThunk";

import { Pagination } from "../models/Pagination";
import { PayloadAction } from "@reduxjs/toolkit";
import { ExtrasResponse } from "../api/models/Extras";
import { getPaginatedExtras } from "../api/extras";

export const sliceName = "extras";

const fetchPaginatedAsyncThunk = createHttpClientThunk(
  `${sliceName}/fetchPaginated`,
  getPaginatedExtras
);

const entityAdapter = createEntityAdapter<ExtrasResponse>({
  selectId: (c) => c.id,
});

type SliceState = {
  entityState: EntityState<ExtrasResponse>;
  pagination: Pagination;
  isFetchingAll: boolean;
  isErrorAll: boolean;
  searchTerm: string;
  sortBy: string;
};

const initialState: SliceState = {
  entityState: entityAdapter.getInitialState(),
  pagination: {
    pageNumber: 1,
    pageSize: 12,
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
    totalPages: 1,
  },
  isFetchingAll: false,
  isErrorAll: false,
  searchTerm: "",
  sortBy: "",
};

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setSearchTerm: (state, { payload }: PayloadAction<string>) => {
      // reset page number otherwise we will could get invalid pagination if results have less pages than current search.
      state.pagination.pageNumber = 1;
      state.searchTerm = payload;
    },
    setSortBy: (state, { payload }: PayloadAction<string>) => {
      state.pagination.pageNumber = 1;
      state.sortBy = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPaginatedAsyncThunk.pending, (state) => {
      state.isFetchingAll = true;
      state.isErrorAll = false;
    });
    builder.addCase(fetchPaginatedAsyncThunk.rejected, (state) => {
      state.isFetchingAll = false;
      state.isErrorAll = true;
    });
    builder.addCase(
      fetchPaginatedAsyncThunk.fulfilled,
      (state, { payload }) => {
        state.isFetchingAll = false;
        entityAdapter.removeAll(state.entityState);
        entityAdapter.setAll(state.entityState, payload.content.items);
        state.pagination = {
          ...payload.content,
        };
      }
    );
  },
});

export const { name, reducer } = slice;

type RootReducerState = {
  [sliceName]: SliceState;
};

const selectSliceState = (state: RootReducerState) => state[sliceName];
const entitySelectors = entityAdapter.getSelectors();

const doPaginatedFetch = (
  dispatch: (action: unknown) => void,
  state: SliceState,
  pageNumber?: number,
  pageSize?: number,
  searchTerm?: string,
  sortBy?: string
) => {
  dispatch(
    fetchPaginatedAsyncThunk({
      pageIndex: pageNumber || state.pagination.pageNumber,
      pageSize: pageSize || state.pagination.pageSize,
      searchTerm: searchTerm || state.searchTerm,
      sortBy: sortBy || state.sortBy,
    })
  );
};

export const actions = {
  fetchAll:
    () =>
    (
      dispatch: (action: unknown) => void,
      getState: () => RootReducerState
    ): void => {
      const state = selectSliceState(getState());
      doPaginatedFetch(dispatch, state);
    },
  setPageNumber:
    (pageNumber: number) =>
    (
      dispatch: (action: unknown) => void,
      getState: () => RootReducerState
    ): void => {
      const state = selectSliceState(getState());
      doPaginatedFetch(dispatch, state, pageNumber);
    },
  setPageSize:
    (pageSize: number) =>
    (
      dispatch: (action: unknown) => void,
      getState: () => RootReducerState
    ): void => {
      const state = selectSliceState(getState());
      doPaginatedFetch(dispatch, state, undefined, pageSize);
    },
  setSearchTerm:
    (newSearchTerm: string) =>
    (dispatch: (action: unknown) => void): void => {
      dispatch(slice.actions.setSearchTerm(newSearchTerm));
    },
  doSearch:
    () =>
    (
      dispatch: (action: unknown) => void,
      getState: () => RootReducerState
    ): void => {
      const state = selectSliceState(getState());
      doPaginatedFetch(dispatch, state);
    },
  setSortBy:
    (sortByString: string) =>
    (
      dispatch: (action: unknown) => void,
      getState: () => RootReducerState
    ): void => {
      dispatch(slice.actions.setSortBy(sortByString));
      const state = selectSliceState(getState());
      doPaginatedFetch(
        dispatch,
        state,
        undefined,
        undefined,
        undefined,
        sortByString
      );
    },
};

const createSliceSelector = <T,>(selector: (state: SliceState) => T) => {
  return createSelector(selectSliceState, selector);
};

export const selectors = {
  apiStateAll: createSliceSelector((state) => ({
    isFetching: state.isFetchingAll,
    isError: state.isErrorAll,
  })),
  selectSearchTerm: createSelector(
    selectSliceState,
    (state) => state.searchTerm
  ),
  all: createSliceSelector((state) =>
    entitySelectors.selectAll(state.entityState)
  ),
  pagination: createSliceSelector((state) => state.pagination),
  selectSortBy: createSelector(selectSliceState, (state) => state.sortBy),
};
