import { CustomerAddress } from "../../models/Customers";
import { TableCell, TableRow, IconButton, makeStyles } from "@material-ui/core";
import {
  ArrowDropDown as ExpandMoreIcon,
  ArrowDropUp as ShowLessIcon,
} from "@material-ui/icons";
import React, { useMemo } from "react";

const useStyles = makeStyles(() => ({
  actions: {
    display: "flex",
  },
  addressNotes: {
    border: "none",
    backgroundColor: "white",
    padding: "16px 16px 16px 10px",
    "& h5, p": {
      margin: "0",
      padding: "1px 0",
    },
  },
  tableRow: {
    borderTop: "1px solid #e0e0e0",
  },
}));

interface CustomerAddressesTableRowProps {
  address: CustomerAddress;
  handleEdit: (id: number) => void;
  handleDelete: () => void;
  expandComponent?: React.ReactNode;
}

const ExpandableTableRow: React.FC<CustomerAddressesTableRowProps> = ({
  address,
  handleEdit,
  handleDelete,
  expandComponent,
}) => {
  const classes = useStyles();
  const determineHasNotes = (address: CustomerAddress) => {
    return address.notes != "" && address.notes != undefined;
  };

  const [isExpanded, setIsExpanded] = React.useState(false);
  const hasNotes = useMemo(() => determineHasNotes(address), [address]);

  return (
    <>
      <TableRow className={classes.tableRow}>
        <TableCell padding="checkbox">
          {hasNotes && (
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <ShowLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>{address.addressLine1}</TableCell>
        <TableCell>{address.addressLine2}</TableCell>
        <TableCell>{address.addressLine3}</TableCell>
        <TableCell>{address.town}</TableCell>
        <TableCell>{address.postcode}</TableCell>
        <TableCell className={classes.actions}>
          {/*<IconButton onClick={() => handleEdit(address.id)}>*/}
          {/*  <Edit />*/}
          {/*</IconButton>*/}
          {/*<IconButton onClick={handleDelete}>*/}
          {/*  <RemoveCircle />*/}
          {/*</IconButton>*/}
        </TableCell>
      </TableRow>
      {hasNotes && isExpanded && (
        <TableRow>
          <TableCell padding="checkbox" className={classes.addressNotes} />
          {expandComponent}
        </TableRow>
      )}
    </>
  );
};

const CustomerAddressesTableRow: React.FC<CustomerAddressesTableRowProps> = ({
  address,
  handleEdit,
  handleDelete,
}) => {
  const classes = useStyles();

  return (
    <ExpandableTableRow
      address={address}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      expandComponent={
        <TableCell colSpan={6} className={classes.addressNotes}>
          <h5>Address Details:</h5>
          <p
            dangerouslySetInnerHTML={{
              __html: address.notes?.replace(/[\r\n]/g, "<br />") ?? "",
            }}
          />
        </TableCell>
      }
    />
  );
};

export default CustomerAddressesTableRow;
