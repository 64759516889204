import { Checkbox, CheckboxProps, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

interface AppCheckboxProps extends CheckboxProps {
  label: string;
}

const useStyles = makeStyles((theme) => ({
  checkbox: {
    "& svg": {
      fill: "url(#BrandGradient)",
    },
  },
}));

const AppCheckbox: React.FC<AppCheckboxProps> = ({ label, ...restOfProps }) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      control={<Checkbox {...restOfProps} className={classes.checkbox} />}
      label={label}
    />
  );
};

export default AppCheckbox;
