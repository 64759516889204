import { makeStyles } from "@material-ui/core";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BookingsTable from "../bookings/BookingsTable";
import CreateBookingDrawer from "../bookings/CreateBookingDrawer/CreateBookingDrawer";
import { AppSearchBox } from "../components/AppSearchBox";
import { PageContainer } from "../components/MainLayout";
import PageHeader from "../components/PageHeader";
import { useConfig } from "../config/ConfigContext";
import { actions, selectors } from "../store";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: theme.spacing(3),
    "& > h1": {
      flexGrow: 1,
      lineHeight: 1,
    },
    "& > a": {
      marginRight: theme.spacing(1),
    },
  },
  search: {
    width: "100%",
  },
}));

const BookingsPage: React.FC = () => {
  const { isFresh } = useConfig();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [createBookingModal, setCreateBookingModal] = useState(false);
  const searchTerm = useSelector(selectors.bookings.selectSearchTerm);

  const handleCreateBookingClose = () => {
    dispatch(actions.bookings.fetchAll());
    setCreateBookingModal(false);
  };

  const handleSearchChange = (value: string) => {
    dispatch(actions.bookings.setSearchTerm(value));
  };

  const handleSubmitSearch = () => {
      dispatch(actions.bookings.doSearch());
  };

  const handleCreateNewBooking = () => {
    dispatch(actions.basket.resetBasket());
    setCreateBookingModal(true);
  };

  return (
    <PageContainer>
      <PageHeader
        title="Bookings"
        action={!isFresh ? () => handleCreateNewBooking() : undefined}
        actionLabel="Create new booking"
      >
        <AppSearchBox
          className={classes.search}
          placeholder={"Search by names or emails..."}
          onSubmit={handleSubmitSearch}
          onChange={handleSearchChange}
          value={searchTerm}
        ></AppSearchBox>
      </PageHeader>
      <BookingsTable />
      <CreateBookingDrawer
        open={createBookingModal}
        onClose={handleCreateBookingClose}
      />
    </PageContainer>
  );
};

export default BookingsPage;
