import { Form, Formik, FormikHelpers } from "formik";
import { checkIfEmailIsAvailable } from "../../api/accountManagement";
import { postcodeExists } from "../../api/geocoding";
import { postcodeFieldName } from "./CustomerAddressFormValues";
import {
  customerFormValidationSchema,
  CustomerCreateFormValues,
  defaultCustomerCreateFormValues,
} from "./CustomerCreateFormValues";
import { emailFieldName } from "./CustomerFormValues";

interface CreateCustomerFormProps {
  onSubmit: (customer: CustomerCreateFormValues) => Promise<void>;
  initialValues?: CustomerCreateFormValues;
}

const CreateCustomerForm: React.FC<CreateCustomerFormProps> = ({
  onSubmit,
  children,
}) => {
  const handleSubmit = async (
    values: CustomerCreateFormValues,
    { setFieldError }: FormikHelpers<CustomerCreateFormValues>
  ) => {
    const emailIsAvailable = await checkIfEmailIsAvailable(values.email);

    if (emailIsAvailable.isError) {
      setFieldError(emailFieldName, "This email is already in use.");
      return;
    }

    const postcodeIsLocateable = await postcodeExists(values.postcode);

    if (postcodeIsLocateable.statusCode === 404) {
      setFieldError(postcodeFieldName, "Could not find this postcode.");
      return;
    }

    await onSubmit(values);
  };

  return (
    <Formik
      initialValues={defaultCustomerCreateFormValues}
      validationSchema={customerFormValidationSchema}
      onSubmit={handleSubmit}
    >
      <Form>{children}</Form>
    </Formik>
  );
};

export default CreateCustomerForm;
