import {
  Button,
  CircularProgress,
  lighten,
  makeStyles,
} from "@material-ui/core";
import { useField } from "formik";
import { MouseEvent, useEffect, useState } from "react";
import { ApiBookableSlotDto } from "../../api/models/BookableSlots";
import {
  days,
  formatDateShortNoYear,
  formatTimeOnly,
  formatTimeStringToMinutesAfterMidnight,
} from "../../dateFormatters";
import ecoIcon from "./images/eco-icon.svg";

interface WidgetBookableSlotsSelectProps {
  bookingSlots: ApiBookableSlotDto[];
  isLoading: boolean;
  firstAvailableBookingSlot: ApiBookableSlotDto;
  handleSlotSelected: (bool: boolean) => void;
}

const ecoGreen = "#00c70f";

const useStyles = makeStyles((theme) => ({
  resourceSelect: {
    flexGrow: 1,
  },
  timeSlot: {
    textTransform: "none",
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[500]}`,
    marginRight: theme.spacing(2),
    width: "100%",
    backgroundColor: theme.palette.common.white,
  },
  selectedTimeSlot: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[500]}`,
    marginRight: theme.spacing(2),
    width: "100%",
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.2),
    },
  },
  buttonLeaf: {
    height: "20px",
    paddingRight: "16px",
  },
  ecoTimeSlot: {
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[500]}`,
    marginRight: theme.spacing(2),
    width: "100%",
    backgroundColor: theme.palette.common.white,
  },
  ecoSelectedTimeSlot: {
    background: ecoGreen,
    color: theme.palette.common.white,
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[500]}`,
    marginRight: theme.spacing(2),
    width: "100%",
    "&:hover": {
      backgroundColor: lighten(ecoGreen, 0.2),
    },
  },

  outerSlotBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  ecoSlotLeaf: {
    top: 0,
    right: 0,
    height: "50px",
    width: "50px",
    backgroundImage: `url(${ecoIcon})`,
    backgroundSize: "50px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right top",
    position: "absolute",
    zIndex: 1,
    transform: "rotate(30deg)",
    transformOrigin: "top right",
  },

  innerSlotBox: {
    height: "100%",
    width: "100%",
    position: "relative",
    paddingTop: theme.spacing(1),
    padding: theme.spacing(2),
  },
  ecoSlotBg: {
    backgroundColor: "#e5f9e7",
  },
  lastSlotBg: {
    backgroundColor: "#fff6eb",
  },
  NoSlotBg: {
    backgroundColor: "#ebf2ff",
  },
  viewAll: {
    padding: theme.spacing(1),
    color: theme.palette.grey[600],
    cursor: "pointer",
    alignSelf: "flex-end",
    backgroundColor: "transparent",
    border: "none",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
  viewAllOff: {
    display: "none",
  },
  allOtherSlots: {
    padding: theme.spacing(2),
  },
  progress: {
    "& span": {
      paddingRight: "30px",
    },
  },
}));

const WidgetBookableSlotsSelect: React.FC<WidgetBookableSlotsSelectProps> = ({
  handleSlotSelected,
  isLoading,
  bookingSlots,
  firstAvailableBookingSlot,
}) => {
  const classNames = useStyles();

  const [selectedSlotID, setSelectedSlotID] = useState<string | undefined>();
  const [allTimesClicked, setAllTimesClicked] = useState<boolean>(false);

  const [, , { setValue: setStart }] = useField<number>("start");
  const [, , { setValue: setEnd }] = useField<number>("end");
  const [, , { setValue: setResourceId }] = useField<number>("resourceId");
  const [{ value: date }, , { setValue: setDate }] = useField<Date | null>(
    "date"
  );

  const [dateDay, setDateDay] = useState<null | Date>(null);

  useEffect(() => {
    if (date)
      setDateDay(new Date(date.getFullYear(), date.getMonth(), date.getDay()));
  }, [date]);

  const timeSlotAsTimeNumber = (timeSlot: Date): number =>
    formatTimeStringToMinutesAfterMidnight(
      formatTimeOnly(new Date(timeSlot), false)
    );

  const getTimeSlotEnd = (timeSlot: Date, duration: number) => {
    const timeSlotAsDate = new Date(timeSlot);
    timeSlotAsDate.setMinutes(timeSlotAsDate.getMinutes() + duration);
    return timeSlotAsDate;
  };

  const viewAllClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setAllTimesClicked(true);
  };

  const handleSlotClick = (
    timeSlot: ApiBookableSlotDto,
    identifier: string
  ) => {
    setSelectedSlotID(identifier);
    const endTime = getTimeSlotEnd(timeSlot.start, timeSlot.durationMinutes);
    const timeSlotAsNum = timeSlotAsTimeNumber(timeSlot.start);
    const timeSlotEndAsNum = timeSlotAsTimeNumber(endTime);
    setStart(timeSlotAsNum, false);
    setEnd(timeSlotEndAsNum, false);
    const startDate = new Date(timeSlot.start);
    if (date && new Date(date).getDate() !== startDate.getDate()) {
      setDate(startDate);
    } else if (date === null) {
      setDate(startDate);
    }
    setResourceId(timeSlot.resourceId, true);
    handleSlotSelected(true);
  };

  const resetSelectedResource = () => {
    setAllTimesClicked(false);
    setSelectedSlotID(undefined);
    setResourceId(0);
    handleSlotSelected(false);
  };

  useEffect(() => {
    if (date !== null) resetSelectedResource();
  }, [date]);

  const mapEcoSlots = (timeSlot: ApiBookableSlotDto, i: number) => {
    const SlotId = `eco-${i}`;
    return (
      <Button
        className={
          selectedSlotID === SlotId
            ? classNames.ecoSelectedTimeSlot
            : classNames.ecoTimeSlot
        }
        onClick={() => handleSlotClick(timeSlot, SlotId)}
        key={`${timeSlot.start}-${timeSlot.resourceId}`}
      >
        <img src={ecoIcon} className={classNames.buttonLeaf}></img>{" "}
        {formatTimeOnly(new Date(timeSlot.start))} -{" "}
        {formatTimeOnly(
          getTimeSlotEnd(timeSlot.start, timeSlot.durationMinutes)
        )}
      </Button>
    );
  };

  const mapAvailableSlots = (timeSlot: ApiBookableSlotDto, i: number) => {
    {
      const SlotId = `standard-${i}`;
      return (
        <Button
          className={
            selectedSlotID === SlotId
              ? classNames.selectedTimeSlot
              : classNames.timeSlot
          }
          onClick={() => handleSlotClick(timeSlot, SlotId)}
          key={`${timeSlot.start}-${timeSlot.resourceId}`}
        >
          {formatTimeOnly(new Date(timeSlot.start))} -{" "}
          {formatTimeOnly(
            getTimeSlotEnd(timeSlot.start, timeSlot.durationMinutes)
          )}
        </Button>
      );
    }
  };

  const availableSlots = bookingSlots.filter((slot) => !slot.isEcoSlot);
  const availableEcoSlots = bookingSlots.filter((slot) => slot.isEcoSlot);

  // // If no eco slots are available, but we have at least 2 available slots, make the earliest of the two an eco slot.
  // if (availableEcoSlots.length === 0 && availableSlots.length > 1) {
  //   const firstAvailableSlot = availableSlots
  //     .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
  //     .shift();
  //   if (firstAvailableSlot) availableEcoSlots.push(firstAvailableSlot);
  // }
  // This has been taken out to allow for release while we decide whether or not to include it.

  const displayEcoSlots = availableEcoSlots.length > 0;
  const displayLastSlot =
    availableEcoSlots.length + availableSlots.length === 1;
  const displayAvailableSlots =
    (availableSlots.length > 1 && !displayEcoSlots) || allTimesClicked;
  const displayNoAvailableSlots =
    availableSlots.length === 0 && availableEcoSlots.length === 0;

  return (
    <>
      {isLoading ? (
        <p className={classNames.progress}>
          <span>Finding appointments... </span>
          <CircularProgress size={24} />
        </p>
      ) : (
        <>
          {displayEcoSlots && (
            <div className={classNames.outerSlotBox}>
              <div
                className={`${classNames.innerSlotBox} ${classNames.ecoSlotBg}`}
              >
                <h3>Eco-Appointments</h3>
                Looks like there&apos;s someone in your area on your selected
                date. By selecting an eco-appointment you are reducing the miles
                travelled by our team to fulfil your service having a positive
                impact on the environment.
                <br />
                <br />
                Tell us whether any of the times below are suitable?
                {availableEcoSlots.map((timeSlot, i) =>
                  mapEcoSlots(timeSlot, i)
                )}
                <div className={classNames.ecoSlotLeaf}></div>
              </div>
              {availableSlots.length > 0 && (
                <button
                  className={
                    !allTimesClicked
                      ? classNames.viewAll
                      : classNames.viewAllOff
                  }
                  onClick={viewAllClick}
                >
                  {" "}
                  View all available times
                </button>
              )}
            </div>
          )}
          {displayAvailableSlots && (
            <div className={classNames.allOtherSlots}>
              {availableSlots.map((timeSlot, i) =>
                mapAvailableSlots(timeSlot, i)
              )}
            </div>
          )}
          {displayNoAvailableSlots && (
            <div className={classNames.outerSlotBox}>
              <div
                className={`${classNames.innerSlotBox} ${classNames.NoSlotBg}`}
              >
                <h3>We&apos;re booked up</h3>
                We don&apos;t have any available appointments that day.
                <br />
                <b>Our next available appointment is on:</b>
                {firstAvailableBookingSlot && (
                  <Button
                    className={classNames.timeSlot}
                    onClick={() =>
                      setDate(new Date(firstAvailableBookingSlot.start))
                    }
                  >
                    {days[new Date(firstAvailableBookingSlot.start).getDay()]}{" "}
                    {formatDateShortNoYear(
                      new Date(firstAvailableBookingSlot.start)
                    )}{" "}
                    |{" "}
                    {formatTimeOnly(new Date(firstAvailableBookingSlot.start))}{" "}
                    -{" "}
                    {formatTimeOnly(
                      getTimeSlotEnd(
                        firstAvailableBookingSlot.start,
                        firstAvailableBookingSlot.durationMinutes
                      )
                    )}
                  </Button>
                )}
                <p>
                  <b>Or please try another date.</b>
                </p>
              </div>
            </div>
          )}
          {displayLastSlot && (
            <div className={classNames.outerSlotBox}>
              <div
                className={`${classNames.innerSlotBox} ${classNames.lastSlotBg}`}
              >
                <h3>Last Slot Available</h3>
                You&apos;re in luck, we have one remaining slot left for your
                chosen day.
                {availableSlots.length === 1
                  ? availableSlots.map((timeSlot, i) =>
                      mapAvailableSlots(timeSlot, i)
                    )
                  : availableEcoSlots.map((timeSlot, i) =>
                      mapEcoSlots(timeSlot, i)
                    )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default WidgetBookableSlotsSelect;
