import { ResourceInput } from "@fullcalendar/resource-common";
import { startOfDay, endOfDay, addMinutes } from "date-fns";
import {
  ResourceSummary,
  ResourceWorkingHours,
} from "../../../models/Resource";
import { MinMaXCalendarTimes, StoredSelectedResource } from "../types";

export const mapResourceToCalendarResource = (
  resource: ResourceSummary
): ResourceInput => {
  return {
    id: resource.id.toString(),
    name: resource.name,
    title: resource.name,
    eventColor: resource.colour,
    timeSelected: null,
    extendedProps: {
      color: resource.colour,
      bookableFrom: resource.bookableFrom,
      bookableUntil: resource.bookableUntil,
    },
  };
};

export const minMaxCalendarTimes = (
  workingHours: ResourceWorkingHours[]
): MinMaXCalendarTimes => {
  const defaultStartOfDay = startOfDay(new Date());
  const defaultEndOfDay = endOfDay(new Date());

  if (workingHours.length > 0) {
    const minStart =
      workingHours
        .filter((item) => !item.nonWorking)
        .reduce((prev, curr) => {
          return prev.startTimeMinutesPastMidnight <
            curr.startTimeMinutesPastMidnight
            ? prev
            : curr;
        }).startTimeMinutesPastMidnight - 30;

    const maxEnd =
      workingHours
        .filter((item) => !item.nonWorking)
        .reduce((prev, curr) => {
          return prev.finishTimeMinutesPastMidnight >
            curr.finishTimeMinutesPastMidnight
            ? prev
            : curr;
        }).finishTimeMinutesPastMidnight + 29;

    return {
      start: addMinutes(defaultStartOfDay, minStart),
      end: addMinutes(defaultStartOfDay, maxEnd),
    };
  }

  return {
    start: defaultStartOfDay,
    end: defaultEndOfDay,
  };
};

export const sortResourcesByTimeSelectedThenNameThenIdAsc = (
  resources: ResourceInput[]
): ResourceInput[] => {
  return resources
    .filter((r) => r.timeSelected)
    .sort(
      (r1, r2) =>
        r1.timeSelected - r2.timeSelected ||
        r2.title!.localeCompare(r1.title!) ||
        +r1.id! - +r2.id!
    );
};

export const getOldestSelectedResource = (
  resources: ResourceInput[]
): ResourceInput | undefined => {
  if (!resources || resources.length === 0) {
    return undefined;
  }

  const newestSelectedResource =
    sortResourcesByTimeSelectedThenNameThenIdAsc(resources).shift();

  return newestSelectedResource;
};

export const getNewestSelectedResource = (
  resources: ResourceInput[]
): ResourceInput | undefined => {
  if (!resources || resources.length === 0) {
    return undefined;
  }

  const newestSelectedResource =
    sortResourcesByTimeSelectedThenNameThenIdAsc(resources).pop();

  return newestSelectedResource;
};

export const cleanStoredSelectedResources = (
  storedSelectedResources: StoredSelectedResource[],
  allResources: ResourceInput[]
) => {
  const cleanStoredSelectedResources = [];

  for (const storedSelectedResource of storedSelectedResources) {
    const resource = allResources.find(
      (r) => r.id === storedSelectedResource.id
    );
    if (resource) {
      cleanStoredSelectedResources.push(storedSelectedResource);
    }
  }

  return cleanStoredSelectedResources;
};
