import { configureStore } from "@reduxjs/toolkit";

import * as resources from "./resources";
import * as appointmentTypes from "./appointmentTypes";
import * as basket from "./basket";
import * as bookings from "./bookings";
import * as algorithmDemo from "./algorithmDemo";
import * as customers from "./customers";
import * as outcodes from "./outcodes";
import * as services from "./services";
import * as extras from "./extras";

const store = configureStore({
  reducer: {
    [resources.name]: resources.reducer,
    [services.name]: services.reducer,
    [appointmentTypes.name]: appointmentTypes.reducer,
    [bookings.name]: bookings.reducer,
    [customers.name]: customers.reducer,
    [algorithmDemo.name]: algorithmDemo.reducer,
    [outcodes.name]: outcodes.reducer,
    [basket.name]: basket.reducer,
    [extras.name]: extras.reducer,
  },
});

export const actions = {
  [resources.name]: resources.actions,
  [services.name]: services.actions,
  [appointmentTypes.name]: appointmentTypes.actions,
  [bookings.name]: bookings.actions,
  [customers.name]: customers.actions,
  [algorithmDemo.name]: algorithmDemo.actions,
  [outcodes.name]: outcodes.actions,
  [basket.name]: basket.actions,
  [extras.name]: extras.actions,
};

export const selectors = {
  [resources.name]: resources.selectors,
  [services.name]: services.selectors,
  [appointmentTypes.name]: appointmentTypes.selectors,
  [bookings.name]: bookings.selectors,
  [customers.name]: customers.selectors,
  [algorithmDemo.name]: algorithmDemo.selectors,
  [outcodes.name]: outcodes.selectors,
  [basket.name]: basket.selectors,
  [extras.name]: extras.selectors,
};

export default store;
export * from "./utils/useDispatchEffect";
export { useTotalPrice } from "./appointmentTypes";
