import { EventInput } from "@fullcalendar/react";
import { ResourceInput } from "@fullcalendar/resource-common";
import {
  addDays,
  differenceInMinutes,
  endOfDay,
  setDay,
  setMinutes,
  startOfDay,
} from "date-fns";
import { BookingSummary } from "../../../models/Booking";
import { CalendarTravelTime } from "../../../models/Calendar";
import { ResourceWorkingHours, TimeOff } from "../../../models/Resource";
import { CALENDAR_VIEWS } from "../AppCalendarContext";

export enum EVENT_TYPE {
  BOOKING = "booking",
  TIME_OFF = "time-off",
  NON_WORKING = "non-working",
  TRAVEL_TIME = "travel-time",
}

export const mapBookingToEventObject = (
  booking: BookingSummary
): EventInput => {
  return {
    id: booking.id.toString(),
    title: booking.firstJobAppointmentTypeName,
    description: booking.description,
    start: booking.start,
    end: booking.end,
    resourceId: booking.resourceId.toString(),
    color: booking.resourceColour,
    extendedProps: {
      type: EVENT_TYPE.BOOKING,
      notes: booking.notes,
      tags: booking.tags,
      resourceName: booking.resourceName,
      resourceColour: booking.resourceColour,
      totalCost: booking.totalPrice.toFixed(2),
      bookingPostcode: booking.customerAddress.postcode,
      customerFirstName: booking.customerFirstName,
      customerLastName: booking.customerLastName,
      bookingStatus: booking.bookingStatus,
      optionalExtras: booking.firstJobOptionalExtras,
      taxRate: booking.taxRate,
      firstJobAppointmentTypePrice: booking.firstJobAppointmentTypePrice,
      totalPriceExVat: booking.totalPriceExVat,
      overriddenTotalPrice: booking.overriddenTotalPrice,
      recurringBookingId: booking.recurringBookingId,
      jobs: booking.jobs,
    },
  };
};

export const mapTimeOffToEventObject = (timeOff: TimeOff): EventInput => {
  return {
    id: timeOff.id.toString(),
    title: "Time-off",
    start: timeOff.start,
    end: timeOff.end,
    resourceId: timeOff.resourceId.toString(),
    backgroundColor: "gray",
    borderColor: "#cad6e5",
    extendedProps: {
      type: EVENT_TYPE.TIME_OFF,
      resourceName: timeOff.resourceName,
      resourceId: timeOff.resourceId,
    },
  };
};

export const mapTravelTimeToEventObject = (
  travelTime: CalendarTravelTime
): EventInput => {
  return {
    id: `${travelTime.id.toString()}-travel-time`,
    title: "Travel-time",
    start: travelTime.start,
    end: travelTime.end,
    resourceId: travelTime.resourceId.toString(),
    backgroundColor: "gray",
    borderColor: "#cad6e5",
    extendedProps: {
      type: EVENT_TYPE.TRAVEL_TIME,
      resourceId: travelTime.resourceId,
      initialDuration: travelTime.initialDuration,
    },
  };
};

export const mapNonWorkingHoursToEventObjects = (
  nonWorkingHours: ResourceWorkingHours,
  startOfWeek: Date,
  view: CALENDAR_VIEWS,
  resources: ResourceInput[]
): EventInput[] => {
  const eventItems: EventInput[] = [];

  if (nonWorkingHours.dayOfWeekId === 0) {
    nonWorkingHours.dayOfWeekId = 7;
  }

  const dayOfWeek = nonWorkingHours.dayOfWeekId - 1;

  const resource = resources.find(
    (res) => res.id === nonWorkingHours.resourceId.toString()
  );
  const currentDate = addDays(new Date(startOfWeek), dayOfWeek);
  const bookableFrom =
    resource?.extendedProps?.bookableFrom &&
    new Date(resource.extendedProps.bookableFrom);
  const bookableUntil =
    resource?.extendedProps?.bookableUntil &&
    new Date(resource.extendedProps.bookableUntil);

  // If the Resource is not trading that day, make the non-working blocks last the entire day.
  // To create a custom visual representation, change the type and create an associated class.
  const firstBlockStart = addDays(startOfDay(startOfWeek), dayOfWeek);
  const firstBlockEnd =
    !bookableFrom ||
    (bookableFrom && startOfDay(bookableFrom) > currentDate) ||
    (bookableUntil && bookableUntil < currentDate)
      ? setMinutes(
          addDays(startOfWeek, dayOfWeek),
          nonWorkingHours.finishTimeMinutesPastMidnight
        )
      : setMinutes(
          addDays(startOfWeek, dayOfWeek),
          nonWorkingHours.startTimeMinutesPastMidnight
        );

  const secondBlockStart = setMinutes(
    addDays(startOfWeek, dayOfWeek),
    nonWorkingHours.finishTimeMinutesPastMidnight
  );
  const secondBlockEnd = addDays(endOfDay(startOfWeek), dayOfWeek);

  eventItems.push({
    id: "non-working",
    title: "Non-working",
    start: firstBlockStart,
    end: firstBlockEnd,
    resourceId: nonWorkingHours.resourceId.toString(),
    backgroundColor: "#f5f5f5",
    display: "background",
    className: "non-working-event",
    extendedProps: {
      type: EVENT_TYPE.NON_WORKING,
    },
  });

  eventItems.push({
    id: "non-working",
    title: "Non-working",
    start: secondBlockStart,
    end: secondBlockEnd,
    resourceId: nonWorkingHours.resourceId.toString(),
    backgroundColor: "#f5f5f5",
    display: "background",
    className: "non-working-event",
    extendedProps: {
      type: EVENT_TYPE.NON_WORKING,
    },
  });

  return eventItems;
};
