import AppFormikTagInput, { AppFormikTagInputProps } from "./AppFormikTagInput";

export type CreatePreconfiguredAppFormTagInputOptions = {
    name: string;
    placeholder: string;
    label: string;
};

export type CreatePreconfiguredAppFormTagInputProps = Omit<AppFormikTagInputProps, "name" | "placeholder" | "label">;

export const createPreconfiguredAppFormTagInput = ({
    name,
    placeholder,
    label,
}: CreatePreconfiguredAppFormTagInputOptions): React.FC<CreatePreconfiguredAppFormTagInputProps> => {
    const PreConfiguredAppFormTagInput = (
        restOfProps: CreatePreconfiguredAppFormTagInputProps
    ) => (
        <AppFormikTagInput
            name={name}
            {...restOfProps}
            placeholder={placeholder}
            label={label}
        />
    );

    return PreConfiguredAppFormTagInput;
};

export default createPreconfiguredAppFormTagInput;