import { HttpClientResponse } from "./common/httpClient";
import {
  ApiPaginatedResponse,
  ApiPaginationQueryParams,
  ApiStandardResponse,
  buildApiUrl,
  doErrorToastIfRequired,
  httpClient,
  mapHttpClientListResponse,
  mapHttpClientPaginatedResponse,
  mapHttpClientResponse,
} from "./common";
import {
  ApiBookingDetails,
  ApiBookingSummary,
  ApiCalendarEntry,
  ApiCalendarSearchParams,
  ApiResourceUserCalendarSearchParams,
} from "./models/Booking";
import {
  BookingDetails,
  BookingSummary,
  CreateBookingDto,
  UpdateBookingDto,
} from "../models/Booking";
import {
  mapFromApiBookingSummary,
  mapFromApiBookingDetails,
} from "../modelMappings/Booking";
import { ApiListResponse } from "./common/ApiListResponse";
import { mapFromApiCalendarEntries } from "../modelMappings/Calendar";
import { CalendarEntries } from "../models/Calendar";
import { CreateRecurringBookingDto } from "../models/RecurringBookableSlot";

export const getPaginatedBookings = async ({
  pageIndex: pageNumber,
  pageSize,
  searchTerm,
}: ApiPaginationQueryParams): Promise<
  HttpClientResponse<ApiPaginatedResponse<BookingSummary>>
> => {
  const queryParameters = [
    `pageNumber=${pageNumber}`,
    `pageSize=${pageSize}`,
    `searchTerm=${searchTerm}`,
  ];

  const url = buildApiUrl(
    `api-admin/paginated-bookings?${queryParameters.join("&")}`
  );
  const response = await httpClient.getRequest<
    ApiPaginatedResponse<ApiBookingSummary>
  >(url);

  doErrorToastIfRequired(response);

  return mapHttpClientPaginatedResponse(response, mapFromApiBookingSummary);
};

export const getCalendarBookings = async ({
  resourceIds,
  start,
  end,
}: ApiCalendarSearchParams): Promise<
  HttpClientResponse<ApiListResponse<BookingSummary>>
> => {
  const queryParameters = [
    `resourceIds=${resourceIds.join()}`,
    `start=${start}`,
    `end=${end}`,
  ];

  const url = buildApiUrl(`api-admin/bookings?${queryParameters.join("&")}`);
  const response = await httpClient.getRequest<
    ApiListResponse<ApiBookingSummary>
  >(url);

  doErrorToastIfRequired(response);

  return mapHttpClientListResponse(response, mapFromApiBookingSummary);
};

export const getCalendarEntries = async (
  resourceIds: number[],
  start: string,
  end: string
): Promise<HttpClientResponse<CalendarEntries>> => {
  const queryParameters = [
    `resourceIds=${resourceIds.join()}`,
    `start=${start}`,
    `end=${end}`,
  ];

  const url = buildApiUrl(
    `api-admin/calendar-entries?${queryParameters.join("&")}`
  );
  const response = await httpClient.getRequest<
    ApiStandardResponse<ApiCalendarEntry>
  >(url);

  return mapHttpClientResponse(response, mapFromApiCalendarEntries);
};

export const getCalendarEntriesResource = async (
  start: string,
  end: string
): Promise<HttpClientResponse<CalendarEntries>> => {
  const queryParameters = [`start=${start}`, `end=${end}`];

  const url = buildApiUrl(
    `api-resource/calendar-entries?${queryParameters.join("&")}`
  );
  const response = await httpClient.getRequest<
    ApiStandardResponse<ApiCalendarEntry>
  >(url);

  return mapHttpClientResponse(response, mapFromApiCalendarEntries);
};

export const getCalendarBookingsResource = async ({
  start,
  end,
}: ApiResourceUserCalendarSearchParams): Promise<
  HttpClientResponse<ApiListResponse<BookingSummary>>
> => {
  const queryParameters = [`start=${start}`, `end=${end}`];

  const url = buildApiUrl(`api-resource/bookings?${queryParameters.join("&")}`);
  const response = await httpClient.getRequest<
    ApiListResponse<ApiBookingSummary>
  >(url);

  doErrorToastIfRequired(response);

  return mapHttpClientListResponse(response, mapFromApiBookingSummary);
};

export const getBookingById = async (
  id: number,
  includeThumbnail?: boolean,
  includeDocuments?: boolean
): Promise<HttpClientResponse<BookingDetails>> => {
  const queryParameters = `includeThumbnails=${includeThumbnail ?? false}&includeDocuments=${includeDocuments ?? false}`;
  const url = buildApiUrl(`api-admin/bookings/${id}?${queryParameters}`);
  const response = await httpClient.getRequest<
    ApiStandardResponse<ApiBookingDetails>
  >(url);

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, mapFromApiBookingDetails);
};

export const createBooking = async (
  createBookingDto: CreateBookingDto
): Promise<HttpClientResponse<number>> => {
  const url = buildApiUrl("api-admin/bookings");
  const response = await httpClient.postRequest<ApiStandardResponse<number>>(
    url,
    createBookingDto
  );

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, (bookingId) => bookingId);
};

export const createRecurringBooking = async (
  createRecurringBookingDto: CreateRecurringBookingDto
): Promise<HttpClientResponse<number>> => {
  const url = buildApiUrl("api-admin/recurring-bookings");
  const response = await httpClient.postRequest<ApiStandardResponse<number>>(
    url,
    createRecurringBookingDto
  );

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, (bookingId) => bookingId);
};

export const editBooking = async (
  createBookingDto: UpdateBookingDto
): Promise<HttpClientResponse<void>> => {
  const url = buildApiUrl("api-admin/bookings");
  const response = await httpClient.putRequest<ApiStandardResponse<void>>(
    url,
    createBookingDto
  );
  doErrorToastIfRequired(response);
  return mapHttpClientResponse(response, (m) => m);
};

export const getBookingByIdResource = async (
  id: number
): Promise<HttpClientResponse<BookingDetails>> => {
  const url = buildApiUrl(`api-resource/bookings/${id}`);
  const response = await httpClient.getRequest<
    ApiStandardResponse<ApiBookingDetails>
  >(url);

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, mapFromApiBookingDetails);
};

export const editBookingResource = async (
  createBookingDto: UpdateBookingDto
): Promise<HttpClientResponse<void>> => {
  const url = buildApiUrl("api-resource/bookings");
  const response = await httpClient.putRequest<ApiStandardResponse<void>>(
    url,
    createBookingDto
  );
  doErrorToastIfRequired(response);
  return mapHttpClientResponse(response, (m) => m);
};

export const cancelBooking = async (
  bookingId: number
): Promise<HttpClientResponse<void>> => {
  const url = buildApiUrl(`api-admin/bookings/${bookingId}/actions/cancel`);
  const response = await httpClient.putRequest<ApiStandardResponse<void>>(url);
  doErrorToastIfRequired(response);
  return mapHttpClientResponse(response, (m) => m);
};

export const cancelBookingResource = async (
  bookingId: number
): Promise<HttpClientResponse<void>> => {
  const url = buildApiUrl(`api-resource/bookings/${bookingId}/actions/cancel`);
  const response = await httpClient.putRequest<ApiStandardResponse<void>>(url);
  doErrorToastIfRequired(response);
  return mapHttpClientResponse(response, (m) => m);
};

export const cancelRecurringBooking = async (
  bookingId: number
): Promise<HttpClientResponse<void>> => {
  const url = buildApiUrl(
    `api-admin/bookings/${bookingId}/actions/cancelRecurring`
  );
  const response = await httpClient.putRequest<ApiStandardResponse<void>>(url);
  doErrorToastIfRequired(response);
  return mapHttpClientResponse(response, (m) => m);
};
