import {
  ApiPaginatedResponse,
  ApiPaginationQueryParams,
  buildApiUrl,
  doErrorToastIfRequired,
  httpClient,
  mapHttpClientPaginatedResponse,
} from "./common";
import { HttpClientResponse } from "./common/httpClient";
import { ExtrasResponse } from "./models/Extras";

export const getPaginatedExtras = async ({
  pageIndex: pageNumber,
  pageSize,
  searchTerm,
  sortBy,
}: ApiPaginationQueryParams): Promise<
  HttpClientResponse<ApiPaginatedResponse<ExtrasResponse>>
> => {
  const queryParameters = [
    `pageIndex=${pageNumber}`,
    `pageSize=${pageSize}`,
    `searchTerm=${searchTerm}`,
    `sortBy=${sortBy}`,
  ];

  const url = buildApiUrl(`api/extras?${queryParameters.join("&")}`);
  const response = await httpClient.getRequest<
    ApiPaginatedResponse<ExtrasResponse>
  >(url);

  doErrorToastIfRequired(response);

  return mapHttpClientPaginatedResponse(response, (m) => m);
};
