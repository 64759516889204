import * as Yup from "yup";
import { postcodeExists } from "../../api/geocoding";
import { ukPostcodeRegex } from "../../constants";

export type CustomerAddressFormValues = {
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  town: string;
  postcode: string;
};

export const validatePostcodeRegex = (postcode: string) => {
  const matches = postcode.match(ukPostcodeRegex);
  return !!matches && matches.length !== 0;
};

export const validatePostcodeExists = async (postcode: string) => {
  if (!postcode || !validatePostcodeRegex(postcode)) return true;

  const response = await postcodeExists(postcode);

  return !response.isError && response.statusCode === 200;
};

export const postcodeFieldName = "postcode";

export const defaultCustomerAddressFormValues: CustomerAddressFormValues = {
  addressLine1: "",
  addressLine2: "",
  addressLine3: "",
  town: "",
  [postcodeFieldName]: "",
};

export const customerAddressFormValidationFields = {
  addressLine1: Yup.string().required("Please provide an address."),
  addressLine2: Yup.string().notRequired(),
  addressLine3: Yup.string().notRequired(),
  town: Yup.string().required("Please provide a town or city."),
  postcode: Yup.string()
    .required("Please enter your postcode")
    .matches(ukPostcodeRegex, "Must be valid postcode including the space.")
    .test(
      "validatePostcodeFormat",
      "Please check you have entered a valid postcode",
      (postcode) => {
        if (!postcode) return true;

        const validRegexResult = validatePostcodeRegex(postcode);

        if (!validRegexResult) return false;

        return validatePostcodeExists(postcode);
      }
    ),
};
