import { useField } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHttpRequest } from "../../../api";
import { getAllPaymentStatuses } from "../../../api/paymentStatuses";
import { ComboBoxOption } from "../../../components/AppComboBox/AppComboBox";
import AppFormikComboBox from "../../../components/AppForm/AppFormikComboBox";
import InformationTextBox from "../../../components/InformationTextBox";
import { actions, selectors } from "../../../store";
import { BookingFormStyles } from "../BookingFormStyles";
import BookingStatus from "./BookingFormBookingStatus";
import BookingFormOptionalExtraSelection from "./BookingFormOptionalExtraSelection";
import BookingFormPaymentStatus from "./BookingFormPaymentStatus";
import BookingFormTotalPrice from "./BookingFormTotalPrice";
import { BookedExtra, job, Jobs } from "../../../models/Booking";
import { BookingFormValues } from "../BookingFormValues";
import { useConfig } from "../../../config/ConfigContext";
import { excludeVATFromPriceIfRequired } from "../../../numericalUtils";
import { useAuth } from "../../../auth";
import AppButton from "../../../components/AppButton";

interface BookingFormAppointmentTypeSelectionProps {
  readOnly?: boolean;
  isEdit?: boolean;
  initialExtras: BookedExtra[];
  manualOverride: boolean;
  setManualOverride: (value: boolean) => void;
  initialValues: BookingFormValues;
  newCustomerId?: number;
  shouldUpdateBasket: boolean;
  isUpdatingExistingService: boolean;
  setIsUpdatingExistingService: (value: boolean) => void;
}

const BookingFormAppointmentTypeSelection: React.FC<BookingFormAppointmentTypeSelectionProps> =
  ({
    isEdit,
    initialExtras,
    initialValues,
    manualOverride,
    setManualOverride,
    shouldUpdateBasket,
    isUpdatingExistingService,
    setIsUpdatingExistingService,
  }) => {
    const classes = BookingFormStyles();
    const dispatch = useDispatch();
    const hasAppointmentTypeChanged = useRef(false);

    const vendorConfig = useConfig();
    const { role } = useAuth();

    const [, , helpers] = useField("requiresOptionalExtra");

    const [
      { value: selectedOptionalExtras },
      selectedOptionalExtrasMeta,
      setSelectedOptionalExtras,
    ] = useField<BookedExtra[]>("firstJobOptionalExtras");
    const [requiresExtras, , optionalExtrasHelpers] = useField("requiresOptionalExtra");

    const [selectedServicePrice, , setSelectedServicePrice] = useField<number>("selectedServicePrice");
    const [{ value: selectedService }, , { setValue: setFirstJobAppointmentTypeId },] = useField<number>("firstJobAppointmentTypeId");
    const [appointmentTypeInputProps] = useField<number>("firstJobAppointmentTypeId");
    const [{ value: overriddenTotalPrice }, , setOverriddenTotalPrice] = useField<number | undefined>("overriddenTotalPrice");
    const [{ value: selectedServiceBasketKey }, , { setValue: setSelectedServiceBasketKey }] = useField<number>("selectedServiceBasketKey");

    const basketStoreServices = useSelector(selectors.basket.services);
    const basketStoreTotal = useSelector(selectors.basket.total);
    // const customerAddresses = useSelector(selectors.customers.allAddresses);
    const appointmentTypes = useSelector(selectors.appointmentTypes.allAppointmentTypes);

    /**
     * This is used for adjusting validation parameters that are used to determine if the form can proceed
     *   and whether the basket should be updated when it does
     */
    useEffect(() => {
      setFirstJobAppointmentTypeId(-1);
      if (basketStoreServices.length > 0) {
        setFirstJobAppointmentTypeId(0);
        setSelectedOptionalExtras.setValue([]);
      }
    }, [basketStoreServices.length]);

    useEffect(() => {
      hasAppointmentTypeChanged.current = true;
    }, []);

    const getPaymentStatuses = useCallback(() => getAllPaymentStatuses(), []);
    const { result: paymentStatuses, isLoading: paymentStatusesLoading } = useHttpRequest(getPaymentStatuses);

    const priceHiddenFromResource = role === "resource" && vendorConfig.hidePricesFromResource;

    const isBookingComplete = initialValues.bookingStatus > 0;
    const vatRate = isBookingComplete
      ? initialValues.taxRate ?? 0
      : vendorConfig.currentTaxRatePercentage;

    const appointmentTypeOptions: ComboBoxOption[] = appointmentTypes.map(
      (appointmentType) => {
        const newPrice = excludeVATFromPriceIfRequired(
          appointmentType.price,
          vatRate
        );
        return {
          label: priceHiddenFromResource
            ? `${appointmentType.name}`
            : `${appointmentType.name} - £${newPrice.toFixed(2)}`,
          id: appointmentType.id,
        };
      }
    );

    const appointmentTypeDetail = appointmentTypes.find(
      (type) => type.id === appointmentTypeInputProps.value
    );

    useEffect(() => {
      const requiresOptionalExtra =
        appointmentTypeDetail?.optionalExtraRequired;
      helpers.setValue(requiresOptionalExtra);
    }, [appointmentTypeDetail]);

    useEffect(() => {
      if (appointmentTypeDetail) return;

      if (manualOverride) helpers.setValue(false);
      else helpers.setValue(initialValues.requiresOptionalExtra);
    }, [manualOverride]);

    useEffect(() => {
      if (appointmentTypeInputProps.value <= 0) {
        setSelectedOptionalExtras.setValue([]);
        setSelectedServicePrice.setValue(0);
        setOverriddenTotalPrice.setValue(undefined);
      }

      if (
        !hasAppointmentTypeChanged.current ||
        !appointmentTypeInputProps.value ||
        !selectedOptionalExtras
      )
        return;
    }, [appointmentTypeInputProps.value]);

    useEffect(() => {
      if (!appointmentTypeDetail) {
        return;
      }

      const service = basketStoreServices.find(service => service.key === selectedServiceBasketKey);

      optionalExtrasHelpers.setValue(appointmentTypeDetail?.optionalExtraRequired);
      setSelectedServicePrice.setValue((service?.overriddenPrice ?? service?.price) ?? 0);

      setService(service => ({
        ...service,
        appointmentTypeId: appointmentTypeDetail.id,
        price: appointmentTypeDetail?.price ?? 0,
        overriddenPrice: null,
        key: selectedServiceBasketKey,
      }));
    }, [appointmentTypeDetail]);

    useEffect(() => {
      if (shouldUpdateBasket) {
        handleSaveService();
      }
    }, [shouldUpdateBasket]);

    const availableOptionalExtras =
      appointmentTypes.find((_) => _.id == appointmentTypeInputProps.value)
        ?.availableOptionalExtras || [];

    const [service, setService] = useState<Jobs>(
      {
        appointmentTypeId: appointmentTypeInputProps.value,
        optionalExtras: selectedOptionalExtras,
        price: appointmentTypeDetail?.price ?? 0,
        overriddenPrice: null,
        key: selectedServiceBasketKey,
      }
    );

    const shallowCopyService = (service: Jobs): Jobs => {
      const serviceCopy = { ...service };
      serviceCopy.overriddenPrice = overriddenTotalPrice ?? null;

      serviceCopy.optionalExtras = selectedOptionalExtras.map((extra) => {
        const t = availableOptionalExtras.find(_ => _.id === extra.optionalExtraId);
        const extraCopy = { ...extra };
        extraCopy.price = t ? t.price : 1;
        return extraCopy;
      });

      return serviceCopy;
    };

    const calculateServiceTotal = (service: Jobs) => {
      let serviceTotal = 0;
      const isServicePriceOverridden = service.overriddenPrice != null;

      service.optionalExtras.forEach(extra => {
        serviceTotal += extra.price * extra.quantity;
      });

      return isServicePriceOverridden ? service.overriddenPrice ?? 0 : serviceTotal + (service.price ?? 0);
    };

    const handleOnPriceOverride = (override: number) => {
      setService(service => ({
        ...service,
        overriddenPrice: override,
        key: selectedServiceBasketKey
      }));
    };

    const handleSaveService = () => {
      if (requiresExtras.value && selectedOptionalExtras.length === 0) {
        setSelectedOptionalExtras.setTouched(true, true);
        return;
      }
      setSelectedOptionalExtras.setTouched(false, false);

      if (selectedService <= 0) {
        return;
      }

      const serviceCopy = shallowCopyService(service);
      const serviceTotal = calculateServiceTotal(serviceCopy);

      if (isUpdatingExistingService) {
        const services = basketStoreServices.filter(service => service.key !== serviceCopy.key);
        services.push(serviceCopy);
        dispatch(actions.basket.setServices(services));
      } else {
        dispatch(actions.basket.addService(serviceCopy));
      }

      dispatch(actions.basket.setBasketTotal(basketStoreTotal + serviceTotal));
      setFirstJobAppointmentTypeId(0);
      setSelectedOptionalExtras.setValue([]);
      setService(job);
      setIsUpdatingExistingService(false);
    };

    const showExtras = () => {
      return ![-1, 0, null].includes(appointmentTypeInputProps.value);
    };

    return (
      <>
        <InformationTextBox>Please select booking details:</InformationTextBox>
        <div className={classes.stepContainer}>
          <h3 className={classes.stepContainerTitle}>
            Service{" "}
            {priceHiddenFromResource ? <></> : vatRate > 0 && `(ex. VAT)`}
          </h3>
          <AppFormikComboBox
            options={appointmentTypeOptions}
            placeholder="Search for a service"
            name="firstJobAppointmentTypeId"
          />

          {/*
            HACK
              - toggling the display between none and block allows this component to update
                preventing a weird issue of selected optional extras persisting between service selections
         */}
          <div id="optional-extra-selection" className={showExtras() ? `${classes.block}` : `${classes.hidden}`}>
            <BookingFormOptionalExtraSelection
              initialExtras={initialExtras}
              manualOverride={manualOverride}
              setManualOverride={setManualOverride}
              initialValues={initialValues}
            />
          </div>

          {![-1, 0, null].includes(appointmentTypeInputProps.value) && (
            <>
              <div>
                <div className={classes.twoColContainer}>
                  {priceHiddenFromResource ? (
                    <></>
                  ) : (
                    <BookingFormTotalPrice
                      paymentStatuses={
                        paymentStatuses ? paymentStatuses.content : []
                      }
                      initialValues={initialValues}
                      onPriceOverride={handleOnPriceOverride}
                      isUpdatingExistingService={isUpdatingExistingService}
                    />
                  )}
                  <BookingFormPaymentStatus
                    paymentStatuses={
                      paymentStatuses ? paymentStatuses.content : []
                    }
                    isLoading={paymentStatusesLoading}
                  />
                </div>
              </div>
            </>
          )}
          {isEdit && (
            <>
              <br />
              <BookingStatus />
            </>
          )}
        </div>
        {appointmentTypeInputProps.value > 0 &&
          <div className={classes.actions}>
            <AppButton onClick={handleSaveService}>
              Save Service
            </AppButton>
          </div>
        }
      </>
    );
  };


export default BookingFormAppointmentTypeSelection;
