import { useField } from "formik";
import { FormikInputPropsKeys } from "./common";
import AppTagSelect, { AppTagSelectProps } from "./AppTagSelect";

export interface AppFormikTagSelectProps<T>
  extends Omit<AppTagSelectProps<T>, FormikInputPropsKeys> {
  name: string;
}

const AppFormikTagSelect = <T extends { id: number }>({
  name,
  label,
  ...restOfProps
}: AppFormikTagSelectProps<T>) => {
  const [fieldProps, fieldMeta, { setValue }] = useField(name);
  const isError = fieldMeta.touched && !!fieldMeta.error;
  const helperText = fieldMeta.touched ? fieldMeta.error : undefined;

  const handleChange = (tags: T[]) => {
    setValue(tags);
  };

  return (
    <AppTagSelect
      {...restOfProps}
      {...fieldProps}
      error={isError}
      helperText={helperText}
      onChange={handleChange}
      label={label}
    />
  );
};

export default AppFormikTagSelect;
