import {
  makeStyles,
  Menu,
  InputAdornment,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { useState, MouseEvent } from "react";
import AppButton from "../AppButton";
import AppCheckbox from "../AppForm/AppCheckbox";
import AppTextField from "../AppForm/AppTextField";
import { useWindowSize } from "../MainLayout/WindowSize";
import { CALENDAR_VIEWS, useCalendar } from "./AppCalendarContext";
import FilterIcon from "./assets/FilterIcon";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    zIndex: 1,
  },
  actionBtn: {
    borderRadius: 0,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.backgrounds.blue,
    fontWeight: 600,
    height: "48px",
    border: "none",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      boxShadow: "none",
    },
    marginLeft: "2rem",
  },
  search: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "& .MuiInput-underline:after": {
      border: "none",
    },
  },
  resourceList: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    maxHeight: 160,
    overflowX: "hidden",
    overflowY: "auto",
    scrollbarWidth: "thin" /* Firefox */,
    scrollbarColor: theme.palette.grey[500],
    "-ms-overflow-style": "none" /* Internet Explorer 10+ */,
    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px",
      padding: "4px",
    },
    "&::-webkit-scrollbar-track": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[500],
      borderRadius: "16px",
      width: "8px",
    },
    "& > label": {
      display: "flex",
    },
  },
  menu: {
    "& ul": {
      paddingTop: 0,
    },
    "& > .MuiPopover-paper": {
      borderRadius: "0 !important",
      backgroundColor: theme.palette.backgrounds.grey,
      maxWidth: "100vw",
      marginTop: theme.spacing(2),
      boxShadow: "0px 3px 6px #00000029",
    },
  },
  clearBtn: {
    boxShadow: "none",
    marginBottom: theme.spacing(1),
  },
  filterWarning: {
    backgroundColor: theme.palette.grey[200],
    marginTop: "20px",
    padding: "10px",
  },
  filterBtn: {
    width: 48,
    height: 48,
    background: "transparent",
    boxShadow: "none",
    borderRadius: 0,
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: theme.spacing(2),
    "& svg": {
      fill: "url(#BrandGradient)",
      width: 36,
      height: 36,
    },
  },
  filterBtnOpen: {
    "& svg": {
      fill: "url(#BrandGradientActive)",
    },
  },
  menuTitle: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    "& h3": {
      margin: 0,
    },
  },
  filterTitle: {
    fontWeight: theme.typography.fontWeightBold,
    textTransform: "none",
  },
  noResultFoundMessage: {
    fontSize: theme.typography.body2.fontSize,
    overflowWrap: "break-word",
  },
  menuPaperStyle: {
    overflow: "visible",
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      marginLeft: -0.5,
      marginRight: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: "calc(50% - 8px)",
      width: 16,
      height: 16,
      backgroundColor: theme.palette.backgrounds.grey,
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
      [theme.breakpoints.up("sm")]: {
        right: 100,
      },
    },
  },
}));

export const AppCalendarResourceFilter: React.FC = () => {
  const classes = useStyles();
  const {
    selectedView,
    allResources,
    selectedResources,
    resourceLimit,
    handleResourceChange,
    setSelectedResources,
    removeStoredResource,
    updateStoredResourceTimeSelected,
  } = useCalendar();
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null);
  const [resourceSearchValue, setResourceSearchValue] = useState("");

  const filterMenuIsOpen = Boolean(menuEl);

  const handleMenuClose = () => {
    setMenuEl(null);
    setResourceSearchValue("");
  };

  const handleResourceSelect = (resourceId: string) => {
    const selected = [...selectedResources];
    if (selected.includes(resourceId)) {
      removeStoredResource(resourceId);
      handleResourceChange(selected.filter((id) => id !== resourceId));
    } else if (resourceLimit === 1) {
      setSelectedResources([resourceId]);
    } else {
      updateStoredResourceTimeSelected(resourceId);
      selected.push(resourceId);
      handleResourceChange(selected);
    }
  };

  const filteredResourceList = allResources.filter((resource) =>
    resourceSearchValue !== ""
      ? resource.name.toLowerCase().includes(resourceSearchValue.toLowerCase())
      : resource
  );
  const { width } = useWindowSize();

  return (
    <div className={classes.container}>
      <IconButton
        aria-label="Filter by mobile worker name"
        className={`${classes.filterBtn} ${
          filterMenuIsOpen && classes.filterBtnOpen
        }`}
        onClick={(e: MouseEvent<HTMLButtonElement>) =>
          setMenuEl(e.currentTarget)
        }
      >
        <FilterIcon />
      </IconButton>
      <Menu
        anchorEl={menuEl}
        getContentAnchorEl={null}
        open={Boolean(menuEl)}
        onClose={handleMenuClose}
        className={classes.menu}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        disableEnforceFocus
        PaperProps={{
          className: classes.menuPaperStyle,
        }}
      >
        {/* Empty span to prevent MUI from putting tabIndex on the menuTitle div */}
        <span></span>
        <div className={classes.menuTitle}>
          <Typography variant="h6" className={classes.filterTitle}>
            Filter
          </Typography>
        </div>
        <div className={classes.search}>
          <AppTextField
            placeholder="Search for a mobile worker..."
            value={resourceSearchValue}
            onChange={(e) => setResourceSearchValue(e.currentTarget.value)}
            focused
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={classes.resourceList}>
          {filteredResourceList.length > 0 ? (
            filteredResourceList.map((resource) => (
              <AppCheckbox
                key={resource.id}
                label={resource.name}
                checked={selectedResources.includes(resource.id ?? "")}
                onChange={() => handleResourceSelect(resource.id ?? "")}
                disabled={
                  selectedResources.length >= resourceLimit &&
                  resourceLimit > 1 &&
                  !selectedResources.includes(resource.id ?? "")
                }
                color="primary"
              />
            ))
          ) : (
            <>
              <p className={classes.noResultFoundMessage}>
                No results found for &quot;<b>{resourceSearchValue}</b>&quot;
              </p>
              <AppButton
                className={classes.clearBtn}
                color="default"
                size="small"
                onClick={() => setResourceSearchValue("")}
              >
                Clear
              </AppButton>
            </>
          )}
        </div>
        {selectedView === CALENDAR_VIEWS.WEEK_VIEW && width > 768 && (
          <div className={classes.filterWarning}>
            To select multiple mobile workers, change to DAY view
          </div>
        )}
      </Menu>
    </div>
  );
};
