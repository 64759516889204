import { CustomerAddress } from "./Customers";
import { Tag } from "./Tag";

export interface BookingCore {
  id: number;
  bookingStatus: number;
  paymentStatusId: number;
  start: Date;
  end: Date;
  notes: string;
  description: string;
  resourceName: string;
}

export interface BookingSummary extends BookingCore {
  bookingStatus: number;
  bookingStatusText: string;
  customerFirstName: string;
  customerLastName: string;
  customerAddress: CustomerAddress;
  customerPhoneNumber: string;
  firstJobAppointmentTypeName: string;
  firstJobAppointmentTypePrice: number;
  firstJobOptionalExtras: OptionalExtra[];
  tags?: Tag[];
  overriddenTotalPrice: number | null;
  totalPrice: number;
  resourceId: number;
  resourceColour: string;
  travelTimeToNextBookingMinutes?: number;
  taxRate: number;
  totalPriceExVat: number;
  recurringBookingId: number | null;
  jobs: Jobs[];
}

export interface OptionalExtra {
  id: number;
  name: string;
  price: number;
  quantity?: number;
}

export type BookedExtra = {
  optionalExtraId: number;
  quantity: number;
  price: number;
};

//New Object added to Jobs basket each time new service is selected
export type Jobs = {
  /**
   * A unique identifier for the Job
   *  This is used to determine which Job to work with when there are multiple of the same Job and therefore
   *  the appointmentTypeId cannot be relied upon to determine which Job to work with
   */
  key?: number;
  appointmentTypeId: number;
  optionalExtras: BookedExtra[];
  price: number;
  overriddenPrice: number | null;
};

export const job: Jobs = {
  appointmentTypeId: 0,
  optionalExtras: [],
  price: 0,
  overriddenPrice: null,
};

export type Image = {
  id: number;
  thumbnailBase64: string;
  fullImageName: string,
  fileSize: number,
  createdAt: Date,
  createdBy: string
};

export type BookingDocument = {
  id: number;
  fileName: string;
  fileURL: string;
  base64: string,
  createdAt: Date,
  createdBy: string,
};

export interface BookingDetails extends BookingCore {
  customerId: number;
  customerAddressId: number;
  customerAddress: CustomerAddress;
  customerPhoneNumber: string;
  resourceId: number;
  firstJobAppointmentTypeId?: number;
  firstJobOptionalExtraIds: number[];
  firstJobOptionalExtras: OptionalExtra[];
  jobs: Jobs[];
  tags?: Tag[];
  totalPrice: number;
  overriddenTotalPrice?: number;
  isEcoSlot: boolean;
  images: Image[];
  documents: BookingDocument[];
}

export interface CreateBookingDto {
  resourceId: number;
  start: Date;
  end: Date;
  notes: string;
  description: string;
  customerId: number;
  customerAddressId: number;
  firstJobAppointmentTypeId?: number;
  firstJobOptionalExtras: BookedExtra[];
  jobs: Jobs[];
  tagIds?: number[];
  paymentStatusId: number;
  overriddenTotalPrice?: number;
  muteConfirmationEmail?: boolean;
}

export interface UpdateBookingDto extends CreateBookingDto {
  id: number;
  bookingStatus?: number;
}

export interface CreateWidgetBookingDto {
  resourceId: number;
  start: Date | null;
  end: Date | null;
  tags?: Tag[];
  customerAddress: {
    addressLine1: string;
    addressLine2?: string;
    addressLine3?: string;
    town: string;
    postcode: string;
  };

  customer: {
    firstName: string;
    lastName: string;
    company: string;
    email: string;
    phoneNumber: string;
    termsAccepted: boolean;
    marketingAccepted: boolean;
  };
  /**
   * jobs represents the appointmentTypes json body parameter
   */
  jobs: Jobs[];
  /**
   * Obsolete parameters from single booking functionality
   *  leaving them here for now to avoid having to refactor lots of this
   *  TODO - Remove obsolete parameters once everything works
   */
  firstJobAppointmentTypeId?: number;
  firstJobOptionalExtras: BookedExtra[];
}

export interface CreateInviteToBookDto {
  resourceId: number;
  inviteGUID: string;
  start: Date | null;
  end: Date | null;
  firstJobOptionalExtras: BookedExtra[];
  tags?: Tag[];
  customerId: number;
  customerAddressId: number;
}

export interface InviteToBookDto {
  id: number;
  inviteGUID: string;
  customerPostcode: string;
  appointmentId: number;
  appointmentName: string;
  appointmentDescription: string;
  appointmentPrice: number;
  bookingId: number;
  customerId: number;
  customerAddressId: number;
}
