import { useEffect, useState } from "react";
import { getBookableSlotsWidget } from "../../api/widget";
import { HttpClientFailureResponse } from "../../api";
import {
  ApiBookableSlotDto,
  ApiBookableSlotsWidgetParameters,
} from "../../api/models/BookableSlots";
import { useSelector } from "react-redux";
import { selectors } from "../../store";

export type UseGetWidgetBookableSlotsReturn = {
  isLoading: boolean;
  httpError?: HttpClientFailureResponse;
  isError: boolean;
  slots: ApiBookableSlotDto[];
  firstAvailableSlots: ApiBookableSlotDto[];
  setParameters: (parameters: ApiBookableSlotsWidgetParameters) => void;
};

const useGetWidgetBookableSlots = (
  initialParameters: ApiBookableSlotsWidgetParameters
): UseGetWidgetBookableSlotsReturn => {
  const [parameters, setParameters] =
    useState<ApiBookableSlotsWidgetParameters>(initialParameters);
  parameters.jobs = useSelector(selectors.basket.services);
  const [isLoading, setIsLoading] = useState(true);
  const [httpError, setHttpError] = useState<
    HttpClientFailureResponse | undefined
  >(undefined);
  const [slots, setSlots] = useState<ApiBookableSlotDto[]>([]);
  const [firstAvailableSlots, setFirstAvailableSlots] = useState<
    ApiBookableSlotDto[]
  >([]);

  useEffect(() => {
    const getSlots = async () => {
      setIsLoading(true);
      setHttpError(undefined);
      const hasDate = parameters.date !== undefined;
      const apiResponse = await getBookableSlotsWidget(parameters);
      if (apiResponse.isError) {
        setHttpError(apiResponse);
      } else {
        setSlots(apiResponse.content.content);
      }
      if (hasDate && !httpError && slots.length === 0) {
        const findFirstApiResponse = await getBookableSlotsWidget({
          ...parameters,
          date: undefined,
        });
        if (findFirstApiResponse.isError) {
          setHttpError(findFirstApiResponse);
        } else {
          setFirstAvailableSlots(findFirstApiResponse.content.content);
        }
      }
      setIsLoading(false);
    };
    getSlots();
  }, [parameters]);

  return {
    isLoading,
    httpError,
    isError: !!httpError,
    slots,
    firstAvailableSlots,
    setParameters,
  };
};

export default useGetWidgetBookableSlots;