import { AppBar, makeStyles, Tab, Tabs } from "@material-ui/core";

interface AppTabsProps {
  tab: number;
  tabs: string[];
  handleChange: (val: number) => void;
}

const useStyles = makeStyles((theme) => ({
  tabBar: {
    backgroundColor: theme.palette.common.white,
    boxShadow: "none",
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  tab: {
    flex: "none",
    paddingLeft: 0,
    paddingRight: 0,
    textTransform: "none",
    "&:not(:last-of-type)": {
      marginRight: theme.spacing(4),
    },
  },
}));

const a11yProps = (index: number) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
};

const AppTabs: React.FC<AppTabsProps> = ({ tab, tabs, handleChange }) => {
  const classes = useStyles();
  return (
    <AppBar position="relative" className={classes.tabBar} color="default">
      <Tabs
        value={tab}
        onChange={(e, val) => handleChange(val)}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="Customer detail tabs"
      >
        {tabs.map((tab, index) => (
          <Tab
            className={classes.tab}
            key={tab}
            label={tab}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
    </AppBar>
  );
};

export default AppTabs;
