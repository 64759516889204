import { Form, useField, useFormikContext } from "formik";
import { useEffect, useState, useCallback } from "react";
import { actions, selectors, useDispatchEffect } from "../../store";
import BookingFormAppointmentTypeSelection from "./BookingFormAppointmentTypeSelection.tsx/BookingFormAppointmentTypeSelection";
import BookingFormCustomerSelection from "./BookingFormCustomerSelection/BookingFormCustomerSelection";
import BookingFormNavigation from "./BookingFormNavigation";
import BookingFormNotes from "./BookingFormNotes/BookingFormNotes";
import BookingFormSlotSelection from "./BookingFormSlotSelection/BookingFormSlotSelection";
import { BookingFormValues } from "./BookingFormValues";
import { useDispatch, useSelector } from "react-redux";
import BookingFormCreateReccurance from "./BookingFormCreateReccurance/BookingFormCreateReccurance";
import BookingFormRecurringSlotSelection from "./BookingFormRecurringSlotSelection/BookingFormRecurringSlotSelection";
import BookingFormCustomerCreation from "./BookingFormCustomerCreation.tsx/BookingFormCustomerCreation";
import BookingFormCustomerAddressCreation from "./BookingFormCustomerCreation.tsx/BookingFormCustomerAddressCreation";

import { getAllTagTypes } from "../../api/tags";
import { useHttpRequest } from "../../api";

import BookingBasket from "../../components/bookings/basket/AdminBookingBasket";
import { makeStyles } from "@material-ui/core";
import { parseOptionalExtras } from "../utilities";
import { BookedExtra, Jobs } from "../../models/Booking";
import ResourceFormFields from "../../resources/Form/ResourceFormFields";
import { Tag } from "../../models/Tag";
import { getCustomerAddresses } from "../../api/customers";
import { errorToast } from "../../toast";

const useStyles = makeStyles((theme) => ({
  activeStepContent: {
    marginBottom: theme.spacing(2),
  },
  addressLine: {
    fontWeight: 400,
    paddingTop: theme.spacing(1),
    lineHeight: "18px",
  },
  tags: {
    marginBottom: theme.spacing(2),
    "& * label": {
      // marginBottom: theme.spacing(2),
      fontSize: "22px;",
      fontWeight: "bold",
      color: "black",
      "& + div": {
        marginTop: theme.spacing(3)
      }
    },
  }
}));

interface BookingFormProps {
  activeStep: number;
  initialValues: BookingFormValues;
  isEdit?: boolean;
  initialCustomerId?: number;
  preSelectOverride?: boolean;
  onPreviousClick(isRecurring?: boolean): void;
  onClose(): void;
  handleEditBooking?: (bookingFormDetails: BookingFormValues) => Promise<void>;
  isCreateCustomer: boolean;
  setCreateCustomer: (createCustomer: boolean) => void;
  newCustomerId?: number;
}

const BookingForm: React.FC<BookingFormProps> = ({
  activeStep,
  initialValues,
  isEdit,
  preSelectOverride,
  onPreviousClick,
  onClose,
  handleEditBooking,
  setCreateCustomer,
  newCustomerId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getTagTypes = useCallback(() => getAllTagTypes(), []);
  const { result: tags, isLoading } = useHttpRequest(getTagTypes);

  useDispatchEffect(actions.appointmentTypes.fetchAll);
  const [manualResourceVisible, setManualResourceVisible] = useState<boolean>(
    preSelectOverride ?? false
  );

  const { values } = useFormikContext<BookingFormValues>();

  const [, , { setValue: setNewCustomerId }] = useField<number>("customerId");
  const [, , { setValue: setNewCustomerAddressId }] = useField<number>("customerAddressId");

  const setNewCustomerValues = (customerId: number) => {
    if (customerId <= 0) {
      return;
    }

    setNewCustomerId(customerId);

    getCustomerAddresses(customerId).then(response => {
      if (!response.isError) {
        setNewCustomerAddressId(response.content.content[0].id);
        dispatch(actions.customers.fetchCustomerAddresses(newCustomerId ?? 0));
      }
    }).catch(error => {
      console.log(error);
      errorToast("Error retrieving address information for newly created customer");
    });
  };

  useEffect(() => {
    if (newCustomerId != undefined && newCustomerId > 0) {
      console.log("New customer ID has been resolved");
      setNewCustomerValues(newCustomerId);
    }
  }, [newCustomerId]);

  const selectedAddress = useSelector(
    selectors.customers.addressById(values.customerAddressId)
  );

  useEffect(() => {
    selectedAddress &&
      dispatch(actions.appointmentTypes.fetchAll(selectedAddress.postcode));
  }, [selectedAddress]);

  // #region basket logic
  const basketStoreServices = useSelector(selectors.basket.services);
  const [shouldUpdateBasket, setShouldUpdateBasket] = useState<boolean>(false);

  const shouldRenderBasket = () => {
    return ([3, 6].includes(activeStep)) && basketStoreServices.length > 0; // && !isEdit;
  };

  const shouldRenderAddress = () => {
    return ![3].includes(activeStep);
  };
  // #endregion

  const shouldRenderTags = () => {
    return [3].includes(activeStep) && tags?.content.length != 0 && !isEdit;
  };

  const [shouldSave, setShouldSave] = useState<boolean>(false);

  const handleEdit = () => {
    setShouldUpdateBasket(true);
    setTimeout(() => {
      setShouldUpdateBasket(false);
      setShouldSave(true);
    }, 2);
  };

  useEffect(() => {
    if (shouldSave) {
      values.jobs = basketStoreServices;
      // this is the bit that triggers the save
      handleEditBooking && handleEditBooking(values);
    }
  }, [basketStoreServices.length, shouldSave]);

  const handleSubmit = () => {
    if (activeStep === 3) {
      setShouldUpdateBasket(true);
      setTimeout(() => setShouldUpdateBasket(false), 1000);
    }
  };

  const handleOnClose = () => {
    dispatch(actions.basket.resetBasket());
    onClose();
  };

  const handleOnPreviousClick = () => {
    setIsUpdatingExistingService(false);
    onPreviousClick(values.isRecurringBooking);
  };

  /**
   * These are used to trigger the BookingFormAppointmentTypeSelection component to re-render when editing a service
   */
  const [{ value: selectedService }, , { setValue: setFirstJobAppointmentTypeId },] = useField<number>("firstJobAppointmentTypeId");
  const [{ value: selectedOptionalExtras }, selectedOptionalExtrasMeta, { setValue: setSelectedOptionalExtras },] = useField<BookedExtra[]>("firstJobOptionalExtras");
  const [{ value: totalPrice }, , { setValue: setTotalPrice }] = useField<number>("totalPrice");
  const [{ value: overriddenTotalPrice }, , { setValue: setOverriddenTotalPrice }] = useField<number | undefined>("overriddenTotalPrice");
  const [isUpdatingExistingService, setIsUpdatingExistingService] = useState<boolean>(false);
  const [{ value: selectedServiceBasketKey }, , { setValue: setSelectedServiceBasketKey }] = useField<number>("selectedServiceBasketKey");
  const [{ value: selectedTags }, i, { setValue: setSelectedTags }] = useField<Tag[]>("tags");

  const handleEditService = (service: Jobs) => {
    setIsUpdatingExistingService(true);
    setSelectedServiceBasketKey(service.key ?? 0);
    setSelectedOptionalExtras(service.optionalExtras);
    setFirstJobAppointmentTypeId(service.appointmentTypeId);
    setTotalPrice(service.price);
    setOverriddenTotalPrice(service.overriddenPrice ?? undefined);
  };

  const activeStepFormContent = [
    <BookingFormCustomerSelection
      key="booking-form-step-0"
      readOnly={isEdit}
      setCreateCustomer={setCreateCustomer}
    />,
    <BookingFormCustomerCreation
      key="booking-form-step-1"
    />,
    <BookingFormCustomerAddressCreation
      key="booking-form-step-2"
    />,
    <BookingFormAppointmentTypeSelection
      key="booking-form-step-3"
      isEdit={isEdit}
      readOnly={isEdit && !!initialValues.firstJobAppointmentTypeId}
      initialExtras={parseOptionalExtras(
        initialValues.firstJobOptionalExtras ?? []
      )}
      manualOverride={manualResourceVisible}
      setManualOverride={setManualResourceVisible}
      initialValues={initialValues}
      newCustomerId={newCustomerId}
      shouldUpdateBasket={shouldUpdateBasket}
      isUpdatingExistingService={isUpdatingExistingService}
      setIsUpdatingExistingService={setIsUpdatingExistingService}
    />,
    <BookingFormSlotSelection
      key="booking-form-step-4"
      readOnly={isEdit}
      isEdit={isEdit}
      manualOverride={manualResourceVisible}
      setManualOverride={setManualResourceVisible}
      initialValues={initialValues}
    />,
    <BookingFormCreateReccurance
      key="booking-form-step-5"
      initialValues={initialValues}
      activeStep={activeStep}
    />,
    <BookingFormRecurringSlotSelection
      key="booking-form-step-6"
      manualOverride={manualResourceVisible}
      activeStep={activeStep}
    />,
    <BookingFormNotes key="booking-form-step-7" isEdit={isEdit} />,
  ];

  return (
    <>
      <Form>
        <BookingFormNavigation
          isEdit={isEdit}
          activeStep={activeStep}
          onPreviousClick={handleOnPreviousClick}
          onClose={handleOnClose}
          handleEdit={handleEdit}
          handleSubmit={handleSubmit}
        />
        <div className={classes.activeStepContent}>
          {activeStepFormContent[activeStep]}
        </div>
        {shouldRenderTags() &&
          <div className={classes.tags}>
            <ResourceFormFields.Tags
              tags={tags ? tags.content : []}
              tagMapper={(t) => ({
                value: t.id,
                label: `${t.name}: ${t.value}`,
              })}
              tagMatcher={(id) =>
                tags?.content.find((t) => t.id === id)
              }
              tagLabel={(t) => `${t.name}: ${t.value}`}
            />
          </div>
        }
        {shouldRenderBasket() &&
          <BookingBasket
            bookingDetails={initialValues}
            canRemoveServices={![6].includes(activeStep) && !isUpdatingExistingService}
            canEditServices={![6].includes(activeStep) && !isUpdatingExistingService}
            handleEditService={handleEditService}
            currentTaxRatePercentage={initialValues.taxRate}>
            {shouldRenderAddress() &&
              <p className={classes.addressLine}>
                {selectedAddress?.addressLine1}, {selectedAddress?.addressLine2 && `${selectedAddress?.addressLine2}, `}
                {selectedAddress?.addressLine3 && `${selectedAddress?.addressLine3}, `}
                {selectedAddress?.town}, {selectedAddress?.postcode}
              </p>
            }
          </BookingBasket>
        }
      </Form>
    </>
  );
};

export default BookingForm;
