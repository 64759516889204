import { HttpClientResponse } from "./common/httpClient";
import {
  ApiPaginatedResponse,
  ApiPaginationQueryParams,
  ApiStandardResponse,
  buildApiUrl,
  doErrorToastIfRequired,
  httpClient,
  mapHttpClientListResponse,
  mapHttpClientPaginatedResponse,
  mapHttpClientResponse,
} from "./common";
import {
  ApiResourceSummary,
  ApiResourceDetails,
  ApiCreateUpdateResourceDto,
  ApiUpdateResourceTimeOffDto,
  ApiResourceType,
} from "./models";
import {
  ResourceSummary,
  CreateUpdateResourceDto,
  UpdateResourceMeDto,
  ResourceDetails,
  ResourceType,
} from "../models/Resource";
import { ApiListResponse } from "./common/ApiListResponse";
import { mapFromApiResource } from "../modelMappings/Resource";
import { ApiResourceTimeOffDto } from "./models";

export const getPaginatedResources = async ({
  pageIndex: pageNumber,
  pageSize,
  searchTerm,
  sortBy,
}: ApiPaginationQueryParams): Promise<
  HttpClientResponse<ApiPaginatedResponse<ResourceSummary>>
> => {
  const queryParameters = [
    `pageNumber=${pageNumber}`,
    `pageSize=${pageSize}`,
    `searchTerm=${searchTerm}`,
    `sortBy=${sortBy}`,
  ];

  const url = buildApiUrl(
    `api-admin/paginated-resources?${queryParameters.join("&")}`
  );
  const response = await httpClient.getRequest<
    ApiPaginatedResponse<ApiResourceSummary>
  >(url);

  doErrorToastIfRequired(response);

  return mapHttpClientPaginatedResponse(response, (m) => m);
};

export const getAllResources = async (): Promise<
  HttpClientResponse<ApiListResponse<ResourceSummary>>
> => {
  const url = buildApiUrl("api-admin/resources");
  const response = await httpClient.getRequest<
    ApiListResponse<ApiResourceSummary>
  >(url);

  doErrorToastIfRequired(response);

  return mapHttpClientListResponse(response, (m) => m);
};

export const getAvailableResources = async (
  appointmentTypeIds: string
): Promise<HttpClientResponse<ApiListResponse<ResourceSummary>>> => {
  const url = buildApiUrl(
    `api-admin/resources?AppointmentTypeIds=${appointmentTypeIds}`
  );
  const response = await httpClient.getRequest<
    ApiListResponse<ApiResourceSummary>
  >(url);

  doErrorToastIfRequired(response);

  return mapHttpClientListResponse(response, (m) => m);
};

export const getResourceById = async (
  id: number,
  includeOutcodeGeoJson?: boolean
): Promise<HttpClientResponse<ResourceDetails>> => {
  const query = includeOutcodeGeoJson ? "?includeOutcodeGeoJson=true" : "";
  const url = buildApiUrl(`api-admin/resources/${id}${query}`);
  const response = await httpClient.getRequest<
    ApiStandardResponse<ApiResourceDetails>
  >(url);

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, mapFromApiResource);
};

export const getAllResourceTypes = async (): Promise<
  HttpClientResponse<ApiListResponse<ResourceType>>
> => {
  const url = buildApiUrl("api-admin/resource-types");
  const response = await httpClient.getRequest<
    ApiListResponse<ApiResourceType>
  >(url);

  doErrorToastIfRequired(response);

  return mapHttpClientListResponse(response, (m) => m);
};

export const getResourceMe = async (): Promise<
  HttpClientResponse<ResourceDetails>
> => {
  const url = buildApiUrl("api-resource/me");
  const response = await httpClient.getRequest<
    ApiStandardResponse<ApiResourceDetails>
  >(url);

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, mapFromApiResource);
};

export const createResource = async (
  createResourceDto: ApiCreateUpdateResourceDto
): Promise<HttpClientResponse<void>> => {
  const url = buildApiUrl("api-admin/resources");
  const response = await httpClient.postRequest<ApiStandardResponse<void>>(
    url,
    createResourceDto
  );

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, (m) => m);
};

export const editResource = async (
  updateResourceDto: CreateUpdateResourceDto
): Promise<HttpClientResponse<void>> => {
  const url = buildApiUrl("api-admin/resources");
  const response = await httpClient.putRequest<ApiStandardResponse<void>>(
    url,
    updateResourceDto
  );

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, (m) => m);
};

export const editResourceMeWorkingHours = async (
  updateResourceDto: UpdateResourceMeDto
): Promise<HttpClientResponse<void>> => {
  const url = buildApiUrl("api-resource/me/working-hours");
  const response = await httpClient.putRequest<ApiStandardResponse<void>>(
    url,
    updateResourceDto
  );

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, (m) => m);
};

export const addResourceTimeOff = async (
  timeOffTimes: ApiResourceTimeOffDto
): Promise<HttpClientResponse<void>> => {
  const url = buildApiUrl("api-resource/time-off");
  const response = await httpClient.postRequest<ApiStandardResponse<void>>(
    url,
    timeOffTimes
  );

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, (m) => m);
};

export const addResourceTimeOffAdmin = async (
  resourceId: number,
  timeOffTimes: ApiResourceTimeOffDto
): Promise<HttpClientResponse<void>> => {
  const url = buildApiUrl(`api-admin/resources/${resourceId}/time-off`);
  const response = await httpClient.postRequest<ApiStandardResponse<void>>(
    url,
    timeOffTimes
  );

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, (m) => m);
};

export const updateResourceTimeOff = async (
  timeOffTimes: ApiUpdateResourceTimeOffDto
): Promise<HttpClientResponse<void>> => {
  const url = buildApiUrl("api-resource/time-off");
  const response = await httpClient.putRequest<ApiStandardResponse<void>>(
    url,
    timeOffTimes
  );

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, (m) => m);
};

export const updateResourceTimeOffAdmin = async (
  timeOffTimes: ApiUpdateResourceTimeOffDto
): Promise<HttpClientResponse<void>> => {
  const url = buildApiUrl("api-admin/resources/time-off");
  const response = await httpClient.putRequest<ApiStandardResponse<void>>(
    url,
    timeOffTimes
  );

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, (m) => m);
};

export const deleteResourceTimeOff = async (
  timeOffId: number
): Promise<HttpClientResponse<void>> => {
  const url = buildApiUrl(`api-resource/time-off/${timeOffId}`);
  const response = await httpClient.deleteRequest<ApiStandardResponse<void>>(
    url
  );

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, (m) => m);
};

export const deleteResourceTimeOffAdmin = async (
  timeOffId: number
): Promise<HttpClientResponse<void>> => {
  const url = buildApiUrl(`api-admin/time-off/${timeOffId}`);
  const response = await httpClient.deleteRequest<ApiStandardResponse<void>>(
    url
  );

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, (m) => m);
};
