import { Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import ServiceForm from "./ServiceForm";

import {
  defaultServiceFormValues,
  ServiceFormPageValidationSchemas,
  ServiceFormValues,
} from "./ServiceFormValues";

interface ServiceFormContainerProps {
  initialValues?: ServiceFormValues;
  onSubmit: (resource: ServiceFormValues) => Promise<void>;
}

const ServiceFormContainer: React.FC<ServiceFormContainerProps> = ({
  onSubmit,
  initialValues = defaultServiceFormValues,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const isLastStep = activeStep === ServiceFormPageValidationSchemas.length - 1;

  const handlePreviousClick = () => {
    setActiveStep((previousActiveStep) => previousActiveStep - 1);
  };

  const handleSubmit = async (
    values: ServiceFormValues,
    { setFieldTouched }: FormikHelpers<ServiceFormValues>
  ) => {
    if (!isLastStep) {
      Object.keys(defaultServiceFormValues).forEach((key) =>
        setFieldTouched(key, false)
      );
      setActiveStep((previousActiveStep) => previousActiveStep + 1);
      return;
    }

    await onSubmit(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ServiceFormPageValidationSchemas[activeStep]}
      onSubmit={handleSubmit}
    >
      <ServiceForm
        activeStep={activeStep}
        isLastStep={isLastStep}
        onPreviousClick={handlePreviousClick}
      />
    </Formik>
  );
};

export default ServiceFormContainer;
