import * as Yup from "yup";

export type LogInFormValues = {
  email: string;
  password: string;
};

export const defaultLogInFormValues: LogInFormValues = {
  email: "",
  password: "",
};

export const logInFormValidationSchema: Yup.SchemaOf<LogInFormValues> =
  Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("Please provide an email address."),
    password: Yup.string().required("Please provide a password."),
  });
