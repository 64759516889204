import { addMinutes, startOfDay } from "date-fns";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { getRecurringBookableSlots } from "../../../api/bookableSlots";
import { ApiBookableSlotDto } from "../../../api/models/BookableSlots";
import { getResourceById } from "../../../api/resources";
import {
  addXHoursToDate,
  addXMinutesToDate,
  isDateUTC as isDateUTC,
} from "../../../dateFormatters";
import {
  RecurringBookableSlot,
  RecurringBookableSlotWithSelection,
} from "../../../models/RecurringBookableSlot";
import { ResourceDetails } from "../../../models/Resource";
import { BookingFormValues } from "../BookingFormValues";
import {useSelector} from "react-redux";
import {selectors} from "../../../store";

export const useBookingFormRecurringSlotSelection = (
  formValues: BookingFormValues,
  postcode: string,
  isManual: boolean,
  recurringNumberOfOccurances: number,
  recurringIntervals: number,
  setRecurringBookableSlots: (
    recurringBookableSlots: RecurringBookableSlotWithSelection[]
  ) => void,
  resourceId?: number
) => {
  const [recurringSchedule, setRecurringSchedule] = useState<
    RecurringBookableSlot[]
  >([]);
  const basketStoreServices = useSelector(selectors.basket.services);
  const [resource, setResource] = useState<ResourceDetails>();
  const [isLoading, setIsLoading] = useState(true);
  const [editId, setEditId] = useState<string>();

  const fetchResource = async () => {
    setIsLoading(true);

    if (!resourceId) {
      return;
    }

    const response = await getResourceById(resourceId);

    if (!response.isError) {
      setResource(response.content);
    }

    setIsLoading(false);
  };

  const fetchRecurringBookableSlots = async () => {
    setIsLoading(true);

    const result = await getRecurringBookableSlots({
      initialSlot: {
        resourceId: formValues.resourceId,
        resourceName: "",
        start: addMinutes(
            startOfDay(new Date(formValues.date)),
            formValues.start
        ),
        durationMinutes: formValues.end - formValues.start,
        isEcoSlot: formValues.isEcoSlot,
        travelMinutesSaved: 0,
      },
      periodWeeks: formValues.recurringNumberOfWeeksBetweenBookings || 0,
      repetitions: formValues.recurringNumberOfOccurances || 0,
      postcode: postcode,
      appointmentTypes: basketStoreServices,
    });

    if (!result.isError) {
      const recurringBookableSlotsWithSelection = result.content.content.map(
        (r) => ({
          ...r,
          isSelected: r.noSlotsDate === null,
        })
      );

      setRecurringBookableSlots(recurringBookableSlotsWithSelection);
      setRecurringSchedule(result.content.content);
      setIsLoading(false);
    }
  };

  const handleCheckboxToggle = (id: string) => {
    setRecurringBookableSlots(
      formValues.recurringBookableSlots.map((r) => {
        const isTargetSlot = r.id === id;
        return {
          ...r,
          isSelected: isTargetSlot ? !r.isSelected : r.isSelected,
        };
      })
    );
  };

  const handleSlotEdit = (bookableSlot: ApiBookableSlotDto) => {
    const schedule = [...formValues.recurringBookableSlots];
    schedule.forEach((s) => {
      if (s.id === editId) {
        const originalStart = s.start;
        s.bookableSlot = { ...bookableSlot };
        s.noSlotsDate = undefined;
        s.start = new Date(bookableSlot.start).toISOString();
        s.end = addMinutes(
          new Date(bookableSlot.start),
          bookableSlot.durationMinutes
        ).toISOString();
        s.isSelected = true;
      }
    });

    setRecurringBookableSlots(schedule);
    setRecurringSchedule(schedule);
    setEditId(undefined);
  };

  const createManualDateTimeSlots = () => {
    setIsLoading(true);

    const initialManualBookingSlotEntry: RecurringBookableSlotWithSelection = {
      id: uuid(),
      bookableSlot: {
        resourceId: formValues.resourceId,
        resourceName: "",
        start: addMinutes(
          startOfDay(new Date(formValues.date)),
          formValues.start
        ),
        durationMinutes: formValues.end - formValues.start,
        isEcoSlot: formValues.isEcoSlot,
        travelMinutesSaved: 0,
      },
      requiresAttention: false,
      start: isManual
        ? addMinutes(
            startOfDay(new Date(formValues.date)),
            formValues.start
          ).toISOString()
        : formValues.date.toISOString(),
      end: isManual
        ? addMinutes(
            startOfDay(new Date(formValues.date)),
            formValues.end
          ).toISOString()
        : addXMinutesToDate(
            formValues.end - formValues.start,
            formValues.date
          ).toISOString(),
      isSelected: true,
    };

    const manualRecurringSchedule: RecurringBookableSlotWithSelection[] = [
      initialManualBookingSlotEntry,
      ...Array.from(Array(recurringNumberOfOccurances).keys()).map((i) => {
        let startDate = addMinutes(
          addXHoursToDate(
            (i + 1) * (recurringIntervals * 7) * 24,
            startOfDay(new Date(formValues.date))
          ),
          formValues.start
        );

        if (!isDateUTC(formValues.date) && isDateUTC(startDate)) {
          startDate = addXMinutesToDate(60, startDate);
        } else if (isDateUTC(formValues.date) && !isDateUTC(startDate)) {
          startDate = addXMinutesToDate(-60, startDate);
        }

        return {
          id: uuid(),
          bookableSlot: {
            resourceId: formValues.resourceId,
            resourceName: "",
            start: addMinutes(
              addXHoursToDate(
                (i + 1) * (recurringIntervals * 7) * 24,
                startOfDay(new Date(formValues.date))
              ),
              formValues.start
            ),
            durationMinutes: formValues.end - formValues.start,
            isEcoSlot: formValues.isEcoSlot,
            travelMinutesSaved: 0,
          },
          requiresAttention: false,
          start: startDate.toISOString(),
          end: addXMinutesToDate(
            formValues.end - formValues.start,
            startDate
          ).toISOString(),
          isSelected: true,
        };
      }),
    ];

    setRecurringBookableSlots(manualRecurringSchedule);
    setIsLoading(false);
  };

  useEffect(() => {
    const recurringBookableSlotsAreEmpty =
      formValues.recurringBookableSlots.length === 0;

    if (recurringBookableSlotsAreEmpty) {
      if (isManual) {
        createManualDateTimeSlots();
      } else {
        fetchRecurringBookableSlots();
      }
    }
  }, []);

  //TODO: Test to see if this actually does anything. I dont think it does but ran into the timezone bug due to being on a different day than the UK - Daniel

    useEffect(() => {
      const basketChange =
        basketStoreServices.length;

      console.log(basketChange);

      if (basketChange > 2) {
        if (isManual) {
          createManualDateTimeSlots();
        } else {
          fetchRecurringBookableSlots();
        }
      }
    }, []);

  useEffect(() => {
    if (resourceId) {
      fetchResource();
    }
  }, [resourceId]);

  return {
    isLoading,
    recurringSchedule,
    editId,
    handleCheckboxToggle,
    handleSlotEdit,
    setEditId,
    resource,
  };
};
