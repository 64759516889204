import { makeStyles } from "@material-ui/core";
import { useRef, useState } from "react";
import AppChip from "../AppChip";
import AppCheckboxSelect from "./AppCheckboxSelect";
import AppFormError from "./AppFormError";
import { AppSelectOption } from "./AppSelect";

export interface AppServiceSelectProps<T> {
  value: T[];
  label: string;
  error?: boolean;
  helperText?: string;
  services: T[];
  pleaseSelectText: string;
  serviceMapper: (service: T) => AppSelectOption;
  serviceMatcher: (value: number) => T | undefined;
  serviceLabel: (service: T) => string;
  validation?: (value: string) => Promise<boolean> | boolean;
  onChange(services: T[]): void;
}

const useStyles = makeStyles((theme) => ({
  container: {},
  ServiceList: {
    listStyle: "none",
    margin: 0,
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.backgrounds.grey,
    minHeight: 100,
    maxHeight: 168,
    overflowY: "auto",
    flexWrap: "wrap",
    "& > li": {
      display: "inline-block",
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
}));

const AppServiceSelect = <T extends { id: number }>({
  value,
  label,
  pleaseSelectText,
  error,
  helperText,
  services,
  serviceMapper,
  serviceMatcher,
  serviceLabel,
  validation,
  onChange,
}: AppServiceSelectProps<T>) => {
  const classes = useStyles();
  const endOfList = useRef<HTMLLIElement>(null);
  const [inputError, setInputError] = useState<string>();
  const [isOpen, setIsOpen] = useState(false);

  const handleRemoveService = (Service: T) => {
    const newValue = [...value].filter((val) => val.id !== Service.id);
    onChange(newValue);
  };

  const handleSelectService = (v: number, option?: string) => {
    const Service = serviceMatcher(v);

    if (option === "clear") {
      onChange([]);
      return;
    }
    if (option === "all") {
      onChange(services);
      return;
    }
    if (option === "close") {
      setIsOpen(false);
      return;
    }

    if (Service && !value.map((v) => v.id).includes(Service.id)) {
      const ServiceList = [...value, Service];
      onChange(ServiceList);
    } else if (Service) {
      const newValue = [...value].filter((val) => val.id !== Service.id);
      onChange(newValue);
    }
  };

  const selectOptions = services.map(serviceMapper).map((t) => ({
    ...t,
    disabled: value.map((v) => v.id).includes(t.value),
  }));

  return (
    <div className={classes.container}>
      <AppCheckboxSelect
        onClick={() => !isOpen && setIsOpen(true)}
        value={-1}
        options={selectOptions}
        onChangeCallback={handleSelectService}
        label={label}
        pleaseSelectText={pleaseSelectText}
        className="services"
        open={isOpen}
      />
      <AppFormError show={!!inputError}>{inputError}</AppFormError>
      <ul className={classes.ServiceList}>
        {value.map((Service) => (
          <li key={Service.id}>
            <AppChip
              size="small"
              color="primary"
              label={serviceLabel(Service)}
              onDelete={() => handleRemoveService(Service)}
            />
          </li>
        ))}
        <li ref={endOfList}></li>
      </ul>
    </div>
  );
};

export default AppServiceSelect;
