import React, { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import AppForm from "../../components/AppForm";
import { AppButtons } from "../../components/AppButton";
import AppFormikSubmitButton from "../../components/AppForm/AppFormikSubmitButton";
import {
  LogInFormValues,
  defaultLogInFormValues,
  logInFormValidationSchema,
} from "./LogInFormValues";
import { LogInFields } from ".";
import { Grid, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { publicPaths } from "../../routes";
import AppFormError from "../../components/AppForm/AppFormError";

interface LogInFormProps {
  onSubmit: (
    loginDetails: LogInFormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => void;
  loginError: boolean;
  initialValues?: LogInFormValues;
}

const useStyles = makeStyles((theme) => ({
  formLink: {
    display: "inline-block",
    paddingTop: theme.spacing(2),
    color: theme.palette.grey[500],
    textDecoration: "none",
  },
  buttonGroup: {
    marginTop: theme.spacing(4),
  },
}));

const LogInForm: React.FC<LogInFormProps> = ({
  onSubmit,
  loginError,
  initialValues,
}) => {
  const classes = useStyles();
  const [submissionAttempted, setSubmissionAttempted] = useState(false);

  const handleSubmit = (
    values: LogInFormValues,
    { setSubmitting }: FormikHelpers<LogInFormValues>
  ) => {
    setSubmissionAttempted(true);
    onSubmit(values, setSubmitting);
  };

  return (
    <Formik
      initialValues={initialValues || defaultLogInFormValues}
      validationSchema={logInFormValidationSchema}
      validateOnBlur={submissionAttempted}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <Form>
        <AppForm>
          <LogInFields.Email autoFocus />
          <LogInFields.Password />
        </AppForm>
        <div className={classes.buttonGroup}>
          <AppButtons>
            <AppFormikSubmitButton fullWidth>Log In</AppFormikSubmitButton>
          </AppButtons>
        </div>
        <AppFormError show={loginError}>
          Email address and/or password are incorrect.
        </AppFormError>
        <Grid container>
          <Grid item xs>
            <Link
              to={publicPaths.forgottenPassword}
              className={classes.formLink}
            >
              Forgot password?
            </Link>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

export default LogInForm;
