import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { createCustomer } from "../../api/customers";
import AppForm from "../../components/AppForm";
import AppFormikSubmitButton from "../../components/AppForm/AppFormikSubmitButton";
import AppModal from "../../components/AppModal";
import InformationTextBox from "../../components/InformationTextBox/InformationTextBox";
import { actions } from "../../store";
import { successToast } from "../../toast";
import CustomerCreateForm from "../Form/CustomerCreateForm";
import { CustomerCreateFormValues } from "../Form/CustomerCreateFormValues";
import { CustomerAddressFields, CustomerFields } from "../Form/Fields";

interface CreateCustomerModalProps {
  open: boolean;
  handleClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const CreateCustomerModal: React.FC<CreateCustomerModalProps> = ({
  open,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleAddCustomer = async (
    customer: CustomerCreateFormValues
  ): Promise<void> => {
    const customerDto = {
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.email.trim(),
      company: customer.company,
      phoneNumber: customer.phoneNumber,
      address: {
        addressLine1: customer.addressLine1,
        addressLine2: customer.addressLine2,
        addressLine3: customer.addressLine3,
        town: customer.town,
        postcode: customer.postcode,
      },
    };

    const response = await createCustomer(customerDto);

    if (!response.isError) {
      dispatch(actions.customers.fetchAll());
      handleClose();
      successToast("Customer has been created");
    }
  };

  return (
    <>
      <AppModal isOpen={open} onClose={handleClose} title="Create Customer">
        <InformationTextBox>
          Please fill out the customer details:
        </InformationTextBox>
        <CustomerCreateForm onSubmit={handleAddCustomer}>
          <AppForm>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CustomerFields.firstName autoFocus />
              </Grid>
              <Grid item xs={6}>
                <CustomerFields.lastName />
              </Grid>
              <Grid item xs={6}>
                <CustomerFields.email />
              </Grid>
              <Grid item xs={6}>
                <CustomerFields.phoneNumber />
              </Grid>
              <Grid item xs={12}>
                <CustomerFields.company />
              </Grid>
              <Grid item xs={12}>
                <CustomerAddressFields.addressLine1 />
              </Grid>
              <Grid item xs={12}>
                <CustomerAddressFields.addressLine2 />
              </Grid>
              <Grid item xs={12}>
                <CustomerAddressFields.addressLine3 />
              </Grid>
              <Grid item xs={6}>
                <CustomerAddressFields.town />
              </Grid>
              <Grid item xs={6}>
                <CustomerAddressFields.postcode />
              </Grid>
            </Grid>
          </AppForm>
          <div className={classes.buttonGroup}>
            <AppFormikSubmitButton>Add Customer</AppFormikSubmitButton>
          </div>
        </CustomerCreateForm>
      </AppModal>
    </>
  );
};

export default CreateCustomerModal;
