import { addMinutes } from "date-fns";
import { v4 as uuid } from "uuid";
import { ApiRecurringBookableSlot } from "../api/models/RecurringBookings";
import { RecurringBookableSlot } from "../models/RecurringBookableSlot";

export const mapFromApiRecurringBookableSlot = (
  apiRecurringBookableSlot: ApiRecurringBookableSlot
): RecurringBookableSlot => {
  return {
    ...apiRecurringBookableSlot,
    id: uuid(),
    start: apiRecurringBookableSlot.bookableSlot
      ? apiRecurringBookableSlot.bookableSlot.start.toString()
      : undefined,
    end: apiRecurringBookableSlot.bookableSlot
      ? addMinutes(
          new Date(apiRecurringBookableSlot.bookableSlot.start),
          apiRecurringBookableSlot.bookableSlot.durationMinutes
        ).toISOString()
      : undefined,
  };
};
