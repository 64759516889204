import { Route, Switch, Redirect } from "react-router-dom";
import { RequireResourceUserRole } from "../../auth";
import { MainLayout } from "../../components/MainLayout";
import DashboardPage from "../../pages/Dashboard";
import ResourceAccountPage from "../../pages/ResourceAccount";
import ResourceUserCalendarPage from "../../pages/ResourceUserCalendar";

export const resourcePaths = {
  home: "/",
  account: "/account",
  dashboard: "/dashboard",
};

// Only available to resource user role
const ResourceRoutes: React.FC = () => {
  return (
    <RequireResourceUserRole>
      <MainLayout>
        <Switch>
          <Route path={resourcePaths.home} exact>
            <ResourceUserCalendarPage />
          </Route>
          <Route path={resourcePaths.account} exact>
            <ResourceAccountPage />
          </Route>
          <Route path={resourcePaths.dashboard}>
            <DashboardPage />
          </Route>
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </MainLayout>
    </RequireResourceUserRole>
  );
};

export default ResourceRoutes;
