import { AppBarChart } from "../../components/AppBarChart";
import { DashboardSummary } from "../../models/Dashboard";
import { minutesToHours } from "date-fns";
import { useWindowSize } from "../../components/MainLayout/WindowSize";
import { formatNumber } from "../../numericalUtils";
import { makeStyles } from "@material-ui/core";

interface DashboardPanelGraphProps {
  summaryDetails?: DashboardSummary;
}

export const useStyles = makeStyles((theme) => ({
  componentContainer: {
    display: "flex",
    flexDirection: "column",
  },
  chartContainer: {
    height: 190,
    gridColumn: "1 / span 3",
  },
  statButtonContainer: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    display: "flex",
    gridGap: theme.spacing(2),
  },
  statButton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 2,
    padding: theme.spacing(2),
    flex: 1,
  },
  statButtonValue: {
    fontSize: "1.5rem",
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    marginBottom: 8,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  statButtonLabel: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.main,
  },
  ecoStatContainer: {
    backgroundColor: "#E7F5C8",
    padding: theme.spacing(4),
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    gridColumn: "1 / span 2",
    [theme.breakpoints.up("md")]: {
      gridColumn: "2",
    },
    [theme.breakpoints.up("lg")]: {
      width: 224,
    },
    [theme.breakpoints.down("xs")]: {
      gridColumn: "1 / span 3",
    },
  },
  ecoStatTitle: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    textTransform: "uppercase",
    fontWeight: theme.typography.fontWeightMedium,
    "& img": {
      width: 24,
      height: "auto",
      marginRight: theme.spacing(1),
    },
  },
  ecoStatItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  ecoStatItemValue: {
    fontSize: 30,
    fontWeight: theme.typography.fontWeightBold,
  },
  ecoStatItemLabel: {
    fontWeight: theme.typography.fontWeightBold,
  },
  bookingStatContainer: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      width: 165,
    },
    [theme.breakpoints.down("xs")]: {
      gridColumn: "1 / span 3",
    },
  },
  bookingStatTitle: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.875rem",
    textTransform: "uppercase",
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
  bookingStatItemContainer: {
    display: "flex",
    flex: 1,
    gridGap: theme.spacing(4),
    alignItems: "center",
    justifyContent: "space-around",
  },
  bookingStatItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  bookingStatItemValue: {
    fontSize: "1rem",
    fontWeight: theme.typography.fontWeightBold,
  },
  bookingStatItemLabel: {
    fontWeight: 500,
  },
}));

const DashboardPanelGraph: React.FC<DashboardPanelGraphProps> = ({
  summaryDetails,
}) => {
  const { width } = useWindowSize();
  const classes = useStyles();

  const totalMinutes = summaryDetails
    ? summaryDetails.totalBookedMinutes +
      summaryDetails.totalTravelTimeMinutes +
      summaryDetails.totalIdleTimeMinutes
    : 0;

  const getPercentageOfTotalMinutes = (minutes: number): number => {
    return minutes <= 0
      ? 0
      : Math.max(1, Math.floor((minutes / totalMinutes) * 100));
  };

  return (
    <div className={classes.componentContainer}>
      <div className={classes.chartContainer}>
        <AppBarChart
          data={[
            {
              name: "Working",
              value: minutesToHours(summaryDetails?.totalBookedMinutes ?? 0),
              color: "#93D700",
              percent: getPercentageOfTotalMinutes(
                summaryDetails?.totalBookedMinutes ?? 0
              ),
            },
            {
              name: "Travel",
              value: minutesToHours(
                summaryDetails?.totalTravelTimeMinutes ?? 0
              ),
              color: "#4570B6",
              percent: getPercentageOfTotalMinutes(
                summaryDetails?.totalTravelTimeMinutes ?? 0
              ),
            },
            {
              name: "Idle",
              value: minutesToHours(summaryDetails?.totalIdleTimeMinutes ?? 0),
              color: "#F7B213",
              percent: getPercentageOfTotalMinutes(
                summaryDetails?.totalIdleTimeMinutes ?? 0
              ),
            },
          ]}
        />
      </div>
      {width > 768 ? (
        <div className={classes.statButtonContainer}>
          <div className={classes.statButton}>
            <div className={classes.statButtonValue}>
              {formatNumber(summaryDetails?.totalRevenue ?? 0, {
                currency: true,
              })}
            </div>
            <div className={classes.statButtonLabel}>Total income</div>
          </div>
          <div className={classes.statButton}>
            <div className={classes.statButtonValue}>
              {formatNumber(summaryDetails?.averageRevenuePerDay ?? 0, {
                currency: true,
              })}
            </div>
            <div className={classes.statButtonLabel}>Daily average</div>
          </div>
          <div className={classes.statButton}>
            <div className={classes.statButtonValue}>
              {formatNumber(summaryDetails?.averageRevenuePerHour ?? 0, {
                currency: true,
              })}
            </div>
            <div className={classes.statButtonLabel}>Hourly average</div>
          </div>
        </div>
      ) : (
        <div className={classes.bookingStatContainer}>
          <div className={classes.bookingStatTitle}>General</div>
          <div className={classes.bookingStatItemContainer}>
            <div className={classes.bookingStatItem}>
              <div className={classes.bookingStatItemValue}>
                {formatNumber(summaryDetails?.totalRevenue ?? 0, {
                  currency: true,
                })}
              </div>
              <div className={classes.bookingStatItemLabel}>Total income</div>
            </div>
            <div className={classes.bookingStatItem}>
              <div className={classes.bookingStatItemValue}>
                {formatNumber(summaryDetails?.averageRevenuePerDay ?? 0, {
                  currency: true,
                })}
              </div>
              <div className={classes.bookingStatItemLabel}>Daily average</div>
            </div>
            <div className={classes.bookingStatItem}>
              <div className={classes.bookingStatItemValue}>
                {formatNumber(summaryDetails?.averageRevenuePerHour ?? 0, {
                  currency: true,
                })}
              </div>
              <div className={classes.bookingStatItemLabel}>Hourly average</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardPanelGraph;
