import { HttpClientResponse } from ".";
import {
  DashboardResourceSummary,
  DashboardSummary,
} from "../models/Dashboard";
import {
  ApiStandardResponse,
  buildApiUrl,
  doErrorToastIfRequired,
  httpClient,
  mapHttpClientListResponse,
  mapHttpClientResponse,
} from "./common";
import {
  ApiDashboardResourceSummary,
  ApiDashboardSummary,
} from "./models/Dashboard";
import {
  mapFromApiDashboardSummaryDetails,
  mapFromApiResourceSummary,
} from "../modelMappings/Dashboard";
import { ApiListResponse } from "./common/ApiListResponse";

export const getDashboardBooking = async (
  start: string,
  end: string
): Promise<HttpClientResponse<DashboardSummary>> => {
  const queryParameters = [`startDate=${start}`, `endDate=${end}`];
  const url = buildApiUrl(
    `api-admin/dashboard/booking-summary?${queryParameters.join("&")}`
  );
  const response = await httpClient.getRequest<
    ApiStandardResponse<ApiDashboardSummary>
  >(url);

  doErrorToastIfRequired(response);

  return mapHttpClientResponse(response, mapFromApiDashboardSummaryDetails);
};

export const getResourceSummary = async (
  start: string,
  end: string,
  resourceIds: number[]
): Promise<HttpClientResponse<ApiListResponse<DashboardResourceSummary>>> => {
  const queryParameters = [
    `startDate=${start}`,
    `endDate=${end}`,
    `resourceIds=${resourceIds}`,
  ];
  const url = buildApiUrl(
    `api-admin/dashboard/resource-summary?${queryParameters.join("&")}`
  );
  const response = await httpClient.getRequest<
    ApiListResponse<ApiDashboardResourceSummary>
  >(url);

  doErrorToastIfRequired(response);

  return mapHttpClientListResponse(response, mapFromApiResourceSummary);
};
