import { createPreconfiguredAppFormikTextField } from "../../components/AppForm";

export { default } from "./ForgottenPasswordForm";

export const ForgottenPasswordFields = {
  Email: createPreconfiguredAppFormikTextField({
    name: "email",
    label: "Email address",
  }),
};
