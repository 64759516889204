import { IconButton, makeStyles } from "@material-ui/core";
import { Edit as EditIcon, Error as WarningIcon } from "@material-ui/icons";
import { useState, useEffect } from "react";
import AppCheckbox from "../../../components/AppForm/AppCheckbox";
import {
  addXMinutesToDate,
  formatDateDateOnly,
  formatTimeOnly,
  isDateUTC,
} from "../../../dateFormatters";
import ecoIconGrey from "../../../eco-icon-grey.svg";
import { RecurringBookableSlot } from "../../../models/RecurringBookableSlot";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    border: `1px solid ${theme.palette.grey[400]}`,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flexWrap: "wrap",
    [theme.breakpoints.up("sm")]: {
      flexWrap: "nowrap",
    },
  },
  containerNotAvailable: {
    border: `1px solid ${theme.palette.secondaryYellow.main}`,
  },
  checkboxContainer: {
    flex: "none",
  },
  dateAndEditContainer: {
    display: "flex",
    flex: "none",
    width: "100%",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      flex: 1,
      width: "auto",
      marginLeft: theme.spacing(1),
    },
  },
  index: {
    flex: "none",
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: theme.spacing(4),
  },
  date: {
    fontWeight: theme.typography.fontWeightBold,
  },
  timeSlotRange: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.grey[300],
    height: theme.spacing(4.5),
    color: theme.palette.grey[700],
    maxWidth: 290,
    "& > svg": {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "auto",
    },
  },
  timeSlotRangeUnavailable: {
    backgroundColor: theme.palette.secondaryYellow.main,
    color: theme.palette.common.white,
  },
  editIcon: {
    marginLeft: theme.spacing(4),
    alignSelf: "center",
    "& svg": {
      fill: "url(#BrandGradient)",
    },
  },
}));

interface BookingFormRecurringSlotSelectionItemProps {
  isSelected: boolean;
  isManual: boolean;
  slot: RecurringBookableSlot;
  index: number;
  bookingDate: Date;
  onEdit: (id: string) => void;
  onToggle: (id: string) => void;
}

const BookingFormRecurringSlotSelectionItem: React.FC<BookingFormRecurringSlotSelectionItemProps> =
  ({ isSelected, isManual, slot, index, bookingDate, onEdit, onToggle }) => {
    const classes = useStyles();
    const [date, setDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");

    const dateIsAvailable = !slot.noSlotsDate;

    useEffect(() => {
      if (slot.noSlotsDate) {
        setDate(formatDateDateOnly(new Date(slot.noSlotsDate)));
      }

      if (dateIsAvailable && slot.bookableSlot) {
        let newDate = new Date(slot.bookableSlot.start);

        if(!isDateUTC(bookingDate) && isDateUTC(newDate) && isManual){
          newDate = addXMinutesToDate(60, newDate);
        }
        else if(isDateUTC(bookingDate) && !isDateUTC(newDate) && isManual){
          newDate = addXMinutesToDate(-60, newDate);
        }

        setDate(formatDateDateOnly(newDate));
        setStartTime(formatTimeOnly(newDate, true));

        setEndTime(
          formatTimeOnly(
            addXMinutesToDate(
              slot.bookableSlot.durationMinutes,
              newDate
            )
          )
        );
      }
    }, [slot, dateIsAvailable]);

    return (
      <div
        className={`${classes.container} ${
          !dateIsAvailable && classes.containerNotAvailable
        }`}
      >
        <div className={classes.checkboxContainer}>
          <AppCheckbox
            label=""
            checked={isSelected}
            onChange={() => onToggle(slot.id)}
            disabled={!dateIsAvailable}
          />
        </div>
        <div className={classes.index}>{index}</div>
        <div className={classes.date}>{date}</div>
        <div className={classes.dateAndEditContainer}>
          {dateIsAvailable && (
            <div className={classes.timeSlotRange}>
              {slot.bookableSlot?.isEcoSlot && (
                <img src={ecoIconGrey} alt="Eco Slot Icon" />
              )}
              <span>
                {startTime} - {endTime}
              </span>
            </div>
          )}
          {!dateIsAvailable && (
            <div
              className={`${classes.timeSlotRange} ${classes.timeSlotRangeUnavailable}`}
            >
              <WarningIcon />
              <span>Date unavailable - edit to change date</span>
            </div>
          )}
          {!isManual && (
            <IconButton
              className={classes.editIcon}
              onClick={() => onEdit(slot.id)}
            >
              <EditIcon fill="url(#BrandGradient)" />
            </IconButton>
          )}
        </div>
      </div>
    );
  };

export default BookingFormRecurringSlotSelectionItem;
