import { makeStyles } from "@material-ui/core";
import { CustomerDetails } from "../models/Customers";
import Tooltip from "@material-ui/core/Tooltip";

interface CustomerDetailOverviewProps {
  customerDetail?: CustomerDetails;
  setTab: (tab: number) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridGap: theme.spacing(3),
    marginTop: theme.spacing(2),
    color: theme.palette.myCustomColor?.main
  },
  clickable: {
    cursor: "pointer",
    marginBottom: theme.spacing(1),
  },
  emailCell: {
    maxWidth: theme.spacing(1),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  key: {
    fontWeight: theme.typography.fontWeightBold,
  },
  value: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valueEmail: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "pointer",
  },
  noCompany: {
    opacity: 0.45,
  },
}));

const CustomerDetailOverview: React.FC<CustomerDetailOverviewProps> = ({
  customerDetail,
  setTab,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.key}>Name</div>
      <div className={classes.value}>
        {customerDetail?.firstName} {customerDetail?.lastName}
      </div>
      <div className={classes.key}>Company name</div>
      {customerDetail?.company ? (
        <div className={classes.value}>{customerDetail?.company}</div>
      ) : (
        <div className={classes.noCompany}>Amend details for company name</div>
      )}
      <div className={classes.key}>Email</div>
      <Tooltip title={customerDetail?.email || ""} placement="right">
        <div className={classes.valueEmail}>{customerDetail?.email}</div>
      </Tooltip>
      <div className={classes.key}>Phone</div>
      <div className={classes.value}>{customerDetail?.phoneNumber}</div>
      <div className={classes.key}>Address</div>
      <div className={classes.value}>
        {customerDetail && (
          <>
            {customerDetail.addresses.length >= 2 ? (
              <div onClick={() => setTab(2)} className={classes.clickable}>
                Multiple
              </div>
            ) : (
              <div>
                {customerDetail?.addresses[0].addressLine1} <br />
                {customerDetail?.addresses[0].addressLine2} <br />
                {customerDetail?.addresses[0].town} <br />
                {customerDetail?.addresses[0].postcode}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CustomerDetailOverview;
