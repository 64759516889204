import { Grid } from "@material-ui/core";
import { BookingFormStyles } from "../BookingFormStyles";
import { BookingFields } from "../BookingFormFields";

const BookingFormCustomerCreation: React.FC = () => {
  const classes = BookingFormStyles();
 
  return (
      <div>
          <Grid>
            <Grid item>
            <h3 className={classes.stepContainerTitle}>First name</h3>
              <BookingFields.firstname autoFocus />
            </Grid>
            <br/>
            <Grid item>
            <h3 className={classes.stepContainerTitle}>Last name</h3>
              <BookingFields.lastname />
            </Grid>
            <br/>
            <h3 className={classes.stepContainerTitle}>Email</h3>
            <Grid item>
              <BookingFields.email />
            </Grid>
            <br/>
            <h3 className={classes.stepContainerTitle}>Phone number</h3>
            <Grid item>
              <BookingFields.phone />
            </Grid>
            <br/>
            <h3 className={classes.stepContainerTitle}>Company name</h3>
            <Grid item>
              <BookingFields.companyName />
            </Grid>
          </Grid>
      </div>
  );
};

export default BookingFormCustomerCreation;

