import { DashboardSummary } from "../../models/Dashboard";
import { formatNumber } from "../../numericalUtils";
import { makeStyles } from "@material-ui/core";

interface DashboardPanelStatsProps {
  summaryDetails?: DashboardSummary;
}

export const useStyles = makeStyles((theme) => ({
  componentContainer: {
    display: "flex",
    flexDirection: "column",
    gridGap: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridGap: theme.spacing(4),
      gridTemplateColumns: "1fr 1fr 1fr",
    },
  },
  chartContainer: {
    flex: 1,
    minHeight: 270,
    gridColumn: "1 / span 3",
  },
  statButtonContainer: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    display: "grid",
  },
  statButton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 2,
  },
  statButtonValue: {
    fontSize: "1.2rem",
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    marginBottom: 8,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  statButtonLabel: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.main,
  },
  ecoStatContainer: {
    backgroundColor: "#E7F5C8",
    padding: theme.spacing(4),
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gridGap: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      gridColumn: "2",
    },
    [theme.breakpoints.up("lg")]: {
      flex: 1,
    },
  },
  ecoStatItemsContainer: {
    flexDirection: "row",
    display: "flex",
    alignItems: "flex-start",
    gridGap: theme.spacing(2),
    justifyContent: "space-around",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  ecoStatTitle: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    textTransform: "uppercase",
    fontWeight: theme.typography.fontWeightMedium,
    "& img": {
      width: 24,
      height: "auto",
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "0.875rem",
    },
  },
  ecoStatItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    flex: 1,
  },
  ecoStatItemValue: {
    fontSize: 30,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  ecoStatItemLabel: {
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
    },
  },
  bookingStatContainer: {
    backgroundColor: theme.palette.common.white,
    display: "flex",
    width: "100%",
    padding: theme.spacing(2),
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      flex: 1,
    },
  },
  bookingStatTitle: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    textTransform: "uppercase",
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
      marginBottom: theme.spacing(2),
    },
  },
  bookingStatItemContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      alignItems: "flex-start",
    },
    [theme.breakpoints.down("lg")]: {
      gridGap: theme.spacing(1),
    },
  },
  bookingStatItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    flex: 1,
  },
  bookingStatItemValue: {
    fontSize: 20,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
  bookingStatItemLabel: {
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
      fontSize: "0.75rem",
    },
  },
}));

const DashboardPanelStats: React.FC<DashboardPanelStatsProps> = ({
  summaryDetails,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.componentContainer}>
      <div className={classes.bookingStatContainer}>
        <div className={classes.bookingStatTitle}>Income</div>
        <div className={classes.bookingStatItemContainer}>
          <div className={classes.bookingStatItem}>
            <div className={classes.bookingStatItemValue}>
              {formatNumber(summaryDetails?.totalCompleteRevenue ?? 0, {
                currency: true,
              })}
            </div>
            <div className={classes.bookingStatItemLabel}>Complete</div>
          </div>
          <div className={classes.bookingStatItem}>
            <div className={classes.bookingStatItemValue}>
              {formatNumber(summaryDetails?.totalConfirmedRevenue ?? 0, {
                currency: true,
              })}
            </div>
            <div className={classes.bookingStatItemLabel}>Confirmed</div>
          </div>
          <div className={classes.bookingStatItem}>
            <div className={classes.bookingStatItemValue}>
              {formatNumber(summaryDetails?.totalProvisionalRevenue ?? 0, {
                currency: true,
              })}
            </div>
            <div className={classes.bookingStatItemLabel}>Provisional</div>
          </div>
          <div className={classes.bookingStatItem}>
            <div className={classes.bookingStatItemValue}>
              {formatNumber(summaryDetails?.totalCancelledRevenue ?? 0, {
                currency: true,
              })}
            </div>
            <div className={classes.bookingStatItemLabel}>
              Lost due to cancellation
            </div>
          </div>
        </div>
      </div>
      <div className={classes.ecoStatContainer}>
        <div className={classes.ecoStatTitle}>
          <img src="/assets/images/eco-icon.svg" alt="Eco icon" />
          Carbon Savings
        </div>
        <div className={classes.ecoStatItemsContainer}>
          <div className={classes.ecoStatItem}>
            <div className={classes.ecoStatItemValue}>
              {formatNumber(summaryDetails?.totalTravelMinutesSaved ?? 0, {
                fractionDigits: 0,
              })}
            </div>
            <div className={classes.ecoStatItemLabel}>
              Driving minutes saved
            </div>
          </div>
          <div className={classes.ecoStatItem}>
            <div className={classes.ecoStatItemValue}>
              {formatNumber(summaryDetails?.totalKgCo2PerMileSaved ?? 0, {
                fractionDigits: 0,
              })}
            </div>
            <div className={classes.ecoStatItemLabel}>Kg CO2 reduced</div>
          </div>
        </div>
      </div>
      <div className={classes.bookingStatContainer}>
        <div className={classes.bookingStatTitle}>Bookings</div>
        <div className={classes.bookingStatItemContainer}>
          <div className={classes.bookingStatItem}>
            <div className={classes.bookingStatItemValue}>
              {summaryDetails?.totalCompleteBookingsCount}
            </div>
            <div className={classes.bookingStatItemLabel}>Complete</div>
          </div>
          <div className={classes.bookingStatItem}>
            <div className={classes.bookingStatItemValue}>
              {summaryDetails?.totalConfirmedBookingsCount}
            </div>
            <div className={classes.bookingStatItemLabel}>Confirmed</div>
          </div>
          <div className={classes.bookingStatItem}>
            <div className={classes.bookingStatItemValue}>
              {summaryDetails?.totalProvisionalBookingsCount}
            </div>
            <div className={classes.bookingStatItemLabel}>Provisional</div>
          </div>
          <div className={classes.bookingStatItem}>
            <div className={classes.bookingStatItemValue}>
              {summaryDetails?.totalCancelledBookingsCount}
            </div>
            <div className={classes.bookingStatItemLabel}>Cancelled</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPanelStats;
