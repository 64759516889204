import AppFormikServiceSelect, {
  AppFormikServiceSelectProps,
} from "./AppFormikServiceSelect";

export type CreatePreconfiguredAppFormServiceSelectOptions = {
  name: string;
  label: string;
  pleaseSelectText: string;
};

export type CreatePreconfiguredAppFormServiceSelectProps<T> = Omit<
  AppFormikServiceSelectProps<T>,
  "name" | "label" | "pleaseSelectText"
>;

export const createPreconfiguredAppFormServiceSelect = ({
  name,
  label,
  pleaseSelectText,
}: CreatePreconfiguredAppFormServiceSelectOptions) => {
  const PreConfiguredAppFormServiceSelect = (
    restOfProps: CreatePreconfiguredAppFormServiceSelectProps<any>
  ) => (
    <AppFormikServiceSelect
      {...restOfProps}
      label={label}
      name={name}
      pleaseSelectText={pleaseSelectText}
    />
  );

  return PreConfiguredAppFormServiceSelect;
};

export default createPreconfiguredAppFormServiceSelect;
