import {
  Action,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { ApiBookableSlotDemoDto } from "../api/models/BookableSlotsDemo";
import { chunkSizeMins } from "../config/algorithmDemoConstants";
import { AlgorithmDemoResource } from "../models/AlgorithmDemo/Resource";
import { AlgorithmDemoTimeSlot } from "../models/AlgorithmDemo/TimeSlot";

const sliceName = "algorithmDemo";

const slotsAdaptor = createEntityAdapter<AlgorithmDemoTimeSlot>({
  selectId: (_) => _.startTotalMins,
});

const resourceAdaptor = createEntityAdapter<AlgorithmDemoResource>({
  selectId: (_) => _.id,
});

type State = {
  resources: EntityState<AlgorithmDemoResource>;
  resourceSlots: {
    [resourceId: number]: EntityState<AlgorithmDemoTimeSlot>;
  };
  offeredSlots: ApiBookableSlotDemoDto[];
  selectedSlotResourceId?: number;
  selectedSlotStartTotalMins?: number;
  logs: string;
};

export const initialState: State = {
  resources: resourceAdaptor.getInitialState(),
  resourceSlots: {},
  offeredSlots: [],
  logs: "",
};

const resourceEntitySelectors = resourceAdaptor.getSelectors();
const slotEntitySelectors = slotsAdaptor.getSelectors();

export type ResetPayload = {
  startTotalMins: number;
  endTotalMins: number;
  resources: AlgorithmDemoResource[];
};

export type ToggleBookSlotPayload = {
  resourceId: number;
  startTotalMins: number;
};

export type SetSelectedSlotPayload = {
  resourceId: number;
  slotStartTotalMins: number;
};

export type SetResourcePostcodePayload = {
  resourceId: number;
  newPostcode: string;
};

const buildSlots = (startTotalMins: number, endTotalMins: number) => {
  const slots: AlgorithmDemoTimeSlot[] = [];

  for (let time = startTotalMins; time < endTotalMins; time += chunkSizeMins) {
    slots.push({
      startTotalMins: time,
      durationMins: chunkSizeMins,
      isBooked: false,
      postcode: "",
    });
  }

  return slots;
};

const slice = createSlice({
  name: "algorithmDemo",
  initialState,
  reducers: {
    reset: (
      state,
      {
        payload: { startTotalMins, endTotalMins, resources },
      }: PayloadAction<ResetPayload>
    ) => {
      const existingResourceIds = resourceEntitySelectors.selectIds(
        state.resources
      );

      existingResourceIds.forEach(
        (resourceId) => delete state.resourceSlots[resourceId as never]
      );
      resourceAdaptor.removeAll(state.resources);

      resources.forEach((resource) => {
        resourceAdaptor.addOne(state.resources, resource);

        state.resourceSlots[resource.id] = slotsAdaptor.addMany(
          slotsAdaptor.getInitialState(),
          buildSlots(startTotalMins, endTotalMins)
        );
      });
    },
    toggleBookSlot: (
      state,
      {
        payload: { resourceId, startTotalMins },
      }: PayloadAction<ToggleBookSlotPayload>
    ) => {
      const slot = state.resourceSlots[resourceId].entities[startTotalMins];

      if (slot) {
        state.selectedSlotResourceId = undefined;
        state.selectedSlotStartTotalMins = undefined;

        if (slot.isBooked) {
          slot.isBooked = false;
          const nextSlot =
            state.resourceSlots[resourceId].entities[
              startTotalMins + chunkSizeMins
            ];
          nextSlot && nextSlot.isBooked && (nextSlot.postcode = slot.postcode);
          slot.postcode = "";
        } else {
          slot.isBooked = true;
          const nextSlot =
            state.resourceSlots[resourceId].entities[
              startTotalMins + chunkSizeMins
            ];
          if (nextSlot && nextSlot.isBooked) {
            slot.postcode = nextSlot.postcode;
            nextSlot.postcode = "";
          }
        }
      }
    },
    setBookableSlots: (
      state,
      { payload }: PayloadAction<ApiBookableSlotDemoDto[]>
    ) => {
      state.offeredSlots = payload;
    },
    setLogs: (state, { payload }: PayloadAction<string>) => {
      state.logs = payload;
    },
    setSelectedSlot: (
      state,
      {
        payload: { resourceId, slotStartTotalMins },
      }: PayloadAction<SetSelectedSlotPayload>
    ) => {
      state.selectedSlotResourceId = resourceId;
      state.selectedSlotStartTotalMins = slotStartTotalMins;
    },
    setSelectedSlotPostcode: (
      state,
      { payload: newPostcode }: PayloadAction<string>
    ) => {
      const { selectedSlotResourceId, selectedSlotStartTotalMins } = state;

      if (!selectedSlotResourceId || !selectedSlotStartTotalMins)
        throw new Error("Expected a slot to be selected.");

      const slot =
        state.resourceSlots[selectedSlotResourceId].entities[
          selectedSlotStartTotalMins
        ];

      if (slot) slot.postcode = newPostcode;
    },
    setResourcePostcode: (
      state,
      {
        payload: { resourceId, newPostcode },
      }: PayloadAction<SetResourcePostcodePayload>
    ) => {
      const resource = state.resources.entities[resourceId];

      if (!resource) return;

      resource.postcode = newPostcode;
    },
  },
});

export const { reducer, name } = slice;

export const actions = {
  reset: (
    resources: AlgorithmDemoResource[],
    startTotalMins: number,
    endTotalMins: number
  ): Action => slice.actions.reset({ resources, startTotalMins, endTotalMins }),
  toggleBookSlot: (resourceId: number, startTotalMins: number): Action =>
    slice.actions.toggleBookSlot({ resourceId, startTotalMins }),
  setBookableSlots: (slots: ApiBookableSlotDemoDto[]): Action =>
    slice.actions.setBookableSlots(slots),
  setLogs: (logs: string): Action => slice.actions.setLogs(logs),
  setSelectedSlot: (resourceId: number, slotStartTotalMins: number): Action =>
    slice.actions.setSelectedSlot({ resourceId, slotStartTotalMins }),
  setSelectedSlotPostcode: (newPostcode: string): Action =>
    slice.actions.setSelectedSlotPostcode(newPostcode),
  setResourcePostcode: (resourceId: number, newPostcode: string): Action =>
    slice.actions.setResourcePostcode({ resourceId, newPostcode }),
};

type RootState = {
  [sliceName]: State;
};

const selectSliceState = (state: RootState) => state[sliceName];

export const selectors = {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  makeSlotsForResourceSelector: (resourceId: number) =>
    createSelector(selectSliceState, (state) => {
      if (!state.resourceSlots[resourceId]) return [];
      const slots = slotEntitySelectors.selectAll(
        state.resourceSlots[resourceId]
      );

      return slots.map((slot, index) => {
        const previousSlot = index == 0 ? null : slots[index - 1];

        return {
          ...slot,
          previousSlotIsBooked:
            previousSlot === null ? false : previousSlot.isBooked,
        };
      });
    }),
  selectedSlotPostcode: createSelector(selectSliceState, (state) => {
    const { selectedSlotResourceId, selectedSlotStartTotalMins } = state;

    if (!selectedSlotResourceId || !selectedSlotStartTotalMins) return "";

    const slot =
      state.resourceSlots[selectedSlotResourceId].entities[
        selectedSlotStartTotalMins
      ];

    return slot ? slot.postcode : "";
  }),
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  makeSelectResourceById: (resourceId: number) =>
    createSelector(selectSliceState, (state) =>
      resourceEntitySelectors.selectById(state.resources, resourceId)
    ),
  allOfferedSlots: createSelector(
    selectSliceState,
    (state) => state.offeredSlots
  ),
  logs: createSelector(selectSliceState, (state) => state.logs),
  selectedSlot: createSelector(selectSliceState, (state) => {
    const { selectedSlotResourceId, selectedSlotStartTotalMins } = state;

    if (
      selectedSlotResourceId === undefined ||
      selectedSlotStartTotalMins === undefined
    )
      return null;

    return (
      state.resourceSlots[selectedSlotResourceId].entities[
        selectedSlotStartTotalMins
      ] || null
    );
  }),
  selectedResource: createSelector(selectSliceState, (state) => {
    if (state.selectedSlotResourceId === undefined) return null;

    return state.resources.entities[state.selectedSlotResourceId] || null;
  }),
  allBookingsHavePostcodes: createSelector(selectSliceState, (state) => {
    let result = true;
    state.resources.ids.forEach((resourceId) => {
      const resourceSlots = slotEntitySelectors.selectAll(
        state.resourceSlots[resourceId as never]
      );

      const everySlotBookedSlotHasPostcode = resourceSlots.every(
        (slot, index) => {
          if (!slot.isBooked) return true;
          const slotShouldHavePostcode =
            index === 0 || !resourceSlots[index - 1].isBooked;
          return slotShouldHavePostcode ? slot.postcode : true;
        }
      );

      if (!everySlotBookedSlotHasPostcode) result = false;
    });
    return result;
  }),
};
