import {
  ApiCustomerBookingSummary,
  ApiCustomerDetails,
} from "../api/models/Customer";
import { CustomerBookingSummary, CustomerDetails } from "../models/Customers";

export const mapFromApiCustomerDetails = (
  apiBooking: ApiCustomerDetails
): CustomerDetails => ({
  ...apiBooking,
  previousBookings: apiBooking.previousBookings.map(
    mapFromApiCustomerBookingDetails
  ),
  upcomingBookings: apiBooking.upcomingBookings.map(
    mapFromApiCustomerBookingDetails
  ),
});

export const mapFromApiCustomerBookingDetails = (
  apiBooking: ApiCustomerBookingSummary
): CustomerBookingSummary => ({
  ...apiBooking,
  start: new Date(apiBooking.start),
  end: new Date(apiBooking.end),
});
